<template>
    <div class="governancePageDetail">
        <div class="governancePageDetail__back">
            <div class="wrap">
                <router-link :to="$route.path"><span style="display: inline-block; margin-right: 5px;">&#8592;</span> {{ $t('investor_governance_detail_back') }}</router-link>
            </div>
        </div>
        <div v-if="pageData">
            <div v-if="pageData.status=='success'" class="governance-page cms-content">
                <div id="pb-content-detail" v-html="pageData.body"></div>
            </div>
            <missing v-else />
        </div>
    </div>
</template>
<style>
.governancePageDetail{
    .governancePageDetail__back{
        padding-bottom: 0!important;
        @media screen and (min-width: 1000px){
            width:90%;
            max-width: 90%;
            margin: auto;
            /* padding: 2em var(--layout-fullwidth-container-xpadding); */
            padding: 2em 15px 1em 15px;
        }
        @media screen and (max-width: 1000px){
            width: 100%;
            max-width: 100%;
            padding: 2em var(--layout-container-x-padding);
        }
    }
    ._clip_table{
        /* 修正有時表格邊框會不見的問題 */
        margin-left: 1px;
    }
}
</style>
<script>
import apibase from "@/static/apibase.js";
import axios from 'axios';
import {setMetaInfo} from "@/libs/js/metaInfo.js";
import Missing from "../pages/Missing.vue";


export default {
        data() {
            return{
                metaData:{},
                pageData:null,
            }
        },

        metaInfo() {
            return this.metaData
        },

        components: {
            "missing": Missing
        },
        methods:{
            fetchPage(){
                var _this=this;
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'pages/detail?uri='+this.$route.query.detail,
                })
                .then((response) => {
                    this.pageData=response.data;
                    this.$nextTick(function () {
                        setTimeout(function() {
                            window.pagebuilder_clip_script_init();
                            setMetaInfo(_this, undefined, undefined, undefined, undefined);
                            _this.$fixPbContent('pb-content-detail');
                            _this.$toggleLoading(false);
                        }, 100);
                    });
                })
            }
        },
        mounted() {
            this.fetchPage();
        }
    };
</script>