<template>
  <div v-if="category != null">
    <section class="text-center">
      <div class="container text-left">
        <div class="col mb-4">
          <h4 v-html="$t('recommends_products')">相關商品</h4>

          <div>
            <recommend-product-filter 
              v-if="navProducts != null" 
              :options="categoryArr"
              :parentCurrCategory="category"
              :firstLeval="true"
              :renderFunc="fetchProducts"
              ref="recommendProductFilter"
            ></recommend-product-filter>
          </div>
        </div>

        <!-- <div class="row"> -->
          <div class="col">
            <!-- <div v-if="products != null" id="js-recommend-product" class="swiper recommend-swiper" ref="recommendSwiper"> -->
            <div id="js-recommend-product" class="swiper recommend-swiper" ref="recommendSwiper">
              <div class="swiper-wrapper">
                <div v-for="item in products" class="swiper-slide">
                  <div class="product">
                    <router-link :to="{ name: 'product-detail', params: { category: item.category_slug, slug: item.slug } }">
                      <div>
                        <img :src="item.cover" :alt="item.name" />
                      </div>
                      <h5>{{ item.name }}</h5>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>

              <div class="swiper-pagination"></div>
            </div>

            <!-- <div v-else>
              <div v-html="$t('msg_no_data')"></div>
            </div> -->
          </div>
        <!-- </div> -->
      </div>
    </section>
  </div>
</template>
<script>
  import recommendProductFilter from "../components/recommendProductFilter.vue";
  import { mapState } from "vuex";
  import { mapActions } from "vuex";
  import apibase from "@/static/apibase.js";
  import axios from 'axios';

	export default {
    props: ['parentCategory'],

    data() {
      return {
        category: this.parentCategory,
        
        categoryArr: {
          children: []
        },

        tmpCategoryArr: this.parentCategory.split(','),
        products: null,
        swiper: null,
        treeInit: true
      }
    },

    components: {
      'recommend-product-filter': recommendProductFilter
    },

    methods: {
      ...mapActions(["setLang", "fetchNavigation"]),

      fetchProducts(cate) {
        axios({
          method: 'get',
          baseURL: apibase,
          url: 'product/items'
        })
        .then((response) => {
          let category = this.category.toLowerCase();
          let c = cate.toLowerCase();
          let cArr = (c == 'all') ? category.split(',') : c.split(',');

          this.products = response.data.rows.filter(o => {
            let v = o.category_slug.toLowerCase();
            let vArr = v.split(',');

            let cArrResult = null;

            cArrResult = cArr.map(cItem => {
              return vArr.some(arrVal => cItem === arrVal);
            });

            if (c == 'all') 
            {
              return cArrResult.some(v => v === true);
            }
            else 
            {
              return cArrResult.every(v => v === true);
            }
          });

          if (this.products.length == 0) this.products = null;
        })
        .catch((error) => {
          console.log(error);
        });
      },

      treeFilter(array, text) {
        const getNodes = (result, object) => {
          let objectUri = object.uri.toLowerCase();
          let cateText = text.toLowerCase();

          if (objectUri === cateText) {
            result.push(object);
            return result;
          }

          return result;
        };

        return array.reduce(getNodes, []);
      },

      slugMerge(tree, uri) {
        return tree.map(node => {   
          if (uri != undefined) node.uri = (node.uri != '') ? `${ uri },${ node.uri }` : uri;

          if (node.children != null) 
          {
            node.children.unshift({ children: null, title: this.$t('ui_allCategory'), uri: '' });
            this.slugMerge(node.children, node.uri);
          }
          
          return node;
        });
      },

      initTreeData() {
        let navProductsTmp = JSON.parse(JSON.stringify([...this.navProducts]));
        let tmpCateArr = [];

        this.tmpCategoryArr.map(item => {
          let result = this.treeFilter(navProductsTmp, item)[0];

          if (result != undefined) 
          {
            tmpCateArr.push(result);
          }
        });

        this.categoryArr.children = this.slugMerge(tmpCateArr);
        this.categoryArr.children.unshift({ children: null, title: this.$t('ui_allCategory'), uri: 'all' });
      },

      initSwiper() {
        this.swiper = new Swiper('#js-recommend-product', {
          spaceBetween: 30,

          navigation: {
            nextEl: '#js-recommend-product .swiper-button-next',
            prevEl: '#js-recommend-product .swiper-button-prev',
          },

          pagination: {
            el: '#js-recommend-product .swiper-pagination',
            clickable: true
          },

          breakpoints: {
            640: {
              slidesPerView: 2,
              spaceBetween: 30
            },

            768: {
              slidesPerView: 3,
              spaceBetween: 30
            }
          }
        });
      }
    },

    computed: {
      ...mapState(['navProducts'])
    },

    mounted() {
      this.fetchNavigation();
    },

    updated() {
      if (this.products != null) 
      {
        if (this.swiper != null) 
        {
          this.swiper.destroy();
          this.initSwiper();
        }
        else 
        {
          this.initSwiper();
        }
      }

      if (this.treeInit && this.navProducts != null) {
        this.treeInit = false;
        this.initTreeData();
      }
    }
  };
</script>