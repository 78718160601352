<template>
	<div v-if="items.length>0">
        <section class="text-center ">
            <div class="container">
                <p class="text-left lead" v-html="$t('recommends_news')">相關新聞</p>
                <div class="row">
                    <div class="col">
                        <div class="slider" data-paging="true" >
                            <ul class="slides is-draggable" >
                                <div class="flickity-slider">
                                    <li class="col-md-4 col-12 slide is-selected" v-for="item in items">
                                        <div class="news">
                                            <router-link :to="{name:'news-detail',params:{slug:item.slug}}">
                                                <img :alt="item.name" :src="item.cover"/>
                                            </router-link>
                                            <router-link class="block" :to="{name:'news-detail',params:{slug:item.slug}}">
                                                <div>
                                                    <h5>{{item.name}}</h5>
                                                </div>
                                            </router-link>
                                        </div>
                                    </li>
                                </div>
                                <ol class="flickity-page-dots"></ol>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
	export default {
        props:['items'],
        // mounted() {
        //     this.$window.initAll();
        // },
    };
</script>