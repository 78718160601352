<template>
    <div v-if="pageData">
        <div v-if="pageData.status=='success'" class="governance-page cms-content">
            <div id="pb-content" v-html="pageData.body"></div>
            <!-- <div :class="['subPage',$route.params.detailSlug?'active':'']"> -->
            <div :class="['subPage',this.$route.query.detail?'active':'']">
                <!-- <router-view></router-view> -->
                <governancePageDetail v-if="this.$route.query.detail"/>
            </div>
        </div>
        <missing v-else />
    </div>
</template>


<style>
.governance-page{
    .subPage{
        position: fixed;
        top:0;
        left: 100%;
        /* left: 275px; */
        width: calc(100% - 275px);
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        background: white;
        z-index: 100;
        transition: all 0.3s;
        &.active{
            left: 275px;
        }
    }
    @media screen and (max-width: 991px){
        .subPage{
            width: 100%;
            height: calc(100% - 47px);
            top:47px;
            &.active{
                left: 0;
            }
        }
    }
}
</style>


<script>
import apibase from "@/static/apibase.js";
import axios from 'axios';
// import { mapState, mapActions } from "vuex";
import {setMetaInfo} from "@/libs/js/metaInfo.js";
import Missing from "../pages/Missing.vue";
import GovernancePageDetail from "../pages/Governance_page_detail.vue";
import loading from '../components/Loading.vue'
export default {
        data() {
            return{
                metaData:{},
                pageData:null,
            }
        },
        watch:{
            '$route' (to, from){
                if(to.path!=from.path){
                    this.fetchPage();
                }
                if(from.query.detail && !to.query.detail){
                    this.$toggleLoading(false);
                }
            }
        },

        methods:{
            fetchPage(){
                var _this=this;
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'pages/detail?uri=governance/'+_this.$route.params.slug,
                })
                .then((response) => {
                    _this.pageData=response.data;
                    _this.$nextTick( () => {
                        setTimeout(() =>{
                            _this.$window.pagebuilder_clip_script_init();
                            setMetaInfo(this, undefined, undefined, undefined, undefined);
                            _this.initDetailLink();
                            _this.$fixPbContent('pb-content');
                            // document.querySelector('.main-container').scrollTo(0, 0);
                            this.$scrollTop();
                            this.$toggleLoading(false);
                        }, 100);
                    });
                })
            },

            initDetailLink(){
                document.querySelectorAll('.governance-page a').forEach((link) =>{
                    link.addEventListener('click', (e) => {
                        if(link.href.indexOf('?detail=')>=0){
                            e.preventDefault();
                            var _detailLink=link.href.split('?detail=')[1];
                            this.$router.push({query:{detail:_detailLink}});
                            this.$toggleLoading(true);
                        }
                    });
                });
            },
        },
        

        metaInfo() {
            return this.metaData
        },

        components: {
            "missing": Missing,
            "governancePageDetail": GovernancePageDetail
        },
        created() {
            // this.parseRoute();
            this.fetchPage();
        },
    };
</script>