<template>
	<div v-if="categories&&items">
		<section class="cover imagebg text-left space--sm" data-overlay="6">
            <div class="background-image-holder">
                <img alt="Blue world map with international connections" :src="$data.siteUrl + '/img/banner-location.jpg'" />
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1 v-html="$t('location_banner_title')">我們的全球據點</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('nav_location')">全球據點</li>
                </ol>
            </div>
        </section>
        <section v-for="category in categories.rows">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="text-block py-4 mb-0">
                            <h4 class="font-weight-normal mb-0">{{category.category_name}}</h4>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="row flex-wrap">
                            <div class="col-12 col-sm-6 py-4" v-for="item,index,key in filteredRows(items.rows,category.category_slug,'category_slug')">
                                <h5 class="mb-0">{{item.name}}</h5>
                                <p>
                                    <span v-if="item.address" v-html="item.address" class="cms-content"></span><br/>
                                    <span v-if="item.phone">T <a :href="'tel:'+item.phone">{{item.phone}}</a></span><br/>
                                    <span v-if="item.fax">F {{item.fax}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    
</template>
<script>
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    // import {readCookie} from "@/libs/js/cookie.js"
    import {setMetaInfo} from "@/libs/js/metaInfo.js"
	export default {
        data:function(){
            return {
                items:null,
                categories:null,
                meta:{
                    title: this.$t('page_title_global_location'),
                    desc: 'Needs to connect with SINBON? Headquarters, production sites and sales office for you to get in touch with SINBON.',
                    img: location.protocol +'//'+ location.host + '/img/banner-location.jpg',
                    // keywords: 'portable ev charger, ev charger manufacturers'
                },
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        methods:{
            filteredRows: function(rows,cc,slug){
                this.$toggleLoading(false);
                var c = cc.toLowerCase();
                return ( c.trim() !== 'all' ) ?
                    rows.filter(function(d){ return d[slug].toLowerCase().indexOf(c) > -1; }) :
                    rows;
            },
            fetchItems:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'csrlocation/items',
                    
                })
                    .then(function (response) {
                        _this.items=response.data;
                        // _this.$window.initAll();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchItemsCategory:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'csrlocation/categories',
                    
                })
                    .then(function (response) {
                        _this.categories=response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        created() {
            // obj, title, url, desc, img
            setMetaInfo( this, this.meta.title, undefined, this.meta.desc, this.meta.img,false,true);
            this.fetchItemsCategory();
            this.fetchItems();
            // this.$window.initAll()
        }
    };

</script>