<template>
	<div>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h1 class="h2 mb-1">{{ $t('nav_privacy') }}</h1>
                        <small class="type--fine-print">{{ $t('privacy_date') }}</small>
                        <hr class="short">
                        <p class="sub-lead">{{ $t('privacy_param_0') }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('privacy_title_1') }}</h2>
                        <h3 class="h4 text-left mb-1">{{ $t('privacy_title_1_1') }}</h3>
                        <p class="sub-lead">{{ $t('privacy_param_1_1') }}</p>
                        <h3 class="h4 text-left mb-1">{{ $t('privacy_title_1_2') }}</h3>
                        <p class="sub-lead">{{ $t('ebike_privacy_param_1_2') }}</p>
                        <h3 class="h4 text-left mb-1">{{ $t('privacy_title_1_3') }}</h3>
                        <p class="sub-lead">{{ $t('privacy_param_1_3') }}</p>
                        <h3 class="h4 text-left mb-1">{{ $t('privacy_title_1_4') }}</h3>
                        <p class="sub-lead">{{ $t('privacy_param_1_4') }}</p>
                        <h3 class="h4 text-left mb-1">{{ $t('privacy_title_1_5') }}</h3>
                        <p class="sub-lead">{{ $t('privacy_param_1_5') }}</p>
                        <h3 class="h4 text-left mb-1">{{ $t('privacy_title_1_6') }}</h3>
                        <p class="sub-lead">{{ $t('privacy_param_1_6') }}</p>
                        <p class="sub-lead">{{ $t('privacy_param_1') }}</p>
                    </div>
                </div>
            </div>
        </section>


        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('privacy_title_2') }}</h2>
                        <p class="sub-lead mb-1">{{ $t('privacy_param_2') }}</p>
                        <ul class="bullets">
                            <li v-for="item in $t('privacy_param_2_list')">{{ item }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>


        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('privacy_title_3') }}</h2>
                        <p class="sub-lead mb-1">{{ $t('privacy_param_3') }}</p>
                        <ul class="bullets">
                            <li v-for="item in $t('privacy_param_3_list')">{{ item }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>


        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('privacy_title_4') }}</h2>
                        <p class="sub-lead">{{ $t('privacy_param_4') }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('privacy_title_5') }}</h2>
                        <p class="sub-lead">{{ $t('privacy_param_5') }}</p>
                    </div>
                </div>
            </div>
        </section>


        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('privacy_title_6') }}</h2>
                        <p class="sub-lead">{{ $t('privacy_param_6') }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('privacy_title_7') }}</h2>
                        <p class="sub-lead">{{ $t('privacy_param_7') }}</p>
                    </div>
                </div>
            </div>
        </section>


        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('privacy_title_8') }}</h2>
                        <p class="sub-lead">{{ $t('privacy_param_8') }}</p>
                    </div>
                </div>
            </div>
        </section>


        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('privacy_title_9') }}</h2>
                        <p class="sub-lead">{{ $t('privacy_param_9_1') }}</p>
                        <p class="sub-lead mb-1">{{ $t('privacy_param_9_2') }}</p>
                        <ul class="bullets">
                            <li v-for="item in $t('privacy_param_9_list')" v-html="item"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('privacy_title_10') }}</h2>
                        <p class="sub-lead mb-1">{{ $t('privacy_param_10') }}</p>
                        <ul class="bullets">
                            <li v-for="item in $t('privacy_param_10_list')">{{ item }}</li>
                        </ul>
                        <p class="sub-lead">{{ $t('privacy_param_10_1') }}</p>
                        <p class="sub-lead">{{ $t('privacy_param_10_2') }}</p>
                    </div>
                </div>
            </div>
        </section>


        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('privacy_title_11') }}</h2>
                        <p class="sub-lead">{{ $t('privacy_param_11') }}</p>
                    </div>
                </div>
            </div>
        </section>


        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('privacy_title_12') }}</h2>
                        <p class="sub-lead">{{ $t('privacy_param_12') }}</p>
                    </div>
                </div>
            </div>
        </section>


        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('privacy_title_13') }}</h2>
                        <p class="sub-lead">{{ $t('privacy_param_13_1') }}</p>
                        <p v-for="item in $t('privacy_param_13_2')" class="sub-lead mb-1" v-html="item"></p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    export default {
        data:function(){
            return {
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        created() {
            $('head').append('<meta property="fb:app_id" content="449286906210219">')
            setMetaInfo( this, undefined, undefined, undefined, undefined )
            // this.$window.initAll();
        }
    };

</script>