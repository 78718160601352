<template>
	<div>
		<section class="cover imagebg text-left space--sm" data-overlay="6">
            <div class="background-image-holder">
                <img alt="background" :src="$data.siteUrl + '/img/banner-solution.jpg'" />
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1 v-html="$t('solution_banner_title')">提供各品牌電子產品<br/>與完善的服務支援</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="pt-5 pb-3 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('nav_solution')">解決方案</li>
                </ol>
            </div>
        </section>
        
        <section>
            <div class="container">
                <div>
                    <div class="masonry-filter-container text-center d-flex align-items-center" v-if="categories">
                        <span v-html="$t('ui_category')">類別</span>
                        <div class="masonry-filter-group">
                            <div class="masonry-filter-holder">
                                <div class="masonry__filters">
                                    <ul>
                                        <li :class="currCategory=='all'?'active':''" @click="updateCategory('all')" v-html="$t('ui_allCategory')">所有類別</li>
                                        <li :class="currCategory==category.category_slug?'active':''" v-for="category,key in categories" @click="updateCategory(category.category_slug)">{{category.category_name}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    {{ categoryDes }}
                    <hr>
                    <!--end masonry filters-->
                    <div class="masonry__container row masonry--active" v-if="items&&categories">
                        <div v-for="item,index in filteredRows(items,currCategory,'category_slug').slice(pageStart, pageStart + itemPerPage)" class="masonry__item col-lg-4 col-md-6 ">
                            <div class="project-thumb">
                                <router-link :to="{ name:'solution-detail', params: { category:item.category_slug,slug:item.slug } }" class="solution__item">
                                    <img class="border--round" :alt="item.name" :src="item.cover">
                                    <h4 class="solution__articleTitle">{{item.name}}</h4>
                                    <span v-if="item.sample_name" class="solution__sampleName">{{item.sample_name}}</span>
                                    <div v-if="item.meta_desc" class="solution__desc">
                                        <div v-html="item.meta_desc" class="solution__desc-content"></div>
                                    </div>
                                    <button class="btn btn--sm solution__btn">Learn more</button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <pagination v-model="currPage" v-on:getTotalPage="totalPage" :itemPerPage="itemPerPage" :currPage="currPage" :itemTotal="totalItemNum" v-if="items" />
        </section>
    <channel-box v-bind:blocks="['contact','contact-custom','global-location']"/>
</div>
    
</template>
<script>
    import channelBox from "../components/ChannelBox.vue";
    import pagination from "../components/Pagination.vue";
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    import {setMetaInfo} from "@/libs/js/metaInfo.js"
    import { mapState, mapActions } from "vuex";
	export default {
        data:function(){
            return{
                items:null,
                currPage:this.$route.query.page,
                itemPerPage:12,
                categories:null,
                currCategory:this.$route.query.cate,
                categoryDes:null,
                meta:{
                    title: 'Solutions | DFx | Design for Excellence | SINBON​',
                    desc: 'SINBON is the most trusted partner for custom cable assemblies and connectivity solutions.',
                    img: location.protocol +'//'+ location.host + '/img/home-rv.jpg',
                    // keywords: 'manufacturing solutions, manufacturing services, electrical layout, flexible production, electrical design services, low volume pcb assembly, flexible pcb assembly'
                },
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        computed:{
            ...mapState(['solutionCate','solutionItem']),
            totalItemNum:function(){
                return this.filteredRows(this.items,this.currCategory,'category_slug').length;
            },
            pageStart: function(){
                return (this.currPage - 1) * this.itemPerPage;
            },
        },
        methods:{
            ...mapActions(['setSelectedContactTopic','fetchSolutionCategory','fetchSolutionItems']),
            filteredRows: function(rows,cc,slug){
                this.$toggleLoading(false);
                this.$scrollTop();
                var c = cc.toLowerCase();
                return ( c.trim() !== 'all' ) ?
                    rows.filter(function(d){ return d[slug].toLowerCase().indexOf(c) > -1; }) :
                    rows;
            },
            updateCategory:function(c){
                if(c==this.currCategory) return;
                this.currCategory=c;
                this.currPage=1;
                this.$router.push({name:'solution',query:{cate:this.currCategory,page:this.currPage}});
            },
            findCate:function( arr ){
                return arr.category_slug === this.$route.query.cate;
            },
            setDesc:function(){
                let routeName = this.$route.name
                if( this.$route.query.cate != 'all'){
                    var found = this[routeName+'Cate'].find( this.findCate )
                    this.categoryDes = found.category_description;
                    setMetaInfo( this, found.meta_title ,undefined, found.meta_desc, found['thumb_'+routeName+'_category'] );
                }else{
                    this.categoryDes = '';
                    setMetaInfo( this, undefined, undefined, undefined, undefined );
                }
            },
            totalPage:function(maxPage){
                if ( this.currPage > maxPage ) {
                    this.currPage = maxPage
                    this.$router.push({name:'solution',query:{cate: this.currCategory, page: this.currPage }});
                }
            }
        },
        created(){
            if(this.currPage==undefined && this.currCategory==undefined){
                console.log('created 不轉');
                // this.$router.push({name:'solution',query:{cate:'all',page:'1'}});
            }
            this.fetchSolutionCategory();
            this.fetchSolutionItems();
            this.setSelectedContactTopic('solutions');
        },
        watch: {
            '$route' (to, from) {
                if(this.$route.query.cate!=this.currCategory){
                    this.currCategory=this.$route.query.cate;
                    this.currPage=1;
                }
                if( this.currPage == undefined ) this.currPage = 1;
                this.$router.push({name:'solution',query:{ cate: this.currCategory, page: this.currPage }});
                this.setDesc();
            },
            solutionCate(val){
                this.categories = val;
                this.setDesc();
            },
            solutionItem(val){
                this.items = val;
                // this.$window.initAll();
            }
        },
        components: {
            "channel-box": channelBox,
            "pagination": pagination
        }
    };

</script>