<template>
    <div class="loading" :class="isLoading? '' : 'hide' ">
        <div class="loadingio-spinner-spin-691pp06jkkw">
            <div class="ldio-0a5p4h1r00f">
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";

export default {
    data() {
        return {}
    },
    computed:{
        // ...mapState(['isLoading']),
        isLoading(){
            return this.$store.state.isLoading
        },
    },
    methods: {
        ...mapActions(["setLoadingStatus",])
    },
    mounted(){
        // this.setLoadingStatus(false);
    }
};
</script>