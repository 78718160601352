<template>
	<div v-if="detail" class="newsDetail">
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs mb-0">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li>
                        <router-link :to="{name:'news',query:{cate:'all',page:'1'}}" v-html="$t('nav_news')">最新消息</router-link>
                    </li>
                    <li>
                        <router-link :to="{name:'news',query:{cate:detail.category_slug,page:'1'}}">{{detail.category_name}}</router-link>
                    </li>
                    <li>{{detail.name}}</li>
                </ol>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-9">
                        <h1 class="h2">{{detail.name}}</h1>
                        <em>{{detail.article_date}}</em>
                    </div>
                </div>
            </div>
        </section>
        <section v-if="detail.photo" class="text-center">
            <div class="container">
                <img class="w-100 border--round" :src="detail.photo" :alt="detail.name"/>
            </div>
        </section>
        <section>
            <div v-if="detail.description_pb" class="container" v-html="detail.description_pb"></div>
            <div v-else class="container sub-lead" v-html="detail.description"></div>
        </section>

        <!--pb content-->
        <!--<div v-if="previewPB == 'true' && detail.description_pb" v-html="detail.description_pb"></div> -->

        <recommend-news :items="detail.related_news" v-if="detail.related_news"/>
        <channel-box v-bind:blocks="['solution','contact','global-location']"/>
    </div>
</template>
<style lang="less">
.newsDetail ._block_section{
    padding-top: 0!important;
}
.newsDetail ._block_container{
    width: 100%!important;
    max-width: 100%!important;
    margin: 0!important;
    padding: 0!important;
}
</style>
<script>
    import channelBox from "../components/ChannelBox.vue";
    import recommendNews from "../components/recommend-news.vue";
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    // import {readCookie} from "@/libs/js/cookie.js";

	export default {
        data() {
            return {
                slug: this.$route.params.slug,
                detail: null,
                metaData: {},
                // previewPB: this.$route.query.previewPB
            }
        },

        metaInfo() {
            return this.metaData
        },

        methods: {
            fetchData() {
                let _this = this;
                

                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'news/detail/' + _this.$route.params.slug,
                    
                })
                .then((response) => {
                    // console.log(response)                    
                    _this.detail = response.data.row;
                    // _this.$window.initAll();

                    var metaTitle;

                    if (_this.detail.meta_title.trim() != '' && _this.detail.meta_title != undefined) metaTitle = _this.detail.meta_title;
                    else metaTitle = _this.detail.name;

                    var metaDesc;

                    if (_this.detail.meta_desc.trim() != '' && _this.detail.meta_desc != undefined) metaDesc = _this.detail.meta_desc;
                    else metaDesc = _this.detail.description;

                    setMetaInfo(_this, metaTitle, window.location.href, metaDesc, _this.detail.photo);
                    
                    if(_this.detail.description_pb){
                        setTimeout(() => {
                            _this.$window.pagebuilder_clip_script_init();
                        }, 100);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            },

            parseRoute() {
                this.detail = null;

                if (!this.$route.params.slug) this.$router.push({ name: 'news', query: { cate: 'all', page: '1' } });

                this.fetchData();
            }
        },

        created() {
            this.parseRoute();
        },

        // mounted() {
        //     this.$nextTick(function () {
        //         setTimeout(function() {
        //             window.pagebuilder_clip_script_init();
        //             // _global.init();
        //         }, 3000);
        //     });
        // },

        components: {
            "channel-box": channelBox,
            "recommend-news": recommendNews
        },

        watch: {
            '$route' (to, from) {
                this.parseRoute();
            }
        }
    };
</script>