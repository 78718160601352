<template>
    <div>
        <div v-if="displayContent==='data'">
            <section class="cover imagebg text-left space--sm" data-overlay="6">
                <div class="background-image-holder">
                    <img alt="background" :src="$data.siteUrl + '/img/banner-investors.jpg'" />
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 col-lg-7">
                            <h1 v-html="$t('investor_newsroom_banner_title')">投資人專區</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section class="py-5 breadcrumbsSection">
                <div class="container">
                    <ol class="breadcrumbs">
                        <li>
                            <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                        </li>
                        <li v-html="$t('nav_investor')">投資人專區</li>
                        <li v-html="$t('nav_investorNewsroom')">投資人訊息</li>
                    </ol>
                </div>
            </section>
            <section>
                <div class="container">
                    <div class="clearfix">
                        <a :href="stockLink" target="_blank" class="float-right mb-3" v-html="$t('investor_newsroom_stocklink')">前往查詢股價(公司代號：3023)</a>
                    </div>
                    <ul class="accordion accordion--right accordion-2">
                        <li class="boxed border mb-3" :id="item.id" v-for="item,index,key in items.rows.slice(pageStart, pageStart + itemPerPage)">
                            <h3 class="accordion__title w-100 border-0 mb-0 pt-0 pb-0 mr-0 ml-0 row align-middle position-relative">
                                <span class="col-12 col-md-3 h4 mb-0 pl-0 pr-0">{{item.publish_on}}</span>
                                <span class="col-12 col-md-9 h4 mb-0 pl-0 pr-0 font-weight-bold">{{item.name}}</span>
                            </h3>
                            <div class="accordion__content ">
                                <hr class="w-100 mt-4 mb-3" />
                                <p v-html="item.description"></p>
                            </div>
                        </li>
                    </ul>
                </div> 
                <pagination v-model="currPage" v-on:getTotalPage="totalPage" :currPage="currPage" :itemPerPage="itemPerPage" :itemTotal="totalItemNum" v-if="items"/>
            </section>
        </div>
        <missing v-else-if="displayContent==='404'" />
    </div>
</template>
<script>
    import pagination from "../components/Pagination.vue";
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    import { mapGetters } from "vuex";
    // import {readCookie} from "@/libs/js/cookie.js"
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    import Missing from "../pages/Missing.vue";
	export default {
        data:function(){
            return{
                items:null,
                currPage:this.$route.params.page,
                itemPerPage:12,
                stockLink:'',
                metaData:{},
                displayContent:'default'
            }
        },
        metaInfo() {
            return this.metaData
        },
        computed:{
            totalItemNum:function(){
                return this.items.rows.length
            },
            pageStart: function(){
                return (this.currPage - 1) * this.itemPerPage;
            },
        },
        methods:{
            ...mapGetters(["getLang"]),
            fetchItems:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'invnews/items',
                    
                })
                .then(function (response) {
                    _this.items=response.data;
                    _this.displayContent = 'data';
                    for(var i in _this.items.rows){
                        _this.items.rows[i]['timeStamp']=new Date(_this.items.rows[i].publish_on);
                    }
                    _this.items.rows=_this.items.rows.slice().sort(function (a, b) {
                        return a['timeStamp'] > b['timeStamp'] ? -1 : 1;
                    });
                    _this.$nextTick(function(){
                        _this.$themeInitAccordion();
                    })
                    // _this.$window.initAll();
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            totalPage:function(maxPage){
                var _this=this;
                if ( this.currPage > maxPage ) {
                    _this.displayContent = '404';
                }
            }
        },
        created(){
            if(this.currPage==undefined)this.currPage='1';
            this.$router.push({name:'investors-news',hash:this.$route.hash,params:{page:this.currPage}});
            this.fetchItems();
            
            let lang=this.getLang();
            if(lang=='tw'||lang=='cn') this.stockLink='https://tw.stock.yahoo.com/q/bc?s=3023';
            else this.stockLink='https://finance.yahoo.com/quote/3023.TW?p=3023.TW';
            setMetaInfo( this, undefined, undefined, undefined, undefined )
        },
        components: {
            "pagination": pagination,
            "missing": Missing
        },
        watch: {
            '$route' (to, from) {
                $('.accordion > li').removeClass('active');
            }
        }
    };

</script>