<template>
	<div class="capability">
		<section class="cover imagebg text-left space--sm" data-overlay="6">
            <div class="background-image-holder">
                <img alt="Manufacturing Solutions & Services - Flexible Production & Design - SINBON" :src="$data.siteUrl + '/img/banner-capability.jpg'" />
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1 v-html="$t('capability_title')">完整製造流程<br/>來自信邦</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('nav_capability')">能力與服務</li>
                </ol>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-12 col-lg-9">
                        <h2 class="h3" v-html="$t('capability_section_1_title_1')">信邦的製造流程涵蓋了初始設計概念、完整的內部製作流程，以及產品驗證，落實了真正的垂直整合。</h2>
                    </div>
                    <div class="col-12">
                        <strong class="h5" v-html="$t('capability_section_1_desc_1')">我們為客戶提供的服務包含：</strong>
                        <ul class="bullets mt-3 row mx-0">
                            <li class="col-12 col-md-6 p-0" v-html="$t('capability_section_1_list_1')">機械及電機設計</li>
                            <li class="col-12 col-md-6 p-0" v-html="$t('capability_section_1_list_2')">軟韌體設計</li>
                            <li class="col-12 col-md-6 p-0" v-html="$t('capability_section_1_list_3')">零組件與供應鏈管理</li>
                            <li class="col-12 col-md-6 p-0" v-html="$t('capability_section_1_list_4')">原線材設計與製造</li>
                            <li class="col-12 col-md-6 p-0" v-html="$t('capability_section_1_list_5')">線材組裝</li>
                            <li class="col-12 col-md-6 p-0" v-html="$t('capability_section_1_list_6')">包覆射出成型</li>
                            <li class="col-12 col-md-6 p-0" v-html="$t('capability_section_1_list_7')">塑膠射出成型</li>
                            <li class="col-12 col-md-6 p-0" v-html="$t('capability_section_1_list_8')">印刷電路板組裝</li>
                            <li class="col-12 col-md-6 p-0" v-html="$t('capability_section_1_list_9')">天線設計與製造</li>
                            <li class="col-12 col-md-6 p-0" v-html="$t('capability_section_1_list_10')">模治具設計與製造</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <video class="fluid-component w-100 m-0" :ref="'automationVideo'">
                <source src="https://sinbon-webinar.oss-cn-hongkong.aliyuncs.com/SINBON%20Automation.mp4"
                data-fluid-hd
                title="SINBON Automation"
                type='video/mp4'/>
            </video>
        </section>
        <!-- <section class="cover imagebg text-left space--md" data-overlay="6">
            <div class="background-image-holder">
                <img alt="background" :src="$data.siteUrl + '/img/banner-capability-2.jpg'" />
            </div>
        </section> -->
        <section>
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-12 col-lg-6">
                        <h2 class="h3" v-html="$t('capability_section_2_title_1')">垂直整合幫助我們全球的客戶取得顯著優勢</h2>
                    </div>
                    <div class="col-12 col-lg-6">
                        <ul class="bullets">
                            <li v-html="$t('capability_section_2_list_1')">品質管制</li>
                            <li v-html="$t('capability_section_2_list_2')">縮短上市時間</li>
                            <li v-html="$t('capability_section_2_list_3')">管控零組件供應鏈</li>
                            <li v-html="$t('capability_section_2_list_4')">為客戶提供一站式服務</li>
                            <li v-html="$t('capability_section_2_list_5')">具有廣泛的製造能力</li>
                            <li v-html="$t('capability_section_2_list_6')">降低客戶的成本</li>
                            <li v-html="$t('capability_section_2_list_7')">快速生產以滿足高需求量</li>
                            <li v-html="$t('capability_section_2_list_8')">彈性製造</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="bg--secondary">
            <div class="container">
                <p class="boxed boxed--border" v-html="$t('capability_acc_desc_1')">At SINBON value engineering aims to not only remove unwanted costs but to improve the overall quality of a project. Our dedicated project management team work closely with you throughout all stages of your product development to understand your strategic objectives and provide design solutions tailored to your needs, this is achieved through the DFM process.  SINBON’s robust NPI procedure and manufacturing integration provides best in class products and services.</p>
                <ul class="accordion accordion-2 accordion--right mt-5">
                    <li>
                        <div class="accordion__title">
                            <span class="h3 mb-0 py-3" v-html="$t('capability_acc_1_title')">工程服務</span>
                        </div>
                        <div class="accordion__content article__body">
                            <div class="row">
                                <p class="col-12" v-html="$t('capability_acc_1_text_1')">信邦提供廣泛的加值工程服務，補足了我們端到端的供應鏈策略，並因此降低成本，加快客戶產品上市速度。首先，信邦的設計和工程團隊為產品開發過程創造豐厚價值，從一開始的概念發想到細節和原型設計，再到批量生產，幾乎涵蓋了完整設計週期。</p>
                                <img class="col-12 mt-3" alt="Image" :src="$data.siteUrl + '/img/banner-capability-acc-1.jpg'" />
                                
                                <h4 class="col-12 h4 mt-5" v-html="$t('capability_acc_1_text_2')">可製造性導向設計（DFM）</h4>
                                <p class="col-12" v-html="$t('capability_acc_1_text_3')">信邦的工程師會依照可製造性導向設計原則(Design for Manufacturing, DFM) 來確保製造過程一致，並在設計階段找出並解決任何潛在的問題。可製造性導向設計原則能降低許多成本，因為透過標準化、外購零件、模組設計和標準設計功能，我們可以用更少的零件快速組裝產品。在打樣階段之前，信邦一定會提交可製造性導向設計評估報告，以確保達成客戶的需求，以及可行的工程方案。除了機械及電機設計之外， 可製造性導向設計同時也包含 3D 建模。</p>

                                <h4 class="col-12 h4 mt-5" v-html="$t('capability_acc_1_text_4')">機械及電機設計</h4>
                                <p class="col-12 my-3" v-html="$t('capability_acc_1_text_5')">信邦旗下擁有 200 多名研發工程師，協助我們將客戶的設計概念推向市場。我們包含的服務有：</p>
                                <ul class="col-12 bullets row mx-0">
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_1_text_6')">機械設計與建模</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_1_text_7')">硬體設計</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_1_text_8')">射頻設計與建模</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_1_text_9')">3D列印產品原型</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_1_text_10')">印刷電路板佈線設計</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_1_text_11')">嵌入式系統設計</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_1_text_12')">韌體設計與整合</li>
                                </ul>
                                
                                <h4 class="col-12 h4 mt-5" v-html="$t('capability_acc_1_text_13')">新產品導入與專案管理</h4>
                                <p class="col-12 mt-3 my-3" v-html="$t('capability_acc_1_text_14')">信邦提供全面的新產品導入（NPI）流程，結合專業工程和製造服務，以及專門的專案管理支援。我們在共同的時區內工作並使用共通的語言，確保在整個製造過程中提供卓越的客戶服務與技術支援。</p>
                                <p class="col-12 my-3" v-html="$t('capability_acc_1_text_15')">我們致力於提供可靠的電子解決方案，重視給客戶即時且深入的協助，我們能夠協助您將設計概念轉化為市場銷售產品。</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="accordion__title">
                            <span class="h3 mb-0 py-3" v-html="$t('capability_acc_2_title')">原線材設計與製造</span>
                        </div>
                        <div class="accordion__content article__body">
                            <div class="row">
                                <p class="col-12 my-3" v-html="$t('capability_acc_2_text_1')">信邦量身訂作的線材解決方案包含原線材設計與製造。</p>
                                <p class="col-12 my-3" v-html="$t('capability_acc_2_text_2')">從單芯線到龐雜的多芯結構，包含了低壓線和同軸線。我們自營的原線材製造使我們能夠開發生產小量電纜，以達成少量多樣的線材組裝製造目標。我們的絕緣與被覆材料能包覆所有物件並耐高、低溫。上述材料包含：</p>
                                <ul class="col-12 bullets row mx-0 my-3">
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_2_text_3')">PVC &amp; PE HYTREL FOAM-PE SR-PVC</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_2_text_4')">LSZH</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_2_text_5')">Cross linked PVC &amp; PE TPU</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_2_text_6')">FEP &amp; PTFE ETFE PFA</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_2_text_7')">Silicone EPDM EPR</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_2_text_8')">TPU &amp; TPE</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="accordion__title">
                            <span class="h3 mb-0 py-3" v-html="$t('capability_acc_3_title')">線材組裝設計與製造</span>
                        </div>
                        <div class="accordion__content article__body">
                            <div class="row">
                                <p class="col-12" v-html="$t('capability_acc_3_text_1')">信邦追求高品質的工程製造能力，使我們能夠因應任何市場或用途，設計並製造出特定的線材。我們的產品在惡劣環境中依然可運作，並維持穩固的機電完整性。若有產量方面的需求，信邦能夠設計並配置全自動生產線，以確保品質一致，及提供實惠的價格。</p>
                                
                                <h4 class="col-12 h4 mt-5" v-html="$t('capability_acc_3_text_2')">少量多樣</h4>
                                <p class="col-12" v-html="$t('capability_acc_3_text_3')">我們在許多市場上體認到少量多樣的需求，為確保能提供客戶最具成本效益的解決方案，我們採用組合式工作站以保證在整個精簡的製造過程中減少浪費。</p>
                                
                                <h4 class="col-12 h4 mt-5" v-html="$t('capability_acc_3_text_4')">自動化</h4>
                                <p class="col-12" v-html="$t('capability_acc_3_text_5')">若有產量方面的需求，信邦能夠設計並配置全自動生產線。自動化對品質、提高生產率、增加材料利用效率、增強安全性，以及縮短工廠交貨時間等，皆能產生重大影響。</p>
                                
                                <h4 class="col-12 h4 mt-5" v-html="$t('capability_acc_3_text_6')">我們的技術包含：</h4>
                                <ul class="col-12 bullets row mx-0">
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_3_text_7')">焊接 - 全自動與高頻（14AWG-40AWG）</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_3_text_8')">超音波焊接（1.5mm2  -  50mm2）</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_3_text_9')">電磁干擾(EMI)及電磁相容性(EMC) 解決方案</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_3_text_10')">自動裁線、剝線和壓接</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_3_text_11')">壓接 － 自動（18AWG  -  28AWG）液壓（4/0 AWG  -  6 AWG）</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_3_text_12')">連接器設計與製造</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_3_text_13')">印刷電路板組裝整合</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_3_text_14')">編織</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_3_text_15')">雷射刻印</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_3_text_16')">客製化線材接頭設計</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_3_text_17')">包覆射出成型（內外模低壓成型）</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_3_text_18')">自動化灌膠</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_3_text_19')">半自動化成型</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="accordion__title">
                            <span class="h3 mb-0 py-3" v-html="$t('capability_acc_4_title')">印刷電路板組裝設計與製造</span>
                        </div>
                        <div class="accordion__content article__body">
                            <div class="row">
                                <p class="col-12" v-html="$t('capability_acc_4_text_1')">信邦為各種市場應用設計及製造印刷電路板，主要目標為提供客戶完整的解決方案。我們結合設計、製造、產品信賴度以及生命週期測試，並遠超過客戶的需求與期待。</p>
                                <h4 class="col-12 h4" v-html="$t('capability_acc_4_text_2')">我們的服務和技術包含：</h4>
                                <ul class="col-12 bullets row mx-0">
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_4_text_3')">印刷電路板設計與佈線</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_4_text_4')">製造設計服務/測試設計服務</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_4_text_5')">單面、雙面、多層、軟性印刷電路板(FPC)</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_4_text_6')">全自動表面黏著與插件製程</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_4_text_7')">球格陣列封裝(BGA)或微型 BGA 設置</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_4_text_8')">自動光學檢測(AOI)</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_4_text_9')">3D X 光線檢驗</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_4_text_10')">自動化測試</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_4_text_11')">功能測試</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_4_text_12')">原型</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="accordion__title">
                            <span class="h3 mb-0 py-3" v-html="$t('capability_acc_5_title')">整機組裝</span>
                        </div>
                        <div class="accordion__content article__body">
                            <div class="row">
                                <p class="col-12" v-html="$t('capability_acc_5_text_1')">信邦因應市場提供完整的整機組裝服務，包含醫療、汽車及航空、綠色能源、工業和通訊相關應用。我們藉由合作共創良好的業務，客戶重視我們的整體理念，並肯定我們將垂直整合與工程能力最佳化的過程。我們擁有跨功能的團隊，能夠提供優質的產品，實現客戶的期望。</p>
                                <ul class="col-12 bullets row mx-0">
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_5_text_2')">機械及電機設計</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_5_text_3')">專案管理</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_5_text_4')">可製造性導向設計（DFM）</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_5_text_5')">軟體設計與韌體整合</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_5_text_6')">印刷電路板製造與整合</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_5_text_7')">塑膠射出製造</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_5_text_8')">線材組裝製造</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_5_text_9')">驗證與生命週期測試</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="accordion__title">
                            <span class="h3 mb-0 py-3" v-html="$t('capability_acc_6_title')">天線設計與製造</span>
                        </div>
                        <div class="accordion__content article__body">
                            <div class="row">
                                <p class="col-12" v-html="$t('capability_acc_6_text_1')">信邦設計並製造了一系列標準及訂製的天線解決方案，包含設計概念、原型、工具、天線製造、電纜、PCBA、殼架、測試。我們的天線解決方案廣泛應用於汽車、醫療、船舶、工業、通訊市場。</p>
                                <h4 class="col-12 h4" v-html="$t('capability_acc_6_text_2')">我們的產品範圍包含：</h4>
                                <ul class="col-12 bullets row mx-0">
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_3')">晶片天線／平板天線</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_4')">偶極天線</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_5')">PCB ／ FPC ／金屬／陶瓷嵌入式天線</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_6')">堅固外接式／ MIMO ／ GNSS 功能天線</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_7')">射頻模組（NB-IoT / LoRa / WiFi / 3G / 4G 等）</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_8')">射頻同軸電纜組件</li>
                                </ul>

                                <h4 class="col-12 h4 mt-5" v-html="$t('capability_acc_6_text_9')">天線樣品</h4>
                                <p class="col-12" v-html="$t('capability_acc_6_text_10')">我們使用雕刻、蝕刻曝光機和 3D 列印快速製作工程樣品。</p>

                                <h4 class="col-12 h4 mt-5" v-html="$t('capability_acc_6_text_11')">測試與驗證</h4>
                                <p class="col-12" v-html="$t('capability_acc_6_text_12')">內部環境和機械測試是由我們的產品測試與認證部門所執行，以確保產品符合客戶所期望的規格。天線的電氣性能用於確保天線設計符合其規格。</p>
                                
                                <h4 class="col-12 h4 mt-5" v-html="$t('capability_acc_6_text_13')">OTA Chamber (ETS-Lindgren) 測試</h4>
                                <h5 class="col-12 h5 my-3" v-html="$t('capability_acc_6_text_14')">無源</h5>
                                <ul class="col-12 bullets row mx-0">
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_15')">峰值增益</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_16')">AVG 增益</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_17')">效率</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_18')">2D 模式</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_19')">3D 模式</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_20')">ECC</li>
                                </ul>
                                <h5 class="col-12 h5 my-3" v-html="$t('capability_acc_6_text_21')">有源 TRP（總輻射功率）／ TIS（總全向靈敏度）</h5>
                                <ul class="col-12 bullets row mx-0">
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_22')">GSM</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_23')">GSM-GPRS</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_24')">GSM-EGPRS</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_25')">CDMA</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_26')">CDMA-EVDO</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_27')">WCDMA</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_28')">RSS</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_29')">LTE-FDD</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_30')">LTE-TDD</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_6_text_31')">Wi-Fi</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="accordion__title">
                            <span class="h3 mb-0 py-3" v-html="$t('capability_acc_7_title')">射出成型</span>
                        </div>
                        <div class="accordion__content article__body">
                            <div class="row">
                                <p class="col-12 my-3" v-html="$t('capability_acc_7_text_1')">信邦在塑膠射出成型的製造方面擁有豐富的經驗，我們的生產設施讓我們能夠為客戶提供廣泛的選擇。</p>
                                <p class="col-12 my-3" v-html="$t('capability_acc_7_text_2')">產品可由多種熱塑性材料製成，包含 ABS、PC、PP、尼龍。產品所用的材料取決於您的機械與環境要求，以確保為您的電子解決方案提供最佳保護。</p>
                                <ul class="col-12 bullets row mx-0 my-3">
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_7_text_3')">多種固定方法，包含卡扣、栓鎖或螺釘固定</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_7_text_4')">可永久防篡改的超音波焊接</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_7_text_5')">各種不同的材料飾面，包含「皮革漆」</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_7_text_6')">雙料射出能力</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_7_text_7')">化學鍵射出能力</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_7_text_8')">所有材料在成型之前都要經過除溼</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_7_text_9')">中央供料系統，提高產品的效率和品質。</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="accordion__title">
                            <span class="h3 mb-0 py-3" v-html="$t('capability_acc_8_title')">模型製作</span>
                        </div>
                        <div class="accordion__content article__body">
                            <div class="row">
                                <p class="col-12" v-html="$t('capability_acc_8_text_1')">信邦自行開發各種模具，包含包覆射出成型、塑膠射出，以及製造壓模和夾具。上述服務有助於縮短上市時間，並且使我們能夠控管供應鏈，降低客戶的成本。</p>
                                <h4 class="col-12 h4" v-html="$t('capability_acc_8_text_2')">模型製作服務</h4>
                                <ul class="col-12 bullets row mx-0">
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_8_text_3')">快速製造，平均 14 至 21 天</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_8_text_4')">模流分析</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_8_text_5')">暫用模具，用於較低的投資要求和原型</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_8_text_6')">3D建模</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_8_text_7')">裝配夾具設計與製造</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_8_text_8')">測試夾具設計與製造</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_8_text_9')">壓接模具製造</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_8_text_10')">印刷電路板夾具設計與製造</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_8_text_11')">精密測量夾具設計與製造</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_8_text_12')">包覆射出成型模具設計與製造</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_8_text_13')">低壓模具設計與製造</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_8_text_14')">塑膠射出模具設計與製造</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_8_text_15')">熱澆道模具設計與製造</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_8_text_16')">連接器外殼模具設計與製造</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_8_text_17')">金屬沖壓模具設計與製造</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_8_text_18')">壓鑄模具設計與製造</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_8_text_19')">超音波模具設計與製造</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_8_text_20')">矽膠模具設計與製造</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="accordion__title">
                            <span class="h3 mb-0 py-3" v-html="$t('capability_acc_9_title')">測試與驗證</span>
                        </div>
                        <div class="accordion__content article__body">
                            <div class="row">
                                <p class="col-12" v-html="$t('capability_acc_9_text_1')">產品和驗證測試是製造過程的關鍵部分。信邦致力於提供可靠且量身訂作的優質製造解決方案，代表我們提供了全面的產品測試服務，使我們能夠提高產量並降低製造成本。我們擁有嫻熟的技術人員和測試工程師，與客戶密切合作以制定測試方案，包含機電和環境測試。我們擁有健全的品質程序，每個樣品都經過檢查和驗證，另外還包含每兩小時一次的隨機抽檢，以確保在大量生產前滿足所有品質要求。</p>
                                <h4 class="col-12 h4" v-html="$t('capability_acc_9_text_2')">可用於線材組裝的測試範例</h4>
                                <ul class="col-12 bullets row mx-0">
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_3')">電氣測試（網絡分析儀）</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_4')">高壓絕緣測試</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_5')">鹽霧試驗</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_6')">防潮性</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_7')">靜態濕度測試</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_8')">彎曲試驗（彎曲角度：45°/ 60°/ 90°/ 120°/ 180°）</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_9')">熱衝擊試驗（溫度範圍：-50℃ -  + 150℃）</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_10')">熱衝擊和彎曲測試（二合一）</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_11')">拉力試驗（容量範圍：0.1 kg ~ 2000 kg）</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_12')">纏繞線材抽出測試</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_13')">端子截面分析</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_14')">冷彎試驗（低至 -50℃）</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_15')">UL 易燃性測試</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_16')">自動插入和抽取測試</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_17')">三座標測量</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_18')">3d 量測顯微鏡</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_19')">能量色散 X 光線光譜儀</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_20')">金屬塗層厚度測試</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_21')">時域反射儀</li>
                                    <li class="col-12 col-md-6 p-0" v-html="$t('capability_acc_9_text_22')">振動測試</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>
<script>
    import {setMetaInfo} from "@/libs/js/metaInfo.js"
    import fluidPlayer from 'fluid-player';
    import $ from 'jquery';

    export default {
         data:function(){
            return{
                meta:{
                    title: 'Electrical Design Services | Electrical Engineering',
                    desc: 'SINBON Electrical Design services provides customers with a wider range of high value-added manufacturing products and services and flexible productions.',
                    img: location.protocol +'//'+ location.host + '/img/banner-capability.jpg',
                    // keywords: 'manufacturing solutions, manufacturing services, electrical layout, flexible production, electrical design services, low volume pcb assembly, flexible pcb assembly'
                },
                metaData:{},
                player: null
            }
        },
        metaInfo() {
            return this.metaData
        },
        methods: {
            initPlayer(){
                // console.log(this.$refs['recordingVideo']);
                var _video=this.$refs['automationVideo'];
                this.player = fluidPlayer(_video, {
                    layoutControls: {
                        fillToContainer: true,
                        preload: 'auto',
                        autoPlay: true,
                        loop:true
                        // preload: 'none',
                        // allowDownload: true,
                        // playbackRateEnabled: true,
                        // allowTheatre: false
                    },
                    // playerInitCallback: (function() {
                    //     console.log('PLAYER READY');
                    // })
                });
            },
        },
        destroyed() {
            if (!this.player) {
                return;
            }
            this.player.destroy();
        },
        created() {
            setMetaInfo( this, this.$t('page_title_capability'), undefined, undefined, undefined,false,true );
            // this.$window.initAll();
        },
        mounted() {
            this.$nextTick(() => {
                this.initPlayer();
                this.$themeInitAccordion();
                $('.accordion>li:first').addClass('active');
                this.$toggleLoading(false);
            });
        },
    };
</script>