import * as langs from "@/static/langs.json";
// import { readCookie } from "@/libs/js/cookie.js";

let hreflangArr = [
  { langCode: "en", urlLang: "" },
  { langCode: "ja", urlLang: "/jp" },
  { langCode: "zh-Hant", urlLang: "/tw" },
  { langCode: "zh-Hans", urlLang: "/cn" },
  { langCode: "de", urlLang: "/de" },
  { langCode: "hu", urlLang: "/hu" },
  { langCode: "x-default", urlLang: "" },
];
export { hreflangArr };

function setMetaLang(baseUrl, routerObj) {
//   let canonicalObj = document.head.querySelector("link[rel=canonical]");
  // let langCodeUrl =
  //   readCookie("lang_code") === "en" ? "" : "/" + readCookie("lang_code");

  // set canonical
//   if (!canonicalObj) {
//     // 第一次進入頁面, 沒有 canonical
//     if (routerObj.query.page != undefined && routerObj.query.page != 1) {
//       $("head").append(
//         '<link rel="canonical" href="' +
//           baseUrl +
//           langCodeUrl +
//           routerObj.fullPath.slice(0, -1) +
//           "1" +
//           '">'
//       );
//     } else {
//       $("head").append(
//         '<link rel="canonical" href="' + window.location.href + '">'
//       );
//     }
//   } else {
    // if (routerObj.query.page != undefined && routerObj.query.page != 1) {
    //   document.head.querySelector("link[rel=canonical]").href = baseUrl + langCodeUrl + routerObj.fullPath.slice(0, -1) + "1";
    // } else {
    //   document.head.querySelector("link[rel=canonical]").href = window.location.href;
    // }
//   }

  // set hreflang
  hreflangArr.forEach((obj) => {
    let queryString = `link[hreflang=${obj.langCode}]`;
    document.head.querySelector(queryString).href =
      baseUrl + obj.urlLang + routerObj.fullPath;
  });
}
export { setMetaLang };
