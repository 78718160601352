<template>
	<div>
		<section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs mb-0">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('nav_terms')">使用條款</li>
                </ol>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h1 class="h2">{{$t('nav_terms')}}</h1>
                        <hr class="short">
                        <p class="sub-lead">{{ $t('terms_param_0_1') }}</p>
                        <p class="sub-lead">{{ $t('terms_param_0_2') }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('terms_title_1') }}</h2>
                        <p class="sub-lead">{{ $t('terms_param_1') }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('terms_title_2') }}</h2>
                        <p class="sub-lead">{{ $t('terms_param_2') }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('terms_title_3') }}</h2>
                        <p class="sub-lead mb-1">{{ $t('terms_param_3_1') }}</p>
                        <ul class="bullets">
                            <li v-for="item in $t('terms_param_3_list')">{{ item }}</li>
                        </ul>
                        <p class="sub-lead">{{ $t('terms_param_3_2') }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('terms_title_4') }}</h2>
                        <p class="sub-lead">{{ $t('terms_param_4') }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('terms_title_5') }}</h2>
                        <p class="sub-lead">{{ $t('terms_param_5_1') }}</p>
                        <p class="sub-lead">{{ $t('terms_param_5_2') }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('terms_title_6') }}</h2>
                        <p class="sub-lead">{{ $t('terms_param_6') }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('terms_title_7') }}</h2>
                        <p class="sub-lead">{{ $t('terms_param_7_1') }}</p>
                        <p class="sub-lead">{{ $t('terms_param_7_2') }}</p>
                        <p class="sub-lead">{{ $t('terms_param_7_3') }}</p>
                        <p class="sub-lead">{{ $t('terms_param_7_4') }}</p>
                        <p class="sub-lead">{{ $t('terms_param_7_5') }}</p>
                        <p class="sub-lead">{{ $t('terms_param_7_6') }}</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    export default {
        data:function(){
            return {
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        created() {
            setMetaInfo( this, this.$t('page_title_terms'), undefined, undefined, undefined,false,true);
            // this.$window.initAll();
        },
    };
</script>