<template>
    <div v-if="detail">
        <div v-if="displayContent==='data'" class="csr-page">
            <!-- <section class="cover imagebg text-left space--sm" data-overlay="6">
                <div class="background-image-holder">
                    <img alt="background" :src="$data.siteUrl + '/img/banner-corporate.jpg'" />
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 col-lg-7">
                            <h1>{{detail.name}}</h1>
                            <p v-html="$t('csr_page_banner_p')">這些價值導引我們的經營運作，並時時提醒我們成功的要素為何</p>
                        </div>
                    </div>
                </div>
            </section>
            <section class="py-5 breadcrumbsSection">
                <div class="container">
                    <ol class="breadcrumbs">
                        <li>
                            <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                        </li>
                        <li v-html="$t('nav_csr')">企業與社會責任</li>
                        <li>{{detail.name}}</li>
                    </ol>
                </div>
            </section> -->
            <!-- <section class="py-0">
                <div class="container text-left">
                    <div class="row">
                        <div class="col">
                            <h2 class="m-0">{{detail.name}}</h2>
                        </div>
                    </div>
                </div>
            </section> -->
            <!-- <section class="py-0">
                <div v-if="editor" v-html="editor"></div>
            </section> -->

            <!-- pb content -->
            <div v-if="detail.description_pb" v-html="detail.description_pb"></div>
        </div>

        <missing v-else-if="displayContent==='404'" />
    </div>
</template>
<script>
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    import { mapState, mapActions } from "vuex";
    // import {readCookie} from "@/libs/js/cookie.js"
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    import Missing from "../pages/Missing.vue";

	export default {
        data() {
            return{
                slug:this.$route.params.slug,
                currId:null,
                detail:null,
                editor:null,
                csrPage:null,
                metaData:{},
                displayContent:'default',
                // previewPB: this.$route.query.previewPB
            }
        },

        metaInfo() {
            return this.metaData
        },

        components: {
            "missing": Missing
        },

        computed:{
            ...mapState(['csrPageItem']),
        },

        methods: {
            ...mapActions(["fetchCsrPage"]),

            fetchData() {
                let _this = this;
                console.log('fetchData');
                console.log(apibase+'csrpage/detail/'+_this.currId);
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'csrpage/detail/' + _this.currId,
                })
                .then((response) => {
                    _this.detail=response.data.row;
                    setMetaInfo(_this, _this.detail.name, undefined, 'none', 'none');
                    _this.displayContent = 'data';
                    // _this.fetchEditor();
                    setTimeout(function() {
                        _this.$window.pagebuilder_clip_script_init();
                    }, 100);
                })
                .catch((error) => {
                    _this.displayContent = '404';
                    console.log(error);
                });
            },

            // fetchEditor() {
            //     let _this = this;

            //     axios({
            //         method: 'get',
            //         url: _this.detail.description,
                    
            //     })
            //     .then((response) => {
            //         _this.editor = response.data;
            //         // _this.$window.initAll();
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //     });
            // },

            parseRoute() {
                this.detail = null;
                this.editor = null;

                if (!this.$route.params.slug) this.$router.push({ name: 'home' });

                for (var i = 0; i < this.csrPage.length; i++) {
                    if (this.$route.params.slug == this.csrPage[i].slug) 
                    {
                        this.currId=this.csrPage[i].id;
                    }
                }

                this.fetchData();
            }
        },

        watch: {
            '$route' () {
                this.parseRoute();
            },

            csrPageItem(val) {
                this.csrPage = val;
                this.parseRoute()
            }
        },

        created() {
            if (!this.$route.params.slug) this.$router.push({ name: 'home' });

            this.fetchCsrPage();

            // setMetaInfo(this, undefined, undefined, undefined, undefined);
        },

        // mounted() {
        //     this.$nextTick(function () {
        //         setTimeout(function() {
        //             window.pagebuilder_clip_script_init();
        //             _global.init();
        //         }, 3000);
        //     });
        // }
    };
</script>