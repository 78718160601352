<template>
	<div>
    <section class="pt-5 pb-0 product breadcrumbsSection">
      <div class="container">
        <ol class="breadcrumbs">
          <li>
            <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
          </li>
          <li v-html="$t('nav_products')">產品</li>
        </ol>
      </div>
    </section>

    <section class="pt-4 pt-lg-0" style="overflow: visible;position: relative;z-index: 9;">
      <div class="container">
        <div>
          <h1 class="d-none">{{ currCategoryName }}</h1>
          
          <div v-if="categories" class="masonry-filter-container d-flex align-items-center">
            <span v-html="$t('ui_category')">類別</span>

            <!-- <product-filter 
              v-if="navProducts != null"
              :options="filterOptions"
              :parent-curr-category="currCategory"
              :firstLeval="true"
            ></product-filter> -->
            <product-filter v-if="navProducts != null" :nav="navProducts"/>


            <!-- 會多一個All -->
            <!-- <div class="masonry-filter-holder" v-else>
              <div class="masonry__filters">
                <ul>
                  <li :class="currCategory == 'all' ? 'active' : ''" @click="updateCategory('all')" v-html="$t('ui_allCategory')">所有類別</li>
                  <li :class="currCategory == category.category_slug ? 'active' : ''" v-for="category, key in categories" @click="updateCategory(category.category_slug)">{{ category.category_name }}</li>
                </ul>
              </div>
            </div> -->
            <!-- 會多一個All -->

            
            <!-- <div class="masonry-filter-holder">
              <div class="masonry__filters">
                <ul>
                  <li :class="currCategory == 'all' ? 'active' : ''" @click="updateCategory('all')" v-html="$t('ui_allCategory')">所有類別</li>
                  <li :class="currCategory == category.category_slug ? 'active':''" v-for="category,key in categories" @click="updateCategory(category.category_slug)">{{ category.category_name }}</li>
                </ul>
              </div>
            </div> -->
          </div>

          <h3 v-html="categoryDes"></h3>

          <hr />

          <!-- <div class="masonry__container row masonry--active" v-if="items" v-lazy-container="{ selector: 'img' }"> -->

          <div v-if="items" class="masonry__container row masonry--active">
            <div v-for="item in filteredRows(items,currCategory,'category_slug').slice(pageStart, pageStart + itemPerPage)" class="masonry__item col-6 col-md-4" data-masonry-filter="item.category_slug">
              <!-- <div class="product">
                <router-link :to="{ name:'product-detail', params: { category: item.category_slug, slug:item.slug }}">
                  <img :alt="item.cover_desc ? item.cover_desc:item.name" :src="item.cover" >
                </router-link>

                <router-link :to="{ name:'product-detail', params: { category: item.category_slug, slug:item.slug }}">
                  <div>
                    <h2 class="h5">{{ item.name }}</h2>
                  </div>
                </router-link>
              </div> -->
              <div class="product">
                <router-link :to="{ name:'product-detail', params: { category: filteredCategory(item.category_slug), slug:item.slug },query:{category:item.category_slug}}">
                  <img :alt="item.cover_desc ? item.cover_desc:item.name" :src="item.cover" >
                </router-link>

                <router-link :to="{ name:'product-detail', params: { category: filteredCategory(item.category_slug), slug:item.slug },query:{category:item.category_slug}}">
                  <div>
                    <h2 class="h5">{{ item.name }}</h2>
                  </div>
                </router-link>
              </div>
            </div>

            <div 
              v-if="filteredRows(items,currCategory,'category_slug').slice(pageStart, pageStart + itemPerPage).length == 0"
              class="masonry__item col"
              v-html="$t('msg_no_data')"
            ></div>
          </div>
        </div>
      </div>

      <pagination v-model="currPage" v-on:getTotalPage="totalPage" :currPage="currPage" :itemPerPage="itemPerPage" :itemTotal="totalItemNum" v-if="items" />
    </section>

    <channel-box v-bind:blocks="['solution', 'contact-custom', 'global-location']" style="position: relative;z-index: 8;" />
  </div>
</template>



<script>
  import channelBox from "../components/ChannelBox.vue";
  import pagination from "../components/Pagination.vue";
  import productFilter from "../components/ProductFilter.vue";
  import apibase from "@/static/apibase.js";
  import axios from 'axios';
  import { mapState } from "vuex";
  import { mapActions } from "vuex";
  import {setMetaInfo} from "@/libs/js/metaInfo.js";
  

	export default {
    data() {
      return{
        items: null,
        currPage: this.$route.query.page,
        itemPerPage: 12,
        categories: null,
        currCategory: this.$route.query.cate,
        currCategoryName: null,
        currCategorySlug: null,
        categoryDes: null,
        meta: {
          title: 'All Product List | Cables & Connectors',
          desc: 'All SINBON products for you to reference, including EV charging cable, ix industrial cables, M series cable connector, and more.'
        },
        metaData: {},
      }
    },

    metaInfo() {
      return this.metaData
    },

    components: {
      'channel-box': channelBox,
      'pagination': pagination,
      'product-filter': productFilter
    },

    methods: {
      ...mapActions(['setSelectedContactTopic','fetchProductCategory', 'fetchProductItems', 'fetchNavigation']),
      // ...mapState(['defaultMeta', 'productCate', 'productItem', 'navProducts']),

      filteredRows(rows, cc, slug) {
        this.$toggleLoading(false);
        let result = null;
        let c = cc.toLowerCase();
        let cArr = c.split(',');

        if (c.trim() !== 'all') 
        {
          result = rows.filter(o => {
            let v = o.category_slug.toLowerCase();
            let vArr = v.split(',');

            let cArrResult = null;

            cArrResult = cArr.map(cItem => {
              return vArr.some(arrVal => cItem === arrVal);
            });

            return cArrResult.every(v => v === true);
          });
        }
        else 
        {
          result = rows;
        }
        
        return result;
      },

      filteredCategory(categoriesSlug){
        var _categoriesSlugArr=categoriesSlug.split(',');
        for(var k in this.categories){
          for(var j in _categoriesSlugArr){
            if(this.categories[k].category_slug.toLowerCase()==_categoriesSlugArr[j].toLowerCase()){
              return this.categories[k].category_slug;
            }
          }
        }
      },

      // updateCategory(c) {
      //   console.log('updateCategory', c);
      //   // if (c == this.currCategory) return;

      //   // this.currCategory = c;
      //   // this.currPage = 1;
      //   // this.$router.push({ name: 'product', query: { cate:this.currCategory, page:this.currPage } });
      // },

      findCate(arr) {
        return arr.category_slug === this.$route.query.cate;
      },

      setDesc() {
        // let routeName = this.$route.name;
        var _lastCate=this.$route.query.cate.split(',').pop();
        var _lastCateInfo;
        for(var k in this.categories){
          if(this.categories[k].category_slug.toLowerCase()==_lastCate.toLowerCase()){
            _lastCateInfo=this.categories[k];
          }
        }
        

        if ( this.$route.query.cate != 'all') 
        {
          // var found = this[routeName + 'Cate'].find(this.findCate);
          // if (found != undefined) 
          // {
          //   this.categoryDes = found.category_description;
          //   setMetaInfo(this, found.meta_title, undefined, found.meta_desc, found['thumb_' + routeName + '_category'],"product",false);
          // }
          setMetaInfo(this, _lastCateInfo.meta_title, undefined, _lastCateInfo.meta_desc, _lastCateInfo['thumb_product_category'],"product",false);
        }
        else
        {
          // console.log('cate ==== all, 用預設 meta');
          this.categoryDes = '';
          setMetaInfo(this, this.meta.title, undefined, this.meta.desc, undefined,"product",false);
        }

        this.$meta().refresh();
      },

      setCurrCategoryName() {
        this.categories.forEach(obj => { 
          if (this.$route.query.cate === 'all')
          {
            this.currCategoryName = 'All products';
          }
          else if (this.currCategory != 'all')
          {
            if (obj.category_slug.toLowerCase() === this.currCategory.toLowerCase()) 
            {
              this.currCategoryName = obj.category_name;
              this.currCategorySlug = obj.category_slug.toLowerCase();
            }
          }
        });
      },

      totalPage(maxPage) {
        if ( this.currPage > maxPage ) 
        {
          this.currPage = maxPage;

          this.$router.push({
            name: 'product', 
            query: { cate: this.currCategory, page: this.currPage }
          });
        }
      },

      // slugMerge(tree, uri) {
      //   return tree.map(node => {   
      //     if (uri != undefined) node.uri = (node.uri != '') ? `${ uri },${ node.uri }` : uri;

      //     if (node.children != null) 
      //     {
      //       node.children.unshift({ children: null, title: this.$t('ui_allCategory'), uri: '' });
      //       this.slugMerge(node.children, node.uri);
      //     }
          
      //     return node;
      //   });
      // }
    },

    computed: {
      ...mapState(['defaultMeta', 'productCate', 'productItem', 'navProducts']),

      totalItemNum() {
        return this.filteredRows(this.items, this.currCategory, 'category_slug').length;
      },

      pageStart() {
        return (this.currPage - 1) * this.itemPerPage;
      },

      // filterOptions() {
      //   console.log('navProducts', this.navProducts);
      //   if (this.navProducts != null) 
      //   {
      //     let result = {};
      //     let navProductsTmp = [...this.navProducts];
      //     //一堆報錯，先註解掉
      //     result.children = this.slugMerge(navProductsTmp);
      //     result.children.unshift({ children: null, title: this.$t('ui_allCategory'), uri: 'all' });

          

      //     return result;
      //   }
      // }
    },

    watch: {
      '$route' (to, from) {
        if (this.currPage != undefined && this.currCategory != undefined)
        {
          if (this.$route.query.cate != this.currCategory){
            // 切到其他 category 時
            this.currCategory = this.$route.query.cate;
            this.currPage = 1;
          }

          if ( this.currPage == undefined ) this.currPage = 1;

          // this.$router.push({
          //   name: 'product',
          //   query: { cate: this.currCategory, page: this.currPage }
          // });

          this.setDesc();
          this.setCurrCategoryName();
        }
      },

      productCate(val) {
        this.categories = val;
        this.setDesc();
        this.setCurrCategoryName();
      },

      productItem(val) {
        this.items = val;
        // this.$window.initAll();
      }
    },

    created() {
      

      if (this.currPage == undefined || this.currCategory == undefined)
      {
        this.currCategory = 'all';
        this.currPage = '1';

        this.$router.push({
          name: 'product', 
          query: { cate: this.currCategory, page: this.currPage }
        });
      }

      this.fetchProductCategory();
      this.fetchProductItems();
      this.fetchNavigation();
      this.setSelectedContactTopic('products');
    }
  };
</script>