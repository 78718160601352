import Vue from 'vue';
// import Meta from 'vue-meta';
import VueCookies from 'vue-cookies';
import i18n from './libs/i18n/lang';

import App from './App.vue';
import router from './router';

// 讀外部的css有很大的問題，一直無法成功，只好將pagebuild的component.css複製到這裡
import './libs/pagebuilder/component.css';
import './libs/less/app.less';


import store from './store/index';
import VeLine from 'v-charts/lib/line.common';
// import VueAnalytics from 'vue-analytics';
import VueLazyload from 'vue-lazyload';
import axios from "axios";
import VueMeta from 'vue-meta';

//bambi import it for date picker?
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en';


import $ from "jquery";




if (!String.prototype.startsWith) {
  (function () {
    'use strict'; // needed to support `apply`/`call` with `undefined`/`null`
    var defineProperty = (function () {
      // IE 8 only supports `Object.defineProperty` on DOM elements
      try {
        var object = {};
        var $defineProperty = Object.defineProperty;
        var result = $defineProperty(object, object, object) && $defineProperty;
      } catch (error) {
        console.log(error);
      }
      return result;
    }());
    var toString = {}.toString;
    var startsWith = function (search) {
      if (this == null) {
        throw TypeError();
      }
      var string = String(this);
      if (search && toString.call(search) == '[object RegExp]') {
        throw TypeError();
      }
      var stringLength = string.length;
      var searchString = String(search);
      var searchLength = searchString.length;
      var position = arguments.length > 1 ? arguments[1] : undefined;
      // `ToInteger`
      var pos = position ? Number(position) : 0;
      if (pos != pos) { // better `isNaN`
        pos = 0;
      }
      var start = Math.min(Math.max(pos, 0), stringLength);
      // Avoid the `indexOf` call if no match is possible
      if (searchLength + start > stringLength) {
        return false;
      }
      var index = -1;
      while (++index < searchLength) {
        if (string.charCodeAt(start + index) != searchString.charCodeAt(index)) {
          return false;
        }
      }
      return true;
    };
    if (defineProperty) {
      defineProperty(String.prototype, 'startsWith', {
        'value': startsWith,
        'configurable': true,
        'writable': true
      });
    } else {
      String.prototype.startsWith = startsWith;
    }
  }());
}




















//====================================================================
//                                                                    
//  ######  ##   ##  #####  ###    ###  #####            ##   ####  
//    ##    ##   ##  ##     ## #  # ##  ##               ##  ##     
//    ##    #######  #####  ##  ##  ##  #####            ##   ###   
//    ##    ##   ##  ##     ##      ##  ##           ##  ##     ##  
//    ##    ##   ##  #####  ##      ##  #####         ####   ####   
//                                                                    
//====================================================================

Vue.prototype.$repositionDropdownsRtl = function () {
  var _this = this;
  var windowWidth = $(window).width();

  $('.dropdown__container').each(function () {
    var container, containerOffset, masterOffset, menuItem, content;

    $(this).css('left', '');

    container = $(this);
    containerOffset = windowWidth - (container.offset().left + container.outerWidth(true));
    masterOffset = $('.containerMeasure').offset().left;
    menuItem = windowWidth - (container.closest('.dropdown').offset().left + container.closest('.dropdown').outerWidth(true));
    content = null;

    container.css('right', ((-containerOffset) + (masterOffset)));

    if (container.find('.dropdown__content:not([class*="lg-12"])').length) {
      content = container.find('.dropdown__content');
      content.css('right', ((menuItem) - (masterOffset)));
    }
  });
  $('.dropdown__content').each(function () {
    var dropdown, offset, width, offsetRight, winWidth, rightCorrect;

    dropdown = $(this);
    offset = windowWidth - (dropdown.offset().left + dropdown.outerWidth(true));
    width = dropdown.outerWidth(true);
    offsetRight = offset + width;
    winWidth = $(window).outerWidth(true);
    rightCorrect = $('.containerMeasure').outerWidth() - width;

    if (offsetRight > winWidth) {
      dropdown.css('right', rightCorrect);
    }

  });
}


//dropdown
Vue.prototype.$themeInitDropdown = function () {
  var _this = this;
  $('body').append('<div class="container containerMeasure" style="opacity:0;pointer-events:none;"></div>');

  // $(document).on('click', 'body:not(.dropdowns--hover) .dropdown, body.dropdowns--hover .dropdown.dropdown--click', function (event) {
  //   console.log('click')
  //   var dropdown = $(this);
  //   if ($(event.target).is('.dropdown--active > .dropdown__trigger')) {
  //     console.log('click >>>');
  //     $('.dropdown--active').removeClass('dropdown--active');
  //     dropdown.addClass('dropdown--active');
  //   } else {
  //     console.log('else');
  //     dropdown.siblings().removeClass('dropdown--active').find('.dropdown').removeClass('dropdown--active');
  //     dropdown.toggleClass('dropdown--active');
  //   }
  // });
  $('.dropdown__trigger').off('click').on('click', function (event) {
    console.log('click dropdown')
    event.stopPropagation();
    var dropdownTrigger = $(this);
    var dropdown = dropdownTrigger.parent();
    if (!dropdown.hasClass('dropdown--active')) {
      dropdown.siblings().removeClass('dropdown--active');
      dropdown.addClass('dropdown--active');
    } else {
      dropdown.removeClass('dropdown--active');
    }
  });

  $(document).on('click touchstart', 'body:not(.dropdowns--hover)', function (event) {
    if (!$(event.target).is('[class*="dropdown"], [class*="dropdown"] *')) {
      $('.dropdown--active').removeClass('dropdown--active');
    }
  });

  $('body.dropdowns--hover .dropdown').off('click').on('click', function (event) {
    event.stopPropagation();
    var hoverDropdown = $(this);
    hoverDropdown.toggleClass('dropdown--active');
  });

  _this.$repositionDropdownsRtl();
  $(window).on('resize', function () {
    _this.$repositionDropdownsRtl();
  });
}

//notification(popup)
Vue.prototype.$themeInitNotification = function () {
  var _this = this;
  $('[data-notification-link]:not(.notification)').off('click').on('click', function (e) {
    e.preventDefault();
    var notificationID = $(this).attr('data-notification-link');
    var notification = $('.notification[data-notification-link="' + notificationID + '"]');
    $('.notification--reveal').addClass('notification--dismissed');
    notification.removeClass('notification--dismissed');
    notification.addClass('notification--reveal');
    $('html').addClass('popup');
  });
  $('.notification-close').off('click').on('click', function (e) {
    e.preventDefault();
    var closeButton = $(this);
    var notification = closeButton.closest('.notification');
    notification.addClass('notification--dismissed');
    $('html').removeClass('popup');
  });
}

//accordion
Vue.prototype.$themeInitAccordion = function () {
  var _this = this;
  $('.accordion__title').on('click', function () {
    $(this).closest('li').toggleClass('active');
  });
}

//background image holder
Vue.prototype.$themeInitbgHolder = function () {
  var _this = this;
  $('.background-image-holder').each(function () {
    var imgSrc = $(this).children('img').attr('src');
    $(this).css('background', 'url("' + imgSrc + '")').css('background-position', 'initial').css('opacity', '1');
  });
}

//modal
Vue.prototype.$showModal = function (modal, millisecondsDelay) {
  var _this = this;
  var delay = (typeof millisecondsDelay !== typeof undefined) ? (1 * millisecondsDelay) : 0,
    $modal = $(modal);

  if ($modal.length) {
    setTimeout(function () {
      $(modal).addClass('modal-active');

    }, delay);
  }
}

Vue.prototype.$closeActiveModal = function () {
  var _this = this;
  var modal = $('body div.modal-active');
  console.log(_this);
  if (modal.length) {
    modal.removeClass('modal-active');
    Vue.prototype.$bus.$emit('themeModalClosed');
  }
}

Vue.prototype.$themeInitModal = function () {
  var _this = this;
  var allPageModals = "<div class=\"all-page-modals\"></div>",
    mainContainer = $('div.main-container');

  var _allModalsContainer;
  if (mainContainer.length) {
    $(allPageModals).insertAfter(mainContainer);
    // mr.modals.allModalsContainer = $('div.all-page-modals');
    _allModalsContainer = $('div.all-page-modals');
  } else {
    $('body').append(allPageModals);
    // mr.modals.allModalsContainer = $('body div.all-page-modals');
    _allModalsContainer = $('body div.all-page-modals');
  }

  $('.modal-container').each(function () {

    // Add modal close if none exists
    var modal = $(this),
      modalContent = modal.find('.modal-content');


    if (!modal.find('.modal-close').length) {
      modal.find('.modal-content').append('<div class="modal-close modal-close-cross"></div>');
    }

    // Set modal height
    if (modalContent.attr('data-width') !== undefined) {
      var modalWidth = modalContent.attr('data-width').substr(0, modalContent.attr('data-width').indexOf('%')) * 1;
      modalContent.css('width', modalWidth + '%');
    }
    if (modalContent.attr('data-height') !== undefined) {
      var modalHeight = modalContent.attr('data-height').substr(0, modalContent.attr('data-height').indexOf('%')) * 1;
      modalContent.css('height', modalHeight + '%');
    }

    // Set iframe's src to data-src to stop autoplaying iframes
    // mr.util.idleSrc(modal, 'iframe');

  });


  $('.modal-instance').each(function (index) {
    var modalInstance = $(this);
    var modal = modalInstance.find('.modal-container');
    var trigger = modalInstance.find('.modal-trigger');

    // Link modal with modal-id attribute
    trigger.attr('data-modal-index', index);
    modal.attr('data-modal-index', index);

    // Set unique id for multiple triggers
    if (typeof modal.attr('data-modal-id') !== typeof undefined) {
      trigger.attr('data-modal-id', modal.attr('data-modal-id'));
    }


    // Attach the modal to the body            
    modal = modal.detach();
    // mr.modals.allModalsContainer.append(modal);
    _allModalsContainer.append(modal);
  });


  $('.modal-trigger').on('click', function () {
    var modalTrigger = $(this);
    var uniqueID, targetModal;

    // Determine if the modal id is set by user or is set programatically
    if (typeof modalTrigger.attr('data-modal-id') !== typeof undefined) {
      uniqueID = modalTrigger.attr('data-modal-id');
      targetModal = _allModalsContainer.find('.modal-container[data-modal-id="' + uniqueID + '"]');
    } else {
      uniqueID = $(this).attr('data-modal-index');
      targetModal = _allModalsContainer.find('.modal-container[data-modal-index="' + uniqueID + '"]');
    }

    _this.$showModal(targetModal);

    return false;
  });

  $(document).on('click', '.modal-close', _this.$closeActiveModal);

  $(document).keyup(function (e) {
    if (e.keyCode === 27) { // escape key maps to keycode `27`
      _this.$closeActiveModal();
    }
  });

  $('.modal-container:not(.modal--prevent-close)').on('click', function (e) {
    if (e.target !== this) return;
    _this.$closeActiveModal();
  });

  // Trigger autoshow modals
  $('.modal-container[data-autoshow]').each(function () {
    var modal = $(this);
    var millisecondsDelay = modal.attr('data-autoshow') * 1;
    _this.$showModal(modal, millisecondsDelay);
  });

  // Exit modals
  $('.modal-container[data-show-on-exit]').each(function () {
    var modal = $(this),
      exitSelector = modal.attr('data-show-on-exit'),
      delay = 0;

    if (modal.attr('data-delay')) {
      delay = parseInt(modal.attr('data-delay'), 10) || 0;
    }

    // If a valid selector is found, attach leave event to show modal.
    if ($(exitSelector).length) {
      modal.prepend($('<i class="ti-close close-modal">'));
      $(document).on('mouseleave', exitSelector, function () {
        if (!$('.modal-active').length) {
          _this.$showModal(modal, delay);
        }
      });
    }
  });

  $(document).on('click', 'a[href^="#"]', function () {
    var modalID = $(this).attr('href').replace('#', '');
    if ($('[data-modal-id="' + modalID + '"]').length) {
      _this.$closeActiveModal();
      setTimeout(_this.$showModal, 500, '[data-modal-id="' + modalID + '"]', 0);
    }
  });

  // Make modal scrollable
  $(document).on('wheel mousewheel scroll', '.modal-content, .modal-content .scrollable', function (evt) {
    if (evt.preventDefault) {
      evt.preventDefault();
    }
    if (evt.stopPropagation) {
      evt.stopPropagation();
    }
    this.scrollTop += (evt.originalEvent.deltaY);
  });
}










//==============================================================
//                                                              
//  ##   ##  ##   ##  #####        ##  ##     ##  ##  ######  
//  ##   ##  ##   ##  ##           ##  ####   ##  ##    ##    
//  ##   ##  ##   ##  #####        ##  ##  ## ##  ##    ##    
//   ## ##   ##   ##  ##           ##  ##    ###  ##    ##    
//    ###     #####   #####        ##  ##     ##  ##    ##    
//                                                              
//==============================================================





Vue.mixin({
  data: function () {
    return {
      siteUrl: SITE_URL,
      baseUri: BASE_URI,
      cookiePrefix: COOKIE_PREFIX
    }
  },
})

Vue.prototype.$cookieSetting=(function(){
  var _wcs;
  var _cookieSetting=localStorage.getItem('cookieSetting');
  if(_cookieSetting){
    _wcs=JSON.parse(_cookieSetting);
  }
  // console.log('wcs',_wcs);
  return _wcs;
})();

Vue.prototype.$fixPbImgSrcForDev = function (pbContent) {
  if(process.env.NODE_ENV !== 'production'){
    const parser = new DOMParser();
    const doc = parser.parseFromString(pbContent, 'text/html');
    const htmlObject = doc.body;
    var imgDom=htmlObject.querySelectorAll('img');
    imgDom.forEach(dom => {
      var src=dom.getAttribute('src');
      if(src&&src.startsWith('/')){
        dom.setAttribute('src','https://www.sinbon.com'+src);
      }
    });
    return htmlObject.outerHTML;
  }else{
    return pbContent;
  }
}
Vue.prototype.$pbScriptExtra = function(){
  document.querySelectorAll('article._plt-set ._clip_table').forEach((tb)=>{
    if(tb.getAttribute('data-rwd')=='scroll'){
      var _cols=tb.querySelectorAll('th').length;
      var _colMinWidth=280;
      //依欄數給予最小寬度
      tb.querySelector('table').style.setProperty('min-width',(_cols*_colMinWidth)+'px');
    }
  });
}

Vue.prototype.$isMobile = function () {
  var userAgent = window.navigator.userAgent;
  var isMobile = /mobile/i.test(userAgent);
  return isMobile;
}

Vue.prototype.$subscribe = function (data) {
  return new Promise((resolve, reject) => {
    //Dotfont test account
    // var _url_dc='gmail.us21';
    // var _url_u='24a2371a9ac19d981a7ab31be';
    // var _url_id='c16632c833';
    // var _url_fid='0023fbe6f0';
    // var _hiddenInput='b_24a2371a9ac19d981a7ab31be_c16632c833';

    //SINBON's account
    var _url_dc = 'sinbon.us6';
    var _url_u = 'cedae6b795425232fc6a89ac3';
    var _url_id = 'cf50d339c0';
    var _url_fid = '00859fe2f0';
    var _hiddenInput = 'b_cedae6b795425232fc6a89ac3_cf50d339c0';

    var _url = `https://${_url_dc}.list-manage.com/subscribe/post-json?u=${_url_u}&amp;id=${_url_id}&amp;f_id=${_url_fid}`;

    $.ajax({
      type: 'GET',
      url: _url,
      data: data + '&' + _hiddenInput + '=',
      cache: false,
      dataType: 'jsonp',
      jsonp: 'c',
      contentType: "application/json; charset=utf-8",
      error: function (err) {
        reject(err);
      },
      success: function (data) {
        if (data.result != "success") {
          reject(data.msg);
        } else {
          resolve();
        }
      }
    });
  });
}






// axios.defaults.baseURL = BASE_URI;
axios.defaults.headers = {
  'X-Requested-With': 'XMLHttpRequest',
  'content-type': 'application/x-www-form-urlencoded'
}

Vue.prototype.$window = window;
window.CDN_URL='/vendors/'
console.log('window',window);
Vue.config.productionTip = false;
Vue.component(VeLine.name, VeLine);
Vue.use(Element, { locale })
Vue.use(VueMeta);
Vue.use(VueCookies);
Vue.use(VueLazyload);


Vue.prototype.$bus = new Vue();
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
