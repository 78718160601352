<template>
    <div>
        <section class="cover imagebg text-left space--sm" data-overlay="6">
            <div class="background-image-holder">
                <img alt="background" :src="$data.siteUrl + '/img/banner-investors.jpg'" />
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1 v-html="$t('investor_shareholder_banner_title')">投資人專區</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('nav_investor')">投資人專區</li>
                    <li v-html="$t('nav_investorShareholder')">股東訊息</li>
                </ol>
            </div>
        </section>
        <section>
            <div class="container">
                <h3 v-html="$t('investor_shareholder_meeting_title')">最近年度股東會議資料</h3>
                <div class="mt-5">
                    <div class="row same-height-col" v-if="items">
                        <div class="col-12 col-md-6" v-for="item in items.rows">
                           <div class="boxed border">
                                <p class="mb-2 h4">{{item.name}}</p>
                                <a :href="item.file" target="_blank" v-html="$t('ui_download')">下載檔案</a>
                           </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                           <div class="boxed border">
                                <p class="mb-2 h4">{{$t('investor_shareholder_recording')}}</p>
                                <div class="modal-instance">
                                    <a class="modal-trigger" href="#" @click="onModalShow">{{$t('investor_shareholder_recording_cta')}}</a>
                                    <div class="modal-container">
                                        <div class="modal-content">
                                            <div class="container">
                                                <div class="row justify-content-center">
                                                    <div class="col-12">
                                                        <div class="boxed boxed--lg bg--white text-center feature">
                                                            <div class="modal-close modal-close-cross"></div>
                                                            <video class="fluid-component w-100 mt-2" :ref="'recordingVideo'">
                                                                <source src="https://sinbon-investor.oss-cn-hongkong.aliyuncs.com/112%20%E8%82%A1%E6%9D%B1%E6%9C%83.mp4"
                                                                data-fluid-hd
                                                                :title="$t('investor_shareholder_recording')"
                                                                type='video/mp4'/>
                                                            </video>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        

        <section>
            <div class="container">
                <h3 v-html="$t('investor_shareholder_dividend_title')">歷年股利分派</h3>
                <div class="common-tablebox boxed border">
                    <table v-if="items2">
                        <thead>
                            <tr>
                                <th v-html="$t('investor_shareholder_dividend_th_1')">年度</th>
                                <th v-html="$t('investor_shareholder_dividend_th_2')">現金股利</th>
                                <th v-html="$t('investor_shareholder_dividend_th_3')">股票股利</th>
                                <th v-html="$t('investor_shareholder_dividend_th_4')">除權息交易日</th>
                                <th v-html="$t('investor_shareholder_dividend_th_5')">基準日</th>
                                <th v-html="$t('investor_shareholder_dividend_th_6')">發放日</th>
                                <th v-html="$t('investor_shareholder_dividend_th_7')">股東會日期</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items2.rows">
                                <td>{{item.year}}</td>
                                <td>NT$ {{item.revenue_cash}}</td>
                                <td>NT$ {{item.revenue_share}}</td>
                                <td>{{item.exdiv_date}}</td>
                                <td>{{item.record_date}}</td>
                                <td>{{item.pay_day}}</td>
                                <td>{{item.meeting_day}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                

                <div class="row">
                    <div class="col-12 col-md-6">
                       <div class="">
                            <p class="mb-2 h4">
                                <span v-html="$t('investor_shareholder_bank')">台新國際商業銀行</span><br/>
                                <span v-html="$t('investor_shareholder_address')">台北市仁愛路四段118號2樓</span><br/>
                                <a href="tel:886225048125">886-2-25048125</a>
                            </p>
                       </div>
                    </div>
                    <div class="col-12 col-md-6" v-if="items3">
                       <div class="boxed border ">
                            <p class="mb-2 h4">{{items3.rows[0].name}}</p>
                            <a :href="items3.rows[0].file" target="_blank" v-html="$t('ui_download')">下載檔PDF</a>
                       </div>
                    </div>
                </div>

            </div>
        </section>
    </div>
</template>
<style scoped>
    @import "~fluid-player/src/css/fluidplayer.css";
</style>
<script>
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    // import {readCookie} from "@/libs/js/cookie.js"
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    import fluidPlayer from 'fluid-player';

    export default {
        data:function(){
            return {
                items:null,
                items2:null,
                items3:null,
                metaData:{},
                player: null
            }
        },
        metaInfo() {
            return this.metaData
        },
        methods:{
            fetchItems:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'invshareholder/items',
                }).then(function (response) {
                    _this.items=response.data;
                }).catch(function (error) {
                    console.log(error);
                });
            },
            fetchItems2:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'invsharerevenue/items',
                    
                })
                    .then(function (response) {
                        _this.items2=response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchItems3:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'invsharereport/items',
                    
                })
                    .then(function (response) {
                        _this.items3=response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            initPlayer(){
                // console.log(this.$refs['recordingVideo']);
                var _video=this.$refs['recordingVideo'];
                this.player = fluidPlayer(_video, {
                    layoutControls: {
                        fillToContainer: true,
                        preload: 'auto'
                        // preload: 'none',
                        // allowDownload: true,
                        // playbackRateEnabled: true,
                        // allowTheatre: false
                    },
                    playerInitCallback: (function() {
                        console.log('PLAYER READY');
                    })
                });
            },
            onModalShow(e){
                if (!this.player) this.initPlayer();
            },
        },
        created() {
            setMetaInfo( this, this.$t('page_title_investor_shareholders_info'), undefined, undefined, undefined,false,true );
            this.fetchItems();
            this.fetchItems2();
            this.fetchItems3();
        },
        mounted() {
            this.$toggleLoading(false);
            var _this=this;
            this.$bus.$on('themeModalClosed', () => {
                if(_this.player) _this.player.pause();
            });
            this.$nextTick(() => {
                // this.$window.initAll();
                this.$themeInitModal();
            });
        },
        beforeDestroy() {
            if (!this.player) {
                return;
            }
            this.$bus.$off('themeModalClosed');
            this.player.destroy();
        },
    };

</script>