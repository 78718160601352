<template>
    <div v-if="detail">
        <section>
            <div class="container">
                <h2>{{detail.row.name}}</h2>
                <p v-html="detail.row.description"></p>
            </div>
        </section>

        <section class="bg--secondary" v-if="detail.taiwan&&detail.row.list_on.includes('tw')">
            <div class="container">
                <h4><span v-html="$t('career_school_area_tw')">台灣</span> {{detail.row.article_date.substr(0,4)}}<span v-html="$t('career_school_info')">年度校園徵才場次資訊</span></h4>
                <div class="row">
                    <div class="col-md-6" v-for="(item,idx) in detail.taiwan" :key="idx">
                        <div class="feature  boxed boxed--border p-4">
                            <h5 class="h4 mb-0">{{item.article_date}}</h5>
                            <p>{{item.location}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="bg--secondary" v-if="detail.china&&detail.row.list_on.includes('cn')">
            <div class="container">
                <h4><span v-html="$t('career_school_area_cn')">中國</span> {{detail.row.article_date.substr(0,4)}}<span v-html="$t('career_school_info')">年度校園徵才場次資訊</span></h4>
                <div class="row">
                    <div class="col-md-6" v-for="(item,idx) in detail.china" :key="idx">
                        <div class="feature  boxed boxed--border p-4">
                            <h5 class="h4 mb-0">{{item.article_date}}</h5>
                            <p>{{item.location}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-center" v-if="detail.photo">
            <div class="container text-left">
                <div class="col mb-5">
                    <h4 v-html="$t('career_school_scene')">徵才現場</h4>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="slider" data-paging="true" data-children="2">
                            <ul class="slides flickity-enabled is-draggable" tabindex="0">
                                <div class="flickity-viewport" >
                                    <div class="flickity-slider">
                                        <li class="col-md-6 col-12 slide text-left" v-for="(photo,idx) in detail.photo" :key="idx">
                                            <div class="project-thumb">
                                                <img alt="Image" class="border--round" :src="photo.cover">
                                            </div>
                                        </li>
                                    </div>
                                </div>
                                <ol class="flickity-page-dots"></ol>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    // import {readCookie} from "@/libs/js/cookie.js"
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    export default {
        data:function(){
            return {
                detail:null,
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        methods:{
            fetchData:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'csrstudent/lastest',
                    
                })
                    .then(function (response) {
                        _this.detail=response.data;
                        // _this.$window.initAll();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        created() {
            this.fetchData();
            setMetaInfo( this, undefined, undefined, undefined, undefined )
        },
    };
</script>