<template>
	<div>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h1 class="h2 mb-1">{{ $t('cookieNotice_title') }}</h1>
                        <small class="type--fine-print">{{ $t('cookieNotice_date') }}</small>
                        <hr class="short">
                        <p class="sub-lead">{{ $t('cookieNotice_param_0_1') }}</p>
                        <p class="sub-lead">{{ $t('cookieNotice_param_0_2') }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('cookieNotice_title_1') }}</h2>
                        <p class="sub-lead">{{ $t('cookieNotice_param_1_1') }}</p>
                        <p class="sub-lead">{{ $t('cookieNotice_param_1_2') }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('cookieNotice_title_2') }}</h2>
                        <p class="sub-lead mb-1">{{ $t('cookieNotice_param_2_1') }}</p>
                        <ul class="bullets">
                            <li v-for="item in $t('cookieNotice_param_2_list')">{{ item }}</li>
                        </ul>
                        <p class="sub-lead">{{ $t('cookieNotice_param_2_2') }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('cookieNotice_title_3') }}</h2>
                        <p class="sub-lead">{{ $t('cookieNotice_param_3_1') }}</p>
                        <p class="sub-lead">{{ $t('cookieNotice_param_3_2') }}</p>
                        <p class="sub-lead">{{ $t('cookieNotice_param_3_3') }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('cookieNotice_title_4') }}</h2>
                        <p class="sub-lead">{{ $t('cookieNotice_param_4_1') }}</p>
                        <p class="sub-lead">{{ $t('cookieNotice_param_4_2') }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('cookieNotice_title_5') }}</h2>
                        <p class="sub-lead" v-html="$t('cookieNotice_param_5')"></p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('cookieNotice_title_6') }}</h2>
                        <p class="sub-lead">{{ $t('cookieNotice_param_6_1') }}</p>
                        <p class="sub-lead mb-1">{{ $t('cookieNotice_param_6_2') }}</p>
                        <ul class="bullets">
                            <li v-for="item in $t('cookieNotice_param_6_list')" v-html="item"></li>
                        </ul>
                        <p class="sub-lead">{{ $t('cookieNotice_param_6_3') }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left" style="display:none;">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h2 class="h3 text-left">{{ $t('cookieNotice_title_7') }}</h2>
                        <!-- <p class="sub-lead">{{ $t('cookieNotice_param_5') }}</p> -->
                        <table>
                            <tr>
                                <th>Cookie</th>
                                <th>Essential or Non-essential</th>
                                <th>Persistent or Session</th>
                                <th>Purpose</th>
                                <th>Owner</th>
                            </tr>
                            <!-- PHP Session -->
                            <tr>
                                <td>PHPSESSIONID</td>
                                <td>Essential</td>
                                <td>Session</td>
                                <td>Necessary (session tracking)</td>
                                <td>First Party</td>
                            </tr>
                            <!-- 表單送出驗證 -->
                            <tr>
                                <td>csrf_cookie_name</td>
                                <td>Essential</td>
                                <td>2 Hours</td>
                                <td>Necessary (prevent CSRF Attack)</td>
                                <td>First Party</td>
                            </tr>
                            <tr>
                                <td>web</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>



                            <!-- msgReaded -->
                            <tr>
                                <td>msgReaded</td>
                                <td></td>
                                <td>1 Years</td>
                                <td>Functionality (檢查是否已讀過重要訊息)</td>
                                <td>First Party</td>
                            </tr>
                            
                            <!-- 判斷偏好語系 -->
                            <tr>
                                <td>lang_code</td>
                                <td>Functional</td>
                                <td>Functionality (language prefer)</td>
                                <td>1 Year</td>
                                <td>First Party</td>
                            </tr>
                            <!-- webinarRegistered -->
                            <tr>
                                <td>webinarRegistered</td>
                                <td></td>
                                <td>1 Years</td>
                                <td>Functionality (檢查是否註冊過webinar)</td>
                                <td>First Party</td>
                            </tr>

                            <!-- cse.google.com 站內搜尋用 -->
                            <tr>
                                <td>cse</td>
                                <td>Functional</td>
                                <td>2 Years</td>
                                <td>Functionality (site analytics)</td>
                                <td>Third party (Google Analytics)</td>
                            </tr>






                            <!-- GA 網站追蹤用 -->
                            <tr>
                                <td>_ga,_gid,_gat_x,_gcl_x</td>
                                <td>Statistics</td>
                                <td>2 Years</td>
                                <td>Functionality (site analytics)</td>
                                <td>Third party (Google Analytics)</td>
                            </tr>
                            
                            
                            
                            
                            <!-- ？？ -->
                            <tr>
                                <td>_fbp</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>fb?</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    export default {
        data:function(){
            return {
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        created() {
            $('head').append('<meta property="fb:app_id" content="449286906210219">')
            setMetaInfo( this, undefined, undefined, undefined, undefined )
            // this.$window.initAll();
            this.$toggleLoading(false);
        }
    };

</script>