//存放 mutation key
export const CHECK_LANG = 'CHECK_LANG';
export const SET_LANG = 'SET_LANG';

export const CHECK_SESSION = "CHECK_SESSION";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const FETCH_NAVIGATION = "FETCH_NAVIGATION";
export const FETCH_PRODUCT_CATEGORY = "FETCH_PRODUCT_CATEGORY";
export const FETCH_PRODUCT_ITEMS = "FETCH_PRODUCT_ITEMS";
export const FETCH_SOLUTION_CATEGORY = "FETCH_SOLUTION_CATEGORY";
export const FETCH_SOLUTION_ITEMS = "FETCH_SOLUTION_ITEMS";
export const FETCH_SOLUTION_DETAIL = "FETCH_SOLUTION_DETAIL";
export const FETCH_CASE_STUDY_CATEGORY = "FETCH_CASE_STUDY_CATEGORY";
export const FETCH_CASE_STUDY_ITEMS = "FETCH_CASE_STUDY_ITEMS";
export const FETCH_CASE_STUDY_DETAIL = "FETCH_CASE_STUDY_DETAIL";
export const FETCH_BLOG_LABEL = "FETCH_BLOG_LABEL";
export const FETCH_BLOG_ITEMS = "FETCH_BLOG_ITEMS";
export const FETCH_BLOG_DETAIL = "FETCH_BLOG_DETAIL";
export const FETCH_NEWS_CATEGORY = "FETCH_NEWS_CATEGORY";
export const FETCH_BRAND_CATEGORY = "FETCH_BRAND_CATEGORY";
// export const FETCH_CSR_PAGE = "FETCH_CSR_PAGE";//should be removed
export const FETCH_ESG_PAGE = "FETCH_ESG_PAGE";
export const SET_CONTACT_QUERY = "SET_CONTACT_QUERY";
export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const SET_SELECTED_CONTACT_TOPIC = "SET_SELECTED_CONTACT_TOPIC";
export const SET_WEBINAR_CONTACT_QUERY = "SET_WEBINAR_CONTACT_QUERY";
export const SET_WEBINAR_STATUS = "SET_WEBINAR_STATUS";
export const SET_COOKIE_ALLOW_STATUS = "SET_COOKIE_ALLOW_STATUS";

export const FETCH_WEBINARBANNER = "FETCH_WEBINARBANNER";
export const FETCH_WEBINAR_CATEGORY = "FETCH_WEBINAR_CATEGORY";
export const FETCH_WEBINAR_ITEMS = "FETCH_WEBINAR_ITEMS";