<template>
<div id="login">
    <section class="cover imagebg text-center height-90 space--md" data-overlay="6">
        <div class="background-image-holder">
            <img alt="background" :src="$data.siteUrl + '/img/dealer-bg.jpg'" />
        </div>
        <div class="container">
            <div class="row">
                <div class="col col-md-5">
                    <h1 v-html="$t('dealer_title')">夥伴專區</h1>
                    <form @submit.prevent="login(loginData)">
                        <input type="text" v-model="loginData.email" :placeholder="$t('dealer_email')" required/>
                        <input type="password" v-model="loginData.password" :placeholder="$t('dealer_password')" required/>
                        <input type="submit" :value="$t('dealer_signin')" class="btn btn--primary"/>
                    </form>
                </div>
            </div>
        </div>
    </section>
    
</div>
</template>
<script>
import {mapActions} from 'vuex'
import {setMetaInfo} from "@/libs/js/metaInfo.js";
export default {
    data() {
        return {
            loginData:{
                "email": "",
                "password": ""
            },
            metaData:{}
        }
    },
    metaInfo() {
        return this.metaData
    },
    methods: {
        ...mapActions(['checkToken','login'])
    },
    created() {
        setMetaInfo( this, undefined, undefined, undefined, undefined )
        // this.$window.initAll();
    },
}
</script>