<template>
	<div>
		<section class="cover imagebg text-left space--sm" data-overlay="6">
            <div class="background-image-holder">
                <img alt="background" :src="$data.siteUrl + '/img/banner-career.jpg'" />
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1 v-html="$t('career_banner_title')">成為我們的夥伴<br/>共同成長</h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('nav_career')">人才招募</li>
                </ol>
            </div>
        </section>

        <section class="pb-0">
            <div class="container">
                <ul class="tabs">
                    <li>
                        <div class="tab__title">
                            <router-link class="h5" :to="{ name:'career-train' }" v-html="$t('nav_careerInfo')">招募資訊</router-link>
                        </div>
                    </li>
                    <li>
                        <div class="tab__title">
                            <router-link class="h5" :to="{ name:'career-job' }" v-html="$t('nav_careerJobs')">職缺一覽</router-link>
                        </div>
                    </li>
                    <!-- <li>
                        <div class="tab__title">
                            <router-link class="h5" :to="{ name:'career-school' }" v-html="$t('nav_careerStudent')">校園徵才</router-link>
                        </div>
                    </li>
                    <li>
                        <div class="tab__title">
                            <router-link class="h5" :to="{ name:'career-intern' }" v-html="$t('nav_careerIntern')">全球實習計畫</router-link>
                        </div>
                    </li> -->
                    <li>
                        <div class="tab__title">
                            <router-link class="h5" :to="{ name:'career-family' }" v-html="$t('nav_careerSinboner')">信邦人</router-link>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        <router-view></router-view>
    </div>
</template>
<style lang="less">
@media (max-width: 767px){
    #app:not(.tw) .tabs{
        display: block!important;
    }
    .tw{
        .tabs li {
            display: inline-block;
            padding:1em;
            border-bottom: none!important;
            &:not(:last-child){
                border-right:1px solid #ECECEC;
            }
        }
    }
}
</style>
<script>
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    export default {
        data:function(){
            return {
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        created() {
            setMetaInfo( this, undefined, undefined, undefined, undefined,false,true );
            // this.$window.initAll();
            this.$toggleLoading(false);
        },
    };
</script>