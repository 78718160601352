<template>
    <div>
        <section class="cover imagebg text-left space--sm" data-overlay="6">
            <div class="background-image-holder">
                <img alt="background" :src="$data.siteUrl + '/img/banner-investors.jpg'" />
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1 v-html="$t('investor_financial_banner_title')">投資人專區</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('nav_investor')">投資人專區</li>
                    <li v-html="$t('nav_investorFinancial')">財務資訊</li>
                </ol>
            </div>
        </section>
        <section>
            <div class="container" v-if="years.length>0">
                <h3 v-html="$t('investor_financial_chart_title')">公司自結合併月營收</h3>
                <div v-if="chartData.rows.length>0">
                    <ve-line :data="chartData" :settings="chartSettings" :colors="chartColor"></ve-line>
                </div>
                <div class="mt-5" v-for="year in years" v-if="items">
                    <h3>{{year}}</h3>
                    <div class="row same-height-col">
                        <div class="col-12 col-md-4" v-for="item in filteredRows(items.rows,year,'article_date')">
                           <div class="boxed border">
                                <p class="mb-2 h4">{{item.name}}</p>
                                <a :href="item.file" target="_blank" v-html="$t('ui_download')">下載檔案</a>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    import { mapGetters } from "vuex";
    // import {readCookie} from "@/libs/js/cookie.js"
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    export default {
        data:function(){
            return {
                items:null,
                chartItems:null,
                chartColor:['#609de6'],
                years:[],
                chartSettings:{
                    // stack: { '營收': ['營收'] },
                    area: true
                },
                chartData: {
                    columns: [this.$t('investor_financial_chart_date'), this.$t('investor_financial_chart_unit')],
                    // columns: ['日期', '營收(台幣仟元)'],
                    rows: [
                        // { '日期': '1/1', '營收(萬)': 1393 },
                        // { '日期': '1/2', '營收(萬)': 3530 },
                        // { '日期': '1/3', '營收(萬)': 2923 },
                        // { '日期': '1/4', '營收(萬)': 1723 },
                        // { '日期': '1/5', '營收(萬)': 3792 },
                        // { '日期': '1/6', '營收(萬)': 4593 }
                    ]
                },
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        methods:{
            ...mapGetters(["getLang"]),
            filteredRows: function(rows,cc,slug){
                return rows.filter(function(d){
                    if(d[slug]){
                        return d[slug].indexOf(cc) > -1;
                    }
                });
            },
            fetchItems:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'invreport/items',
                    
                })
                    .then(function (response) {
                        _this.items=response.data;
                        for(var k in _this.items.rows){
                            if(_this.years.indexOf(_this.items.rows[k].article_date)<0){
                                _this.years.push(_this.items.rows[k].article_date);
                            }
                        }
                        // _this.$window.initAll();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchChartData:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'invrevenue/items',
                    
                })
                    .then(function (response) {
                        _this.chartItems=response.data;
                        _this.chartItems.rows.reverse();
                        for(var k in _this.chartItems.rows){
                            if(_this.getLang()=='tw'){_this.chartData.rows.push({'日期': _this.chartItems.rows[k].year, '營收(台幣仟元)': Math.ceil(_this.chartItems.rows[k].revenue)});}
                            else if(_this.getLang()=='cn'){_this.chartData.rows.push({'日期': _this.chartItems.rows[k].year, '营收(台币仟元)': Math.ceil(_this.chartItems.rows[k].revenue)});}
                            else{_this.chartData.rows.push({'Date': _this.chartItems.rows[k].year, 'NTD in thousand': Math.ceil(_this.chartItems.rows[k].revenue)});}
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        created() {
            setMetaInfo( this, undefined, undefined, undefined, undefined )
            this.fetchItems();
            this.fetchChartData();
        }
    };

</script>