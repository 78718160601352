<template>
	<div>
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('nav_trends')">熱門趨勢</li>
                    <li v-html="$t('solar_title')">太陽能光電系統</li>
                </ol>
            </div>
        </section>


        <section class=" ">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-md-7">
                        <h1 class="mb-5" v-html="$t('solar_title')">太陽能光電系統服務</h1>
                        <p class="lead" v-html="$t('solar_content')">
                            為追求綠活意境<br/>開發整合前瞻性太陽能產品及應用<br/>期許讓你我因太陽能而受益
                        </p>
                    </div>
                </div>
            </div>
        </section>


        <section class="text-center bg--primary">
          <div class="container">
            <div class="row">
              <div class="col-md-10 col-lg-8 mb-3">
                <h2 v-html="$t('solar_section_1_title')">我們提供的不只是一個產品<br/>而是一個適切的方案</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="feature feature-3 boxed boxed--lg">
                  <img alt="" :src="$data.siteUrl + '/img/solar-1.svg'" />
                  <h4  v-html="$t('solar_section_1_point_1')">太陽能系統組件 </h4>
                </div>
              </div>
              <div class="col-md-4">
                <div class="feature feature-3 boxed boxed--lg">
                    
                  <img :src="$data.siteUrl + '/img/solar-2.svg'" />
                  <h4  v-html="$t('solar_section_1_point_2')">系統規劃設計</h4>
                </div>
              </div>
              <div class="col-md-4">
                <div class="feature feature-3 boxed boxed--lg">
                  <img :src="$data.siteUrl + '/img/solar-3.svg'" />
                  <h4  v-html="$t('solar_section_1_point_3')">監造與售後服務</h4>
                </div>
              </div>
            </div>
            
          </div>
        </section>

        <section class="imageblock switchable switchable--switch height-50 bg--secondary">
            <div class="imageblock__content col-lg-6 col-md-4 pos-right">
                <div class="background-image-holder">
                    <img alt="Solar Photovoltaic System & Cable Assembly - SINBON" :src="$data.siteUrl + '/img/solar-1.jpg'" />
                </div>
            </div>
            <div class="container pos-vertical-center sub-lead">
                <div class="row">
                    <div class="col-lg-5 col-md-7">
                        <p  v-html="$t('solar_section_2_des')">除了提供系統整合服務外，信邦亦有提供併聯系統產品銷售服務，有別於傳統太陽光電系統，提供單片監控功能，在維運上更能得心應手
                        </p>
                    </div>
                </div>
            </div>
        </section>

       

        <section class="imageblock switchable h-auto bg--secondary">
            <div class="imageblock__content col-lg-6 col-md-4 pos-right">
                <div class="background-image-holder">
                    <img alt="image" :src="$data.siteUrl + '/img/solar-2.jpg'" />
                </div>
            </div>
            <div class="container pos-vertical-strat ">
                <div class="row">
                    <div class="col-lg-5 col-md-7 sub-lead">
                        <ul>
                            <li class="mt-5 mb-5">
                                <strong  v-html="$t('solar_section_3_title_1')">最適化光電系統設計</strong>
                                <p v-html="$t('solar_section_3_des_1')">源頭入手，依案場設置環境條件，進行最適化光電系統設計、設施配置、使系統高效運行，降低運維成本</p>
                            </li>
                            <li class="mt-5 mb-5">
                                <strong  v-html="$t('solar_section_3_title_2')">於系統驗收、運維發現的問題、提出合理化維護改善作法</strong>
                                <p  v-html="$t('solar_section_3_des_2')">信邦太陽能的專業能力與豐富經驗能精確判讀電廠資料，快速找出問題並提出應對方針，優化電廠的發電狀況</p>
                            </li>
                            <li class="mt-5 mb-5">
                                <strong v-html="$t('solar_section_3_title_3')">即時分析、檢測與排除</strong>
                                <p v-html="$t('solar_section_3_des_3')">用預防性維護概念，對光電系統的潛在故障，進行即時分析、檢測與排除，以防範高風險事件發生。
搭配信邦太陽能電廠運維服務與電廠監控資料，將定期為客戶進行系統檢查&amp;提出體檢報告，主動發現電廠異常狀況，並提供維護改善服務</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container" >
                <h3 v-html="$t('solar_qa')">常見問題</h3>
                <div class="col-12" v-for="item in qna" v-if="item.q!=''">
                    <div class="row boxed border mb-3">
                        <div class="col-12 mb-0 h4">
                            <ul class="accordion accordion--right accordion-2 ">
                                <li :id="item.nid" >
                                    <h3 class="accordion__title w-100 border-0 mb-0 pt-0 pb-0">
                                        <span class="h4 mb-0 font-weight-bold" v-html="item.q"></span>
                                    </h3>
                                    <div class="accordion__content ">
                                        <hr class="w-100 mt-4 mb-3" />
                                        <p v-html="item.a"></p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> 
        </section>

        <section v-if="items&&filteredRows(items.rows,'solar','group').length > 0">
            <div class="container">
                <hr class="mt-0 mb-5">
                <h3 class="pt-5" v-html="$t('solar_recommen')">相關產品</h3>
                <div class="row" items.rows>
                    <div v-for="item in filteredRows(items.rows,'solar','group')" class=" col-6 col-md-4" data-masonry-filter="item.category_slug" >
                        <div class="product">
                            <router-link :to="{ name:'product-detail', params: {category:item.category_slug, slug:item.slug }}">
                                <img :alt="item.name" :src="item.cover">
                            </router-link>
                            <router-link :to="{ name:'product-detail', params: {category:item.category_slug, slug:item.slug }}">
                                <div>
                                    <h5>{{item.name}}</h5>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        

        <!-- <recommend-item/> -->
        <div class="container">
            <hr class="m-0">
        </div>
        <channel-box v-bind:blocks="['solution','contact','global-location']"/>
    </div>
    
</template>
<script>
    import channelBox from "../components/ChannelBox.vue";
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    import {setMetaInfo} from "@/libs/js/metaInfo.js"
    // import {readCookie} from "@/libs/js/cookie.js"
    export default {
        data:function(){
            return {
                items:null,
                qna:[
                {
                    nid:1,
                    q:this.$t('solar_q1'),
                    a:this.$t('solar_a1'),
                },
                {
                    nid:2,
                    q:this.$t('solar_q2'),
                    a:this.$t('solar_a2'),
                },
                {
                    nid:3,
                    q:this.$t('solar_q3'),
                    a:this.$t('solar_a3'),
                },
                {
                    nid:4,
                    q:this.$t('solar_q4'),
                    a:this.$t('solar_a4'),
                },
                {
                    nid:5,
                    q:this.$t('solar_q5'),
                    a:this.$t('solar_a5'),
                },
                {
                    nid:6,
                    q:this.$t('solar_q6'),
                    a:this.$t('solar_a6'),
                },
                {
                    nid:7,
                    q:this.$t('solar_q7'),
                    a:this.$t('solar_a7'),
                },
                {
                    nid:8,
                    q:this.$t('solar_q8'),
                    a:this.$t('solar_a8'),
                },
                {
                    nid:9,
                    q:this.$t('solar_q9'),
                    a:this.$t('solar_a9'),
                },
                {
                    nid:10,
                    q:this.$t('solar_q10'),
                    a:this.$t('solar_a10'),
                },
                {
                    nid:11,
                    q:this.$t('solar_q11'),
                    a:this.$t('solar_a11'),
                },
                {
                    nid:12,
                    q:this.$t('solar_q12'),
                    a:this.$t('solar_a12'),
                },
                {
                    nid:13,
                    q:this.$t('solar_q13'),
                    a:this.$t('solar_a13'),
                }],
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        methods:{
            filteredRows: function(rows,cc,slug){
                var c = cc.toLowerCase();
                if(rows!= undefined){
                return ( c.trim() !== 'all') ?
                    rows.filter(function(d){ return d[slug].toLowerCase().indexOf(c) > -1; }) :
                    rows;
                }
            },
            fetchItems:function(){
                var _this=this;
                
                    axios({
                        method: 'get',
                        baseURL: apibase,
                        url: 'product/items',
                        
                    })
                    .then(function (response) {
                        _this.items=response.data;
                        // _this.$window.initAll();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
            
        },
        created() {
            this.fetchItems();
            // var metaTitle=this.$t('solar_title');
            // var metaDesc=this.$t('solar_content');
            // setMetaInfo(metaTitle,window.location.href,metaDesc, undefined);
            // setMetaInfo( this, this.meta.title, this.meta.url, this.meta.desc, this.meta.img );
            // setMetaInfo( this, "Solar Energy & Power Solutions", this.meta.url, "SINBON Solar Energy & Power Solutions specialize in developing and integrating advanced solar photovoltaic systems and cable assemblies.", this.meta.img );
            setMetaInfo( this, this.$t('page_title_solar_system'), undefined, "SINBON Solar Energy & Power Solutions specialize in developing and integrating advanced solar photovoltaic systems and cable assemblies.", location.protocol +'//'+ location.host + '/img/solar-1.jpg',false,true );
        },
        mounted() {
            this.$themeInitAccordion();
        },
        components: {
            "channel-box": channelBox
        },
    
    };

</script>