<template>
     <div class="contact notification pos-bottom notification--reveal notification--dismissed m-0 p-0 h-100 " data-animation="from-bottom" data-notification-link="contact">
        <div class="contact__table">
            <div class="contact__cell">
                <div  class=" container contactBox col-10 col-sm-9 ml-auto mr-auto p-0">
                    <div class="feature feature-1 mb-0">
                        <div class="feature__body boxed boxed--lg boxed--border ">
                            <!-- <div class="text-block">
                                <div class="row">
                                    <h3 class="col-md-12" v-html="$t('contact_title')">聯絡與洽詢</h3>
                                </div>
                            </div> -->
                            <h3 class="notification-title mb-3" v-html="$t('contact_title')">聯絡與洽詢</h3>
                            <ContactForm class="mt-0"/>
                        </div>
                    </div>

                    <div class="notification-close-cross notification-close"></div>
                
                </div>  
            </div>
        </div>
    </div>
</template>


<style lang="less" scoped>

</style>


<script>
    import { mapState, mapActions, mapGetters } from "vuex";
    import ContactForm from '../components/ContactForm.vue';

    export default {
        components: { ContactForm },
        data:function(){
            return{
            }
        },
        // methods:{
        //     ...mapActions(["setLoadingStatus","fetchProductCategory","fetchSolutionCategory","fetchProductItems"]),
        //     closePopup:function(){
        //         var _this=this;
        //         // remove hash
        //         _this.$router.push({path:_this.$route.path});

        //         $(document).mouseup(function(e){
        //           var _con = $('.contactBox');   
        //           if(!_con.is(e.target) && _con.has(e.target).length === 0){
        //             $('#contactForm').addClass('notification--dismissed');
        //             $('html').removeClass('popup');
        //           }
        //         });
        //     },
        // },
    };

</script>