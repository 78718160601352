<template>
	<div v-if="detail">
		<section>
            <div class="container">
                <h2>{{detail.row.name}}</h2>
                <p v-html="detail.row.description"></p>
            </div>
        </section>

        <section class="bg--secondary" v-if="detail.subject">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 ">
                        <h2 v-html="$t('career_intern_info')">實習計畫內容</h2>
                    </div>
                    <div class="col-md-8">
                        <div v-for="subject in detail.subject">
                            <h4>{{subject.name}}</h4>
                            <p v-html="subject.description"></p>
                            <hr/>    
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section v-if="detail.feeling">
            <div class="container">
                <h2 v-html="$t('career_intern_story')">實習心得</h2>
                <ul>
                    <li class="clearfix boxed boxed--border p-5" v-for="feeling in detail.feeling">
                        <div class="row ">
                            <div class="col-sm-2 col-12 text-left">
                                <img :alt="feeling.people_name" :src="feeling.cover" class="image--md">
                            </div>
                            <div class="col-sm-10 col-12">
                                <strong class="d-block mb-0">{{feeling.people_name}}</strong>
                                <em class="d-block mb-2">{{feeling.job}}</em>
                                <p class="h3">{{feeling.title}}</p>
                                <hr/>
                                <div class="switchable__text">
                                    <ul class="accordion accordion-2">
                                        <li>
                                            <div class="accordion__title border-0 mb-0">
                                                <span class="h5" v-html="$t('career_intern_view')">看看他的故事</span>
                                            </div>
                                            <div class="accordion__content ">
                                                <p class="lead cms-content" v-html="feeling.description"></p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>

        <section class="imageblock switchable h-auto bg--dark" v-if="detail.require">
            <div class="imageblock__content col-lg-6 col-md-4 pos-right">
                <div class="background-image-holder">
                    <img alt="image" :src="$data.siteUrl + '/img/case-3.png'" />
                </div>
            </div>
            <div class="container pos-vertical-strat">
                <div class="row">
                    <div class="col-lg-5 col-md-7 sub-lead">
                        <h3 v-html="$t('career_intern_cta')">馬上加入實習計畫</h3>
                        <ul>
                            <li class="mt-5 mb-5" v-for="require in detail.require">
                                <strong>{{require.name}}：</strong>
                                <p v-html="require.description"></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    // import {readCookie} from "@/libs/js/cookie.js"
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
	export default {
        data:function(){
            return {
                detail:null,
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        methods:{
            fetchData:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'csrintern/lastest',
                    
                })
                    .then(function (response) {
                        _this.detail=response.data;
                        // _this.$window.initAll();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        created() {
            this.fetchData();
            setMetaInfo( this, undefined, undefined, undefined, undefined )
        },
    };
</script>