<template>
    <div class="loading" :class="isLoading? '' : 'hide' ">
    <!-- <div class="loading"> -->
        <div class="loadingio-spinner">
            <div class="ldio">
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>

</style>
<!-- 個單元都沒有去更改mutations.js中的isLoading，所以等同無用 -->
<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";

export default {
    data() {
        return {}
    },
    computed:{
        // ...mapState(['isLoading']),
        isLoading(){
            return this.$store.state.isLoading
        },
    },
    methods: {
        ...mapActions(["setLoadingStatus",])
    },
    mounted(){
        // this.setLoadingStatus(false);
    }
};
</script>