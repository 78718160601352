<template>
	<div v-if="detail">
    <!-- <section class="p-0" v-if="detail.detail_photo">
      <div class="container-fluid">
        <div class="row">
          <img class="w-100" :alt="detail.name" :src="detail.detail_photo">
        </div>
      </div>
    </section> 
    
    <section class="py-5 breadcrumbsSection">
      <div class="container">
        <ol class="breadcrumbs">
          <li>
            <router-link :to="{ name: 'home'}" v-html="$t('nav_home')">首頁</router-link>
          </li>

          <li>
            <router-link :to="{ name: 'solution', query: { cate:'all',page:'1' } }" v-html="$t('nav_solution')">解決方案</router-link>
          </li>

          <li>{{ detail.name }}</li>
        </ol>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-md-7">
            <h4 class="h5 mb-3">{{ detail.sample_name }}</h4>
            <h1 class="h2 mb-5" v-html="detail.name"></h1>
            <p class="sub-lead" v-html="detail.quote"></p>
          </div>
        </div>
      </div>
    </section>

    <section class="cover imagebg text-left" data-overlay="5" :style="{ 'background-image': 'url(' + detail.background + ')' }">
      <div class="container">
        <div class="row">
          <div class="col-12 title-bg">
            <p class="sub-lead" v-html="$t('nav_solution')">解決方案</p>
            <h2 class="h3" v-html="detail.method_title"></h2>
          </div>
        </div>
      </div>
    </section>

    <div v-if="editor" v-html="editor"></div>

    <section class="cover imagebg text-left bg--primary-4" v-if="detail.conclusion">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="h3" v-html="detail.conclusion"></p>
          </div>
        </div>
      </div>
    </section> -->

    <!-- pb content -->
    <div v-if="detail.description_pb" v-html="$fixPbImgSrcForDev(detail.description_pb)"></div>

    <recommend-product v-if="detail.product_category" :parentCategory="detail.product_category_slug" />

    <hr v-if="detail.product_category && detail.related_solution" class="my-0" />
    
    <recommend-item v-if="detail.related_solution" :type="'solution'" :items="detail.related_solution" />

    <channel-box v-bind:blocks="['contact', 'contact-custom', 'global-location']" />
  </div>
</template>

<style lang="less">
  .title-bg {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
</style>

<script>
  import channelBox from "../components/ChannelBox.vue";
  import recommendProduct from "../components/recommend-product.vue";
  import recommendItem from "../components/recommend-item.vue";
  import apibase from "@/static/apibase.js";
  import axios from 'axios';
  import { setMetaInfo } from "@/libs/js/metaInfo.js"
    import { mapState, mapActions } from "vuex";

	export default {
    data() {
      return {
        slug: this.$route.params.slug,
        detail: null,
        editor: null,
        metaData: {}
      }
    },
    
    metaInfo() {
      return this.metaData
    },

    methods: {
      ...mapActions(['fetchSolutionDetail','setSelectedContactTopic']),
      fetchData() {
        let _this = this;
        this.fetchSolutionDetail(_this.$route.params.slug);

        // axios({
        //   method: 'get',
        //   baseURL: apibase,
        //   url: 'solution/detail/' + _this.$route.params.slug
        // })
        // .then((response) => {
        //   var metaTitle;
        //   var metaDesc;

        //   _this.detail = response.data.row;

        //   if (_this.detail.meta_title.trim() != '' && _this.detail.meta_title != undefined) metaTitle = _this.detail.meta_title;
        //   else metaTitle = _this.detail.name;

        //   if (_this.detail.meta_desc.trim() != '' && _this.detail.meta_desc != undefined) metaDesc = _this.detail.meta_desc;
        //   else metaDesc = _this.$t('solution_banner_title');

        //   setMetaInfo(_this, metaTitle, window.location.href, metaDesc, _this.detail.meta_cover);
          
        //   _this.fetchEditor();
        // })
        // .catch((error) => {
        //   console.log(error);
        // });
      },

      //???應該是讀舊內容？
      //這個就是pb內容，description_pb
      // fetchEditor() {
      //   let _this = this;

      //   axios({
      //     method: 'get',
      //     url: _this.detail.description,  
      //   })
      //   .then((response) => {
      //     _this.editor = response.data;
      //     // _this.$window.initAll();
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      // },

      setDesc(){
        var metaTitle;
        var metaDesc;

        if (this.detail.meta_title.trim() != '' && this.detail.meta_title != undefined) metaTitle = this.detail.meta_title;
        else metaTitle = this.detail.name;

        if (this.detail.meta_desc.trim() != '' && this.detail.meta_desc != undefined) metaDesc = this.detail.meta_desc;
        else metaDesc = this.$t('solution_banner_title');
        setMetaInfo(this, metaTitle, window.location.href, metaDesc, this.detail.meta_cover);
      },

      parseRoute() {
        this.detail = null;
        this.editor = null;

        if (!this.$route.params.slug) this.$router.push({ name: 'solution', query: { cate: 'all', page: '1' } });
        
        this.fetchData();
      }
    },

    created() {
      this.parseRoute();
      this.setSelectedContactTopic('solutions');
    },
    computed:{
        ...mapState(['solutionDetail']),
    },
    // mounted() {
    //   this.$nextTick(function () {
    //     setTimeout(function() {
    //       window.pagebuilder_clip_script_init();
    //       _global.init();
    //     }, 3000);
    //   });
    // },

    components: {
      'channel-box': channelBox,
      'recommend-product': recommendProduct,
      'recommend-item': recommendItem
    },

    watch: {
      '$route'() {
        this.parseRoute();
      },
      solutionDetail(val){
        console.log(val)
        this.detail = val;
        this.setDesc();
        setTimeout(() => {
            this.$window.pagebuilder_clip_script_init();
        }, 100);
      },
    }
  };
</script>