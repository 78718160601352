<template>
    <div>
        <section class="cover imagebg text-left space--sm" data-overlay="6">
            <div class="background-image-holder">
                <img alt="background" :src="$data.siteUrl + '/img/banner-investors.jpg'" />
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1 v-html="$t('investor_governance_banner_title')">公司治理</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('nav_investor')">投資人專區</li>
                    <li v-html="$t('nav_investorGovernance')">公司治理</li>
                </ol>
            </div>
        </section>
        <section v-if="items">
            <div class="container">
                <!-- <h2 v-html="$t('investor_governance_section_1_title')">公司組織架構</h2> -->
                <h3 class="text-left" v-html="items.rows[0].name">公司組織</h3>
                <img class="w-100" :alt="items.rows[0].name" :src="items.rows[0].cover" v-if="items.rows[0].cover">
                <p v-if="items.rows[0].description!=''" v-html="items.rows[0].description"></p>
                <div class="row same-height-col">
                    <div class="col-12 col-md-6" v-if="items.rows[0].file_6">
                       <div class="boxed border">
                            <p class="mb-2 h4" v-html="$t('investor_governance_section_0_item_1')">各單位主要職掌及業務</p>
                            <a class="btn-view" :href="items.rows[0].file_6" target="_blank" v-html="$t('ui_download')">檢視檔案</a>
                       </div>
                    </div>
                    <div class="col-12 col-md-6" v-if="items.rows[0].file_7">
                       <div class="boxed border">
                            <p class="mb-2 h4" v-html="$t('investor_governance_section_0_item_2')">功能性委員會及組織成員</p>
                            <a class="btn-view" :href="items.rows[0].file_7" target="_blank" v-html="$t('ui_download')">檢視檔案</a>
                       </div>
                    </div>
                    <div class="col-12 col-md-6" v-if="items.rows[0].file_8">
                       <div class="boxed border">
                            <p class="mb-2 h4" v-html="$t('investor_governance_section_0_item_3')">公司治理運作情形</p>
                            <a class="btn-view" :href="items.rows[0].file_8" target="_blank" v-html="$t('ui_download')">檢視檔案</a>
                       </div>
                    </div>
                </div>
            </div>
        </section>
        <section v-if="items">
            <div class="container">
                <h3 class="text-left" v-html="$t('investor_governance_section_1_title')">董事會</h3>

                
                <div class="row same-height-col">
                    <div class="col-12 col-md-6" v-if="(getLang()=='tw'||getLang()=='cn')&&items.rows[0].file_1">
                       <div class="boxed border">
                            <p class="mb-2 h4" v-html="$t('investor_governance_section_1_item_1')">最近年度董事會績效考核自評結果檔案</p>
                            <a class="btn-view" :href="items.rows[0].file_1" target="_blank" v-html="$t('ui_download')">檢視檔案</a>
                       </div>
                    </div>
                    <div class="col-12 col-md-6" v-if="items.rows[0].file_2">
                       <div class="boxed border">
                            <p class="mb-2 h4" v-html="$t('investor_governance_section_1_item_2')">董事會成員多元化方針及落實執行情形檔案</p>
                            <a class="btn-view" :href="items.rows[0].file_2" target="_blank" v-html="$t('ui_download')">檢視檔案</a>
                       </div>
                    </div>
                    <div class="col-12 col-md-6" v-if="items.rows[0].file_4">
                       <div class="boxed border">
                            <p class="mb-2 h4" v-html="$t('investor_governance_section_1_item_3')">董事會成員</p>
                            <a class="btn-view" :href="items.rows[0].file_4" target="_blank" v-html="$t('ui_download')">檢視檔案</a>
                       </div>
                    </div>
                    <div class="col-12 col-md-6" v-if="items.rows[0].file_5">
                       <div class="boxed border ">
                            <p class="mb-2 h4" v-html="$t('investor_governance_section_1_item_4')">董事會決議事項</p>
                            <a class="btn-view" :href="items.rows[0].file_5" target="_blank" v-html="$t('ui_download')">檢視檔案</a>
                       </div>
                    </div>
                </div>
            </div>
        </section>
        <section v-if="items2">
            <div class="container">
                 <h3 class="text-left" v-html="$t('investor_governance_section_2_title')">公司治理規章</h3>
                 <div class="row same-height-col">
                    <div class="col-12 col-md-6" v-for="item,index in items2.rows" v-if="item.id!='28'&&item.id!='30'">
                       <div class="boxed border">
                            <p class="mb-2 h4">{{item.name}}</p>
                            <a class="btn-view" :href="item.file" target="_blank" v-html="$t('ui_download')">檢視檔案</a>
                       </div>
                    </div>
                 </div>
            </div>
        </section>
        <section>
            <div class="container">
                <h3 class="text-left" v-html="$t('investor_governance_section_3_title')">內部稽核運作</h3>
                <p v-html="$t('investor_governance_section_3_p')">內部稽核之目的，在於協助董事會及經理人檢查及覆核內部控制制度之缺失及衡量營運之效果及效率，並適時提供改進建議，以確保內部控制制度得以持續有效實施及作為檢討修正內部控制制度之依據。<br/><br/>本公司設置隸屬董事會直接指揮之專責內部稽核單位，並受獨立董事之督導，稽核單位應置主管一人，由董事會任免之，且稽核主管應列席董事會進行稽核業務報告，稽核主管及其所屬稽核人員共計4人。</p>
            </div>
        </section>
        <section>
            <div class="container">
                <h3 class="text-left" v-html="$t('investor_governance_section_4_title')">內部稽核單位之運作</h3>
                <p v-html="$t('investor_governance_section_4_p')">稽核室應基於風險評估結果決定稽核項目，編製年度稽核計劃，年度稽核計劃應列明每月、每季應稽核之項目，據以執行專案(個案)稽核作業之依據。<br/><br/>內部稽核人員應與受查單位就年度稽核項目查核結果充分溝通，對於評估所發現之內部控制制度缺失及異常缺失事項，包括主管機關檢查所發現、內部稽核作業所發現、內部控制制度聲明書所列、自行評估及會計師專案審查所發現之各項缺失，應據實揭露於稽核報告，並於該報告陳核後加以追蹤，至少按季作成追蹤報告至改善為止，以確定相關單位業已及時採取適當之改善措施。<br/><br/>稽核人員應覆核各單位及子公司之內部控制制度自行檢查報告，併同稽核單位所發現之內部控制缺失及異常事項改善情形，以作為評估整體內部控制制度有效性及出具內部控制制度聲明書之主要依據。<br/><br/>內部稽核人員如發現有重大違規情事或公司有受重大損害之虞時，應立即作成報告陳核，並通知獨立董事。</p>
            </div>
        </section>
        <section v-if="items&&items.rows[0].file_3&&(getLang()=='tw'||getLang()=='cn')">
            <div class="container">
                 <div class="row">
                     <div class="col-12 col-sm-6 col-md-6 ">
                        <div class="boxed border w-100 d-flex justify-content-between align-items-center">
                            <span v-html="$t('investor_governance_item_1')">獨立董事監察人與內部稽核主管及會計師之溝通情形</span>
                            <a class="btn-view" :href="items.rows[0].file_3" target="_blank" v-html="$t('ui_download')">檢視檔案</a>
                        </div>
                     </div>
                 </div>
            </div>
        </section>

        <!-- section5 -->
        <section>
            <div class="container">
                <h3 class="text-left">
                    <!-- 薪資報酬委員會 -->
                    {{ $t('investor_governance_section_5_title') }}
                </h3>
                <p v-html="$t('investor_governance_section_5_p')"></p>
            </div>
        </section>
        <section>
            <div class="container">
                 <div class="row">
                    <div class="col-12 col-md-6">
                       <div class="boxed border">
                            <p class="mb-2 h4" v-html="$t('investor_governance_section_5_item_1')">薪資報酬委員會組織規程</p>
                            <a class="btn-view" v-if="(getLang()=='tw'||getLang()=='cn')" href="/files/MR-TW024-V6.pdf" target="_blank" v-html="$t('ui_download')">檢視檔案</a>
                            <a class="btn-view" v-else href="/files/MR-TW024-V6_en.pdf" target="_blank" v-html="$t('ui_download')">檢視檔案</a>
                       </div>
                    </div>
                    <div class="col-12 col-md-6">
                       <div class="boxed border">
                            <p class="mb-2 h4" v-html="$t('investor_governance_section_5_item_2')">薪資報酬委員會運作情形</p>
                            <!-- <a class="btn-view" v-if="(getLang()=='tw'||getLang()=='cn')" href="/files/2022-remuneration-committee.pdf" target="_blank" v-html="$t('ui_download')">檢視檔案</a> -->
                            <a class="btn-view" v-if="(getLang()=='tw'||getLang()=='cn')" href="/files/Operations_of_the_Remuneration_Committee.pdf" target="_blank" v-html="$t('ui_download')">檢視檔案</a>
                            <a class="btn-view" v-else href="/files/2022-remuneration-committee_en.pdf" target="_blank" v-html="$t('ui_download')">檢視檔案</a>
                       </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- section_6 -->
        <section>
            <div class="container">
                <h3 class="text-left">
                    <!-- 企業風險管理 -->
                    {{ $t('investor_governance_section_6_title') }}
                </h3>
                
                <!-- section_6_1 -->
                <h4 class="text-left" style="font-weight:bold;">
                    <!-- 風險管理政策與程序 -->
                    {{ $t('investor_governance_section_6_1_title') }}
                </h4>
                <p v-html="$t('investor_governance_section_6_1_desc')"></p>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6" v-for="item,index in items2.rows" v-if="item.id=='30'">
                        <div class="boxed border">
                            <p class="mb-2 h4">{{item.name}}</p>
                            <a class="btn-view" :href="item.file" target="_blank" v-html="$t('ui_download')">檢視檔案</a>
                        </div>
                    </div>
                </div>
                
                <!-- section_6_2 -->
                <h4 class="text-left mt-3" style="font-weight:bold;">
                    <!-- 風險治理與管理組織架構 -->
                    {{ $t('investor_governance_section_6_2_title') }}
                </h4>
                <div style="text-align:center;width:100%;overflow:visible;">
                    <!-- <img :src="$data.siteUrl+'/img/Org-03.png'" alt="風險治理與管理組織架構" style="min-width:1000px;margin:-85px -2000px -50px -2000px;"/> -->
                    <div class="treeChart treeChart--section6">
                        <h5 class="treeChart__root">
                            <!-- 董事會 -->
                            {{ $t('investor_governance_section_6_2_chart_0') }}
                        </h5>
                        <div class="treeChart__branch  treeChart__branch--section6">
                            <strong>
                                <!-- 稽核室 -->
                                {{ $t('investor_governance_section_6_2_chart_branch') }}
                            </strong>
                        </div>
                        <h5 class="treeChart__top">
                            <!-- 審計委員會 -->
                            {{ $t('investor_governance_section_6_2_chart_1') }}
                        </h5>
                        <h5 class="treeChart__top">
                            <!-- 經營決策小組 -->
                            {{ $t('investor_governance_section_6_2_chart_2') }}
                        </h5>
                        <h5 class="treeChart__top">
                            <!-- 風險管理人員 -->
                            {{ $t('investor_governance_section_6_2_chart_3') }}
                        </h5>
                        <h5 class="treeChart__top treeChart__top--noLine">
                            <!-- 風險責任人 -->
                            {{ $t('investor_governance_section_6_2_chart_4') }}
                        </h5>
                    </div>
                </div>
                <p>
                    {{ $t('investor_governance_section_6_2_desc_1')}}
                    
                </p>

                <table>
                    <tr>
                        <th width="110px;">
                            <!-- 風險管理層級 -->
                            {{ $t('investor_governance_section_6_2_th_1') }}
                        </th>
                        <th>
                            <!-- 風險管理運作 -->
                            {{ $t('investor_governance_section_6_2_th_2') }}
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <!-- 第一線責任 -->
                            {{ $t('investor_governance_section_6_2_tr_1_td_1') }}
                        </td>
                        <td>{{ $t('investor_governance_section_6_2_tr_1_td_2') }}</td>
                    </tr>
                    <tr>
                        <td>
                            <!-- 第二線責任 -->
                            {{ $t('investor_governance_section_6_2_tr_2_td_1') }}
                        </td>
                        <td>{{ $t('investor_governance_section_6_2_tr_2_td_2') }}</td>
                    </tr>
                    <tr>
                        <td>
                            <!-- 第三線責任 -->
                            {{ $t('investor_governance_section_6_2_tr_3_td_1') }}
                        </td>
                        <td>{{ $t('investor_governance_section_6_2_tr_3_td_2') }}</td>
                    </tr>
                </table>
                <p>
                    {{ $t('investor_governance_section_6_2_desc_2') }}
                    <!-- 稽核室為公司內部獨立監督機制，督導各執行單位遵循核決權限與相關管理辦法及作業程序，並進行有關風險管理是否有效落實執行之評估，確保制度落實與遵循。 -->
                </p>
                <p>
                    {{ $t('investor_governance_section_6_2_desc_3') }}
                    <!-- 審計委員會協助董事會督導風險管理成效，並透過會議報告瞭解運作情形。 -->
                </p>
                
                
                <!-- section_6_3 -->
                <h4 class="text-left mt-5" style="font-weight:bold;">
                    <!-- 風險管理運作情形 -->
                    {{ $t('investor_governance_section_6_3_title') }}
                </h4>
                <p class="mb-1">{{ $t('investor_governance_section_6_3_desc') }}</p>
                <ul class="bullets row mx-0">
                    <li>{{ $t('investor_governance_section_6_3_item1') }}</li>
                    <li>{{ $t('investor_governance_section_6_3_item2') }}</li>
                    <li style="flex:0 0 100%;">
                        {{ $t('investor_governance_section_6_3_item3') }}
                        <ul class="bullets mx-0" style="width: 100%;">
                            <li>{{ $t('investor_governance_section_6_3_item3_1') }}</li>
                            <li>{{ $t('investor_governance_section_6_3_item3_2') }}</li>
                            <li>{{ $t('investor_governance_section_6_3_item3_3') }}</li>
                        </ul>
                    </li>
                </ul>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6" v-for="item,index in items2.rows" v-if="item.id=='28'" :key="index">
                        <div class="boxed border">
                            <p class="mb-2 h4">{{item.name}}</p>
                            <a class="btn-view" :href="item.file" target="_blank" v-html="$t('ui_download')">檢視檔案</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- section7 -->
        <section>
            <div class="container">
                <h3 class="text-left" v-html="$t('investor_governance_section_7_title')">營運持續管理</h3>
                <!-- 7_1 -->
                <h4 class="text-left" style="font-weight:bold;" v-html="$t('investor_governance_section_7_1_title')">政策與目的</h4>
                <p v-html="$t('investor_governance_section_7_1_p')">信邦長期追求為客戶創造價值，為能提供不間斷之服務，建置營運持續管理(Business Continuity Management, BCM) 機制，並對具有中斷營運之風險，模擬風險情境並評估對組織營運產生的衝擊，依風險情境建立相應營運持續計畫(Business Continuity Plan, BCP)，確保風險評估之周延性與預防措施，藉由減少營運衝擊、縮短復原時間以達到營運持續目標。</p>
                <!-- 7_2 -->
                <h4 class="text-left" style="font-weight:bold;" v-html="$t('investor_governance_section_7_2_title')">營運持續管理執行團隊與運作</h4>
                <p v-html="$t('investor_governance_section_7_2_p1')">營運持續管理執行團隊由集團總經理擔任召集人，對於可能造成營運中斷之風險及關鍵資源相關性，召集各部門探討並制定營運持續計畫。</p>
                <p v-html="$t('investor_governance_section_7_2_p2')">營運持續管理為確保非計畫性之災害或事件發生時，仍得以保護重要營運過程不受重大資訊系統失效或災害的影響；此為持續性、週期性之管理系統，包含風險評估與營運衝擊分析、決定營運持續管理策略、發展與實行營運持續管理、營運持續計畫演練、維護與檢核。</p>
                <div class="treeChart treeChart--section7">
                    <h5 v-html="$t('investor_governance_section_7_2_chart_lv1')" class="treeChart__root">集團<br>總經理</h5>
                    <div class="treeChart__branch">
                        <strong>BCM PM</strong>
                    </div>
                    <div class="treeChart__lv2">
                        <div class="treeChart__coordination">
                            <h6 v-html="$t('investor_governance_section_7_2_chart_coordination_title')">營運統籌</h6>
                            <ul>
                                <li v-html="$t('investor_governance_section_7_2_chart_coordination_item1')">財務</li>
                                <li v-html="$t('investor_governance_section_7_2_chart_coordination_item2')">策略溝通</li>
                                <li v-html="$t('investor_governance_section_7_2_chart_coordination_item3')">客戶服務</li>
                                <li v-html="$t('investor_governance_section_7_2_chart_coordination_item4')">供應鏈</li>
                                <li v-html="$t('investor_governance_section_7_2_chart_coordination_item5')">研發</li>
                                <li v-html="$t('investor_governance_section_7_2_chart_coordination_item6')">製造</li>
                                <li v-html="$t('investor_governance_section_7_2_chart_coordination_item7')">法務</li>
                                <li v-html="$t('investor_governance_section_7_2_chart_coordination_item8')">行政</li>
                                <li v-html="$t('investor_governance_section_7_2_chart_coordination_item9')">人資</li>
                                <li v-html="$t('investor_governance_section_7_2_chart_coordination_item10')">IT</li>
                            </ul>
                        </div>
                    </div>
                    <div class="treeChart__repsponse">
                        <h6 v-html="$t('investor_governance_section_7_2_chart_repsponse_title')">緊急應變</h6>
                        <p v-html="$t('investor_governance_section_7_2_chart_repsponse_p')">各地緊急應變小組：包含各事業處、工廠、採購、倉庫、行政、財務、HR、法務、IT</p>
                    </div>
                </div>
                <!-- 7_3 -->
                <h4 class="text-left" style="font-weight:bold;" v-html="$t('investor_governance_section_7_3_title')">營運持續計畫</h4>
                <p v-html="$t('investor_governance_section_7_3_p1')">依照風險鑑別與衡量結果，針對可能造成營運中斷之風險，展開營運持續管理計畫。營運持續管理計畫包含預防措施、緊急應變措施、營運持續運作與復原措施等；透過風險情境模擬，共同探討集團營運因應對策與應變措施等。</p>
                <p v-html="$t('investor_governance_section_7_3_p2')">為確保營運持續計畫有效性，制定演練計畫並依據演練結果進行檢討、修改營運持續計畫。採取持續改善循環式管理(Plan-Do-Check-Act, PDCA)，確實達到營運持續管理之目的且讓同仁具有風險管理之觀念。BCM召集人得視每年演練結果與新增風險主題召集會議進行討論，確保營運持續計劃有效性和完整性。</p>
                <table>
                    <tr>
                        <th v-html="$t('investor_governance_section_7_3_th1')">風險主題</th>
                        <th v-html="$t('investor_governance_section_7_3_th2')">營運持續計畫重點</th>
                    </tr>
                    <tr>
                        <td v-html="$t('investor_governance_section_7_3_r1_td1')">傳染病</td>
                        <td>
                            <ul>
                                <li v-html="$t('investor_governance_section_7_3_r1_td2_li1')">因傳染病影響供應商與物流供貨之應變措施</li>
                                <li v-html="$t('investor_governance_section_7_3_r1_td2_li2')">工廠員工因染傳染病之情境模擬，針對關鍵資源擬定各項應變措施</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td v-html="$t('investor_governance_section_7_3_r2_td1')">供應商因突發或不可預料的意外事故，導致原材料斷料</td>
                        <td>
                            <ul>
                                <li v-html="$t('investor_governance_section_7_3_r2_td2_li1')">掌握多重供貨來源，快速滿足需求</li>
                                <li v-html="$t('investor_governance_section_7_3_r2_td2_li2')">集團內異地備援，廠區間互相調料</li>
                                <li v-html="$t('investor_governance_section_7_3_r2_td2_li3')">進行後備廠商供貨</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td v-html="$t('investor_governance_section_7_3_r3_td1')">地緣政治</td>
                        <td>
                            <ul>
                                <li v-html="$t('investor_governance_section_7_3_r3_td2_li1')">制定防災相關訓練計畫，確保員工安全為優先</li>
                                <li v-html="$t('investor_governance_section_7_3_r3_td2_li2')">重要資訊系統異地備援</li>
                                <li v-html="$t('investor_governance_section_7_3_r3_td2_li3')">緊急預備金規劃</li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </div>
        </section>
    </div>
    
</template>
<style lang="less" scoped>
.btn-view{
    white-space: nowrap;
}
span+.btn-view{
    margin-left: 1rem;
}
table,th,td{
    border: 1px solid #eee!important;
    border-collapse: collapse;
}
table{
    ul{
        list-style: disc;
        margin-left: 0px;
        /* set to 0 if your not using a list-style-type */
        padding-left: 20px;
    }
}

.treeChart{
    position: relative;
    &--section6{
        margin: 15px 0 30px 0;
        transform: translateX(72px);
    }
    &--section7{
        margin: 15px 0 45px 0;
    }
    .treeChart__root{
        position: relative;
        display: block;
        padding: 1.125rem;
        background-color: #00578e;
        color: white;
        line-height: 1.4;
        width: 50%;
        margin: 0 auto;
        margin-bottom: 30px;
        text-align: center;
        font-size: 1rem;
        &::after{
            content: '';
            position: absolute;
            bottom: -30px;
            right: 50%;
            width: 1px;
            height: 30px;
            background-color: #ccc;
        }
    }
    &.treeChart--section7 .treeChart__root{
        margin-bottom: 0;
        &::after{
            display: none;
        }
    }
    .treeChart__branch{
        position: absolute;
        display: inline-block;
        right: 50%;
        padding: 2rem;
        border-right: 1px solid #ccc;
        line-height: 1.4;
        strong{
            position: relative;
            overflow: visible;
            display: inline-block;
            padding: 1rem 2rem;
            background-color: #00578e;
            color: white;
            font-size: 1rem;
            white-space: nowrap;
            &::after{
                content: '';
                position: absolute;
                top: 50%;
                right: -2rem;
                width: 2rem;
                height: 1px;
                background-color: #ccc;
            }
        }
        &--section6{
            top:52px;
            right: 75%;
            border-right: none;
            strong{
                padding: 0.99rem 2rem;
                background-color:#4F71BE;
            }
        }
        &--section7{
            top:82px
        }
    }
    .treeChart__top{
        display: block;
        position: relative;
        padding: 1.125rem;
        background-color:lighten(#00578e,10);
        color: white;
        line-height: 1.4;
        width: 50%;
        margin: 0 auto;
        text-align: center;
        font-size: 1rem;
        margin-bottom: 30px;
        &::after{
            content: '';
            position: absolute;
            bottom: -30px;
            right: 50%;
            width: 1px;
            height: 30px;
            background-color: #ccc;
        }
        &--noLine{
            margin-bottom: 0;
            &::after{
                display: none;
            }
        }
    }
    .treeChart__lv2{
        display: flex;
        margin-top: 7.4rem;
        *{
            color: white;
        }
    }
    .treeChart__coordination{
        flex: 0 0 100%;
        background-color:lighten(#00578e,10);
        padding-top: 1rem;
        h6{
            font-size: 1.125rem;
            display: block;
            text-align: center;
            margin-bottom: 1rem;
        }
        ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            background-color:lighten(#00578e,20);
            @media screen and (max-width:767px){
                flex-direction: column;
            }
        }
        li{
            flex:0 0 20%;
            padding: 1rem;
            border: 1px solid rgba(255,255,255,0.3);
            border-left:none;
            border-bottom: none;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1.4;
            text-align: center;
            // margin-top: -1px ;
            // margin-left: -1px;
            // &:first-child{
            //     border: none;
            // }
            @media screen and (max-width:1280px){
                flex:0 0 50%;
            }
            @media screen and (max-width:767px){
                // -webkit-writing-mode: vertical-lr;
                // writing-mode: vertical-lr;
                // justify-content: flex-start;
                flex:0 0 100%;
                padding: 1rem 0;
                border-left: none;
                border-top: 1px solid rgba(255,255,255,0.3);
            }
        }
    }
    .treeChart__repsponse{
        background-color:#4F71BE;
        padding: 1rem;
        margin-top: 1rem;
        *{
            color: white;
        }
        h6{
            font-size: 1.125rem;
            display: block;
            text-align: center;
            margin-bottom: 0.5rem;
        }
        p{
            text-align: center;
            line-height: 1.4;
        }
    }
}
</style>
<script>
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    import { mapGetters } from "vuex";
    // import {readCookie} from "@/libs/js/cookie.js"
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    export default {
        data:function(){
            return {
                items:null,
                items2:null,
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        methods:{
            ...mapGetters(["getLang"]),
            onlyShowInCh:function(idx){
                if(idx>=10){
                    if(this.getLang()!='tw'&&this.getLang()!='cn') return false;
                }
                return true
            },
            fetchItems:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'invcompany/items',
                    
                })
                    .then(function (response) {
                        // console.log(response)
                        _this.items=response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchItems2:function(){
                var _this=this;
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'invrule/items',
                    
                })
                    .then(function (response) {
                        _this.items2=response.data;
                        console.log('_this.items2',_this.items2);
                        // _this.$window.initAll();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        created() {
            setMetaInfo( this, this.$t('page_title_investor_corporate_governance'), undefined, undefined, undefined,false,true )
            this.fetchItems();
            this.fetchItems2();
        }
    };

</script>