<template class="product-detail">
	<div v-if="detail">
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home' }" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name:'product', query: { cate: 'all', page: '1' }}" v-html="$t('nav_products')">產品</router-link>
                    </li>
                    <li v-if="$route.params.category != null">
                        <!-- <router-link :to="{ name: 'product', query: { cate: $route.params.category, page: '1' }}">{{ categoryName }}</router-link> -->
                        <router-link :to="{ name: 'product', query: { cate: $route.params.category, page: '1' }}">{{ categoryName }}</router-link>
                    </li>
                    <li>{{ detail.name }}</li>
                </ol>
            </div>
        </section>

		<section>
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-5 col-lg-7">
                        <div class="switchable__text">
                            <h1>{{ detail.name }}</h1>
                            <hr class="short">
                            <p class="lead" v-html="detail.description"></p>
                        </div>
                    </div>
                </div>
                <!--end of row-->
            </div>
            <!--end of container-->
        </section>

        <section class="pb-0" v-if="detail.photo_main">
                <div class="row justify-content-center align-items-start" v-lazy-container="{ selector: 'img' }">
                    <img class="w-100 h-auto" :alt="detail.cover_desc ? detail.cover_desc:detail.name" :data-src="detail.photo_main"/>
                </div>
            <!--end of container-->
        </section>

        <section class="bg--secondary product-detail__accordion">
            <div class="container">
                <div class="row justify-content-around">
                    <div class="col">
                        <div class="d-flex flex-wrap align-self-stretch">
                            <div class="w-50 product-detail__accordion-item" v-if="detail.title1!=''&&detail.info1!=''">
                                <div class="accordion__title">
                                    <span class="h5">{{detail.title1}}</span>
                                </div>
                                <div class="accordion__content">
                                    <p v-html="detail.info1"></p>
                                </div>
                            </div>
                            <div class="w-50 product-detail__accordion-item" v-if="detail.title2!=''&&detail.info2!=''">
                                <div class="accordion__title">
                                    <span class="h5">{{detail.title2}}</span>
                                </div>
                                <div class="accordion__content">
                                   <p v-html="detail.info2"></p>
                                </div>
                            </div>
                            <hr class="w-100 mt-5 mb-5" v-if="detail.title3!=''"/>
                            <div class="w-50 product-detail__accordion-item" v-if="detail.title3!=''&&detail.info3!=''">
                                <div class="accordion__title">
                                    <span class="h5">{{detail.title3}}</span>
                                </div>
                                <div class="accordion__content">
                                    <p v-html="detail.info3"></p>
                                </div>
                            </div>
                            <div class="w-50 product-detail__accordion-item" v-if="detail.title4!=''&&detail.info4!=''">
                                <div class="accordion__title">
                                    <span class="h5">{{detail.title4}}</span>
                                </div>
                                <div class="accordion__content">
                                    <p v-html="detail.info4"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- pb content -->
                <div v-if="detail.info_pb" class="product-detail_pb" v-html="detail.info_pb"></div>

                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="w-100 pt-5">
                            <div data-notification-link="contact" class="btn btn--primary block  type--uppercase pt-3 pb-3 mt-5">
                                <span class="btn__text" v-html="$t('ui_ask')">與我們洽詢</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="w-100 pt-5">
                            <div class="pt-2 pb-2 mt-5 text-center">
                                <span v-html="$t('product_shareto')">分享至</span>
                                <a class="product-detail-socialmedia__btn" :href="decodeURL" target="_blank">
                                    <i class="socicon-facebook"></i>
                                </a>
                                <a class="product-detail-socialmedia__btn" :href="decodeLinkedlnURL" target="_blank">
                                    <i class="socicon-linkedin"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12 col-md-6">
                        <div class="w-100 pt-5">
                            <div class="fb-share-button btn btn--primary bg-primary block  type--uppercase pt-3 pb-3 mt-5" :data-href="currentURL" data-layout="button" data-size="small">
                                <a :href="decodeURL" target="_blank" class="fb-xfbml-parse-ignore text-white">分享至 FB</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="w-100 pt-5">
                            <div class="btn btn--primary bg-primary block  type--uppercase pt-3 pb-3 mt-5">
                                <a :href="decodeLinkedlnURL" target="_blank" class="text-white">分享至 Linkedln</a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </section>

        <div class="container">
            <hr class="m-0">
        </div>

        <recommend-product :items="detail.related_product" v-if="detail.related_product"/>
        <channel-box v-bind:blocks="['solution','contact-custom','global-location']"/>
    </div>
</template>
<script>
    import channelBox from "../components/ChannelBox.vue";
    import recommendProduct from "../components/recommend-product.vue";
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    import {setMetaInfo} from "@/libs/js/metaInfo.js"
    // import {readCookie} from "@/libs/js/cookie.js" 
    // import { mapActions } from "vuex";
    import { mapState, mapActions, mapGetters } from "vuex";
     
    export default {
        data() {
            return {
                productCats: null,
                category:this.$route.params.category,
                slug:this.$route.params.slug,
                detail:null,
                categoryName: '',
                photos:null,
                currentURL: window.location.href,
                decodeURL: null,
                decodeLinkedlnURL: null,
                metaData: {}
            }
        },

        metaInfo() {
            return this.metaData
        },

        methods: {
            ...mapActions(['setSelectedContactTopic','fetchProductCategory']),
            fetchData() {
                let _this = this;
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'product/detail/' + _this.$route.params.slug,
                })
                .then(function (response) {
                    _this.detail = response.data.row;
                    _this.photos = response.data.photos;
                    // _this.$window.initAll();
                    // let category_idx = _this.detail.category_slug.split(',').indexOf(_this.$route.params.category);
                    // _this.categoryName = _this.detail.category_name.split(',')[category_idx];

                    var metaTitle;
                    if(_this.detail.meta_title.trim() != '' && _this.detail.meta_title != undefined) metaTitle = _this.detail.meta_title;
                    else metaTitle = _this.detail.name;
                    
                    var metaDesc;
                    if (_this.detail.meta_desc.trim() != '' && _this.detail.meta_desc!=undefined) metaDesc = _this.detail.meta_desc;
                    else metaDesc=_this.$t('home_banner_p');
                    
                    var metaImg;
                    if (_this.detail.photo_main) metaImg = _this.detail.photo_main;
                    // setMetaInfo(_this, metaTitle, window.location.href, metaDesc, metaImg);
                    setMetaInfo(_this, metaTitle, window.location.href, metaDesc, metaImg,"product",false);
                    if(_this.detail.info_pb){
                        setTimeout(() => {
                            _this.$window.pagebuilder_clip_script_init();
                        }, 100);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            parseRoute() {
                this.detail=null;
                // if(!this.$route.params.category||!this.$route.params.slug) this.$router.push({name:'product'});
                this.fetchData();
                
            }
        },

        created() {
            this.fetchProductCategory();
            this.setSelectedContactTopic('products');
            this.parseRoute();
        },

        components: {
            "channel-box": channelBox,
            "recommend-product": recommendProduct
        },

        mounted() {
            this.decodeURL = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent( this.currentURL ) + '&amp;src=sdkpreparse';
            this.decodeLinkedlnURL ='https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent( this.currentURL );

            // this.$nextTick(function () {
            //     setTimeout(function() {
            //         window.pagebuilder_clip_script_init();
            //         // _global.init();
            //     }, 3000);
            // });
        },
        computed: {
            ...mapState(['productCate']),
        },
        watch: {
            '$route' () {
                this.parseRoute();
            },
            productCate(val) {
                this.productCats = val;
                var _paramsCategories=this.$route.params.category.split(',');
                for(var k in this.productCats){
                    for(var j in _paramsCategories){
                        if(this.productCats[k].category_slug.toLowerCase()==_paramsCategories[j].toLowerCase()){
                            this.categoryName=this.productCats[k].category_name;
                        }
                    }
                }
            },
        }
    };

</script>