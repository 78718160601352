<template>
    <form class="text-left" @submit.prevent="sendFrom">
        <div class="row">
            <div class="col-md-12 mb-3">
                <label class="contact_label" v-html="$t('contact_label_inqiery')">洽詢項目*</label>
                <div class="input-select" v-if="inputInquery">
                    <select id="inquerySelect" class="validate-required" v-model="inputInquery">
                        <option disabled selected v-html="$t('contact_select_inqiery')">請選擇洽詢項目</option>
                        <option :value="inquery.slug" v-for="inquery in contacts">{{inquery.subject}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row" v-if="inputInquery == 'products'">
            <div class="col-md-6" v-if="categories">
                <label class="contact_label" v-html="$t('contact_label_category')">產品類別*</label>
                <div class="input-select">
                    <select class="validate-required" v-model="inputCategory" @change="currCategory = $event.target.options[$event.target.options.selectedIndex].dataset.slug">
                        <option disabled selected v-html="$t('contact_select_category')">請選擇產品類別</option>
                        <option v-for="category,key in categories" :value="category.category_slug" :data-slug="category.category_slug">{{category.category_name}}</option>
                        <option value="ev-charging" v-html="$t('contact_evcharging')">充電解決方案</option>
                        <option value="solar-system" v-html="$t('contact_solar')">太陽光電系統</option>
                        <option value="robotic" v-html="$t('contact_robotic')">機器人解決方案</option>
                        <option value="e-bike" v-html="$t('contact_ebike')">電力輔助自行車</option>
                        <option value="others" v-html="$t('contact_others')">其他</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6" v-if="products">
                <label class="contact_label" v-html="$t('contact_label_product')">產品</label>
                <div class="input-select">
                    <select class="validate-required" v-model="inputProduct">
                        <option disabled selected v-html="$t('contact_select_product')">請選擇產品</option>
                        <option v-for="product, index in filteredRows(products, currCategory, 'category_slug')" :value="product.slug">{{product.name}}</option>
                        <option v-if="currCategory == undefined" value="others" selected v-html="$t('contact_others')">其他</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row" v-if="inputInquery == 'solutions'">
            <div class="col-md-6" v-if="solutions">
                <label class="contact_label" v-html="$t('contact_label_solution')">解決方案類別*</label>
                <div class="input-select">
                    <select class="validate-required" v-model="inputSolution">
                        <option disabled selected v-html="$t('contact_select_solution')">請選擇解決方案類別</option>
                        <option v-for="solution, key in solutions" :data-slug="solution.category_slug" :value="solution.category_slug">{{solution.category_name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label class="contact_label" v-html="$t('contact_label_country')">國家*</label>
                <div class="input-select">
                    <select v-model="inputCountry" v-if="countries">
                        <option disabled selected v-html="$t('contact_select_country')">請選擇國家</option>
                        <option v-for="country in countries">{{ country.name }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <label class="contact_label" v-html="$t('contact_label_city')">城市*</label>
                <input v-model="inputCity" type="text" name="city" />
            </div>
            <div class="col-md-6">
                <label class="contact_label" v-html="$t('contact_label_company')">公司名稱*</label>
                <input v-model="inputCompany" type="text" name="company" />
            </div>
            <div v-if="lang=='tw'||lang=='cn'||lang=='jp'" class="col-md-6 nameCol">
                <div class="field">
                    <label class="contact_label" v-html="$t('contact_label_lname')">姓*</label>
                    <input v-model="inputLName" type="text" name="Lname" />
                </div>
                <div class="field">
                    <label class="contact_label" v-html="$t('contact_label_fname')">名*</label>
                    <input v-model="inputFName" type="text" name="Fname" />
                </div>
            </div>
            <div v-else class="col-md-6 nameCol">
                <div class="field">
                    <label class="contact_label" v-html="$t('contact_label_fname')">名*</label>
                    <input v-model="inputFName" type="text" name="Fname" />
                </div>
                <div class="field">
                    <label class="contact_label" v-html="$t('contact_label_lname')">姓*</label>
                    <input v-model="inputLName" type="text" name="Lname" />
                </div>
            </div>
            <div class="col-md-6">
                <label class="contact_label" v-html="$t('contact_label_email')">電子郵件*</label>
                <input v-model="inputEmail" type="email" name="email" />
            </div>
            <div class="col-md-6">
                <label class="contact_label" v-html="$t('contact_label_phone')">聯絡電話</label>
                <input v-model="inputTel" type="text" name="tel" />
            </div>
            <div class="col-md-12">
                <label class="contact_label" v-html="$t('contact_label_hear_us')">從何種管道得知我們公司</label>
                <div class="input-select">
                    <select v-model="inputSource">
                        <option disabled selected v-html="$t('contact_select_hear_us_0')">請選擇</option>
                        <option value="Search Engine (Google/Yahoo/Bing/Baidu)" v-html="$t('contact_select_hear_us_1')"></option>
                        <option value="Social Media (LinkedIn, facebook, etc.)" v-html="$t('contact_select_hear_us_2')"></option>
                        <option value="Recommended by Friend or Colleague" v-html="$t('contact_select_hear_us_3')"></option>
                        <option value="News" v-html="$t('contact_select_hear_us_4')"></option>
                        <option value="Trade Show " v-html="$t('contact_select_hear_us_5')"></option>
                        <option value="Other" v-html="$t('contact_select_hear_us_6')"></option>
                    </select>
                </div>
            </div>
            <div class="col-md-12" v-show="inputSource== 'Other'">
                <input v-model="inputOther" type="text" name="other" />
            </div>
            <div class="col-md-12">
                <label class="contact_label" v-html="$t('contact_label_message')">洽詢內容*</label>
                <textarea v-model="inputMessage" rows="6" name="Message"></textarea>
            </div>
            <!-- <div class="col-md-12 checkboxSet">
                <div class="input-checkbox">
                    <input id="inputAgreeInfo" type="checkbox" v-model="inputAgreeInfo" />
                    <label for="inputAgreeInfo" v-html="$t('contact_agree_info')">我願意接受信邦電子及其相關企業的資訊</label>
                </div>
            </div> -->
            <div class="col-md-12 checkboxSet">
                <div class="input-checkbox">
                    <input id="inputAgreePrivacy" type="checkbox" v-model="inputAgreePrivacy" />
                    <label for="inputAgreePrivacy" v-html="$t('contact_agree_privacy')">我已詳閱並同意隱私權政策</label>
                </div>
            </div>
            <div class="col-md-12">
                <button type="submit" :disabled="sendIsDisabled" class="btn btn--primary type--uppercase" v-html="$t('contact_label_send')">送出訊息</button>
            </div>
        </div>
    </form>       
</template>

<style lang="less" scoped>
.nameCol{
    display: flex;
    justify-content: space-between;
    .field{
        flex:0 0 49%;
    }
}
</style>

<script>
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    import { mapState, mapActions, mapGetters } from "vuex";
    import {readCookie} from "@/libs/js/cookie.js";
    import {country} from "@/static/countryList.json";
    import $ from "jquery";
    
    export default {
        data: function()
        {
            return{
                // props: ['inquery','category','product','solution'],
                contacts: null,
                categories: null,
                currCategory: null,
                products: null,
                solutions: null,
                countries: country,

                inputInquery: ' ',
                inputCategory: '',
                inputProduct: '',
                inputSolution: '',
                inputCountry: '',
                inputCity: '',
                inputCompany: '',
                // inputName: '',
                inputFName: '',
                inputLName: '',
                inputEmail: '',
                inputTel: '',
                inputSource: '',
                inputOther: '',
                inputMessage : '',
                // inputAgreeInfo:false,
                inputAgreePrivacy:false,
                sendIsDisabled:false,
                
                routeChanged: false,

                lang: readCookie('lang_code')
            }
        },

        methods:{
            ...mapActions(["setSelectedContactTopic", "setLoadingStatus", "fetchProductCategory", "fetchSolutionCategory", "fetchProductItems"]),
            ...mapGetters(["getSelectedContactTopic"]),

            closePopup: function()
            {
                $(document).mouseup(function(e)
                {
                  var _con = $('.contactBox');

                  if (!_con.is(e.target) && _con.has(e.target).length === 0)
                  {
                    $('#contactForm').addClass('notification--dismissed');
                    $('html').removeClass('popup');
                  }
                });
            },

            filteredRows: function(rows,cc,slug)
            {
                if (cc)
                {
                    var c = cc.toLowerCase();

                    return ( c.trim() !== 'all' ) ?
                        rows.filter(function(d)
                            { return d[slug].toLowerCase().indexOf(c) > -1; }) :
                        rows;
                }
            },

            fetchContact()
            {
                var _this = this;

                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'contact/subject'
                })
                .then(function (response) {
                     const newSubject = [];
                     response.data.items.forEach((i)=>{
                        if(i.slug != 'business-conduct') newSubject.push(i);
                     });
                    _this.contacts = newSubject;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            syncInput()
            {
                var _this = this;
                
                if (_this.$route.name == 'contact')
                {
                    _this.inputInquery = _this.selectedContactTopic;
                    return;
                }
                
                if (_this.$route.name == 'product' || _this.$route.name == 'product-detail')
                {
                    _this.inputInquery = 'products';

                    if (_this.$route.params.category != 'all')
                    {
                        _this.currCategory = _this.$route.params.category;
                        _this.inputCategory = _this.$route.params.category;
                    }
                    else
                    {
                        _this.currCategory = '';
                        _this.inputCategory = '';
                    }
                    
                    if (_this.$route.params.slug) _this.inputProduct = _this.$route.params.slug;
                    
                    return;
                }
                
                if (_this.$route.name == 'solution' || _this.$route.name == 'solution-detail')
                {
                    _this.inputInquery = 'solutions';
                    if (_this.$route.params.category != 'all') _this.inputSolution=_this.$route.params.category;
                    else _this.inputSolution = '';
                    return;
                }
                
                if (_this.$route.name == 'ev-charging' || _this.$route.name == 'solar-system' || _this.$route.name == 'robotic' || _this.$route.name == 'e-bike')
                {
                    _this.inputInquery = 'products';
                    _this.currCategory = undefined;
                    _this.inputCategory = _this.$route.name;
                    _this.inputProduct = 'others';
                    return;
                }

                // _this.resetInputInquery();
                // _this.inputCategory = '';
                // _this.inputProduct = '';
                // _this.inputSolution = '';
            },

            sendFrom()
            {
                var _this = this;
                
                if (_this.inputInquery.trim() == '')
                {
                    alert(_this.$t('contact_alert_1')); // 請選擇洽詢類別
                    return;
                }
                
                if (_this.inputInquery == 'product'&& _this.inputCategory.trim() == '')
                {
                    alert(_this.$t('contact_alert_2')); // 請選擇產品類別
                    return;
                }

                if (_this.inputInquery == 'solution'&& _this.inputSolution.trim() == '')
                {
                    alert(_this.$t('contact_alert_3')); // 請選擇解決方案類別
                    return;
                }
                
                if (_this.inputCountry.trim() == '')
                {
                    alert(_this.$t('contact_alert_4')); // 請選擇國家
                    return;
                }

                // if (_this.inputCity.trim() == '')
                // {
                //     alert(_this.$t('contact_alert_5')); // 請填寫城市
                //     return;
                // }
                
                if (_this.inputCompany.trim() == '')
                {
                    alert(_this.$t('contact_alert_6')); // 請填寫公司名稱
                    return;
                }
                
                if (_this.inputFName.trim() == ''||_this.inputLName.trim() == '')
                {
                    alert(_this.$t('contact_alert_7')); // 請填寫姓名
                    return;
                }
                
                if (_this.inputEmail.trim() == '')
                {
                    alert(_this.$t('contact_alert_8')); // 請填寫電子郵件
                    return;
                }
                
                var r = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                
                if (!r.test(_this.inputEmail.trim().toLowerCase()))
                {
                    alert(_this.$t('contact_alert_9')); // 請填寫正確的電子郵件格式
                    return;
                }
                
                if (_this.inputSource.trim() == '')
                {
                    alert(_this.$t('contact_alert_12')); // 請選擇得知管道
                    return;
                }
                if (_this.inputSource == 'Other' && _this.inputOther.trim() == '')
                {
                    alert(_this.$t('contact_alert_12_other')); // 請填寫得知管道內容
                    return;
                }
                if (_this.inputMessage.trim() == '')
                {
                    alert(_this.$t('contact_alert_10')); // 請填寫洽詢內容
                    return;
                }
                
                if (_this.inputAgreePrivacy == false)
                {
                    alert(_this.$t('contact_alert_13')); // 請詳閱並同意隱私權政策
                    return;
                }

                // ga('send', 'event', 'contact', 'send', _this.inputInquery,_this.inputCategory+' '+_this.inputProduct);
                var _gtagVal;
                
                if (_this.inputInquery == 'products')
                {
                    _gtagVal = _this.inputCategory + '/' + _this.inputProduct;
                }
                
                if (_this.inputInquery == 'solutions')
                {
                    _gtagVal = _this.inputSolution;
                }
                
                var data = {
                    'category_question': _this.inputInquery,
                    'category_product': _this.inputCategory,
                    'product': _this.inputProduct,
                    'solution': _this.inputSolution,
                    'conutry': _this.inputCountry,
                    'city': _this.inputCity,
                    'company': _this.inputCompany,
                    'name': _this.inputFName+' '+_this.inputLName,
                    'email': _this.inputEmail,
                    'phone': _this.inputTel,
                    'how_to_know': _this.inputSource == 'Other' ? _this.inputOther: _this.inputSource,
                    'message':_this.inputMessage,
                };

                data['csrf_hash_name'] = readCookie(COOKIE_PREFIX + 'csrf_cookie_name');
                data['d0ntf1llth1s1n'] = ' ';
                data['lang'] = readCookie('lang_code');
                
                _this.setLoadingStatus(true);

                var bodyFormData = new FormData();
                for (var k in data) bodyFormData.append(k, data[k]);
                
                this.sendIsDisabled = 'disabled';
                
                axios(apibase + 'contact', {
                    method: 'post',
                    data: bodyFormData
                })
                .then(function (response)
                {
                    // subscribe email
                    // _this.subscribe();
                    var _data=`EMAIL=${_this.inputEmail}&FNAME=${_this.inputFName}&LNAME=${_this.inputLName}&MMERGE11=${_this.inputCompany}&MMERGE5=${_this.inputCountry}&MMERGE10=???`;

                    _this.$subscribe(_data)

                    _this.inputInquery = '';
                    _this.inputCategory = '';
                    _this.inputProduct = '';
                    _this.inputSolution = '';
                    _this.inputCountry = '';
                    _this.inputCity = '';
                    _this.inputCompany = '';
                    // _this.inputName = '';
                    _this.inputFName = '';
                    _this.inputLName = '';
                    _this.inputEmail = '';
                    _this.inputTel = '';
                    _this.inputSource = '';
                    _this.inputMessage = '';
                    
                    $('#contactForm').addClass('notification--dismissed');
                    $('html').removeClass('popup');
                    
                    alert(_this.$t('contact_alert_11')); // 送出成功，我們將儘快與您聯繫，謝謝您的洽詢
                    
                    _this.sendIsDisabled = false;
                    _this.setLoadingStatus(false);

                    document.getElementById("contactForm").reset();
                })
                .catch(function (error) {
                    console.log(error);
                    _this.setLoadingStatus(false);
                    _this.sendIsDisabled=false;
                });
            },

            // subscribe() {
            //     var _this = this;
                
            //     var data = {
            //         'email_subscribe': _this.inputEmail
            //     };
                
            //     data['csrf_hash_name'] = readCookie(COOKIE_PREFIX + 'csrf_cookie_name');
            //     data['lang'] = readCookie('lang_code');
                
            //     var bodyFormData = new FormData();
            //     for (var k in data) bodyFormData.append(k, data[k]);
                
            //     axios(apibase + "edm", {
            //         method: "post",
            //         data: bodyFormData,
            //         params: {
            //             lang: readCookie('lang_code')
            //         }
            //     })
            //     .then(function(response)
            //     {
            //         _this.inputEmail = "";
            //     })
            //     .catch(function(error)
            //     {
            //         console.log(error);
            //     });
            // },

            resetInputInquery() {
                console.log('resetInputInquery');
                
                // 不可爲 ''，必須有空格
                this.inputInquery = ' ';
            }
        },

        created() {
            this.fetchSolutionCategory();
            this.fetchProductCategory();
            this.fetchProductItems();
            this.fetchContact();
            this.syncInput();
        },

        mounted()
        {
            console.log('contact form');
            console.log($);
        },

        computed: {
            ...mapState(['productCate','solutionCate','productItem']),
            
            selectedContactTopic()
            {
                return this.getSelectedContactTopic();
            }
        },

        mounted()
        {
            // console.log('this.currCategory',this.currCategory);
        },

        watch: {
            contactQuery(value){
                if( !this.routeChanged ){
                    this.syncInput();
                    this.routeChanged = !this.routeChanged;
                }
            },

            selectedContactTopic(val)
            {
                this.syncInput();
            },

            '$route'(to, from)
            {
                this.syncInput();
                this.routeChanged = true;

                // Set content when click on btn which was created by pb
                if (to.hash != '')
                {
                    let hashArr = to.hash.split('#');

                    this.inputInquery = hashArr[1];
                    this.inputCategory = hashArr[2];
                    this.currCategory = this.inputCategory;

                    if (this.inputInquery=== 'products')
                    {
                        this.inputProduct = hashArr[3];
                    }
                    else if (this.inputInquery=== 'solutions')
                    {
                        this.inputSolution = hashArr[3];
                    }

                    $('.nav-contact').trigger( "click" );
                }

                if (!$('#contactForm').hasClass('notification--dismissed')) $('#contactForm').addClass('notification--dismissed');
            },

            productCate(val)
            {
                this.categories = val;
            },

            solutionCate(val)
            {
                this.solutions = val
            },

            productItem(val)
            {
                this.products = val;
            }
        }
    };
</script>