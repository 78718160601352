const lang={
    /////////////////////////////////
    //sitemap
    /////////////////////////////////
    sitemap_product_medicalHealth:"醫療保健",
    sitemap_product_automotiveAndAviation:"汽車與航太",
    sitemap_product_greenEnergy:"綠色能源",
    sitemap_product_industrialApplication:"工業應用",
    sitemap_product_communication:"通訊相關",
    sitemap_solution_designManufacturingSolutions:"協作設計開發",
    sitemap_solution_integrationSolutions:"組裝客製化服務",
    sitemap_solution_radioFrequencyRfSolutions:"無線射頻整合設計服務",
    sitemap_csr_chairmanMsg:"經營者的話",
    sitemap_csr_corporateGovernance:"治理營運",
    sitemap_csr_green:"綠色信邦",
    sitemap_csr_givingBack:"回饋社會",
    /////////////////////////////////
    //page titles
    /////////////////////////////////
    page_title_global_location:"全球據點",
    page_title_news:"最新消息",
    page_title_privacy:"隱私權政策",
    page_title_robotic:"機器人解決方案 – 連結現在與未來",
    page_title_investor_shareholders_info:"投資人資訊",
    page_title_investor_business_overview:"營運概況",
    page_title_investor_corporate_governance:"公司治理 | 投資人專區",
    page_title_terms:"使用條款",
    page_title_webinar:"網路研討會",
    page_title_webinar_communication:"通訊 | 網路研討會",
    page_title_webinar_e_mobility:"智慧移動 | 網路研討會",
    page_title_webinar_industrial_application:"工業應用 | 網路研討會",
    page_title_solar_system:"太陽能光電系統服務",
    page_title_about:"關於信邦",
    page_title_download:"電子型錄",
    page_title_capability:"設計研發製造服務",
    page_title_career_job:"加入我們",
    page_title_career_train:"我們的願景",
    page_title_contact:"聯絡與洽詢",
    /////////////////////////////////
    //msg
    /////////////////////////////////
    msg_ix_text:"信邦電子結合廣瀨電機(HIROSE Electric) ix工業用系列連接器提供強固、高速傳輸線材解決方案，將於2019年11月26至28日參加德國紐倫堡的國際工業自動化展(SPS)，展覽攤位號 Hall 10.0 Booth 630，歡迎大家前來參觀交流，有關資訊請參閱 <a href='https://ix.sinbon.eu/' target='_blank'>ix.sinbon.eu</a>",
    msg_ix_btn:"前往了解",
    msg_sinbon30th_text:"想不到吧!? 我把最重要的消息放在這！30週年線上藝廊獨家呈獻。",
    msg_no_data:"此分類比對查無結果。",
    /////////////////////////////////
    //nav
    /////////////////////////////////
    nav_site:"信邦電子",
    nav_slogan:"Solution Provider, Assembly Manufacturing",
    nav_home:"首頁",
    //resources
    nav_resources:"資源",
    nav_resourcesBrochure:"產品型錄",
    nav_resourcesWebinar: "線上研討會",
    //trands
    nav_trends:"熱門趨勢",
    //product
    nav_products:"產品",
    nav_evCharging:"充電解決方案",
    nav_solarSystem:"太陽光電系統",
    nav_robotic:"機器人解決方案",
    nav_ebike:"電力輔助自行車",
    nav_solution:"解決方案",
    nav_casestudy:"案例分享",
    nav_blog:"文章",
    nav_brands:"品牌代理",
    nav_capability:"能力與服務",
    //investor
    nav_investor:"投資人專區",
    nav_investorNewsroom:"投資人訊息",
    nav_investorGovernance:"公司治理",
    nav_investorFinancial:"財務資訊",
    nav_investorShareholder:"股東訊息",
    nav_investorBusiness:"公司營運概況",
    //csr
    nav_csr:"企業永續責任",
    nav_csrContact:"利害關係人溝通管道",
    nav_csrDownload:"報告書下載",
    nav_sustainability_report:"永續報告書",
    nav_sustainabilityReportDownload:"報告書下載",
    //ESG
    nav_esg:"ESG企業永續",
    //
    nav_sinbon:"信邦電子",
    nav_location:"全球據點",
    nav_ix:"ix系列線材方案",
    nav_news:"最新消息",
    nav_about:"關於信邦",
    //career
    nav_career:"人才招募",
    nav_careerInfo:"成長與願景",
    nav_careerJobs:"職缺一覽",
    nav_careerStudent:"校園徵才",
    nav_careerIntern:"全球實習計畫",
    nav_careerSinboner:"信邦人",
    //
    nav_contact:"聯絡與洽詢",
    nav_searchSite:"搜尋",
    nav_search:"搜尋",
    nav_dealer:"夥伴專區",
    nav_downloads:"資源下載",
    nav_sitemap:"網站地圖",
    nav_copyright:"© SINBON Electronics Co., Ltd.",
    nav_terms:"使用條款",
    nav_privacy:"隱私政策",
    nav_privacy_setting:"隱私設定",
    nav_webmail:"信邦人信箱",
    /////////////////////////////////
    //ui
    /////////////////////////////////
    ui_category:"類別",
    ui_allCategory:"所有類別",
    ui_filter:"篩選",
    ui_clear:"清除",
    ui_download:"檢視檔案",
    ui_summary:"摘要檔案",
    ui_downloadVideo:"下載影片",
    ui_contact:"聯繫",
    ui_ask:"洽詢產品",
    ui_view:"瀏覽",
    ui_askme:"有任何需求嗎? 請與我們聯絡",
    ui_subscribe_ok:"訂閱成功",
    /////////////////////////////////
    //home
    /////////////////////////////////
    home_banner_title: "最値得信賴的線材<br>與連接器夥伴",
    home_banner_p:"提供客製化線材與連接器研發製造<br>優良的品質、嚴格控制的成本、迅速的交期",
    home_banner_cta:"我們的能力與服務",
    home_more_news_title: "最新消息",
    home_more_news: "更多訊息",
    home_register_webinar: "註冊",
    home_on_demand_webinar: "觀看",
    home_benefit_title:"提供最完整的解決方案",
    home_benefit_p:"醫療保健、汽車與航太、綠色能源、工業應用、通訊，信邦是最值得信賴的全方位的電子產品研發、製造整合服務提供者為客戶提供各種不同的電子產品解決方案",
    // home_benefit_li_1:"6座產線工廠遍布各洲",
    // home_benefit_li_2:"全球超過20個服務據點",
    // home_benefit_li_3:"超過100個品牌客戶合作",
    // home_benefit_cta:"了解信邦的整合能力",
    // home_service_1:"提供完整供應鏈<br>的品牌代理",
    // home_service_1_cta:"了解更多",
    // home_service_2:"符合各式標準的充電槍",
    // home_service_2_cta:"了解更多",
    // home_service_3:"節能更有效率的太陽能發電系統",
    // home_service_3_cta:"了解更多",
    home_subscribe_title:"訂閱我們的電子報，隨時掌握最新動態",
    home_subscribe_placeholder:"請輸入電子郵件",
    home_subscribe_send:"訂閱",
    /////////////////////////////////
    //about
    /////////////////////////////////
    about_banner_title:"夢想、快樂、<br>持續創新的卓越企業",
    about_banner_p:"這些價值導引我們的經營運作，並時時提醒我們成功的要素為何",
    about_philosophy_title:"為客戶提供更廣泛的高附加價值產品服務",
    about_philosophy_p_1:"信邦成立於西元1989年，為提供電子零件整合設計與製造服務的領導廠商，產品應用範圍涵蓋資料擷取、電腦週邊和其他電子產品。順應科技產業的快速發展和日益激增的市場需求，信邦致力於產品研發和強化員工專業知識，以求達到客戶的技術要求，並提供客戶滿意且完整的解決方案。經過這幾年的努力，信邦成功轉型為提供完整解決方案的製造商，並且跨入了汽車電子、航太、工業控制、醫療器材和消費性電子產品領域，為客戶提供更廣泛的高附加價值產品服務。",
    about_philosophy_p_2:"信邦於台灣、中國、日本、英國、德國、匈牙利和美國等地廣佈據點，架起市場和商業資訊可相互整合及交換的資源網路。透過此一網路，信邦可以快速回應客戶的需求、加速訂單的處理、縮短生產與交貨時程、降低設計與開發成本，提供客戶無國界和無時差的服務與支援。此外，從全球原物料搜詢採購服務、產品接單、打樣與製造到售後服務，信邦提供了客戶最愉悅的合作環境，並且可將客戶對產品的初步構想轉化為高品質、高效能的產品。",
    about_feature_1:"培育人才、凝聚共識",
    about_feature_2:"雙贏的原則下<br>提供客戶設計整合及服務、共創產品價值",
    about_feature_3:"創造員工與股東最大利益<br/>共同分享公司的成果",
    about_feature_4:"善盡社會公民的責任",
    about_milestone:"重大記事",
    about_quality:"品質政策",
    about_quality_des_1:"提供客戶最高品質的產品為信邦的首要之務。我們遵守業界最嚴謹的要求和標準，以確保可以如期地完成設計、並將品質優良的產品交給客戶。信邦會持續加強製造技術、加速產品交期，來符合客戶對高品質產品的需求。",
    about_quality_title_1:"關於 ISO 45001",
    about_quality_declaration_1:"安全衛生宣言",
    about_quality_declaration_2:"安全至上 / 快樂工作 / 健康幸福 / 社會和諧",
    about_quality_commitment_1:"安全衛生承諾",
    about_quality_commitment_2:"本公司基於維護員工健康及工作安全，減少因生產活動對員工所造成之影響致力於持續不斷的改善及傷害預防，提供安全工作環境積極推行安全與健康宣導遵循政府法令規章符合各項安全標準，訂定安衛目標以展現改善績效建立安衛管理系統，透過內外部溝通宣導向社會及利害關係者公開、運作審查達到永續經營。",
    /////////////////////////////////
    //distribution
    /////////////////////////////////
    distribution_banner_title:"提供各品牌電子產品與<br/>完善的服務支援",
    /////////////////////////////////
    //career
    /////////////////////////////////
    career_banner_title:"成為我們的夥伴<br/>共同成長",
    career_train_learning_title:"完整的訓練體系<br>讓夥伴們扎實的充實自身的能力",
    career_train_learning_1_title:"各種職能訓練",
    career_train_learning_1_list_1:"核心職能",
    career_train_learning_1_list_2:"專業職能",
    career_train_learning_1_list_3:"管理職能",
    career_train_learning_2_title:"夥伴的自我成長",
    career_train_learning_2_list_1:"工作本職訓練80%",
    career_train_learning_2_list_2:"非工作本職訓練20%",
    career_train_learning_3_title:"帶動企業成長",
    career_train_learning_3_list_1:"高素質、專業化的人才，帶動公司整體成長，成為永續經營的原動力",
    career_train_promotion_title:"順暢的成長路徑<br>讓有能力的夥伴能夠實現自我遠大的夢想",
    career_train_promotion_step_1:"階段 1",
    career_train_promotion_step_1_title:"作業層",
    career_train_promotion_step_2:"階段 2",
    career_train_promotion_step_2_title:"專業層",
    career_train_promotion_step_2_p:"研發、廠務、業務、企劃",
    career_train_promotion_step_3:"階段 3",
    career_train_promotion_step_3_title:"營運層",
    career_train_promotion_step_3_p:"研發、廠務、業務、企劃",
    career_train_promotion_step_4:"階段 4",
    career_train_promotion_step_4_title:"國際營運層",
    career_train_promotion_step_4_p:"台灣、中國、歐洲、美國",
    career_train_promotion_step_5:"階段 5",
    career_train_promotion_step_5_title:"全球領導層",
    career_train_promotion_step_5_p:"營運事業群、全球業務事業群",
    career_train_banner_title:"我們重視每一個人的福利",
    career_train_welfare_title:"完善福利制度",
    career_school_area_tw:"台灣",
    career_school_area_cn:"中國",
    career_school_info:"年度校園徵才場次資訊",
    career_school_scene:"徵才現場",
    career_job_title:"我們深信，是卓越的團隊，造就了今天的信邦，<br>我們不是在徵人，而是在尋找一起打拼未來的好夥伴。<br/>誠摯邀請你與信邦共同成長!",
    career_job_view:"瀏覽職缺",
    career_intern_info:"計畫內容",
    career_intern_story:"實習心得",
    career_intern_view:"看看他的故事",
    career_intern_cta:"馬上加入實習計畫",
    career_family_view:"看看他的故事 »",
    career_footer_title:"成為我們的夥伴",
    career_footer_p:"我們深信，是卓越的團隊，造就了今天的信邦，<br/>我們不是在徵人，而是在尋找一起打拼未來的好夥伴。",
    career_footer_cta:"加入信邦",
    /////////////////////////////////
    //investor
    /////////////////////////////////
    //newsroom
    investor_newsroom_banner_title:"投資人訊息",
    investor_newsroom_stocklink:"前往查詢股價(公司代號：3023)",
    //governance
    investor_governance_banner_title:"公司治理",
    investor_governance_section_0_item_1:"各單位主要職掌及業務",
    investor_governance_section_0_item_2:"功能性委員會及組織成員",
    investor_governance_section_0_item_3:"公司治理運作情形",
    investor_governance_section_1_title:"董事會",
    investor_governance_section_1_item_1:"最近年度董事會績效考核自評結果檔案",
    investor_governance_section_1_item_2:"董事會成員多元化方針及落實執行情形檔案",
    investor_governance_section_1_item_3:"董事會成員",
    investor_governance_section_1_item_4:"董事會決議事項",
    investor_governance_section_2_title:"公司治理規章",
    investor_governance_section_3_title:"內部稽核運作",
    investor_governance_section_3_p:"內部稽核之目的，在於協助董事會及經理人檢查及覆核內部控制制度之缺失及衡量營運之效果及效率，並適時提供改進建議，以確保內部控制制度得以持續有效實施及作為檢討修正內部控制制度之依據。<br/><br/>本公司設置隸屬董事會直接指揮之專責內部稽核單位，並受獨立董事之督導，稽核單位應置主管一人，由董事會任免之，且稽核主管應列席董事會進行稽核業務報告，稽核主管及其所屬稽核人員共計4人，依本公司修訂之『公司治理實務守則』規定，內部稽核人員之任免、考評、薪資報酬由稽核主管簽報董事長核定。",
    investor_governance_section_4_title:"內部稽核單位之運作",
    investor_governance_section_4_p:"稽核室應基於風險評估結果決定稽核項目，編製年度稽核計劃，年度稽核計劃應列明每月、每季應稽核之項目，據以執行專案(個案)稽核作業之依據。<br/><br/>內部稽核人員應與受查單位就年度稽核項目查核結果充分溝通，對於評估所發現之內部控制制度缺失及異常缺失事項，包括主管機關檢查所發現、內部稽核作業所發現、內部控制制度聲明書所列、自行評估及會計師專案審查所發現之各項缺失，應據實揭露於稽核報告，並於該報告陳核後加以追蹤，至少按季作成追蹤報告至改善為止，以確定相關單位業已及時採取適當之改善措施。<br/><br/>稽核人員應覆核各單位及子公司之內部控制制度自行檢查報告，併同稽核單位所發現之內部控制缺失及異常事項改善情形，以作為評估整體內部控制制度有效性及出具內部控制制度聲明書之主要依據。<br/><br/>內部稽核人員如發現有重大違規情事或公司有受重大損害之虞時，應立即作成報告陳核，並通知獨立董事。",
    investor_governance_section_5_title:"薪資報酬委員會",
    investor_governance_section_5_p:'評估及檢討公司董事及經理人之薪資報酬及績效評估與薪資報酬之政策、制度、標準與結構。<br/><br/>薪資報酬委員會每年進行評估，其評估面向包含：對公司營運之參與程度、功能性委員會職責認知、提升功能性委員會決策品質、功能性委員會組成及成員選任及內部控制，評估結果分為三個等級：超越標準、符合標準及仍可加強。<br/><br/>高階經理人之薪酬，依據公司薪酬政策，將透過薪酬委員會綜合考量及產業營運機會與風險等，並參考外部薪酬市場及同業同類職位水準，經董事會綜合考量報酬數額、支付方式等事項後決議。績效衡量標準包含財務面，如營收等獲利之關鍵績效，同時也囊括非財務面之環境、社會、治理等 ESG 永續經營成效。<br/><br/>更多與董事及經理人之酬金內容請參閱<a href="https://www.sinbon.com/tw/shareholders-info">信邦股東會年報</a>。',
    investor_governance_section_5_item_1:"薪資報酬委員會組織規程",
    investor_governance_section_5_item_2:"薪資報酬委員會運作情形",
    investor_governance_section_6_title:"企業風險管理",
    investor_governance_section_6_1_title:"風險管理政策與程序",
    investor_governance_section_6_1_desc:"信邦自2021年10月制定「風險管理政策與作業程序」，並由審計委員會同意後，經董事會決議通過，以作為本公司風險管理最高指導原則，及全體員工執行風險管理的指引。<br><br>為強化公司治理、確保風險管理制度之完整性，以落實風險管理制衡機制，提升風險管理分工之效能，及全員風險管理認知，依照公司整體營運方針來定義各類風險，透過組織規劃，有效辨識、預防及控制風險，並依據內外環境變化，持續調整改善最佳風險管理實務，以保護員工、股東、合作夥伴與顧客的利益，增加公司價值，達成公司永續經營。",
    investor_governance_section_6_2_title:"風險治理與管理組織架構",
    investor_governance_section_6_2_chart_0:"董事會",
    investor_governance_section_6_2_chart_branch:"稽核室",
    investor_governance_section_6_2_chart_1:"審計委員會",
    investor_governance_section_6_2_chart_2:"經營決策小組",
    investor_governance_section_6_2_chart_3:"風險管理人員",
    investor_governance_section_6_2_chart_4:"風險責任人",
    investor_governance_section_6_2_desc_1:"信邦依循管理組織體系及內部控制循環，強調全員全面風險控管，平時落實層層防範，以最具成本效益的方式，積極面對與管控營運過程所應考量之風險，風險管理之執行按照各層級分工架構來運作。",
    investor_governance_section_6_2_th_1:"風險管理層級",
    investor_governance_section_6_2_th_2:"風險管理運作",
    investor_governance_section_6_2_tr_1_td_1:"第一線責任",
    investor_governance_section_6_2_tr_1_td_2:"各單位或業務承辦人為其承辦業務之風險責任人，負責最初的風險發覺、評估及控制。",
    investor_governance_section_6_2_tr_2_td_1:"第二線責任",
    investor_governance_section_6_2_tr_2_td_2:"各部門權責主管或經指派之功能/部門風險管理人員，負責相關業務之風險管理。",
    investor_governance_section_6_2_tr_3_td_1:"第三線責任",
    investor_governance_section_6_2_tr_3_td_2:"經營決策小組，審視風險管理相關機制之完整性，及監控各單位之相關風險，並定期每年向董事會報告風險治理情形。",
    investor_governance_section_6_2_desc_2:"稽核室為公司內部獨立監督機制，督導各執行單位遵循核決權限與相關管理辦法及作業程序，並進行有關風險管理是否有效落實執行之評估，確保制度落實與遵循。",
    investor_governance_section_6_2_desc_3:"審計委員會協助董事會督導風險管理成效，並透過會議報告瞭解運作情形。",
    investor_governance_section_6_3_title:"風險管理運作情形",
    investor_governance_section_6_3_desc:"信邦積極推動並落實風險管理機制，透過定期召開經營決策小組會議，及每年向董事會報告風險治理情形。2023年度風險治理主要運作情形如下：",
    investor_governance_section_6_3_item1:"每四個月召開經營決策小組會議報告風險管理執行情形，包括風險鑑別、風險衡量及因應措施。",
    investor_governance_section_6_3_item2:"2023/10/24向董事會報告風險管理治理情形，包括當年評估公司所面臨的關鍵風險項、風險衡量及回應對策。",
    investor_governance_section_6_3_item3:"2023年透過公告與教育宣導，以持續強化風險治理與風險管理意識：",
    investor_governance_section_6_3_item3_1:"6月及8月進行「氣候相關財務揭露(Task Force on Climate-related Financial Disclosures, TCFD)」教育宣導",
    investor_governance_section_6_3_item3_2:"9月執行「內部控制三道防線」說明宣導",
    investor_governance_section_6_3_item3_3:"10月透過「集團營運風險管控重申」公告",
    investor_governance_item_1:"獨立董事監察人與內部稽核主管及會計師之溝通情形",
    investor_governance_section_7_title:"營運持續管理",
    investor_governance_section_7_1_title:"政策與目的",
    investor_governance_section_7_1_p:"信邦長期追求為客戶創造價值，為能提供不間斷之服務，建置營運持續管理(Business Continuity Management, BCM) 機制，並對具有中斷營運之風險，模擬風險情境並評估對組織營運產生的衝擊，依風險情境建立相應營運持續計畫(Business Continuity Plan, BCP)，確保風險評估之周延性與預防措施，藉由減少營運衝擊、縮短復原時間以達到營運持續目標。",
    investor_governance_section_7_2_title:"營運持續管理執行團隊與運作",
    investor_governance_section_7_2_p1:"營運持續管理執行團隊由集團總經理擔任召集人，對於可能造成營運中斷之風險及關鍵資源相關性，召集各部門探討並制定營運持續計畫。",
    investor_governance_section_7_2_p2:"營運持續管理為確保非計畫性之災害或事件發生時，仍得以保護重要營運過程不受重大資訊系統失效或災害的影響；此為持續性、週期性之管理系統，包含風險評估與營運衝擊分析、決定營運持續管理策略、發展與實行營運持續管理、營運持續計畫演練、維護與檢核。",
    investor_governance_section_7_2_chart_lv1:"集團<br>總經理",
    investor_governance_section_7_2_chart_coordination_title:"營運統籌",
    investor_governance_section_7_2_chart_coordination_item1:"財務",
    investor_governance_section_7_2_chart_coordination_item2:"策略溝通",
    investor_governance_section_7_2_chart_coordination_item3:"客戶服務",
    investor_governance_section_7_2_chart_coordination_item4:"供應鏈",
    investor_governance_section_7_2_chart_coordination_item5:"研發",
    investor_governance_section_7_2_chart_coordination_item6:"製造",
    investor_governance_section_7_2_chart_coordination_item7:"法務",
    investor_governance_section_7_2_chart_coordination_item8:"行政",
    investor_governance_section_7_2_chart_coordination_item9:"人資",
    investor_governance_section_7_2_chart_coordination_item10:"IT",
    investor_governance_section_7_2_chart_repsponse_title:"緊急應變",
    investor_governance_section_7_2_chart_repsponse_p:"各地緊急應變小組：包含各事業處、工廠、採購、倉庫、行政、財務、HR、法務、IT",
    investor_governance_section_7_3_title:"營運持續計畫",
    investor_governance_section_7_3_p1:"依照風險鑑別與衡量結果，針對可能造成營運中斷之風險，展開營運持續管理計畫。營運持續管理計畫包含預防措施、緊急應變措施、營運持續運作與復原措施等；透過風險情境模擬，共同探討集團營運因應對策與應變措施等。",
    investor_governance_section_7_3_p2:"為確保營運持續計畫有效性，制定演練計畫並依據演練結果進行檢討、修改營運持續計畫。採取持續改善循環式管理(Plan-Do-Check-Act, PDCA)，確實達到營運持續管理之目的且讓同仁具有風險管理之觀念。BCM召集人得視每年演練結果與新增風險主題召集會議進行討論，確保營運持續計劃有效性和完整性。",
    investor_governance_section_7_3_th1:"風險主題",
    investor_governance_section_7_3_th2:"營運持續計畫重點",
    investor_governance_section_7_3_r1_td1:"傳染病",
    investor_governance_section_7_3_r1_td2_li1:"因傳染病影響供應商與物流供貨之應變措施",
    investor_governance_section_7_3_r1_td2_li2:"工廠員工因染傳染病之情境模擬，針對關鍵資源擬定各項應變措施",
    investor_governance_section_7_3_r2_td1:"供應商因突發或不可預料的意外事故，導致原材料斷料",
    investor_governance_section_7_3_r2_td2_li1:"掌握多重供貨來源，快速滿足需求",
    investor_governance_section_7_3_r2_td2_li2:"集團內異地備援，廠區間互相調料",
    investor_governance_section_7_3_r2_td2_li3:"進行後備廠商供貨",
    investor_governance_section_7_3_r3_td1:"地緣政治",
    investor_governance_section_7_3_r3_td2_li1:"制定防災相關訓練計畫，確保員工安全為優先",
    investor_governance_section_7_3_r3_td2_li2:"重要資訊系統異地備援",
    investor_governance_section_7_3_r3_td2_li3:"緊急預備金規劃",

    investor_governance_detail_back:"返回",

    //financial
    investor_financial_banner_title:"財務資訊",
    investor_financial_chart_title:"公司自結合併月營收",
    investor_financial_chart_date:"日期",
    investor_financial_chart_unit:"營收(台幣仟元)",
    //shareholder
    investor_shareholder_banner_title:"股東訊息",
    investor_shareholder_meeting_title:"最新年度股東會年報",
    investor_shareholder_recording:'2023股東會錄影',
    investor_shareholder_recording_cta:'觀看影片',
    investor_shareholder_dividend_title:"歷年股利分派",
    investor_shareholder_dividend_th_1:"年度",
    investor_shareholder_dividend_th_2:"現金股利",
    investor_shareholder_dividend_th_3:"股票股利",
    investor_shareholder_dividend_th_4:"除權息交易日",
    investor_shareholder_dividend_th_5:"基準日",
    investor_shareholder_dividend_th_6:"發放日",
    investor_shareholder_dividend_th_7:"股東會日期",
    investor_shareholder_bank:"台新國際商業銀行",
    investor_shareholder_address:"台北市仁愛路四段118號2樓",
    //bussiness
    investor_bussiness_banner_title:"公司營運概況",
    investor_bussiness_overview_title:"季度營運概況簡報",
    investor_bussiness_overview_desc:" ",
    investor_bussiness_lawMeeting_title:"最新年度法說會簡報",
    investor_bussiness_video_title:"最新年度法說會影音",
    /////////////////////////////////
    //csr
    /////////////////////////////////
    //page
    // csr_page_banner_title:"夢想、快樂、<br/>持續創新的卓越企業",
    // csr_page_banner_p:"這些價值導引我們的經營運作，並時時提醒我們成功的要素為何",
    //download
    csr_download_banner_title:"報告書下載",
    // csr_download_banner_p:"這些價值導引我們的經營運作，並時時提醒我們成功的要素為何",
    csr_download_section_title:"永續報告書",
    //contact
    csr_contact_banner_title:"利害關係人溝通管道",
    // csr_contact_banner_p:"這些價值導引我們的經營運作，並時時提醒我們成功的要素為何",
    csr_contact_group_1_title:"投資人關係與新聞連絡人暨公司治理",
    csr_contact_group_1_item_1_title:"公司發言人(財務長) ",
    csr_contact_group_1_item_1_name:"張集州",
    csr_contact_group_1_item_2_title:"代理發言人",
    csr_contact_group_1_item_2_name:"游雅茹",
    csr_contact_group_2_title:"採購",
    csr_contact_group_2_item_1_title:"材料零組件相關 ",
    csr_contact_group_2_item_1_name:"孔寅芳",
    csr_contact_group_2_item_2_title:"行政相關 ",
    csr_contact_group_2_item_2_name:"陳羽凡",
    csr_contact_group_3_title:"企業社會責任",
    csr_contact_group_4_title:"業務服務信箱",
    csr_contact_group_5_title:"從業道德",
    csr_contact_group_5_item_1_title:"稽核室",
    csr_contact_group_5_item_1_name:"李惠君",
    /////////////////////////////////
    //location
    /////////////////////////////////
    location_banner_title:"我們的全球據點",
    /////////////////////////////////
    //news
    /////////////////////////////////
    location_news_title:"最新消息",
    /////////////////////////////////
    //product
    /////////////////////////////////
    product_banner_title:"提供各領域品質最穩定的<br/>客製化線材與連接器",
    product_shareto:"分享至",
    /////////////////////////////////
    //footer block
    /////////////////////////////////
    footer_block_1_title:"完善整合<br/>了解信邦全面整合力",
    footer_block_2_title:"創新服務<br/>線上客製需求的線材",
    footer_block_3_title:"聯繫我們<br/>尋求完善解決方案",
    footer_block_4_title:"完整的全球佈局<br/>為您提供更全面的服務",
    /////////////////////////////////
    //solution
    /////////////////////////////////
    solution_banner_title:"為各種不同的需求<br/>提供最完善的解決方案",
    /////////////////////////////////
    //case study
    /////////////////////////////////
    casestudy_banner_title:"為各種不同的需求<br/>提供最完善的解決方案",
    /////////////////////////////////
    //blog
    /////////////////////////////////
    blog_banner_title:"為各種不同的需求<br/>提供最完善的解決方案",
    /////////////////////////////////
    //contact
    /////////////////////////////////
    contact_title:"聯絡與洽詢",
    contact_subtitle_1:'投資人關係與新聞連絡人暨公司治理',
    contact_subtitle_2:'企業永續發展',
    contact_subtitle_2_des: `永續辦公室`,
    contact_subtitle_3:'產品相關',
    contact_subtitle_3_des: `業務負責單位`,
    contact_subtitle_4:'解決方案相關',
    contact_subtitle_4_des: `方案專家`,
    contact_subtitle_5:'品牌代理',
    contact_subtitle_5_des: `智能零組件事業處`,
    contact_subtitle_6:'企業庶務相關採購',
    contact_subtitle_7:'材料零組件相關採購',
    contact_subtitle_8:'從業道德',
    contact_subtitle_8_des: `稽核室`,
    contact_subtitle_9:'人才招募',
    contact_subtitle_9_des: `人才招募服務中心`,
    contact_subtitle_10:'員工申訴',
    contact_subtitle_10_des: `員工關係小組`,
    contact_subtitle_11:'其他問題與建議',
    contact_label_inqiery:"洽詢項目",
    contact_select_inqiery:"洽詢項目",
    contact_label_category:"產品類別(選填)",
    contact_select_category:"請選擇產品類別",
    contact_evcharging:"充電解決方案",
    contact_solar:"太陽光電系統",
    contact_robotic:"機器人解決方案",
    contact_ebike:"電力輔助自行車",
    contact_label_product:"產品(選填)",
    contact_select_product:"請選擇",
    contact_label_solution:"解決方案類別(選填)",
    contact_select_solution:"請選擇",
    contact_label_country:"國家",
    contact_select_country:"請選擇",
    contact_label_city:"城市(選填)",
    contact_label_company:"公司名稱",
    contact_label_name:"姓名",
    contact_label_fname:"名",
    contact_label_lname:"姓",
    contact_label_email:"電子郵件",
    contact_label_phone:"聯絡電話(選填)",
    contact_label_message:"洽詢內容",
    contact_label_send:"送出訊息",
    contact_others:"其他",
    contact_label_hear_us:"從何種管道得知我們公司?",
    contact_select_hear_us_0:"請選擇",
    contact_select_hear_us_1:"網路搜尋 (Google/Yahoo/Bing/百度等)",
    contact_select_hear_us_2:"社群媒體 (LinkedIn/facebook等)",
    contact_select_hear_us_3:"朋友/同事推薦",
    contact_select_hear_us_4:"新聞報導",
    contact_select_hear_us_5:"展覽",
    contact_select_hear_us_6:"其他",
    // contact_agree_info:"我願意接受信邦電子及其相關企業的資訊",
    contact_agree_privacy:"當您送出後，代表您同意收到更多關於信邦的產品服務與新聞活動資訊，您隨時可以在未來收到的信件中取消訂閱信邦電子報。",
    contact_alert_1:"請選擇洽詢類別",
    contact_alert_2:"請選擇產品類別",
    contact_alert_3:"請選擇解決方案類別",
    contact_alert_4:"請選擇國家",
    contact_alert_5:"請填寫城市",
    contact_alert_6:"請填寫公司名稱",
    contact_alert_7:"請填寫姓名",
    contact_alert_8:"請填寫電子郵件",
    contact_alert_9:"請填寫正確的電子郵件格式",
    contact_alert_10:"請填寫洽詢內容",
    contact_alert_11:"送出成功，我們將儘快與您聯繫，謝謝您的洽詢",
    contact_alert_12:"請選擇得知管道",
    contact_alert_12_other:"請填寫得知管道內容",
    contact_alert_13:"請詳閱並同意隱私權政策",
    /////////////////////////////////
    //webinar contact
    /////////////////////////////////
    webinarContact_title:"線上研討會",
    webinarContact_subtitle:"填寫資訊即可觀看線上研討會",
    webinarContact_label_country:"國家",
    webinarContact_select_country:"請選擇",
    webinarContact_label_city:"城市",
    webinarContact_label_company:"公司",
    webinarContact_label_name:"姓名",
    webinarContact_label_email:"電子郵件",
    webinarContact_label_job:"職稱",
    webinarContact_label_register:"送出",
    webinarContact_agree_info:"我願意接受信邦電子及其相關企業的資訊",
    webinarContact_agree_privacy:"當您送出後，代表您同意收到更多關於信邦的產品服務與新聞活動資訊，您隨時可以在未來收到的信件中取消訂閱信邦電子報。",
    webinarContact_alert_1:'請選擇國家',
    webinarContact_alert_2:'請填寫公司名稱',
    webinarContact_alert_3:'請填寫姓名',
    webinarContact_alert_4:'請填寫城市',
    webinarContact_alert_5:'請填寫職稱',
    webinarContact_alert_6:'請填寫電子信箱',
    webinarContact_alert_7:'請填寫有效的電子信箱',
    webinarContact_alert_8:'資料已送出，請繼續觀看影片',
    webinarContact_alert_9:"請勾選我已詳閱並同意隱私權政策",
    /////////////////////////////////
    //evCharging
    /////////////////////////////////
    ev_metaTitle:'電動車充電解決方案',
    ev_metaDes:'信邦憑藉著線材與連接器的高度技術研發能力，投入電動車充電設備設計與製造，從充電槍、插座到充電樁的多年耕耘，信邦讓許多國際知名的汽車品牌成為我們的重要合作夥伴。',
    ev_title:"駛向未來 – 電動車充電解決方案",
    ev_content:"從2012年起，信邦憑藉著線材與連接器的高度技術研發能力，投入電動車充電設備設計與製造。從充電槍、插座到充電樁的多年耕耘，信邦讓許多國際知名汽車品牌成為我們的重要合作夥伴。<br><br>「與時俱進」與「品質」是我們最重視的。隨著電動車充電需求日益演變，信邦也投入高功率及家用充電電纜的研發。我們擁有汽車業品質管理系統IATF 16949認證，確保品質維持在最高水準。我們的產品經過各項認證與測試，保障使用者的安全。",
    ev_download_btn:"下載產品型錄",
    ev_contact_btn:"聯絡我們",
    ev_section_1_title:"完整充電系統客製化解決方案",
    ev_section_1_des:"信邦提供完整電動車電力相關解決服務，包括交流/直流充電槍，涵括美國、歐洲、中國標準，滿足不同地區客戶需求；並有攜帶式充電槍-模式二，全面照顧不同需求。<br><br>除充電槍外，亦研發與充電槍匹配的車端交流/直流插座、高壓線束等多款相關產品，讓客戶能享受一站式的客製服務。",
    ev_section_2_title:"交流充電槍",
    ev_section_2_des:"信邦提供涵蓋歐洲標準、美國標準、中國標準的交流充電槍，滿足客戶跨地區的市場需求。<br><br>具有一體成形專利，防護等級高達IP55，有效提升產品防水性與耐受性，保障使用者安全。",
    ev_section_2_list_1:"安全可靠",
    ev_section_2_list_2:"專利設計",
    ev_section_2_list_3:"高防水",
    ev_section_2_list_4:"符合人體工學",
    ev_section_2_list_5:"高結構強度",
    ev_section_2_sub_title_1:"具附加價值的產品設計",
    ev_section_2_sub_list_1:"全球首創以包覆成型(Overmold)提供產品良好的防水(IP55)與耐受性",
    ev_section_2_sub_list_2:"握把具有防鬆脫功能的按鍵設計",
    ev_section_2_sub_list_3:"鍍銀銅合金端子，低接觸電阻",
    ev_section_2_pd_1:"Type1 交流充電槍 - 美國標準 (SAE J1772)",
    ev_section_2_pd_2:"Type2 交流充電槍 - 歐洲標準 (IEC) ",
    ev_section_2_pd_3:"GB 交流充電槍 - 中國標準 (GB/T)",
    ev_section_3_title:"直流充電槍",
    ev_section_3_des:"信邦提供涵蓋歐洲標準、美國標準及中國標準的大電流(350A)充電槍，全面提升充電速度。",
    ev_section_3_list_1:"安全可靠",
    ev_section_3_list_2:"專利設計",
    ev_section_3_list_3:"高防水",
    ev_section_3_list_4:"符合人體工學",
    ev_section_3_list_5:"高結構強度",
    ev_section_3_list_6:"防止熱插拔",
    ev_section_3_sub_title_1: "細緻工藝技術",
    ev_section_3_sub_list_1:"全球首創以包覆成形(overmold)提供產品良好的防水(IP55)與耐受性。",
    ev_section_3_sub_list_2:"阻燃(UL94 V-0)、耐紫外線。",
    ev_section_3_sub_list_3:"內建溫度感測，通過環境溫度-40〜50℃、終端溫升<50K測試",
    ev_section_3_pd_1:"CCS2 家用直流充電槍 - 歐洲標準 (IEC)",
    ev_section_3_pd_2:"GB 直流充電槍 - 中國標準 (GB/T)",
    ev_section_3_pd_3:"CCS1 直流充電槍 - 美國標準 (SAE J1772)",
    ev_section_3_pd_4:"CCS2 直流充電槍 - 歐洲標準 (IEC)",
    ev_section_4_title:"便攜式充電槍",
    ev_section_4_des:"信邦便攜式交流充電槍，不論何時何地，只要接上一般插座即可充電。",
    ev_section_4_list_1:"模組化系統",
    ev_section_4_list_2:"電磁兼容性強",
    ev_section_4_list_3:"高防水",
    ev_section_4_list_4:"符合人體工學",
    ev_section_4_list_5:"可替換式接頭",
    ev_section_4_sub_title_1: "確保使用安全",
    ev_section_4_sub_list_1:"專利過溫保護，通過AC插頭溫度> 80℃，PCB温度> 85℃等測試。",
    ev_section_4_sub_list_2:"四段可控電壓，搭配三色LED充電狀態指示燈，即時監控插頭溫度。",
    ev_section_4_sub_list_3:"符合IC-CPD國際標準、Type B漏電保護。",
    ev_section_4_pd_1:"便攜式充電槍 - 模式二",
    ev_section_5_title:"插座",
    ev_section_5_des:"信邦可提供不同規格對應交流、直流充電槍的插座。",
    ev_section_5_sub_title_1:"堅固耐用",
    ev_section_5_sub_list_1:"符合IP55防護等級。",
    ev_section_5_sub_list_2:"通過插拔耐用性> 10,000次測試。",
    ev_section_5_sub_list_3:"通過環境溫度-40℃ ~ +85℃測試。",
    ev_section_5_pd_1:"GB 電動車直流充電插座",
    ev_section_5_pd_2:"GB 電動車交流充電插座",
    ev_section_5_pd_3:"CCS1 電動車直流充電插座",
    ev_section_5_pd_4:"CCS2 電動車直流充電插座",
    ev_section_6_title:"交流和直流充電樁",
    ev_section_6_des:"信邦提供客製化充電樁設計與模組組裝。​",
    ev_section_6_sub_title_1:"安全防護",
    ev_section_6_sub_list_1:"符合IP54防護等級。",
    ev_section_6_sub_list_2:"過壓、過流、過熱、漏電的安全保護。​",
    ev_section_6_pd_1:"交流和直流充電樁",
    ev_section_7_title:"高功率電線束",
    ev_section_7_des:"電動車的電壓一般為60~1000V的高電壓，而傳統汽車電壓只有12~48V，所以高壓連接器在此就扮演著極其重要的角色。",
    ev_section_7_sub_title_1:"安全的環境下傳輸大電流和高電壓",
    ev_section_7_sub_list_1:"調整高壓互鎖、插座設計。",
    ev_section_7_sub_list_2:"改善插拔連接器時斷裂危險，減低觸電事故及損壞發生。",
    ev_section_7_pd_1:"高功率電線束",
    ev_section_8_title:"除了產品，我們更希望為客戶提升產品競爭力",
    ev_section_8_sub_title_1:"選材優化",
    ev_section_8_sub_des_1:"協助客戶進行原材料選材優化，提高產品附加價值",
    ev_section_8_sub_title_2:"客製化設計",
    ev_section_8_sub_des_2:"針對各別客戶需求進行產品、製程客製化",
    ev_section_8_sub_title_3:"加速生產效率",
    ev_section_8_sub_des_3:"簡化組裝流程、導入自動化設備，加速生產效率",
    ev_section_9_title:"信邦專利設計",
    ev_section_9_sub_title_1:"一體成型",
    ev_section_9_sub_des_1:"使用TPE包覆，帶來絕佳防護性能，並大幅提升結構強度。外型設計符合人體工學，觸感較市面上其他充電槍柔軟。",
    ev_section_9_sub_title_2:"專利電子鎖保護",
    ev_section_9_sub_des_2:"有效防止帶電插拔，杜絕任何漏電、觸電的可能性。",
    ev_section_10_title:"安全可靠的品質",
    ev_section_10_sub_title_1:"IP55",
    ev_section_10_sub_des_1:"防護等級達IP55，防水防塵，確保安全無虞。",
    ev_section_10_sub_title_2:"電性要求",
    ev_section_10_sub_des_2:"電性要求通過絕緣阻抗>10MΩ，端子溫升＜50K等測試。",
    ev_section_10_sub_title_3:"機構設計",
    ev_section_10_sub_des_3:"通過插拔壽命>10,000次，插拔力F＜100N等測試。",
    ev_section_11_title:"有客製充電解決方案需求嗎？<br>我們將提供您最完整及專業的充電系統客製化解決方案",
    /////////////////////////////////
    //solar system
    /////////////////////////////////
    solar_title:"太陽能光電系統服務",
    solar_content:"為追求綠活意境<br/>開發整合前瞻性太陽能產品及應用<br/>期許讓你我因太陽能而受益",
    solar_section_1_title:"我們提供的不只是一個產品<br/>而是一個適切的方案",
    solar_section_1_point_1:"太陽能系統組件",
    solar_section_1_point_2:"系統規劃設計",
    solar_section_1_point_3:"監造與售後服務",
    solar_section_2_des:"除了提供系統整合服務外，信邦亦有提供併聯系統產品銷售服務，有別於傳統太陽光電系統，提供單片監控功能，在維運上更能得心應手",
    solar_section_3_title_1:"最適化光電系統設計",
    solar_section_3_des_1:"源頭入手，依案場設置環境條件，進行最適化光電系統設計、設施配置、使系統高效運行，降低運維成本",
    solar_section_3_title_2:"於系統驗收、運維發現的問題、提出合理化維護改善作法",
    solar_section_3_des_2:"信邦太陽能的專業能力與豐富經驗能精確判讀電廠資料，快速找出問題並提出應對方針，優化電廠的發電狀況",
    solar_section_3_title_3:"即時分析、檢測與排除",
    solar_section_3_des_3:"用預防性維護概念，對光電系統的潛在故障，進行即時分析、檢測與排除，以防範高風險事件發生。 搭配信邦太陽能電廠運維服務與電廠監控資料，將定期為客戶進行系統檢查&提出體檢報告，主動發現電廠異常狀況，並提供維護改善服務",
    solar_qa:"常見問題",
    solar_q1:"有辦法得知系統實際的轉電效率嗎？",
    solar_a1:"是的。一旦完成系統安裝，業主可以上我們的線上即時監控系統(手機也可以)，包括每一個Panel的即時發電訊息的回報。",
    solar_q2:"安裝系統後在節能環保上究竟能有多少貢獻呢？",
    solar_a2:"以設置容量 1 仟瓦(kWp)來說： 年發電量約為 1300 度， 所減少的二氧化碳排放量：約 814 公斤 ，減少氮化物、硫化物、微塵量的排放量：約 13.68 公斤。",
    solar_q3:"整個施作流程大致上的脈絡是怎麼樣？",
    solar_a3:"1.基地探勘 2.評估規劃與報價 3.申請與簽約施工 4.貼心的售後服務",
    solar_q4:"到竣工驗收預計會花多久的時間呢?",
    solar_a4:"一般為4~6個月，其中在台電的躉電申請這段時間比較長，幾乎會占去2個月的時間。",
    solar_q5:"目前模組的轉電效率大約為多少？",
    solar_a5:"單晶矽的模組轉換效率會比多晶矽的模組高，約有19%的效率表現。",
    solar_q6:"電池的發電效率會逐年下降嗎？",
    solar_a6:"每年會有0.8%的正常耗損，10年的效能約為原90%，20年的效能約為原80%",
    solar_q7:"法規是否有規範屋頂裝設太陽能系統的高度範圍限制？",
    solar_a7:"是的，依照法規所裝設系統的最高點以不超過頂樓平面的4.5米為原則。",
    solar_q8:"我們台灣常裝設太陽能發電系統的空間有哪些？",
    solar_a8:"常見的案例包括透天屋頂/工廠廠房屋頂/農舍的屋頂，等…",
    solar_q9:"大約投資多久後可以回收成本?",
    solar_a9:"將視區域而定，一般來說大約7~10年左右。",
    solar_q10:"外力損害是否可以賠償? 有無保險可以投保呢?",
    solar_a10:"外力/天然災害等不可抗力因素引發之故障或損壞，不在我們的賠償範圍內。不過可以投保產物保險，遇到上述損害可由保險公司來賠償。",
    solar_q11:"可以申請銀行貸款嗎？利率大約是多少？",
    solar_a11:"安裝太陽能發電系統可以申請銀行貸款，利率大約3~5%，由銀行評估決定。",
    solar_q12:"太陽能模組該如何維護保養? 保養的費用約多少？",
    solar_a12:"在20年的保固期間內我們會定期做巡查/維護保養，保固期滿後可以再共同 簽訂維護合約，費用將依案子大小和維護項目而定。",
    solar_q13:"現在裝設太陽能發電系統的時機好嗎？",
    solar_a13:"根據國外的經驗，政府對於系統的補助價格會逐年降低。而台電未來很有可能再調高每度用電的電價，所以越早裝設是越有利的。",
    solar_recommen:"相關產品",
    /////////////////////////////////
    //robotic
    /////////////////////////////////
    robotic_title:"信邦機器人解決方案 – 連結現在與未來",
    robotic_content_1:"信邦多年來專注於機器人領域，除了提供客製化線材組裝與加值解決方案；包括印刷電路板組裝（PCBA）、天線、包覆射出成型以及塑膠射出成型外， 亦提供機器人整合式影音解決方案的一站式服務。",
    robotic_content_2:"用於機器人的線材組裝必須符合機械、化學與環境的最高標準。 相較於其它市場，機器人應用環境所需的參數是獨一無二的；須能承受瞬間的加減速、拉伸、機械與扭轉應力；並須能承受劇烈的彎曲循環，高溫、焊接飛濺物、油與多種化學物質。 我們使用合適的材質以確保線材組裝符合先進市場的客戶需求，同時也擁有最高階的測試設備，可模擬運轉並確定線材壽命。 這些測試包含振動測試、鹽霧試驗、濕度測試、粉塵測試、電磁干擾（EMI）/ 電磁相容性（EMC）以及加速老化試驗等。",
    robotic_list_title:"我們的產品與服務包含",
    robotic_list_content_1:"機構及電機設計",
    robotic_list_content_2:"軟韌體整合",
    robotic_list_content_3:"原線材設計與製造",
    robotic_list_content_4:"線材組裝與接線器",
    robotic_list_content_4_1:"極細同軸線",
    robotic_list_content_4_2:"網路線材",
    robotic_list_content_4_3:"高速線材(包含客製化USB Type-C) ",
    robotic_list_content_4_4:"移動零件專用高彈性組裝",
    robotic_list_content_4_5:"固定式線材",
    robotic_list_content_5:"包覆射出成型",
    robotic_list_content_6:"印刷電路板組裝",
    robotic_list_content_7:"天線設計、製造與整合",
    robotic_list_content_8:"整合式影音解決方案",
    robotic_list_content_9:"塑膠射出成型",
    
    robotic_section_1_title:"工業機器人",
    robotic_section_1_des:"工業機器人通常是指應用於工業環境的多關節機器手臂，專門用於焊接、物料搬運、塗裝及其它用途。其應用環境需求包括極緻的彎曲與旋轉活動、最高規格的靈活度以及微型化。信邦在工業環境擁有豐富經驗，專精於提供全面的強固連接方案。",
    robotic_section_1_list_title:"我們的產品包含",
    robotic_section_1_list_content_1:"馬達驅動控制器線材",
    robotic_section_1_list_content_2:"電力傳輸",
    robotic_section_1_list_content_3:"控制櫃線材組裝 – 連接控制、驅動與安全電路",
    robotic_section_1_list_content_4:"機櫃與基座連接纜線 – 電源和馬達編碼控制器",
    robotic_section_1_list_content_5:"印刷電路板組裝",

    robotic_section_2_title:"倉儲機器人",
    robotic_section_2_des:"靈活性與效率成為零售商與物流公司開始設置機器人的主要原因，這些新型解決方案加快了交貨時間、減少人為錯誤並能減輕倉儲人員的負擔。 為了要打造超越業界標準的最佳產品，信邦持續與許多倉儲機器人先驅品牌合作。",
    robotic_section_2_list_title:"我們的產品包含",
    robotic_section_2_list_content_1:"機器人充電連接解決方案，包含高電壓連接器線材",
    robotic_section_2_list_content_2:"紅外線數據與驅動裝置線材",
    robotic_section_2_list_content_3:"ODS 電源與電池充電線材",
    robotic_section_2_list_content_4:"乙太網路線材",
    robotic_section_2_list_content_5:"伺服馬達線材",
    robotic_section_2_list_content_6:"印刷電路板組裝",

    robotic_section_3_title:"線性與協作機器人",
    robotic_section_3_des:"隨著自動化興起，線性與協作機器人的應用隨之增加。 線性機器人往往具有較高精準度，因此常被作為重複性任務的理想自動化解決方案。而協作機器人的設計目的是在人機共享的工作環境下運作，這類型的連接解決方案須能承受瞬間的加減速、拉伸、機械與扭轉應力。 信邦為這種應用提供訊號、電源與拖曳線材。",

    robotic_section_4_title:"醫療機器人",
    robotic_section_4_des:"信邦已在醫療市場耕耘數年，我們的工廠通過 ISO13485 與 FDA 認證。 在高性能的客製線材組裝為了病患的安全與舒適，不論是用於機器人或電腦輔助手術上，都有著生物相容性與抗菌化合物。",
    robotic_section_4_list_title:"我們的產品包含",
    robotic_section_4_list_content_1:"極細同軸線組裝",
    robotic_section_4_list_content_2:"高速 USB type C",
    robotic_section_4_list_content_3:"乙太網路線材",
    robotic_section_4_list_content_4:"光導纖維",
    robotic_section_4_list_content_5:"混合線材組裝",
    robotic_section_4_list_content_6:"測量與控制裝置線材組裝",

    robotic_section_5_title:"類人型機器人",
    robotic_section_5_des:"感測器是類人型機器人的主要零件之一，而信邦持續與許多領導機器人品牌合作，以發展影音應用程式的解決方案。 除了這些解決方案，也有必要將線材組裝微型化，以及延長線材壽命。",
    robotic_section_5_list_title:"我們的產品包含",
    robotic_section_5_list_content_1:"線材整合與印刷電路板組裝解決方案",
    robotic_section_5_list_content_2:"影像應用的極細同軸線",
    robotic_section_5_list_content_3:"感測器線材與組裝",
    robotic_section_5_list_content_4:"高速數據傳輸線材組裝",

    robotic_footer_title:"實現你的構想",
    robotic_footer_content:"信邦不是一家常規的公司，我們往往能提供超越客戶期待與需求的服務。無論是工業、倉儲、醫療及類人型機器人等產業，信邦皆能從最初產品研發過程、原型設計到最終量產，提供量身訂造解決方案。",
    
    /////////////////////////////////
    //ebike
    /////////////////////////////////
    ebike_title:"信邦電力輔助自行車解決方案－翻轉距離定義",
    ebike_content_1:"電力輔助自行車接軌環保潮流，低污染、操作簡單、省力及不需駕照就能上路成為新潮的代步工具。",
    ebike_content_2:"信邦的客製化整合服務能力可為電動自行車相關客戶提供從零組件到OEM / ODM的整機組裝服務解決方案，為客戶大幅縮短製程或開發時間，有效降低時間成本。",
    ebike_links_title: "關於智慧移動(E-mobility)<br>你不可不知的X個關鍵",
    ebike_links_btn: "閱讀更多",
    ebike_footer_link_1: `Connectivity Solution`,
    ebike_footer_link_2: `E-System as a whole`,
    ebike_contact_title: `Interested in customizing your E-bike solution?<br>Don't hesitate, contact us now!`,
    // ebike_section_1_title:"我們的產品與服務包含",
    // ebike_section_1_list_1:"軟韌體整合",
    // ebike_section_1_list_2:"機構及電機設計",
    // ebike_section_1_list_3:"印刷電路板組裝",
    // ebike_section_1_list_4:"顯示儀表版的整機組裝服務",
    // ebike_section_1_list_5:"馬達驅動系統解決方案",
    // ebike_section_1_list_5_sub_1:"線材組裝",
    // ebike_section_1_list_5_sub_2:"印刷電路板組裝",
    // ebike_section_1_list_6:"充電系統解決方案",
    // ebike_section_1_list_6_sub_1:"電源開關線束",
    // ebike_section_1_list_6_sub_2:"充電線束",
    // ebike_section_1_list_6_sub_3:"充電電池模組",
    // ebike_section_1_list_6_sub_4:"充電電池連接器",
    // ebike_section_1_list_6_sub_5:"充電轉接頭（含充電線＋插座）",
    // ebike_section_1_list_7:"無線通訊模組與天線設計",
    // ebike_section_1_list_7_des:"可支援全球LTE 及 UMTS / HSPA+ 網絡系統。",
    // ebike_section_1_list_7_sub_1:"無線通訊模組解決方案",
    // ebike_section_1_list_7_sub_2:"4G 模組",
    // ebike_section_1_list_7_sub_3:"4G 天線",
    // ebike_section_1_list_7_sub_4:"GPS 模組",
    // ebike_section_1_list_7_sub_5:"NFC 天線",
    // ebike_section_1_list_7_sub_6:"多種客製化的訊號解決方案",
    // ebike_section_1_list_8:"整合感測器服務",
    // ebike_section_1_list_9:"從原線材到線材組裝與客製化連接器開發",
    // ebike_section_1_list_9_sub_1:"訊號傳輸線",
    // ebike_section_1_list_9_sub_2:"電源傳輸線",
    // ebike_section_1_list_9_sub_3:"控制器線束",
    // ebike_section_1_list_9_sub_4:"電子鎖線束",
    // ebike_section_1_list_9_sub_5:"各式客製化的線材",
    // ebike_section_1_list_9_sub_6:"客製化連接器開發",
    // ebike_section_1_list_10:"ECU 設計",
    // ebike_section_1_list_10_sub_1:"微控制器(MCU)",
    // ebike_section_1_list_10_sub_2:"能量管理系统(PMS)",
    // ebike_section_1_list_10_sub_3:"電池管理系統(BMS)",
    // ebike_section_1_list_10_sub_4:"無線通訊模組",
    // ebike_section_1_list_10_sub_5:"感測器",
    // ebike_section_1_list_10_sub_6:"馬達控制",
    // ebike_section_1_list_10_sub_7:"無刷直流馬達應用及電路圖設計",

    // ebike_section_2_content_1:"信邦在電力輔助自行車解決方案除了提供天線、無線通訊模組、線材與線材組裝等服務之外，也提供物聯網整合性解決方案。同時，信邦可提供顯示儀表版的整機組裝服務，並提供整合性的整車組裝服務。信邦在電力輔助自行車顯示儀表板的解決方案經過 IPX5 防水認證；馬達時速可符合各國法規，並提供穩定動力的驅動系統；充電系統更可以在 -10 ℃~+85 ℃ 的環境下正常運作，完美適應世界各地的天氣，並能保持充電期間的安全及穩定；無線通訊模組有效解決因零件安在金屬整機外殼內而阻擋訊號的問題，使訊號傳輸仍能長時間穩定傳輸。",
    // ebike_section_2_content_2:"信邦從進料、組裝、測試等ㄧ站到府的客製化服務，全面採行E化管理，讓每一台電力輔助自行車都擁有自己的生產履歷，品質管理有保證。除此之外，信邦亦提供完整測試服務，可依據客戶產品的環境需求，進行合適路段的騎乘測試，以確保產品狀態良好。",

    /////////////////////////////////
    //ebike privacy
    /////////////////////////////////
    ebike_privacy_param_1_2:"當您在我們的網站（「網站」）或我們的任何平台（與該網站一起統稱為「平台」）上建立帳戶，訂閱接收我們的及時消息或其他行銷資訊，或同意向我們提供商品或服務，我們可能會要求您提供您的聯絡資訊。例如，如您是我們的客戶，我們將儲存您的姓名、地址、電子郵件和電話號碼等資訊用於填入未來訂單的結帳資訊。當您向我們購買商品時，我們也會收集帳單和帳戶資訊。當您作為客戶、潛在客戶，供應商、顧問或其他第三方與我們展開業務時，我們可能會處理您的個人資訊。例如，我們可能會保存您的業務聯絡資訊和財務帳戶資訊（如有）以及您與我們之間的其他通訊，以維持我們與您的業務關係。",
    /////////////////////////////////
    //ebike terms
    /////////////////////////////////
    ebike_terms_title:"使用條款",
    ebike_terms_des:"最新更新︰",
    ebike_terms_param_0:'本應用程式係由信邦電子股份有限公司(以下簡稱“我們”或“信邦”)提供。請在使用本App前仔細閱讀本使用條款。在任何電腦、手機、平板電腦或者其他裝備使用本行動應用程式 (“本應用程式”)，即表明您已經閱讀、理解並同意受本使用條款和其他適用法律約束，不論您是否為註冊用戶。我們有權在任何時間不經通知修改本使用條款，並於公布在本應用程式之時起生效。 您如持續使用本應用程式，即視為接受本使用條款的修訂。如果您不同意本使用條款，請勿使用本應用程式。',
    ebike_terms_title_1:"智慧財產權",
    ebike_terms_param_1:'除使用者產生內容，本應用程式的全部智慧財產權(包括受著作權、商標或專利法保護的材料)均為我們或我們的授權方所有。本應用程式所顯示的全部商標、服務標章和商品名稱為我們所有、註冊和/或授權。本應用程式全部內容（除使用者產生內容外），包括但不限於文字、軟體、腳本、代碼、設計、圖示、圖片、聲音、音樂、錄影、應用、互動功能和其他所有內容（以下簡稱“內容”）均為中華民國和其他國家著作權法下的共同著作，且為我們或我們的授權方的專有財產。',
    ebike_terms_title_2:"平台使用限制",
    ebike_terms_param_2:"您只能為您自己的非商業用途使用本本應用程式。您同意不修改或刪除任何您從本應用程式下載或列印的所有權聲明。您同意未經我們事先書面同意，不得改變、複製、翻譯、廣播、演出、展示、散布、生產、再版、下載、發表、傳輸或銷售任何本應用程式上出現的或內容，包括使用者產生內容（但是您自己合法發布於本應用程式的使用者產生內容不在此限）。本使用條款中任何內容不應被解釋為授予您任何智慧財產權。",
    ebike_terms_title_3:"使用者名稱/密碼保護",
    ebike_terms_param_3:"為保護您使用者名稱及密碼的安全，請勿允許其他任何人透過您的使用者名稱及密碼使用本應用程式。我們對您前述行為所造成的損失，不承擔任何責任，不論該使用您是否知悉。",
    ebike_terms_title_4:"使用者產生內容",
    ebike_terms_param_4:'“使用者產生內容”為您和/或其他本應用程式使用者發布於本應用程式或透過本應用程式提供的資訊，包含但不限於數據、評價、圖片、檔案、訊息、網站連結、音樂、錄影、設計、圖表、聲音等其他任何內容。您的使用者產生內容並非保密或專屬資訊。您授予並保證有權授予非獨家、不可撤銷、全球性、可轉讓、免權利金的，以任何目的，包括商業目的、廣告目的而使用您的使用者產生內容的永久使用權，包括但不限於翻譯、展示、複製、修改、散布、等商業化權利，無須支付任何費用予您。',
    ebike_terms_title_5:"您應遵守的使用者行為",
    ebike_terms_param_5_1:"請勿作出任何行為或發布任何內容使我們或其使用者可能受到任何傷害或承擔任何責任。您應對您的使用者產生內容、您與其他使用者的互動和您在平台上的行為，全權負責。",
    ebike_terms_param_5_2:"請勿利用本應用程式向其他使用者徵集個人資訊或發送未經請求的電子郵件或其他通信。請勿未經他人同意或以非法目的在本應用程式上收集、使用或發布他人隱私資訊。",
    ebike_terms_param_5_3:'請勿在本應用程式上發布任何廣告或進行商業行為。請勿發布任何涉及傳送“垃圾郵件”或“連鎖信件”',
    ebike_terms_param_5_4:"請勿偽造或提供虛假身分或年齡資訊。請勿代他人註冊或銷售或轉移您的個人檔案或帳戶。請勿使用或嘗試使用他人的帳戶。",
    ebike_terms_param_5_5:"您擔保任何您發布的使用者產生內容現在或將來皆不侵犯或違反任何他人的權利，包括著作權、商標、專利、商業祕密、隱私、肖像權等權利。",
    ebike_terms_param_5_6:"請勿採取任何干擾、破壞、損害、停用、過載或限制本應用程式功能的行為。請勿規避或修改任何本應用程式的安全技術或軟體。",
    ebike_terms_title_6:"註冊",
    ebike_terms_param_6:'您需註冊帳戶以使用本應用程式。本應用程式僅供已滿20歲的使用者或年滿13歲、未滿20歲但已取得您的監護人同意的使用者使用。您同意在本應用程式的全部註冊表格上提供您準確及最新的資料。如果您未滿13歲，註冊本應用程式的行為將被視為違反本使用條款，您的帳戶都可能會在不加警告的情況下予以刪除。社交網路和登入帳號<br>您亦可透過多種線上第三方服務，如 Facebook 或 Twitter 等社交媒體和社交網路服務 (「社交網路服務」) 啟用或登入服務。我們可以要求您在社交網路服務供應商的網站上，驗證、註冊或登入社交網路服務。在進行此整合工作的過程中，社交網路服務會讓我們存取您提供給他們的某些資訊，而我們將依據我們的隱私權政策，使用、儲存與揭露此類資訊。請記住，第三方服務 (包括社交網路服務) 使用、儲存與揭露您的資訊的方式，完全由此類第三方服務的政策管轄。',
    ebike_terms_title_7:"連結",
    ebike_terms_param_7:'本應用程式可能連結到由其他公司（“第三人平台”)經營的網站、應用程式或其他產品、服務。第三人平台具有獨立的使用條款和隱私政策。我們對第三人平台的內容和政策不負任何責任，您需自行評估及承擔是否進入或使用第三人平台的風險。',
    ebike_terms_title_8:"行動服務",
    ebike_terms_param_8:"我們盡力在眾多平台上提供服務，但我們無法保證應用程式與您的裝置相容。如果您使用應用程式，您的標準數據和簡訊費率將適用；您從中下載應用程式之應用程式商店的規則也將適用。您同意我們得以電子方式透過您的行動裝置與您通訊，如果您的手機號碼更改或者停用，您須及時更新您的帳戶資訊從而確保我們不會把您的訊息發送給其他人。",
    ebike_terms_title_9:"隱私",
    ebike_terms_param_9:"我們的隱私政策為本使用條款引用納入的組成部分，將進一步解釋本應用程式對資訊的蒐集和使用。",
    ebike_terms_title_10:"使用者互動的免責聲明",
    ebike_terms_param_10:"您因對您透過使用本應用程式與他人的互動全權負責。您同意與其他在本應用程式上的使用者進行互動時應採取合理預防措施並在接觸其他人之前進行必要的調查。我們對於您於本應用程式或透過本應用程式與本應用程式其他使用者、透過本應用程式接觸您的人、或者基於您在本應用程式上發布的資訊而發現您的人之間的互動所造成的任何損失或損害概不負責。",
    ebike_terms_title_11:"免責聲明",
    ebike_terms_param_11:'本應用程式、使用者產生內容、內容及本應用程式上的資料是在“現狀”的基礎上提供的且無任何擔保。我們不擔保本應用程式不會中斷、沒有任何錯誤、缺陷將獲得糾正、本應用程式或本應用程式伺服器不會受到病毒的攻擊或者受到任何傷害。在適用法律所允許的最大範圍內，我們不對本應用程式資料和內容使用的正確性、準確性、充分性、有效性、可靠性等作任何保證或聲明。您理解並同意您應自行承擔下載、對於您的使用及因您的使用致損害您進入本應用程式的行動裝置、電腦系統或發生資料遺失或者其他任何種類的傷害時，皆由您全權負責。我們保留隨時更改任何和全部內容及本應用程式使用且不必事先告知的權利。',
    ebike_terms_title_12:"責任限制",
    ebike_terms_param_12:"我們不對任何由於使用本應用程式或不能使用本應用程式、本應用程式其他使用者的行為而引起的直接、特殊、附隨，間接或衍生性損害承擔責任，包括任何利益損失或資料遺失，即使我們已經被告知此類損害的可能性。您由於使用本應用程式或任何內容的唯一救濟是停止使用本應用程式。",
    ebike_terms_title_13:"其他",
    ebike_terms_param_13:"您同意關於本應用程式、本使用條款、隱私政策以及任何您和我們之間的糾紛（以下簡稱“糾紛”）均適用中華民國法律，但選法法則除外。您同意所有由本應用程式直接或間接產生或與之相關的糾紛、索賠和訴訟應單獨解決，不訴諸任何形式的集體訴訟，且均應提交台北地方法院依照中華民國法律專屬解決。您同意就管轄地點及管轄權，放棄向對該糾紛具有管轄權的台北法院提出不具屬人管轄及不便利法庭原則的全部抗辯權利。我們未履行或未執行任何本使用條款中的條款，不應被視為放棄該等條款或權利。",
    ebike_terms_title_14:"可分割性",
    ebike_terms_param_14:"如果本使用條款中的任一條款被認定屬無效，仍得繼續執行本使用條款的其他條款。如果本使用條款中的任一條款被認定是違法、無效或不可執行，則該條款將視為與本使用條款相分割，其餘條款仍屬有效及可執行。",
    ebike_terms_title_15:"終止條款",
    ebike_terms_param_15:"我們得不經通知，以任何理由或無理由單方終止您帳戶、刪除您個人資料以及您的使用者產生內容並限制您使用本應用程式的全部或部分的權利，並且不對您或任何人承擔責任。您理解並同意，即使你的使用者產生內容被移除或您的帳戶被終止，您於其他平台（例如，Google等）的部分使用者產生內容，可能會繼續出現在本應用程式或其他平台上。您的帳戶被終止後，本使用條款仍然有效。本使用條款中的智慧財產權條款、使用者互動的免責聲明、免責聲明、責任限制條款、其他條款、可分割性條款以及其他按其性質於本使用條款終止後仍繼續有效的條款，於本使用條款終止後仍繼續有效。",
    /////////////////////////////////
    //capability
    /////////////////////////////////
    capability_title:"完整製造流程<br/>來自信邦",
    capability_section_1_title_1:"信邦的製造流程涵蓋了初始設計概念、完整的內部製作流程，以及產品驗證，落實了真正的垂直整合。",
    capability_section_1_desc_1:"我們為客戶提供的服務包含：",
    capability_section_1_list_1:"機械及電機設計",
    capability_section_1_list_2:"軟韌體設計",
    capability_section_1_list_3:"零組件與供應鏈管理",
    capability_section_1_list_4:"原線材設計與製造",
    capability_section_1_list_5:"線材組裝",
    capability_section_1_list_6:"包覆射出成型",
    capability_section_1_list_7:"塑膠射出成型",
    capability_section_1_list_8:"印刷電路板組裝",
    capability_section_1_list_9:"天線設計與製造",
    capability_section_1_list_10:"模治具設計與製造",
    capability_section_2_title_1:"垂直整合幫助我們全球的客戶取得顯著優勢",
    capability_section_2_list_1:"品質管制",
    capability_section_2_list_2:"縮短上市時間",
    capability_section_2_list_3:"管控零組件供應鏈",
    capability_section_2_list_4:"為客戶提供一站式服務",
    capability_section_2_list_5:"具有廣泛的製造能力",
    capability_section_2_list_6:"降低客戶的成本",
    capability_section_2_list_7:"快速生產以滿足高需求量",
    capability_section_2_list_8:"彈性製造",
    capability_acc_desc_1:"信邦工程服務在於提升專案的品質，減少不必要的開支。我們的專業團隊在產品開發的各階段即與客戶進行密切的合作，從可製造性設計流程出發，以客戶導向提供量身打造的設計解決方案。信邦完整的新產品開發流程及生產製造為客戶成就一流的服務及產品。",
    capability_acc_1_title:"工程服務",
    capability_acc_1_text_1:"信邦提供廣泛的加值工程服務，補足了我們端到端的供應鏈策略，並因此降低成本，加快客戶產品上市速度。首先，信邦的設計和工程團隊為產品開發過程創造豐厚價值，從一開始的概念發想到細節和原型設計，再到批量生產，幾乎涵蓋了完整設計週期。",
    capability_acc_1_text_2:"可製造性導向設計（DFM）",
    capability_acc_1_text_3:"信邦的工程師會依照可製造性導向設計原則(Design for Manufacturing, DFM) 來確保製造過程一致，並在設計階段找出並解決任何潛在的問題。可製造性導向設計原則能降低許多成本，因為透過標準化、外購零件、模組設計和標準設計功能，我們可以用更少的零件快速組裝產品。在打樣階段之前，信邦一定會提交可製造性導向設計評估報告，以確保達成客戶的需求，以及可行的工程方案。除了機械及電機設計之外， 可製造性導向設計同時也包含 3D 建模。",
    capability_acc_1_text_4:"機械及電機設計",
    capability_acc_1_text_5:"信邦旗下擁有 200 多名研發工程師，協助我們將客戶的設計概念推向市場。我們包含的服務有：",
    capability_acc_1_text_6:"機械設計與建模",
    capability_acc_1_text_7:"硬體設計",
    capability_acc_1_text_8:"射頻設計與建模",
    capability_acc_1_text_9:"3D列印產品原型",
    capability_acc_1_text_10:"印刷電路板佈線設計",
    capability_acc_1_text_11:"嵌入式系統設計",
    capability_acc_1_text_12:"韌體設計與整合",
    capability_acc_1_text_13:"新產品導入與專案管理",
    capability_acc_1_text_14:"信邦提供全面的新產品導入（NPI）流程，結合專業工程和製造服務，以及專門的專案管理支援。我們在共同的時區內工作並使用共通的語言，確保在整個製造過程中提供卓越的客戶服務與技術支援。",
    capability_acc_1_text_15:"我們致力於提供可靠的電子解決方案，重視給客戶即時且深入的協助，我們能夠協助您將設計概念轉化為市場銷售產品。",
    capability_acc_2_title:"原線材設計與製造",
    capability_acc_2_text_1:"信邦量身訂作的線材解決方案包含原線材設計與製造。",
    capability_acc_2_text_2:"從單芯線到龐雜的多芯結構，包含了低壓線和同軸線。我們自營的原線材製造使我們能夠開發生產小量電纜，以達成少量多樣的線材組裝製造目標。我們的絕緣與被覆材料能包覆所有物件並耐高、低溫。上述材料包含：",
    capability_acc_2_text_3:"PVC &amp; PE HYTREL FOAM-PE SR-PVC",
    capability_acc_2_text_4:"LSZH",
    capability_acc_2_text_5:"Cross linked PVC &amp; PE TPU",
    capability_acc_2_text_6:"FEP &amp; PTFE ETFE PFA",
    capability_acc_2_text_7:"Silicone EPDM EPR",
    capability_acc_2_text_8:"TPU &amp; TPE",
    capability_acc_3_title:"線材組裝設計與製造",
    capability_acc_3_text_1:"信邦追求高品質的工程製造能力，使我們能夠因應任何市場或用途，設計並製造出特定的線材。我們的產品在惡劣環境中依然可運作，並維持穩固的機電完整性。若有產量方面的需求，信邦能夠設計並配置全自動生產線，以確保品質一致，及提供實惠的價格。",
    capability_acc_3_text_2:"少量多樣",
    capability_acc_3_text_3:"我們在許多市場上體認到少量多樣的需求，為確保能提供客戶最具成本效益的解決方案，我們採用組合式工作站以保證在整個精簡的製造過程中減少浪費。",
    capability_acc_3_text_4:"自動化",
    capability_acc_3_text_5:"若有產量方面的需求，信邦能夠設計並配置全自動生產線。自動化對品質、提高生產率、增加材料利用效率、增強安全性，以及縮短工廠交貨時間等，皆能產生重大影響。",
    capability_acc_3_text_6:"我們的技術包含：",
    capability_acc_3_text_7:"焊接 - 全自動與高頻（14AWG-40AWG）",
    capability_acc_3_text_8:"超音波焊接（1.5mm<sup>2</sup>  -  50mm<sup>2</sup>）",
    capability_acc_3_text_9:"電磁干擾(EMI)及電磁相容性(EMC) 解決方案",
    capability_acc_3_text_10:"自動裁線、剝線和壓接",
    capability_acc_3_text_11:"壓接 － 自動（18AWG  -  28AWG）液壓（4/0 AWG  -  6 AWG）",
    capability_acc_3_text_12:"連接器設計與製造",
    capability_acc_3_text_13:"印刷電路板組裝整合",
    capability_acc_3_text_14:"編織",
    capability_acc_3_text_15:"雷射刻印",
    capability_acc_3_text_16:"客製化線材接頭設計",
    capability_acc_3_text_17:"包覆射出成型（內外模低壓成型）",
    capability_acc_3_text_18:"自動化灌膠",
    capability_acc_3_text_19:"半自動化成型",
    capability_acc_4_title:"印刷電路板組裝設計與製造",
    capability_acc_4_text_1:"信邦為各種市場應用設計及製造印刷電路板，主要目標為提供客戶完整的解決方案。我們結合設計、製造、產品信賴度以及生命週期測試，並遠超過客戶的需求與期待。",
    capability_acc_4_text_2:"我們的服務和技術包含：",
    capability_acc_4_text_3:"印刷電路板設計與佈線",
    capability_acc_4_text_4:"製造設計服務/測試設計服務",
    capability_acc_4_text_5:"單面、雙面、多層、軟性印刷電路板(FPC)",
    capability_acc_4_text_6:"全自動表面黏著與插件製程",
    capability_acc_4_text_7:"球格陣列封裝(BGA)或微型 BGA 設置",
    capability_acc_4_text_8:"自動光學檢測(AOI)",
    capability_acc_4_text_9:"3D X 光線檢驗",
    capability_acc_4_text_10:"自動化測試",
    capability_acc_4_text_11:"功能測試",
    capability_acc_4_text_12:"原型",
    capability_acc_5_title:"整機組裝",
    capability_acc_5_text_1:"信邦因應市場提供完整的整機組裝服務，包含醫療、汽車及航空、綠色能源、工業和通訊相關應用。我們藉由合作共創良好的業務，客戶重視我們的整體理念，並肯定我們將垂直整合與工程能力最佳化的過程。我們擁有跨功能的團隊，能夠提供優質的產品，實現客戶的期望。",
    capability_acc_5_text_2:"機械及電機設計",
    capability_acc_5_text_3:"專案管理",
    capability_acc_5_text_4:"可製造性導向設計（DFM）",
    capability_acc_5_text_5:"軟體設計與韌體整合",
    capability_acc_5_text_6:"印刷電路板製造與整合",
    capability_acc_5_text_7:"塑膠射出製造",
    capability_acc_5_text_8:"線材組裝製造",
    capability_acc_5_text_9:"驗證與生命週期測試",
    capability_acc_6_title:"天線設計與製造",
    capability_acc_6_text_1:"信邦設計並製造了一系列標準及訂製的天線解決方案，包含設計概念、原型、工具、天線製造、電纜、PCBA、殼架、測試。我們的天線解決方案廣泛應用於汽車、醫療、船舶、工業、通訊市場。",
    capability_acc_6_text_2:"我們的產品範圍包含：",
    capability_acc_6_text_3:"晶片天線／平板天線",
    capability_acc_6_text_4:"偶極天線",
    capability_acc_6_text_5:"PCB ／ FPC ／金屬／陶瓷嵌入式天線",
    capability_acc_6_text_6:"堅固外接式／ MIMO ／ GNSS 功能天線",
    capability_acc_6_text_7:"射頻模組（NB-IoT / LoRa / WiFi / 3G / 4G 等）",
    capability_acc_6_text_8:"射頻同軸電纜組件",
    capability_acc_6_text_9:"天線樣品",
    capability_acc_6_text_10:"我們使用雕刻、蝕刻曝光機和 3D 列印快速製作工程樣品。",
    capability_acc_6_text_11:"測試與驗證",
    capability_acc_6_text_12:"內部環境和機械測試是由我們的產品測試與認證部門所執行，以確保產品符合客戶所期望的規格。天線的電氣性能用於確保天線設計符合其規格。",
    capability_acc_6_text_13:"OTA Chamber (ETS-Lindgren) 測試",
    capability_acc_6_text_14:"無源",
    capability_acc_6_text_15:"峰值增益",
    capability_acc_6_text_16:"AVG 增益",
    capability_acc_6_text_17:"效率",
    capability_acc_6_text_18:"2D 模式",
    capability_acc_6_text_19:"3D 模式",
    capability_acc_6_text_20:"ECC",
    capability_acc_6_text_21:"有源 TRP（總輻射功率）／ TIS（總全向靈敏度）",
    capability_acc_6_text_22:"GSM",
    capability_acc_6_text_23:"GSM-GPRS",
    capability_acc_6_text_24:"GSM-EGPRS",
    capability_acc_6_text_25:"CDMA",
    capability_acc_6_text_26:"CDMA-EVDO",
    capability_acc_6_text_27:"WCDMA",
    capability_acc_6_text_28:"RSS",
    capability_acc_6_text_29:"LTE-FDD",
    capability_acc_6_text_30:"LTE-TDD",
    capability_acc_6_text_31:"Wi-Fi",
    capability_acc_7_title:"射出成型",
    capability_acc_7_text_1:"信邦在塑膠射出成型的製造方面擁有豐富的經驗，我們的生產設施讓我們能夠為客戶提供廣泛的選擇。",
    capability_acc_7_text_2:"產品可由多種熱塑性材料製成，包含 ABS、PC、PP、尼龍。產品所用的材料取決於您的機械與環境要求，以確保為您的電子解決方案提供最佳保護。",
    capability_acc_7_text_3:"多種固定方法，包含卡扣、栓鎖或螺釘固定",
    capability_acc_7_text_4:"可永久防篡改的超音波焊接",
    capability_acc_7_text_5:"各種不同的材料飾面，包含「皮革漆」",
    capability_acc_7_text_6:"雙料射出能力",
    capability_acc_7_text_7:"化學鍵射出能力",
    capability_acc_7_text_8:"所有材料在成型之前都要經過除溼",
    capability_acc_7_text_9:"中央供料系統，提高產品的效率和品質。",
    capability_acc_8_title:"模型製作",
    capability_acc_8_text_1:"信邦自行開發各種模具，包含包覆射出成型、塑膠射出，以及製造壓模和夾具。上述服務有助於縮短上市時間，並且使我們能夠控管供應鏈，降低客戶的成本。",
    capability_acc_8_text_2:"模型製作服務",
    capability_acc_8_text_3:"快速製造，平均 14 至 21 天",
    capability_acc_8_text_4:"模流分析",
    capability_acc_8_text_5:"暫用模具，用於較低的投資要求和原型",
    capability_acc_8_text_6:"3D建模",
    capability_acc_8_text_7:"裝配夾具設計與製造",
    capability_acc_8_text_8:"測試夾具設計與製造",
    capability_acc_8_text_9:"壓接模具製造",
    capability_acc_8_text_10:"印刷電路板夾具設計與製造",
    capability_acc_8_text_11:"精密測量夾具設計與製造",
    capability_acc_8_text_12:"包覆射出成型模具設計與製造",
    capability_acc_8_text_13:"低壓模具設計與製造",
    capability_acc_8_text_14:"塑膠射出模具設計與製造",
    capability_acc_8_text_15:"熱澆道模具設計與製造",
    capability_acc_8_text_16:"連接器外殼模具設計與製造",
    capability_acc_8_text_17:"金屬沖壓模具設計與製造",
    capability_acc_8_text_18:"壓鑄模具設計與製造",
    capability_acc_8_text_19:"超音波模具設計與製造",
    capability_acc_8_text_20:"矽膠模具設計與製造",
    capability_acc_9_title:"測試與驗證",
    capability_acc_9_text_1:"產品和驗證測試是製造過程的關鍵部分。信邦致力於提供可靠且量身訂作的優質製造解決方案，代表我們提供了全面的產品測試服務，使我們能夠提高產量並降低製造成本。我們擁有嫻熟的技術人員和測試工程師，與客戶密切合作以制定測試方案，包含機電和環境測試。我們擁有健全的品質程序，每個樣品都經過檢查和驗證，另外還包含每兩小時一次的隨機抽檢，以確保在大量生產前滿足所有品質要求。",
    capability_acc_9_text_2:"可用於線材組裝的測試範例",
    capability_acc_9_text_3:"電氣測試（網絡分析儀）",
    capability_acc_9_text_4:"高壓絕緣測試",
    capability_acc_9_text_5:"鹽霧試驗",
    capability_acc_9_text_6:"防潮性",
    capability_acc_9_text_7:"靜態濕度測試",
    capability_acc_9_text_8:"彎曲試驗（彎曲角度：45°/ 60°/ 90°/ 120°/ 180°）",
    capability_acc_9_text_9:"熱衝擊試驗（溫度範圍：-50℃ -  + 150℃）",
    capability_acc_9_text_10:"熱衝擊和彎曲測試（二合一）",
    capability_acc_9_text_11:"拉力試驗（容量範圍：0.1 kg ~ 2000 kg）",
    capability_acc_9_text_12:"纏繞線材抽出測試",
    capability_acc_9_text_13:"端子截面分析",
    capability_acc_9_text_14:"冷彎試驗（低至 -50℃）",
    capability_acc_9_text_15:"UL 易燃性測試",
    capability_acc_9_text_16:"自動插入和抽取測試",
    capability_acc_9_text_17:"三座標測量",
    capability_acc_9_text_18:"3d 量測顯微鏡",
    capability_acc_9_text_19:"能量色散 X 光線光譜儀",
    capability_acc_9_text_20:"金屬塗層厚度測試",
    capability_acc_9_text_21:"時域反射儀",
    capability_acc_9_text_22:"振動測試",
    /////////////////////////////////
    //dealer
    /////////////////////////////////
    dealer_title:"夥伴專區",
    dealer_email:"電子郵件",
    dealer_password:"密碼",
    dealer_signin:"登入",
    /////////////////////////////////
    //downloads
    /////////////////////////////////
    resources_title:"資源",
    downloads_title:"產品型錄",
    /////////////////////////////////
    //terms
    /////////////////////////////////
    terms_param_0_1:"您得以在遵守本網站使用條款（「條款」）下使用本網站（「網站」）。除非本條款明確允許，否則不得以任何方式影印、複製、重新發布、上傳、張貼、傳輸或散佈本網站的任何資料。透過造訪或使用本網站，包括但不限於購買本網站之產品或服務，即表示您同意受本條款的約束。如您不同意受本條款約束，您必須立即停止使用本網站。",
    terms_param_0_2:"信邦保留隨時更新或修改本條款的權利。任何您在變更後的繼續使用即視為您接受更新的條款。",
    terms_title_1:"網站內容",
    terms_param_1:"本網站，包括其所有內容，例如文字、圖像和用於產生頁面的 HTML（「資料」），都是我們(或我們授權者)的財產。未經信邦書面許可，您不得進行任何公眾或商業目的的使用、下載、上傳、複製、列印、展示、執行、複製、出版、修改、刪除、新增、授權、張貼、傳輸或散佈本網站的任何資料的全部或部分內容。我們得隨時終止、變更、暫停或中止本網站任何方面的內容或服務，包括但不限於資訊、資料、文字、音樂、聲音、照片、圖形、影片、訊息或其他資料（「內容」） 、功能和/或可用時間，並且我們不因此而需對您或任何第三方承擔責任。",
    terms_title_2:"授權",
    terms_param_2:"在您造訪我們的網站時，您被授予非獨家且不可轉讓的權利使用本網站所包含的資訊和服務。除非另有規定，本網站並未授予或允許您得以在本網站上存取任何內容、功能或資料，您不得修改、出租、租賃、出借、出售、散佈本網站內容或創建此類內容、特徵或材料(無論全部或部分)的衍生作品。除信邦另有規定外，您不得為任何目的下載或保存任何內容或螢幕的副本。如果您未依本條款使用本網站，則可能違反著作權法或其他法律，並需因此承擔可能責任。您瞭解本網站未授予您任何本網站上所使用到我們的商標、註冊商標、服務標誌、任何受版權保護的資料、或其他智慧財產權上的許可或其他授權。惟，您同意我們得以在本條款規範下無償任意使用您在網站上發布的任何內容。",
    terms_title_3:"使用限制和安全規則",
    terms_param_3_1:"任何人均應按合法目的使用本網站。您應對自己在本網站的行為(包括但不限於上傳、傳輸和發布訊息、在本網站上或透過本網站發布訊息)的後果負責。信邦禁止任何人並要求所有使用者同意不為以下目的使用本網站：",
    terms_param_3_list:[
        "發布任何不完整、虛假、不準確或不屬於您的訊息;",
        "冒充他人;",
        "從事或鼓勵從事構成刑事犯罪、引起民事責任或任何違反法律或法規的行為;",
        "發布受著作權保護或由第三人所有的資料，除非您是該著作權所有者或獲得所有者的許可;",
        "發布或洩漏商業機密，除非您是這些資料的所有者或取得所有者的許可;",
        "發布任何侵犯他人智慧財產權、隱私權或公開權的資料;",
        "試圖以任何方式干擾、侵犯本網站、信邦的網路或網路安全，或試圖利用本網站的服務以未經授權方式入侵任何電腦系統;",
        "試圖獲取非屬您的資料或登入您無權存取的伺服器或帳戶;",
        "試圖在未經授權的情況下探測、掃描或測試系統或網路的漏洞或破壞安全或驗證措施;",
        "試圖干擾任何使用者、主機或網路的服務，包括但不限於透過向網站投放病毒、超載、郵件轟炸；或發送未經請求的電子郵件，包括產品或服務的促銷和/或廣告，或在任何電子郵件或訊息群組貼文中偽造任何 TCP/IP 封包標頭或標頭資訊"
    ],
    terms_param_3_2:"違反系統或資訊網路安全可能涉及民事或刑事責任。我們將對異常事件進行調查，並與執法機構合作並追究參與此類違規行為使用者的責任。",
    terms_title_4:"專有權利",
    terms_param_4:"信邦（或其對應的授權者）是本網站上所出現的任何商標、註冊商標和/或服務標誌的所有者和/或經授權使用者，也是本網站內容和/或資訊的著作權所有者或被授權者。產品名稱、標誌、設計、標題、文字或短語可能受信邦或其他實體所有的商標、服務標記或商號的法律保護。未經我們事先許可，您同意不以任何方式展示或使用信邦識別標識、服務名稱或商標。",
    terms_title_5:"免責聲明",
    terms_param_5_1:"您瞭解並同意在適用法律允許的最大範圍內，本網站，包括但不限於所有內容、功能、材料和服務均「按AS IS」提供，不提供任何明示或暗示的保證，包括但不限於資訊、資料、資料處理服務或不間斷服務，資訊的可用性、準確性、完整性、有用性或內容的任何保證，以及有關所有權、不侵權、適銷性或特定用途適用性的任何保證。信邦不保證網站或由此提供的功能、內容或服務將及時、安全、不間斷或無錯誤，也不保證缺陷將得到糾正。信邦不保證網站將滿足使用者的期望或要求。您透過本網站獲得的任何建議、結果或資訊或資料（無論是口頭或書面）均不構成本條款的任何保證。若您不滿意本網站，您唯一的補救措施是停止使用本網站。",
    terms_param_5_2:"透過使用本網站下載或以其他方式取得的任何資料均由您自行決定並自行承擔風險，並且您將對下載任何此類資料所造成的任何損害承擔全部責任。",
    terms_title_6:"責任限制",
    terms_param_6:"在適用法律允許的最大範圍內，信邦、其關係企業或其董事、管理人員、員工、代理人、內容或服務的提供者，均不需因使用/誤用或無法使用本網站或與之相關的內容、材料和功能而直接或間接相關的任何間接、特殊、偶然、繼發、懲戒性或懲罰性損害承擔責任，包括，但不限於收入損失，或預期利潤，或業務、數據或銷售損失，或替代服務成本，即使信邦、其代表已被告知發生此類損害的可能性。上述某些限制可能因某些司法管轄區不允許責任限製或排除而不適用於您。在任何情況下，信邦均不承擔任何因本條款或您對本網站的使用而造成的任何損害、損失和訴訟（無論是合約規定或侵權行為，包括但不限於疏忽或其他原因）的責任。",
    terms_title_7:"一般規定",
    terms_param_7_1:"本條款構成您與信邦之間的完整協議，作為您使用本網站的管理，並取代您與信邦之間的任何先前協議。您也可能受到適用於本網站的附加條款和條件的約束。",
    terms_param_7_2:"您同意信邦與您之間不因本條款或您對本網站的使用而存在合資、合夥、僱傭或代理關係。",
    terms_param_7_3:"任何您可能與信邦或本網站間的索賠或訴訟事由必須在索賠或訴訟原因發生後一 (1) 年內提出。",
    terms_param_7_4:"任何信邦未行使或執行的本條款權利或規定，並不構成對該權利或規定的放棄。如本條款的任何規定經有管轄權的法院認為無效，雙方仍同意法院應盡力實現該規定中所反映的雙方的意圖，並且本條款的其他規定仍然有效。",
    terms_param_7_5:"未經我們明確書面同意，您不得轉讓本條款或本條款項下您的任何權利或義務。",
    terms_param_7_6:"本條款中的章節標題僅為便利起見，不具法律或契約效力。",
    /////////////////////////////////
    //privacy
    /////////////////////////////////
    privacy_date:"最新更新暨生效日期：2024年7月1日",
    privacy_param_0:"本隱私權政策解釋了我們收集、儲存、使用和傳播個人資訊的內容和方式。本隱私權政策不僅是我們對客戶的隱私權保護原則，也是對我們的供應商和業務合作夥伴的隱私權保護原則。",
    privacy_title_1:"我們收集哪些類型的資訊？",
    privacy_title_1_1:"您提供給我們的資訊",
    privacy_param_1_1:"當您註冊、接受我們的服務或訂購產品時，或在向我們提供產品或服務時，或為了職務招聘及人力資源管理目的時，或為了我們的任何資訊通訊、或回應我們的調查時，我們會收集您自願提供的個人資訊。我們也可能在與您互動時收集您的姓名、電子郵件地址和組織訊息，以便從您那裡獲取商品或服務，或以其他方式與您建立關係。",
    privacy_title_1_2:"因業務開展時收集的資訊",
    privacy_param_1_2:"當您在我們的網站（「網站」）或我們的任何平台（與該網站一起統稱為「平台」）上建立帳戶，訂閱接收我們的及時消息或其他行銷資訊，或同意向我們提供商品或服務，我們可能會要求您提供您的聯絡資訊。例如，如您是我們的客戶，我們將儲存您的姓名、地址、電子郵件和電話號碼等資訊用於填入未來訂單的結帳資訊。當您向我們購買商品時，我們也會收集帳單和帳戶資訊。當您作為客戶、潛在客戶，供應商、顧問或其他第三方與我們展開業務時，我們可能會處理您的個人資訊。例如，我們可能會保存您的業務聯絡資訊和財務帳戶資訊（如有）以及您與我們之間的其他通訊，以維持我們與您的業務關係。",
    privacy_title_1_3:"電子通訊聯繫",
    privacy_param_1_3:"如果您在我們的聯絡頁面向我們發送電子郵件或以其他方式上向我們提交訊息，我們將從您那裡獲取您在電子郵件中的資訊內容以及在我們的聯絡資訊頁面上包含的資訊。我們可能會將此類資訊儲存在專用於您的文件夾中，該保存將根據我們的保留程序進行。如您在網站上留下評論，您的使用者名稱、聯絡電子郵件和發佈時間亦可能連同與該評論相關的訊息保存。我們可能會使用這些資訊回覆您的詢問，或提供客戶支援服務。",
    privacy_title_1_4:"位置數據",
    privacy_param_1_4:"我們的平台可能會收集有關您所在位置的資料，例如您的 IP 位址的大致位置。除非有您的允許，否則該位置資料將不會以能識別您個人身分的形式匿名收集。我們亦可能因您從特定位置訪問本網站而向您發送通知。",
    privacy_title_1_5:"使用資料",
    privacy_param_1_5:"當您使用我們的平台時，我們可能會收集某些使用資料，例如您的網頁瀏覽器、行動裝置或作業系統，以便利您使用我們的平台，及優化您使用我們平台的體驗。",
    privacy_title_1_6:"透過自動收集工具收集的資訊",
    privacy_param_1_6:"在需要您同意的情況下，我們會在取得您同意時使用自動資訊收集工具，例如 cookie，其使用目的是透過保存您的偏好使您可以更加便利地使用我們的平台。我們可能會無限期保留這些訊息，但我們不會將這些資訊與您提供給我們的任何個人資料加以串聯。我們可能允許選定的第三方在平台上放置 cookie，以便我們更了解平台的使用情況或使用者人口統計數據，或向您提供相關廣告。這些第三方可能在您使用我們的平台時收集有關消費者隨著時間的推移和跨不同網站的線上活動的資訊。我們無法控制此類第三方 cookie 的使用或由此產生的資訊，也不對這些第三方的任何行為或政策負責。根據適用法律的要求，我們會事先徵求您的同意以使用 cookie 或其他追蹤技術。您可以隨時更新或撤回此同意。欲了解更多信息，請參閱我們的 <a href='/cookie-notice'>Cookie 聲明</a>。",
    privacy_param_1:"您沒有義務並可拒絕向我們提供任何個人資訊，但如果您不向我們提供所需的個人資訊，我們可能無法向您提供我們的產品或服務、從您那裡獲取產品或服務、或以其他方式與您互動。",
    privacy_title_2:"我們如何使用您的資訊？",
    privacy_param_2:"我們可能因各式目的需要使用自您收集到的相關資訊，我們使用時將符合適用法律和法規的規定。以下是我們可能使用您的資訊的例示：",
    privacy_param_2_list:[
        "按您需求提供我們的產品或服務或向您接收產品或服務；",
        "執行我們的使用條款或與您達成的協議，或遵守我們在此類協議下的義務（例如，支付款項）；",
        "回答您有關我們或我們的產品或服務的可能問題，或詢問有關您提供的產品或服務的問題；",
        "優化我們的產品、服務和平台；",
        "告知您我們認為您可能感興趣的產品、服務、促銷和優惠；",
        "進行一般商業行為，包括改善客戶服務、幫助我們開發和改進產品和服務的功能、更好地了解我們的客戶、促進和衡量營銷和促銷、防止不當行為以及管理我們的業務；",
        "通知您我們平台的更新；",
        "籌辦或進行促銷活動；",
        "進行其他行銷和商業活動；",
        "管理供應商；",
        "進行人員招募、面試活動以及錄取程序；及促進和維護平台資訊安全、管理和合法合規。"
    ],
    privacy_title_3:"我們會向誰披露您的資訊？",
    privacy_param_3:"任何有關您的資訊揭露將在取得您的允許後披露，但有以下狀況時，我們得不需取得您許可進行披露：",
    privacy_param_3_list:[
        "我們可能會與信邦集團的其他關係企業、成員分享您的個人資訊，例如，如果您向整個集團提供商品或服務。",
        "我們可能會披露有關您的資訊以幫助您完成交易，包括與我們操作平台或完成、確認您與我們進行的交易相關資訊。當我們將您的個人識別資料轉移給我們的服務方時，我們會要求服務方：(a) 僅得使用此類資訊來執行我們要求的服務、(b) 遵守本隱私權政策、(c) 根據適用法律要求為所收受的資訊提供充足保護。",
        "我們得不受限制地揭露已不具可識別個人資訊的匯總或匿名資料的結果。您的任何個人資訊將確保不會在此情況下被揭露。",
        "當信邦全部或大部分資產或業務或其任何業務線有實際或潛在合併、收購或其他出售或轉讓時，我們可能會向一個或多個第三方披露獲得的有關您的資訊。我們無法保證收到此資訊的本項交易第三方將遵守本隱私權政策。",
        "為接受相關專業服務並在遵守標準保密義務下，我們可能會提供予我們的審計師、法律顧問和類似第三方所收集到的有關您的資料。我們也可能因我們涉及的任何實際、潛在或預期的法律訴訟分享您的個人資訊。",
        "在特定情況且有法律義務時，我們可能會向政府或第三方披露有關您的信息，例如我們平台的涉嫌涉及非法活動、回應傳票或法院命令或其他法律程序。我們將盡力確保在必要且適當的情況下向執法部門、相關政府官員和監理機構提供自我們的平台收集的資訊。",
        "我們也可能因i)執行我們平台的使用條款、ii) 調查潛在的違規行為、iii) 偵測、預防或以任何方式處理詐欺、資訊安全或平台技術問題、或iv)預防我們平台受到損害，而向第三方揭露有關您的資訊。"
    ],
    privacy_title_4:"我們採取哪些安全措施保護有關您的資訊？",
    privacy_param_4:"我們採取商業上必要且合理的措施來儲存所收集的數據、資料，以防止未經授權的存取及確保所收集的的資料隱私受到保護。當我們傳輸和接收敏感資訊（例如財務資訊）時，我們會將訪客導向到安全伺服器以傳輸資料。然而，請注意，儘管我們致力遵循本隱私權政策，但沒有任何技術是完全安全的也無法保證資料傳輸過程完全安全。因此，儘管我們將採取合理的措施來保護您提供的所有資訊的安全性和完整性，但信邦不能保證任何此類信息，無論是在傳輸過程中還是在我們的系統上儲存或以其他方式由我們保管時，都會是絕對安全，不會受到未經您授權的其他人的入侵。任何此類傳輸或共享的風險均由您自行承擔，並由您確保您發送給我們的任何個人資料均安全發送。",
    privacy_title_5:"我們的平台支援「請勿追蹤」訊號嗎？",
    privacy_param_5:"我們未使用得以識別您的網路瀏覽器發出的「請勿追蹤」訊號的技術。您可以透過啟動您瀏覽器上允許您拒絕接受cookies 的設定來拒絕cookies、於首次造訪平台時拒絕同意放置 cookies、或隨後撤回此類同意。然而，如果您選擇停用 cookies，您可能無法使用我們平台的部分功能。",
    privacy_title_6:"連結本網站的其他第三方的隱私政策為何？",
    privacy_param_6:"本隱私政策僅適用於信邦對所獲得的資訊的使用和揭露的管理和保護。您應當注意，當您使用我們的平台，您可能會被引導至我們無法控制的其他應用程式或網站，而信邦不對第三方的隱私政策負責。我們預期大多數這些第三方將在您使用他們的服務時收集有關您的資訊。然而，無論第三方是否或以任何方式連接到本平台，信邦不對第三方的隱私政策提供擔保。我們鼓勵您在與任何第三方網站、應用程式或其他產品互動時閱讀其發布的隱私權政策。",
    privacy_title_7:"我們的平台對兒童使用者的政策是什麼？",
    privacy_param_7:"我們不會故意收集或保存13 歲以下人士的個人性識別資料，我們的平台也非供13 歲以下的人士使用。如果您未滿 13 歲，請不要在任何時候或以任何方式使用我們的平台。如果您得知您 13 歲以下的孩子未經您同意向我們提供了個人資訊，請與我們聯繫。",
    privacy_title_8:"您的加州消費者隱私法權益",
    privacy_param_8:"加州法允許居住在加州的客戶在基於個人、家庭或家用目的與信邦進行交易，且當信邦分享揭露該等加州客戶的資訊予第三方用以行銷產品或服務時，得以要求被告知有此類揭露情形或選擇退出。信邦並未出於第三方的直接行銷目的與第三方分享此類資訊。",
    privacy_title_9:"個人資料的跨境傳輸",
    privacy_param_9_1:"請注意，我們在歐盟和英國以外的區域運營，因此，信邦自歐盟和英國收集的個人資料將可能在無法提供等同歐盟和英國個人資料保護的司法管轄區進行處理。如我們將此類個人資料傳輸至未及GDPR保護水準的司法管轄區時，我們將以歐盟標準條款與接受方簽署協議以採取適當的保障措施保障該等資料。",
    privacy_param_9_2:"我們將依據下述法律允許依據處理平台與您相關的個人資訊：",
    privacy_param_9_list:[
        "<strong>合法權益。</strong>在多數情況下，我們處理您的個人資訊的依據是這樣做是否符合我們的合法商業利益。我們的合法商業利益規範於「我們如何使用您的資訊？」的 (c) – (k) 中。如果您代表您的雇主購買、銷售或提供產品或服務，這也將是我們處理您的個人資訊的合法依據。",
        "<strong>同意。</strong>我們可能在您同意的情況下處理您的個人資訊。當我們在您同意的基礎上處理您的個人訊息，您可以隨時撤回該同意。",
        "<strong>執行合約。</strong>為履行我們與您之間的合約、行使我們在合約下的權利或在與您簽訂合約之前根據您的要求採取措施，我們將可能於必要時處理您的個人資訊。本項將是我們向您提供您訂購的產品或服務、執行我們的使用條款、回答您對信邦、我們的產品或我們的服務問題時的法律依據。",
        "<strong>遵守法律義務。</strong>為了遵守法律義務，我們可能會在必要時，例如為了防止詐欺，而處理您的個人資訊。"
    ],
    privacy_title_10:"您的合法權利",
    privacy_param_10:"根據適用法律，您可能有權：",
    privacy_param_10_list:[
        "請求獲取我們所處理的您的個人資訊；",
        "立即糾正我們持有的任何有關您個人資訊的不正確處；",
        "要求刪除所持有的有關您的個人資訊；",
        "要求我們在不刪除的情況下，禁止或限制處理您的個人資訊；和在技​​術可行的情況下，請我們將您的個人資訊直接傳輸給第三方"
    ],
    privacy_param_10_1:"您可以透過「如何聯絡我們？」與我們聯絡來行使任何這些權利。我們將在 1個月內回覆您提出的請求，但如有必要，我們可將該期限再延長2個月。",
    privacy_param_10_2:"如果您認為我們未遵守本隱私權政策或適用法律規定的義務，您有權向相關當局提出申訴。我們希冀您先直接與我們聯繫、解決，許多資料保護機構亦要求您在提出申訴之前這麼做。",
    privacy_title_11:"保存期間",
    privacy_param_11:"我們得以在您繼續使用本平台、在我們這裡擁有帳戶，或為實現本隱私權政策目的所需的期間內盡可能保存您的個人資訊。您可以透過本政策約定聯絡我們要求關閉您的帳戶，我們會根據要求刪除您的個人資訊。然而，我們可能會基於法律、稅務或監管需要，或正當合法的商業目的，按照適用法律所允許或要求的規定額外保留您的個人資訊一段時間。",
    privacy_title_12:"有關本隱私政策的變更條款是什麼？",
    privacy_param_12:"我們保留隨時更新本隱私政策的權利。當我們進行更改時，我們將在此頁面上發布更新的隱私政策以及更新的生效日期。請定期造訪此頁面，以便您即時收到任何變更。",
    privacy_title_13:"您如何聯絡我們？",
    privacy_param_13_1:"如果您對本隱私政策有任何疑問，或因任何其他原因需要聯絡我們，您可以透過以下任一方式與我們聯絡：",
    privacy_param_13_2:[
        "電子郵件：<a href='mailto:service@sinbon.com'>service@sinbon.com</a> 或透過 <a href='tel:886226989999'>+886-2-26989999</a> 致電我們",
        "郵寄：<br>信邦電子股份有限公司<br>臺灣新北市汐止區新台五路一段79號4樓之13<br>收件人 法務處"
    ],
    /////////////////////////////////
    //recommends
    /////////////////////////////////
    recommends_news:"相關新聞",
    recommends_products:"相關產品",
    recommends_solutions:"相關解決方案",
    recommends_casestudy:"相關案例分享",
    recommends_blog:"相關文章",
    /////////////////////////////////
    //information
    /////////////////////////////////
    information_content:'本網站使用cookies協助我們在您使用網站時提供良好的使用者體驗，亦讓我們分析網站流量數據。按下「接受 Cookies」後即表示您同意我們使用cookies。',
    information_btn:"接受 Cookies",
    /////////////////////////////////
    //integrity
    /////////////////////////////////
    integrityContact_title:'違反從業道德行為舉報',
    integrityContact_des:'檢舉人應透過信邦正常管道反應問題，因檢舉案件受理涉及貪瀆不法情事，必須秉持公平正義原則，小心求證，勿枉勿縱，請嚴肅謹慎待之。<br>為能盡早調查與解決問題，請您務必提供可能的相關具體資訊與文件，若相關資訊與文件不齊備時，我們將無法進行調查。<br>信邦將依照個人資料保護法規定，以保密方式處理，保護檢舉人之身分，並採行適當之安全措施，防止個人資料被竊取、竄改、毀損、滅失或洩漏。請您自行輸入個人資料，並同意信邦：<br><br><ul><li>・於符合個人資料保護法的相關規定下，使用您的個人資料。</li><li>・於相關業務管理及執行等範圍內，對於您的相關個人資料之蒐集、處理或利用。</li><li>・以您所提供的個人資料，確認您的身分，進行聯絡及提供相關服務。</li></ul><p>請提供以下資訊：</p>',
    integrityContact_btn_start:'進行舉報',
    integrityContact_label_relation:'您與信邦的關係？',
    integrityContact_option_please_select:'Please select',
    integrityContact_option_relation_1:'供應商',
    integrityContact_option_relation_2:'客戶',
    integrityContact_option_relation_3:'其他(請說明)',
    integrityContact_relation_placeholder:'請說明您與信邦的關係',
    integrityContact_label_info:'個人資料(非必填)',
    integrityContact_label_name:'姓名',
    integrityContact_label_phone:'聯絡電話',
    integrityContact_label_email: '電子郵件',
    integrityContact_label_identify: '請列出涉入違規案件的信邦人員',
    integrityContact_label_who:'姓名',
    integrityContact_label_unit:'單位',
    integrityContact_label_position:'職稱',
    integrityContact_label_violate:'請說明違規行為',
    integrityContact_option_violate_1:'向本公司之交易對象要求、期約、索取、收受任何不正當利益',
    integrityContact_option_violate_2:'藉職務上之關係要求本公司之交易對象饋贈佣金、酬金或其他不正當利益，唯年節禮品往來且價值不高於新台幣一千元者，不在此限',
    integrityContact_option_violate_3:'於本公司任職期間持有與本公司交易對象之未上市櫃股份；唯於公開市場上買賣上市櫃股票則不在此限',
    integrityContact_option_violate_4:'有任何貪污、受賄、挪用、侵佔其負責保管或使用之本公司財產',
    integrityContact_option_violate_5:'其他(請具體描述)',
    integrityContact_violate_placeholder:'請具體描述違規行為',
    integrityContact_label_how:'您如何得知此違規行為？',
    integrityContact_option_how_1:'親身經歷',
    integrityContact_option_how_2:'同事或親友的經歷',
    integrityContact_option_how_3:'藉由相關證據推測得知',
    integrityContact_option_how_4:'傳聞',
    integrityContact_option_how_5:'其他(請說明)',
    integrityContact_how_placeholder:'請說明您如何得知此違規行為',
    integrityContact_label_time:'事件具體的或發生的大致時間',
    integrityContact_label_where:'事件發生的地點',
    integrityContact_label_how_long:'您認為此事件發生的持續期間？',
    integrityContact_label_year: '年',
    integrityContact_label_month: '月',
    integrityContact_label_reason:'您認為這個問題是如何發生的？',
    integrityContact_option_reason_1:'信邦員工主動要求/暗示',
    integrityContact_option_reason_2:'供應商主動提供但信邦員工未拒絕 ',
    integrityContact_option_reason_3:'透過第三方人員媒介',
    integrityContact_option_reason_4:'其他(請具體描述)',
    integrityContact_reason_placeholder:'請描述您認為事件的發生原因',
    integrityContact_label_others:'請提供本案件的細節說明及任何相關有價值之資料',
    integrityContact_label_upload:'如果您有支援此案件的文件或檔案，請上傳',
    integrityContact_ps:'檔案須為下列格式: PDF, DOC, DOCX, XLS, XLSX, PPT, PPTX, HTML, TXT, CSV, JPG, PNG，檔案大小限5MB內',
    integrityContact_checkbox_des:'提供個資代表您同意本公司蒐集、處理及利用您的個資以提供相關服務。參閱我們的<a href="../../privacy" target="_blank">隱私政策</a>以取得更多資訊。',
    integrityContact_btn_send:'送出',
    /////////////////////////////////
    //integrity remind
    /////////////////////////////////
    integrityContact_alert_1:"請選擇您與信邦的關係",
    integrityContact_alert_2:"請說明您與信邦的關係",
    integrityContact_alert_3:'請填寫電話',
    integrityContact_alert_4:'請填寫違規案件之信邦人員姓名',
    integrityContact_alert_5:'請填寫違規案件之信邦人員單位',
    integrityContact_alert_6:'請填寫違規案件之信邦人員職稱',
    integrityContact_alert_7:'請選擇違規行為',
    integrityContact_alert_8:'請選擇如何得知此違規行為',
    integrityContact_alert_9:'請填寫事件發生的大致時間',
    integrityContact_alert_10:'請填寫事件發生的地點',
    integrityContact_alert_11:'請填寫您認為事件發生的持續時間',
    integrityContact_alert_12:'請填寫您認為事件的發生原因',
    integrityContact_alert_13:'請勾選我已充分了解且同意上述說明',
    integrityContact_alert_14:'檔案請小於5MB',
    integrityRemind_des:'信邦秉持「誠信合法經營、恪遵職業道德」，致力遵循各項公司治理法規。同仁於從事與公司業務行為之過程中，不得直接或間接提供、承諾、要求或收受任何不正當利益，或做出其他違反誠信、不法或違背受託義務等不誠信行為。信邦鼓勵內部及外部人員檢舉不合法、不道德及不誠信行為，若您有任何發現，請主動告知我們，我們將會有專員與您聯絡。<br>除法律另有規定外，我們將以保密方式處理檢舉案件，並由獨立管道查證，全力保護檢舉人之身分、個人資料及隱私。<br><br>本公司雖並不要求提供所欲舉報事項以外的更多資訊，惟評估舉報內容之重要資訊包括：<br><ul><li>・您的姓名及聯繫資料，包含電話、郵件地址、電子郵箱地址及與本公司的關係，主為於舉報調查期間，得為調查舉報事項與聯繫用途而處理利用。</li><li>・檢舉案相對人的姓名或其他足資識別其身分特徵之資料。</li><li>・可供調查的具體事證。</li></ul>有下列各款情形之一者，信邦得逕予回覆不予受理並結案：<br><ul><li>・未依前項規定提供資料。</li><li>・檢舉內容涉有惡意攻訐、虛偽不實、無具體內容、非屬違反法令者。</li><li>・檢舉內容顯無具體事證可供核查。</li><li>・同一相對人之被檢舉情事，業經信邦查證或已結案，再次檢舉者。但檢舉人已提出新事證證明該案有重新調查之必要者，不在比限。</li></ul><p>除依法律規定要求外，您不得將調查過程中與信邦之間的聯絡內容及資訊向第三方揭露，或以該聯絡內容作為訴訟相關資料。</p>',
    integrityRemind_checkbox_des:'我已充分了解且同意上述說明',
    /////////////////////////////////
    //cookie notice
    /////////////////////////////////
    cookieNotice_title:"Cookie Notice",
    cookieNotice_date:"Last updated and effective as of: July 1, 2024",
    cookieNotice_param_0_1:"SINBON respects your privacy and values the relationship we have with you.  This Cookie Notice explains our use of cookies and similar tracking technologies on our website (“Website”) and our online Web Shop (“Web Shop,” together with the Website, our “Platforms”).",
    cookieNotice_param_0_2:"We may obtain information about your general internet usage by using “cookies”.  A cookie is a small text file that’s passed to your computer’s hard disk through your web browser, so the website remembers who you are when you return.  Cookies are widely used to make websites work or work more efficiently (our Platforms won’t work without some of the cookies we use).  They also provide useful information to website owners.  Other, similar tracking technologies exist (such as pixels).  For the purposes of this Cookie Notice, reference to cookies should be read to include these similar technologies.",
    cookieNotice_title_1:"What are essential and non-essential cookies?",
    cookieNotice_param_1_1:"Cookies that are strictly necessary for our Platforms to work are called “essential” cookies.  These are placed when you land on the relevant page on the Platform, and the law states that we do not need your consent to place these.",
    cookieNotice_param_1_2:'However, all other cookies are known as “non-essential” cookies.  We will only place non-essential cookies where you have given us your explicit consent (e.g.  by clicking ["Allow all cookies"] or ["Allow selection"] on our cookie banner).',
    cookieNotice_title_2:"What are session and persistent cookies?",
    cookieNotice_param_2_1:"Cookies can also be categorised as follows:",
    cookieNotice_param_2_list:[
        "The first type is known as a “session-based” cookie and is allocated to your device only for the duration of your visit to our Platform.  It helps you to move around the Platform faster.  This cookie automatically expires when you close down your browser.",
        "The second type is a “persistent” cookie.  These cookies store information between visits.",
    ],
    cookieNotice_param_2_2:"A full list of cookies placed by our Platforms is included in this Cookie Notice as an.",
    cookieNotice_title_3:"When will SINBON place cookies?",
    cookieNotice_param_3_1:"When you land on the relevant page on our Platforms, you will be presented with a cookie banner, asking for your permission for the Platform to place non-essential cookies.  We will only place such cookies when you have expressly given your consent for this.  You can change or withdraw your consent for our Platforms to place such cookies at any time by updating your preferences below.",
    cookieNotice_param_3_2:"You can also check your browser’s instructions for information on how to block all cookies.  However, if you block all cookies (including essential cookies) you may not be able to access all or certain parts of our Platform.  Unless you’ve set your browser to refuse cookies, our system will issue essential cookies as soon you visit our Platforms.",
    cookieNotice_param_3_3:"If you want to delete any cookies that are already on your computer, you should check the instructions for your file management software to locate the file or directory that stores cookies.",
    cookieNotice_title_4:"What other types of technology are used?",
    cookieNotice_param_4_1:"As well as cookies, we may use other technologies to recognize and track your use of our Platforms.  A web beacon (also known as a “tracking pixel” or “clear GIF”) is a clear graphic image (typically a one-pixel tag) that is delivered through a web browser or HTML e-mail, typically in conjunction with a cookie.",
    cookieNotice_param_4_2:"Web beacons may allows us, for example, to monitor how users move from one page within our Platforms to another, to track access to our communications, to understand whether users have come to our Platforms from an online advertisement displayed on a third-party website, to measure how ads have been viewed and to improve site performance.",
    cookieNotice_title_5:"Where can I find more information?",
    cookieNotice_param_5:"For more information about deleting and controlling cookies visit <a href='https://ico.org.uk/your-data-matters/online/cookies/' target='_blank'>https://ico.org.uk/your-data-matters/online/cookies/</a>, <a href='https://www.youronlinechoices.org/' target='_blank'>www.youronlinechoices.com</a> or <a href='https://www.aboutcookies.org/' target='_blank'>www.aboutcookies.org</a>.",
    cookieNotice_title_6:"How can I update my preferences?",
    cookieNotice_param_6_1:"For more information about updating your cookie preferences please visit the websites shown below depending upon which browser you are using.",
    cookieNotice_param_6_2:"How to manage cookies in:",
    cookieNotice_param_6_list:[
        "Internet Explorer: <a href='https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10' target='_blank'>https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10</a>",
        "Firefox: <a href='https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectslug=Cookies&redirectlocale=en-US' target='_blank'>https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectslug=Cookies&redirectlocale=en-US</a>",
        "Chrome: <a href='https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666' target='_blank'>https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666</a>",
        "Safari: <a href='https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac' target='_blank'>https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac</a>",
        "IOS: <a href='https://support.apple.com/en-us/HT201265' target='_blank'>https://support.apple.com/en-us/HT201265</a>"
    ],
    cookieNotice_param_6_3:'In addition, you may withdraw or adjust your consent preferences by clicking on the ["Manage consent"] icon located [on the bottom left-hand corner of the browser].',
    cookieNotice_title_7:"Appendix – cookies and similar technologies used by our Platform.",
    /////////////////////////////////
    //cookie setting
    /////////////////////////////////
    cookieSetting_title:"隱私權設定",
    cookieSetting_block1_title:"必要的 Cookies",
    cookieSetting_block1_desc:"這些 Cookie 為網站運作所必需，且您不能在系統中將其關閉。通常這些 Cookie 設定是用來反應您所做出的操作或服務請求，如隱私偏好設定、保持登入或自動填入。您可以將您的瀏覽器設置為禁用或向您警示這些 Cookie ，但可能會導致某些網站功能無法運作。這項技術不會儲存任何個人身份資訊。",
    cookieSetting_block2_title:"功能性 Cookies",
    cookieSetting_block2_desc:"這些 Cookie 使網站能夠提供附加的功能和個人化服務，它們可能由我們或網站頁面上提供各項服務的第三方供應商所設置。 如果您不允許這些 Cookie ，則這些服務可能部分或全部將無法正常運作。",
    cookieSetting_block3_title:"效能性 Cookies",
    cookieSetting_block3_desc:"使用這類 Cookie 我們可以計算網站流量和流量來源，以便衡量和提高我們網站的效能。 Cookie 有助於我們了解哪些頁面最受歡迎、哪些最不受歡迎，並查看訪問者如何瀏覽網站。這些 Cookie 收集的所有資訊都彙整在一起，並以去識別化方式處理。如果您不允許使用這些 Cookie ，我們將不知道您何時訪問了我們的網站，也無法監控其效能。",
    cookieSetting_btn_save:"儲存設定",
    cookieSetting_btn_accept_all:"接受全部",
    cookieTip_btn_setting:"設定",
    cookieTip_btn_accept:"接受",
    cookieTip_content:"本網站使用cookies協助我們在您使用網站時提供良好的使用者體驗，亦讓我們分析網站流量數據。按下「接受」後即表示您同意我們使用cookies。"
}

export default lang