import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './langPack/en'
import tw from './langPack/tw'
import cn from './langPack/cn'
import de from './langPack/de'
import jp from './langPack/jp'
import hu from './langPack/hu'

import {defaultLang} from "@/static/langs.json"
import {readCookie} from "@/libs/js/cookie.js"

Vue.use(VueI18n);

const messages={en,tw,cn,de,jp,hu};
var currentLang=defaultLang;


//後端控制語系用
if(readCookie('lang_code')!=undefined||readCookie('lang_code')!=null) currentLang=readCookie('lang_code');

// local僅vue開發用，強制指定語系
if(process.env.NODE_ENV !== 'production'){
    currentLang='tw';
    // currentLang='en';
    // currentLang='de';
}

const i18n = new VueI18n({
    locale: currentLang,
    messages
});

export default i18n