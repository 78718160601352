<template>
    <div id="channelBox">
        <section class="unpad text-center ">
            <div class="row--gapless row no-gutters">
                <div class="col-12" :class="'col-sm-'+colNum" v-if="getBlock('solution')">
                    <router-link :to="{name:'solution',query:{ cate:'all',page:'1'}}" class="block">
                        <div class="feature feature-7 boxed imagebg mb-0" data-overlay="5">
                            <div class="background-image-holder">
                                <img alt="Putting two puzzles together with sunset pouring in the room" :src="$data.siteUrl + 'img/footer-1.png'" />
                            </div>
                            <h4 class="pos-vertical-center" v-html="$t('footer_block_1_title')">完善整合<br/>了解信邦全面整合力</h4>
                        </div>
                    </router-link>
                </div>
                <div class="col-12" :class="'col-sm-'+colNum" v-if="getBlock('contact-custom')">
                    <a href="https://ix.sinbon.eu/" target="_blank" class="block">
                        <div class="feature feature-7 boxed imagebg mb-0" data-overlay="5">
                            <div class="background-image-holder" >
                                <img alt="background" :src="$data.siteUrl + 'img/footer-2.png'" />
                            </div>
                            <h4 class="pos-vertical-center" v-html="$t('footer_block_2_title')">創新服務<br/>線上客製需求的線材</h4>
                        </div>
                    </a>
                </div>
                <div class="col-12" :class="'col-sm-'+colNum" v-if="getBlock('contact')">
                    <a href="#" data-notification-link="contact" class="block">
                        <div class="feature feature-7 boxed imagebg mb-0" data-overlay="5">
                            <div class="background-image-holder" >
                                <img alt="People are brainstorming in the meeting room while a woman is writing her idea on the glass" :src="$data.siteUrl + 'img/footer-3.png'" />
                            </div>
                            <h4 class="pos-vertical-center" v-html="$t('footer_block_3_title')">聯繫我們<br/>尋求完善解決方案</h4>
                        </div>
                    </a>
                </div>
                <div class="col-12" :class="'col-sm-'+colNum" v-if="getBlock('global-location')">
                    <router-link :to="{name:'global-location'}" class="block">
                        <div class="feature feature-7 boxed imagebg mb-0" data-overlay="5">
                            <div class="background-image-holder" >
                                <img alt="Blue world map with international connections" :src="$data.siteUrl + 'img/footer-4.png'" />
                            </div>
                            <h4 class="pos-vertical-center" v-html="$t('footer_block_4_title')">完整的全球佈局<br/>為您提供更全面的服務</h4>
                        </div>
                    </router-link>
                </div>
            </div>
            <!--end of row-->
        </section>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    props:['blocks','col'],
    methods:{
        getBlock(blockName){
            if(this.blocks.indexOf(blockName)!=-1) return true;
        }
    },
    mounted(){
        this.$themeInitNotification();
    },
    data(){
        return {
            colNum:this.col?12/this.col:4
        }
    }
};
</script>