<template>
	<div v-if="items">
		<section :class="['feature-large',index%2==0 ? 'switchable' : '']" v-for="item,index in items.rows">
            <div class="container">
                <div class="row justify-content-around">
                    <div class="col-md-6">
                        <img :alt="item.title" class="border--round box-shadow-wide" :src="item.cover">
                    </div>
                    <div class="col-md-6 col-lg-5">
                        <div class="switchable__text">
                            <span>{{item.people_name}}</span>
                            <h2 class="h4">{{item.subtitle}}</h2>
                            <h3 class="h3">{{item.title}}</h3>
                            <router-link :to="{ name:'career-family-detail',params:{id:item.id}}" v-html="$t('career_family_view')">看看他的故事 »</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    // import {readCookie} from "@/libs/js/cookie.js"
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
	export default {
        data:function(){
            return{
                items:null,
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        methods:{
            fetchItems:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'csrsinboner/items',
                    
                })
                .then(function (response) {
                    _this.items=response.data;
                    // _this.$window.initAll();
                    _this.$toggleLoading(false);
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        },
        created(){
            this.fetchItems();
            setMetaInfo( this, undefined, undefined, undefined, undefined )
        },
    };
</script>