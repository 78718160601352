<template>
	<div class="webinar">
        <section class="webinar_slide height-50 slider slider--animate cover cover-3" data-paging="true" data-timing="5000" v-if="banners">
            <ul class="slides">
                <li class="imagebg" :data-overlay="index" v-for="item,index in banners" :key="index">
                    <div class="background-image-holder">
                        <img alt="image" :src="item.cover">
                    </div>
                    <div class="container pos-absolute pos-vertical-center">
                        <div class="row">
                            <div class="col-sm-10">
                                <div class="webinar_slide__upcoming">UPCOMING WEBINARS</div>
                                <h3 class="h1 mb-0">{{item.title}}</h3>
                                <p class="lead mb-3 webinar_slide__subtitle">
                                   {{item.subtitle}}
                                </p>
                                <div class="d-flex align-content-center">
                                    <img class="mr-2 mb-0" src="../../public/img/webinar-icon-calendar.svg"/>  
                                    <p class="font-weight-bold">{{item.eventdate}}</p>
                                </div>
                                <a :href="item.link" target="_blank" class="webinar_slide__btn btn btn--md mt-5 col-sm-3">
                                    <span class="btn__text">
                                        REGISTER
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </section>
        <section class="pt-5 pb-0 product breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('resources_title')">資源</li>
                    <li v-html="$t('nav_resourcesWebinar')">產品</li>
                </ol>
            </div>
        </section>
        <section class="pt-4 pt-lg-0">
            <div class="container">
                <div>
                    <h1 class="d-none">{{ currCategoryName }}</h1>
                    <div class="masonry-filter-container d-flex align-items-center">
                        <span v-html="$t('ui_category')">類別</span>
                        <div class="masonry-filter-group">
                            <div class="masonry-filter-holder">
                                <div class="masonry__filters">
                                    <ul>
                                        <li :class="currCategory=='all'?'active':''" @click="updateCategory('all')" v-html="$t('ui_allCategory')">所有類別</li>
                                        <li :class="currCategory==category.category_slug?'active':''" v-for="category,key in categories" @click="updateCategory(category.category_slug)">{{category.category_name}}</li>
                                    </ul>
                                </div>
                            </div>    
                        </div>
                    </div>
                    <h3>{{ categoryDes }}</h3>
                    <hr>
                    <div class="masonry__container row masonry--active" v-if="items">
                        <div class="masonry__item col-6 col-md-4" :key="index" v-for="item,index in filteredRows( items,currCategory,'category_slug').slice(pageStart, pageStart + itemPerPage)" data-masonry-filter="item.category_slug" >
                            <div class="webinar__item">
                                <router-link :to="{ name:'webinar-detail', params: {category:item.category_slug, slug:item.slug }}">
                                    <img :alt="item.cover_desc ? item.cover_desc:item.name" :src="item.cover" class="border--round">
                                </router-link>
                                <router-link :to="{ name:'webinar-detail', params: {category:item.category_slug, slug:item.slug }}">
                                    <div class="webinar__article">
                                        <h2 class="webinar__articleTitle">{{item.title}}</h2>
                                        <span>{{item.subtitle}}</span>

                                        <div class="webinar__desc" v-if="item.description">
                                            <div class="webinar__desc-content" v-html="item.description">
                                                <!-- {{item.description}} -->
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <pagination v-model="currPage" v-on:getTotalPage="totalPage" :currPage="currPage" :itemPerPage="itemPerPage" :itemTotal="totalItemNum" v-if="items"/>
        </section>
        <!-- <channel-box v-bind:blocks="['solution','contact-custom','global-location']"/> -->
    </div>
</template>
<script>
    // import channelBox from "../components/ChannelBox.vue";
    import pagination from "../components/Pagination.vue";
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    import { mapState } from "vuex";
    import { mapActions } from "vuex";
    import {setMetaInfo} from "@/libs/js/metaInfo.js"
	export default {
        data:function(){
            return{
                items:null,
                currPage:this.$route.query.page,
                itemPerPage:12,
                categories:null,
                currCategory:this.$route.query.cate?this.$route.query.cate:'all',
                currCategoryName:null,
                categoryDes:null,
                currBanner:0,
                metaData:{},
                banners: null
            }
        },
        metaInfo() {
            return this.metaData
        },
        components: {
            // "channel-box": channelBox,
            "pagination": pagination
        },
        computed:{
            ...mapState(['defaultMeta','webinarItem','webinarbanner','webinarCate']),
            totalItemNum:function(){
                return this.filteredRows(this.items,this.currCategory,'category_slug').length;
            },
            pageStart: function(){
                return (this.currPage - 1) * this.itemPerPage;
            },
        },
        methods:{
            ...mapActions(['fetchWebinarItems','fetchWebinarBanner','fetchWebinarCategory']),
            filteredRows: function(rows,cc,slug){
                this.$toggleLoading(false);
                var c = cc.toLowerCase();
                return ( c.trim() !== 'all' ) ?
                    rows.filter(function(d){ return d[slug].toLowerCase().indexOf(c) > -1; }) :
                    rows;
            },
            updateCategory:function(c){
                if(c==this.currCategory) return;
                this.currCategory=c;
                this.currPage=1;
                this.$router.push({name:'webinar',query:{cate:this.currCategory, page:this.currPage}});
            },
            findCate:function( arr ){
                return arr.category_slug === this.$route.query.cate;
            },
            setDesc:function(){
                let routeName = this.$route.name
                
                if( this.$route.query.cate != 'all'){
                    // console.log('cate != all');
                    // var found = this[routeName+'Cate'].find( this.findCate )
                    // this.categoryDes = found.category_description;
                    // setMetaInfo( this, found.meta_title ,undefined, found.meta_desc, found['thumb_'+routeName+'_category'] );
                    var _webinarTitle;
                    var _webinarCate=this.$route.query.cate.toLowerCase().replace('-','_');
                    if(this.$t('page_title_webinar_'+_webinarCate)){
                        _webinarTitle=this.$t('page_title_webinar_'+_webinarCate);
                    }
                    setMetaInfo( this, _webinarTitle, undefined, 'All SINBON products for you to reference, including EV charging cable, ix industrial cables, M series cable connector, and more.', undefined,false,true );
                }else{
                    // console.log('cate ==== all, 用預設 meta');
                    // this.categoryDes = ''
                    // setMetaInfo( this, this.meta.title, undefined, this.meta.desc, undefined );
                    setMetaInfo( this, this.$t('page_title_webinar'), undefined, 'All SINBON products for you to reference, including EV charging cable, ix industrial cables, M series cable connector, and more.', undefined,false,true );
                }

                this.$meta().refresh();
            },
            setCurrCategoryName:function(){
                this.categories.forEach( obj => { 
                    if( this.$route.query.cate === 'all'){
                        this.currCategoryName = 'All videos'
                    }else if( this.currCategory != 'all' && obj.category_slug === this.currCategory ){
                        this.currCategoryName = obj.category_name;
                    }
                })
            },
            totalPage:function(maxPage){
                if ( this.currPage > maxPage ) {
                    this.currPage = maxPage
                    this.$router.push({name:'webinar',query:{cate: this.currCategory, page: this.currPage }});
                }
            }
        },
        watch: {
            '$route' (to, from) {
                if(this.$route.query.cate!=this.currCategory){ // 切到其他 category 時
                    this.currCategory=this.$route.query.cate;
                    this.currPage=1;
                }
                if( this.currPage == undefined ) this.currPage = 1;
                this.$router.push({name:'webinar',query:{ cate: this.currCategory, page: this.currPage }});
                this.setDesc();
                this.setCurrCategoryName();
            },
            webinarCate(val){
                this.categories = val;
                this.setDesc();
                this.setCurrCategoryName();
            },
            webinarItem(val){
                this.items = val;
                // this.$window.initAll();
            },
            webinarbanner(val){
                this.banners = val;
                // this.$window.initAll();
            }
        },
        created(){
            if(this.currPage==undefined || this.currCategory==undefined){
                this.$router.push({name:'webinar',query:{cate:'all',page:'1'}});
            }
            this.fetchWebinarItems();
            this.fetchWebinarBanner();
            this.fetchWebinarCategory();
        }
    };

</script>