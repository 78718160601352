<template>
    <section id="searchResult" class="py-5">
      <div class="container">
        <gcse:search></gcse:search>
      </div>
    </section>
</template>
<script>
  import {setMetaInfo} from "@/libs/js/metaInfo.js";
  export default {
      data:function(){
          return {
              metaData:{}
          }
      },
      metaInfo() {
          return this.metaData
      },
      created() {
          setMetaInfo( this, undefined, undefined, undefined, undefined )
          // this.$window.initAll();
          this.$toggleLoading(false);
      },
  };
</script>