<template>
  <div>
    <section class="cover imagebg text-left space--sm" data-overlay="6">
      <div class="background-image-holder">
        <img alt="background" :src="$data.siteUrl + '/img/dealer-bg.jpg'" />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-lg-7">
            <h1 v-html="$t('downloads_title')">夥伴專區</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="py-5 breadcrumbsSection">
      <div class="container">
        <ol class="breadcrumbs">
          <li>
            <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
          </li>
          <li v-html="$t('resources_title')">資源</li>
          <li v-html="$t('downloads_title')">產品型錄</li>
        </ol>
      </div>
    </section>

    <section v-if="categories&&items" v-for="category in categories">
      <div class="container text-center">
        <h3 class="text-left" v-html="category.category_name"></h3>
        <div v-for="item in filteredRows(items,category.category_name,'category_name')">
          <div class="item d-flex boxed boxed--border">
            <div class="d-flex">
              <img v-if="item.thumb" :src="item.thumb" :alt="item.name"/>
              <h4 class="font-weight-bold d-inline-block text-left m-0 pr-3">{{item.name}}</h4>
            </div>
            <a v-if="item.file" class="btn btn--primary" :href="item.file" target="_blank"><span class="btn__text" v-html="$t('ui_download')">下載檔案</span></a>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-md-4" v-for="item in filteredRows(items,category.category_name,'category_name')">
            <div class="feature feature-3 boxed boxed--lg boxed--border">
              <img v-if="item.thumb" :src="item.thumb" :alt="item.name"/>
              <h4>{{item.name}}</h4>
              <a v-if="item.file" class="btn btn--primary mt-3" :href="item.file" target="_blank"><span class="btn__text" v-html="$t('ui_download')">下載檔案</span></a>
            </div>
          </div>
        </div> -->
      </div>
    </section>

    <!-- <section v-if="categories&&items" v-for="category in categories">
      <div class="container">
        <h3 class="text-left" v-html="category.category_name"></h3>
        <div class="row">
          <div
            class="col-12 col-md-6"
            v-for="item in filteredRows(items,category.category_name,'category_name')"
          >
            <div class="boxed border">
              <img :src="item.thumb"/>
              <p class="mb-2 h4" v-html="item.name"></p>
              <a :href="item.file" target="_blank" v-html="$t('ui_download')">下載檔案</a>
            </div>
          </div>
        </div>
      </div>
    </section> -->

  </div>
</template>


<style lang="less">
.item{
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  >div{
    align-items: center;
    img{
      width: 80px;
      border-radius: 5px;
      border:1px solid #f3f3f3;
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
}
@media screen and (max-width: 480px){
  .item{
    flex-direction: column;
    align-items: flex-start;
    >div{
      flex-direction: column;
      align-items: flex-start;
      img{
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
    .btn{
      width: 100%;
      margin-top: 20px;
    }
  }  
}

</style>


<script>
import apibase from "@/static/apibase.js";
import axios from "axios";
// import {readCookie} from "@/libs/js/cookie.js"
import {setMetaInfo} from "@/libs/js/metaInfo.js";
export default {
  data: function() {
    return {
      items: null,
      categories: null,
      metaData:{}
    }
  },
  metaInfo() {
      return this.metaData
  },
  methods: {
    filteredRows: function(rows,cc,slug){
      this.$toggleLoading(false);
      return rows.filter(function(d){ return d[slug].indexOf(cc) > -1; });
    },
    fetchItems:function(){
        var _this=this;
        
        axios({
            method: 'get',
            baseURL: apibase,
            url: 'downloads/items',
            
        })
            .then(function (response) {
              _this.items=response.data.rows;
              // _this.$window.initAll();
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    fetchItemsCategory:function(){
        var _this=this;
        
        axios({
            method: 'get',
            baseURL: apibase,
            url: 'downloads/categories',
            
        })
            .then(function (response) {
              _this.categories=response.data.rows;
              this.$toggleLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
  },
  created() {
    setMetaInfo( this, this.$t('page_title_download'), undefined, undefined, undefined,false,true );
    this.fetchItemsCategory();
    this.fetchItems();
  }
};
</script>