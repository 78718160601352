<template>
    <div class="container" v-if="totalPage>1">
        <div class="pagination">
            <div class="pagination__prev" v-if="currPage!=1" @click.prevent="setPage(currPage-1)">«</div>
            <ol>
                <li v-for="n in totalPage" v-bind:class="currPage==n ? 'pagination__current' : ''" @click.prevent="setPage(n)">
                    <div class="pagination__btn">{{n}}</div>
                </li>
            </ol>
            <div class="pagination__next" v-if="currPage!=totalPage" @click.prevent="setPage(currPage+1)">»</div>
        </div>
    </div>
</template>
<script>
	export default {
        props:['currPage','itemPerPage','itemTotal'],
        computed:{
            totalPage: function(){
                return Math.ceil(this.itemTotal / this.itemPerPage);
            }
        },
        methods:{
            setPage: function(idx){
                if( idx <= 0 || idx > this.totalPage ) return;
                this.$emit('input', idx);
                if( this.$route.name === "product" || this.$route.name === "solution" || this.$route.name === "news"|| this.$route.name === "webinar"){
                    this.$router.push({name:this.$route.name,query:{cate:this.$route.query.cate, page:idx}});
                }else{
                    this.$router.push({name:this.$route.params.name,params:{category:this.$route.params.category,page:idx}});
                }
            },
        },
        created(){
            this.$emit('getTotalPage', this.totalPage);
        }
    };

</script>