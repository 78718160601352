import Vue from 'vue'
import Router from 'vue-router'
// import {readCookie} from "@/libs/js/cookie.js"

import Home from './pages/Home.vue'

import Product from './pages/Product.vue'
import ProductDetail from './pages/Product_detail.vue'

import ProductEvCharging from './pages/Product_ev_charging.vue'
import ProductSolarSystem from './pages/Product_solar_system.vue'
import ProductRobotic from './pages/Product_robotic.vue'
import ProductEbike from './pages/Product_ebike.vue'
import ProductEbikePrivacyPolicy from './pages/Product_ebike_privacy.vue'
import ProductEbikeTerms from './pages/Product_ebike_terms.vue'

import Solution from './pages/Solution.vue'
import SolutionDetail from './pages/Solution_detail.vue'
import CaseStudy from './pages/Case_study.vue'
import CaseStudyDetail from './pages/Case_study_detail.vue'
import Blog from './pages/Blog.vue'
import BlogDetail from './pages/Blog_detail.vue'


import Sitemap from './pages/Sitemap.vue'

import Distribution from './pages/Distribution.vue'

import Capability from './pages/Capability.vue'

import InvestorsNews from './pages/Investors_news.vue'

import InvestorCorporateGovernance from './pages/Investor_corporate_governance.vue'
import GovernancePage from './pages/Governance_page.vue'


import InvestorFinancialInfo from './pages/Investor_financial_info.vue'
import InvestorShareholdersInfo from './pages/Investor_shareholders_info.vue'
import InvestorBusinessOverview from './pages/Investor_business_overview.vue'
import Missing from './pages/Missing.vue'


import CsrPage from './pages/Csr_page.vue'
// import CsrStakeholderContact from './pages/Csr_stakeholder_contact.vue'
//should be removed
import CsrReport from './pages/Csr_report.vue'


import SustainabilityReport from './pages/Sustainability_report.vue'

import EsgPage from './pages/Esg_page.vue'


import GlobalLocation from './pages/Global_location.vue'
import News from './pages/News.vue'
import NewsDetail from './pages/News_detail.vue'
import About from './pages/About.vue'

import Career from './pages/Career.vue'
import CareerTrain from './pages/Career_train.vue'
import CareerJob from './pages/Career_job.vue'
import CareerSchool from './pages/Career_school.vue'
import CareerIntern from './pages/Career_intern.vue'
import CareerFamily from './pages/Career_family.vue'
import CareerFamilyDetail from './pages/Career_family_detail.vue'


import Login from './pages/Login.vue'
// import DealerResource from './pages/Dealer_resource.vue'
import Downloads from './pages/Downloads.vue'
import Webinar from './pages/Webinar.vue'
import WebinarDetail from './pages/Webinar_detail.vue'
import SearchResult from './pages/Search_result.vue'

import Terms from './pages/Terms.vue'
import PrivacyPolicy from './pages/Privacy_policy.vue'
import Contact from './pages/Contact.vue'
import CookieNotice from './pages/Cookie_notice.vue'


Vue.use(Router);

let getRouterBase = function(){
  // let routerBase = '/'
  // if ( readCookie('lang_code') != 'en' ) routerBase = '/' + readCookie('lang_code')
  /*let routerBase = readCookie('lang_code') != 'en' ? '/' + readCookie('lang_code') : '/'
  return routerBase*/
  return BASE_URI;
}

const originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
}

export default new Router({
  // base:'/'+readCookie('lang_code') ? readCookie('lang_code'):'',
  base: getRouterBase(),
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    // keep-alive 返回缓存页面后记录浏览位置
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    }
    // 异步滚动操作
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 1 });
      }, 0);
    });
  },

  
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/product',
      // path: '/product/:category?/:page?',
      name: 'product',
      component: Product
    },
    {
      // path: '/product-detail/:category?/:slug?',
      path: '/product/:category?/:slug?',
      name: 'product-detail',
      component: ProductDetail
    },
    {
      path: '/ev-charging',
      name: 'ev-charging',
      component: ProductEvCharging
    },
    {
      path: '/solar-system',
      name: 'solar-system',
      component: ProductSolarSystem
    },
    {
      path: '/robotic',
      name: 'robotic',
      component: ProductRobotic
    },
    {
      path: '/e-bike',
      name: 'e-bike',
      component: ProductEbike
    },
    {
      path: '/e-bike-privacy',
      name: 'e-bike-privacy',
      component: ProductEbikePrivacyPolicy
    },
    {
      path: '/e-bike-terms',
      name: 'e-bike-terms',
      component: ProductEbikeTerms
    },


    {
      // path: '/solution/:category?/:page?',
      path: '/solution',
      name: 'solution',
      component: Solution
    },
    {
      path: '/solution/:category?/:slug?',
      name: 'solution-detail',
      component: SolutionDetail
    },



    {
      path: '/casestudy',
      name: 'casestudy',
      component: CaseStudy
    },
    {
      path: '/casestudy/:category?/:slug?',
      name: 'casestudy-detail',
      component: CaseStudyDetail
    },



    {
      path: '/blog',
      name: 'blog',
      component: Blog
    },
    {
      path: '/blog/:slug?',
      name: 'blog-detail',
      component: BlogDetail
    },



    {
      path: '/map',
      name: 'sitemap',
      component: Sitemap
    },



    {
      path: '/distribution/:category?/:page?',
      name: 'distribution',
      component:Distribution
    },



    {
      path: '/capability',
      name: 'capability',
      component:Capability
    },



    {
      path: '/investors-news/:page?/:id?',
      name: 'investors-news',
      component:InvestorsNews
    },
    {
      path: '/corporate-governance',
      name: 'corporate-governance',
      component:InvestorCorporateGovernance
    },
    {
      path: '/financial-info',
      name: 'financial-info',
      component:InvestorFinancialInfo
    },
    {
      path: '/shareholders-info',
      name: 'shareholders-info',
      component:InvestorShareholdersInfo
    },
    {
      path: '/business-overview',
      name: 'business-overview',
      component:InvestorBusinessOverview
    },
    


    // {
    //   path: '/csr/stakeholder-contact',
    //   name: 'csr-stakeholder-contact',
    //   component:CsrStakeholderContact
    // },


    //should be removed
    {
      path: '/csr/report',
      name: 'csr-report',
      component:CsrReport
    },


    
    {
      path: '/sustainability-report',
      name: 'sustainability-report',
      component:SustainabilityReport
    },


    {
      path: '/csr/:slug?',
      name: 'csr-page',
      component:CsrPage
    },


    {
      path: '/esg/:slug?',
      name: 'esg-page',
      component:EsgPage
    },


    {
      path:'/governance/:slug',
      name:'governance-page',
      component:GovernancePage,
      // children:[
      //   {
      //     path: ':detailSlug',
      //     name: 'governance-page-detail',
      //     component: GovernancePageDetail,
      //     meta: { transition: 'slide-left' },
      //   },
      // ]
    },


    {
      path: '/global-location',
      name: 'global-location',
      component:GlobalLocation
    },
    {
      path: '/news',
      name: 'news',
      component:News
    },
    {
      path: '/news/:slug?',
      name: 'news-detail',
      component:NewsDetail
    },
    
    {
      path: '/about',
      name: 'about',
      component:About
    },




    {
      path: '/career',
      name: 'career',
      component:Career,
      children:[
        {
          path: '/career/vision',
          name: 'career-train',
          component: CareerTrain
        },
        {
          path: '/career/job-opportunities',
          name: 'career-job',
          component: CareerJob
        },
        {
          path: '/career/student-and-graduate-programs',
          name: 'career-school',
          component: CareerSchool
        },
        {
          path: '/career/internship-program',
          name: 'career-intern',
          component: CareerIntern
        },
        {
          path: '/career/sinboner',
          name: 'career-family',
          component: CareerFamily
        },
      ]
    },
    {
      path: '/career/family-detail/:id?',
      name: 'career-family-detail',
      component:CareerFamilyDetail
    },
    
    
    {
      path: '/login',
      name: 'login',
      component:Login
    },
    // {
    //   path: '/partner-portal',
    //   name: 'dealer-resource',
    //   component:DealerResource,
    //   meta: {requireAuth: true}
    // },
    {
      path: '/brochure',
      name: 'brochure',
      component:Downloads
    },
    {
      path: '/webinar',
      name: 'webinar',
      component:Webinar
    },
    {
      path: '/webinar/:category?/:slug?',
      name: 'webinar-detail',
      component: WebinarDetail
    },
    {
      path: '/search-result',
      name: 'search-result',
      component:SearchResult
    },
    {
      path: '/terms',
      name: 'terms',
      component:Terms
    },
    {
      path: '/privacy',
      name: 'pravicy-policy',
      component:PrivacyPolicy
    },
    {
      path: '/contact',
      name: 'contact',
      component:Contact
    },
    {
      path: '/cookie-notice',
      name: 'cookie-notice',
      component:CookieNotice
    },
    {
      path: '*',
      name: 'missing',
      component: Missing
    }
  ]
})

