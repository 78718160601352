<template>
	<div>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h1 class="h2" v-html="$t('ebike_terms_title')">Privacy Notice</h1>
                        <!-- <p class="sub-lead" v-html="$t('ebike_terms_des')"></p> -->
                        <hr class="short">
                        <p class="sub-lead" v-html="$t('ebike_terms_param_0')"></p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h3 class="text-left" v-html="$t('ebike_terms_title_1')"></h3>
                        <p class="sub-lead" v-html="$t('ebike_terms_param_1')"></p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h3 class="text-left" v-html="$t('ebike_terms_title_2')"></h3>
                        <p class="sub-lead" v-html="$t('ebike_terms_param_2')"></p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h3 class="text-left" v-html="$t('ebike_terms_title_3')"></h3>
                        <p class="sub-lead" v-html="$t('ebike_terms_param_3')"></p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h3 class="text-left" v-html="$t('ebike_terms_title_4')"></h3>
                        <p class="sub-lead" v-html="$t('ebike_terms_param_4')"></p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h3 class="text-left" v-html="$t('ebike_terms_title_5')"></h3>
                        <ul class="bullets mt-2">
                            <li v-html="$t('ebike_terms_param_5_1')"></li>
                            <li v-html="$t('ebike_terms_param_5_2')"></li>
                            <li v-html="$t('ebike_terms_param_5_3')"></li>
                            <li v-html="$t('ebike_terms_param_5_4')"></li>
                            <li v-html="$t('ebike_terms_param_5_5')"></li>
                            <li v-html="$t('ebike_terms_param_5_6')"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h3 class="text-left" v-html="$t('ebike_terms_title_6')"></h3>
                        <p class="sub-lead" v-html="$t('ebike_terms_param_6')"></p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h3 class="text-left" v-html="$t('ebike_terms_title_7')"></h3>
                        <p class="sub-lead" v-html="$t('ebike_terms_param_7')"></p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h3 class="text-left" v-html="$t('ebike_terms_title_8')"></h3>
                        <p class="sub-lead" v-html="$t('ebike_terms_param_8')"></p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h3 class="text-left" v-html="$t('ebike_terms_title_9')"></h3>
                        <p class="sub-lead" v-html="$t('ebike_terms_param_9')"></p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h3 class="text-left" v-html="$t('ebike_terms_title_10')"></h3>
                        <p class="sub-lead" v-html="$t('ebike_terms_param_10')"></p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h3 class="text-left" v-html="$t('ebike_terms_title_11')"></h3>
                        <p class="sub-lead" v-html="$t('ebike_terms_param_11')"></p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h3 class="text-left" v-html="$t('ebike_terms_title_12')"></h3>
                        <p class="sub-lead" v-html="$t('ebike_terms_param_12')"></p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h3 class="text-left" v-html="$t('ebike_terms_title_13')"></h3>
                        <p class="sub-lead" v-html="$t('ebike_terms_param_13')"></p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h3 class="text-left" v-html="$t('ebike_terms_title_14')"></h3>
                        <p class="sub-lead" v-html="$t('ebike_terms_param_14')"></p>
                    </div>
                </div>
            </div>
        </section>
        <section class="text-left">
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <h3 class="text-left" v-html="$t('ebike_terms_title_15')"></h3>
                        <p class="sub-lead" v-html="$t('ebike_terms_param_15')"></p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    export default {
        data:function(){
            return {
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        created() {
            setMetaInfo( this, undefined, undefined, undefined, undefined )
            // this.$window.initAll();
        }
    };

</script>