<template>
    <div id="subscribeForm" data-animation="from-bottom" data-notification-link="edmForm" class="edmForm notification pos-bottom notification--reveal notification--dismissed m-0 p-0 h-100">
    <!-- <div id="subscribeForm" data-animation="from-bottom" class="edmForm notification pos-bottom notification--reveal notification--dismissed m-0 p-0 h-100"> -->
        <div class="contact__table">
            <div class="contact__cell">
                <div class="container contactBox col-10 col-sm-9 ml-auto mr-auto p-0">
                    <div class="feature feature-1 mb-0">
                        <div class="feature__body boxed boxed--lg boxed--border">
                            <!-- <div class="text-block">
                                <div class="row"> -->
                            <h3 class="notification-title">
                                <!-- 訂閱我們的電子報，隨時掌握最新動態 -->
                                {{ $t('home_subscribe_title') }}
                            </h3>
                                <!-- </div>
                            </div> -->
                            <div id="mc_embed_shell">
                                <div id="mc_embed_signup">
                                    <form action="#" method="get" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate text-left" target="_blank" @submit.prevent="subscribe">
                                        <div id="mc_embed_signup_scroll" class="row">
                                            <div class="col-md-6">
                                                <label class="contact_label">
                                                    <!-- 國家 -->
                                                    {{ $t('contact_label_country') }}
                                                </label>
                                                <div class="input-select">
                                                    <select v-model="formData.country" v-if="countries" required>
                                                        <option disabled selected v-html="$t('contact_select_country')">請選擇國家</option>
                                                        <option v-for="country in countries">{{ country.name }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                            <div class="col-md-6">
                                                <label class="contact_label">
                                                    <!-- 公司名稱 -->
                                                    {{ $t('contact_label_company') }}
                                                </label>
                                                <input v-model="formData.company" type="text" name="company" required>
                                            </div>
                                            
                                            <div class="col-md-6">
                                                <label for="mce-FNAME" class="contact_label">
                                                    <!-- 名 -->
                                                    {{ $t('contact_label_fname') }}
                                                </label>
                                                <input v-model="formData.fname" type="text" name="FNAME" class="required text" id="mce-FNAME" required>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="mce-LNAME" class="contact_label">
                                                    <!-- 姓 -->
                                                    {{ $t('contact_label_lname') }}
                                                </label>
                                                <input v-model="formData.lname" type="text" name="LNAME" class="required text" id="mce-LNAME" required>
                                            </div>
                                            <div class="col-md-12">
                                                <label for="mce-EMAIL" class="contact_label">
                                                    <!-- 電子郵件 -->
                                                    {{ $t('contact_label_email') }}
                                                </label>
                                                <input v-model="formData.email" type="email" name="EMAIL" class="required email" id="mce-EMAIL" required>
                                            </div>

                                            <div id="mce-responses" class="clear foot">
                                                <div class="response" id="mce-error-response" style="display: none;"></div>
                                                <div class="response" id="mce-success-response" style="display: none;"></div>
                                            </div>

                                            <div aria-hidden="true" style="position: absolute; left: -5000px;">
                                                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups -->
                                                <input type="text" name="b_24a2371a9ac19d981a7ab31be_c16632c833" tabindex="-1" value="">
                                            </div>
                                            
                                            <div class="optionalParent mt-4 col-md-12">
                                                <div class="clear foot">
                                                    <input type="submit" :disabled="sendIsDisabled" name="subscribe" id="mc-embedded-subscribe" class="btn btn--primary type--uppercase" :value="$t('home_subscribe_send')"/>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="notification-close-cross notification-close"></div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import {country} from "@/static/countryList.json";
import { mapActions } from "vuex";
import $ from "jquery";

export default {
    methods:{
        ...mapActions(["setLoadingStatus"]),

        subscribe(e){
            if(e) e.preventDefault();
            var _this=this;

            if (_this.formData.country.trim() == '')
            {
                alert(_this.$t('contact_alert_4')); // 請選擇國家
                return;
            }
            if (_this.formData.company.trim() == '')
            {
                alert(_this.$t('contact_alert_6')); // 請填寫公司名稱
                return;
            }
            if (_this.formData.fname.trim() == ''||_this.formData.lname.trim() == '')
            {
                alert(_this.$t('contact_alert_7')); // 請填寫姓名
                return;
            }
            if (_this.formData.email.trim() == '')
            {
                alert(_this.$t('contact_alert_8')); // 請填寫電子郵件
                return;
            }


            _this.sendIsDisabled='disabled';
            _this.setLoadingStatus(true);

            var _data=`EMAIL=${this.formData.email}&FNAME=${this.formData.fname}&LNAME=${this.formData.lname}&MMERGE11=${this.formData.company}&MMERGE5=${this.formData.country}&MMERGE10=???`;
            
            _this.$subscribe(_data)
            .then(()=>{
                _this.setLoadingStatus(false);
                _this.sendIsDisabled=false;
                // console.log('success')
                for(var k in _this.formData){
                    _this.formData[k]='';
                }
                $('#subscribeForm').addClass('notification--dismissed');
                $('html').removeClass('popup');
                alert(_this.$t('ui_subscribe_ok')); // 訂閱成功
            })
            .catch((err)=>{
                console.log(err);
                _this.setLoadingStatus(false);
                _this.sendIsDisabled=false;
                alert('Something went wrong, Please try again later.');
            })
        }
    },
    mounted() {
        let mcValidateJS = document.createElement('script')
        mcValidateJS.setAttribute('src', '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js')
        document.head.appendChild(mcValidateJS)
    },
    data:function(){
        return{
            countries: country,
            sendIsDisabled: false,
            formData:{
                email:'',
                fname:'',
                lname:'',
                company:'',
                country:'',
            },
        }
    }
}
</script>