<template>
    <div class="information-container" :class=" getCookieAllowStatus ? 'hide' : '' ">
        <div class="container information-wrap">
            <section class="row information">
                <div class="information-left col" v-html="$t('information_content')">
                    本網站使用cookies協助我們在您使用網站時提供良好的使用者體驗，亦讓我們分析網站流量數據。<br>按下「接受 Cookies」後即表示您同意我們使用cookies。
                </div>
                <div class="information-right col-md-4">
                    <div class="btn btn--primary btn--md" @click="hideInfo" v-html="$t('information_btn')">
                        確定
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
    name: 'information',
    data: function() {
        return {
            hide: false
        };
    },
    computed:{
        ...mapGetters(["getCookieAllowStatus"]),
    },
    methods:{
        hideInfo(){
            this.hide = true;

            // set cookie
            $cookies.set( "cookieAllowed", true );
            this.$store.dispatch( 'setCookieAllowStatus' , true );
        }
    },
    created(){
        this.$store.dispatch( 'setCookieAllowStatus' , $cookies.get( "cookieAllowed") );
    }
};
</script>