<template>
	<div v-if="detail">
    <!-- pb content -->
    <div v-if="detail.description_pb" v-html="detail.description_pb"></div>

    <recommend-product v-if="detail.product_category" :parentCategory="detail.product_category_slug" />

    <hr v-if="detail.product_category && detail.related_article" class="my-0" />
    <recommend-item v-if="detail.related_article" :type="'blog'" :items="detail.related_article" />

    <channel-box v-bind:blocks="['contact', 'contact-custom', 'global-location']" />
  </div>
</template>

<style lang="less">
  .title-bg {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
</style>

<script>
  import channelBox from "../components/ChannelBox.vue";
  import recommendProduct from "../components/recommend-product.vue";
  import recommendItem from "../components/recommend-item.vue";
  import apibase from "@/static/apibase.js";
  import axios from 'axios';
  import { setMetaInfo } from "@/libs/js/metaInfo.js"
    import { mapState, mapActions } from "vuex";

	export default {
    data() {
      return {
        slug: this.$route.params.slug,
        detail: null,
        metaData: {}
      }
    },
    
    metaInfo() {
      return this.metaData
    },

    methods: {
      ...mapActions(['fetchBlogDetail','setSelectedContactTopic']),
      fetchData() {
        let _this = this;
        this.fetchBlogDetail(_this.$route.params.slug);
      },

      setDesc(){
        var metaTitle;
        var metaDesc;

        if (this.detail.meta_title.trim() != '' && this.detail.meta_title != undefined) metaTitle = this.detail.meta_title;
        else metaTitle = this.detail.name;

        if (this.detail.meta_desc.trim() != '' && this.detail.meta_desc != undefined) metaDesc = this.detail.meta_desc;
        else metaDesc = this.$t('blog_banner_title');
        setMetaInfo(this, metaTitle, window.location.href, metaDesc, this.detail.meta_cover);
      },

      parseRoute() {
        this.detail = null;
        if (!this.$route.params.slug) this.$router.push({ name: 'blog', query: { page: '1' } });
        this.fetchData();
      }
    },

    created() {
      this.parseRoute();
      this.setSelectedContactTopic('solutions');
    },
    computed:{
        ...mapState(['blogDetail']),
    },
    // mounted() {
    //   this.$nextTick(function () {
    //     setTimeout(function() {
    //       window.pagebuilder_clip_script_init();
    //       _global.init();
    //     }, 3000);
    //   });
    // },

    components: {
      'channel-box': channelBox,
      'recommend-product': recommendProduct,
      'recommend-item': recommendItem
    },

    watch: {
      '$route'() {
        this.parseRoute();
      },
      blogDetail(val){
        this.detail = val;
        this.setDesc();
        setTimeout(() => {
            this.$window.pagebuilder_clip_script_init();
        }, 100);
      },
    }
  };
</script>