//存放 state 與 mutation 函式
import * as types from './mutations_type.js'
import router from '@/router'
import i18n from '@/libs/i18n/lang'
import apibase from "@/static/apibase.js";
import axios from 'axios';

// 純靜態網頁才需要
import { defaultLang, langs } from "@/static/langs.json"
import { createCookie, readCookie } from "@/libs/js/cookie.js"
import { hreflangArr } from "@/libs/js/metaLang.js"

//state
export const state = {
  siteUrl: SITE_URL,
  baseUri: BASE_URI,
  cookiePrefix: COOKIE_PREFIX,

  defaultMeta: {
    title: 'Solution Provider, Assembly Manufacturing',
    description:'SINBON is the most trusted partner for custom cable assemblies and connectivity solutions.',
    url:'https://www.sinbon.com',
    img:'https://www.sinbon.com/img/home-rv.jpg',
    // keywords:'custom cable assemblies,	custom cable manufacturersr, custom cable assembly manufacture'
  },

  lang: defaultLang,
  currentUser: null,
  routerToAfterLoggedin: '/',
  
  // contact
  selectedContactTopic: ' ',

  webinarContactQuery: null,
  webinarRegisterStatus: null,
  cookieAllowStatus: null,
  showApp: false,
  navigation: null,
  navTrends: null,
  navProducts: null,
  navEsg: null,
  navGovernance: null,
  productCate: null,
  productItem: null,
  solutionCate: null,
  solutionItem: null,
  solutionDetail: null,
  caseStudyCate: null,
  caseStudyItem: null,
  caseStudyDetail: null,
  blogLabel: null,
  blogItem: null,
  blogDetail: null,
  newsCate: null,
  isLoading: false,
  csrPageItem: null,//should be removed
  esgPageItem:null,
  brandCate: null,
  webinarbanner: null,
  webinarCate: null,
  webinarItem: null
}

//mutations
export const mutations = {
  // 由pyrocms後端框架控制
  [types.CHECK_LANG](state) {
    // var lang=defaultLang;
    // state.lang = lang;
    // i18n.locale = lang;
    //正常流程，只要抓cookie設定語系即可
    // if(readCookie('lang_code')){
    state.lang = PYRO_GTLD == '' ? 'en' : PYRO_GTLD;
    
    // 修改 html lang
    let htmlLang 
    let found = hreflangArr.find(function(item) {
        if (item.urlLang === '/' + PYRO_GTLD ) return item
    })
    
    if ( found != undefined && PYRO_GTLD != '')
    {
      htmlLang = found.langCode
    }
    else
    {
      htmlLang = 'en'
    }

    document.querySelector('html').lang = htmlLang;

    state.showApp = true;

    // }else{
    //     console.log('no lang_code cookie');
    //     // 沒有 lang_code cookie
    //     createCookie('lang_code','en');
    //     this.dispatch('setLang','en');
    // }
  },

  [types.SET_LANG](state, newLang) {
    var baseUrl;
    let fullPath = router.app.$route.fullPath
    let restUrl
    
    // 處理 baseUrl
    if ( newLang === 'en' )
    {
      baseUrl = location.protocol + '//' + location.host;
    } 
    else 
    {
      baseUrl = location.protocol + '//' + location.host + '/' + newLang;
    }
    
    // 處理 restUrl
    if ( fullPath.substr(2, 1) === '/' )
    {
      // 原本為英文以外語系
      restUrl = fullPath.substring(2, fullPath.length)
    }
    else
    {
      restUrl = fullPath
    }

    window.location = baseUrl + restUrl;
  },

  [types.FETCH_NAVIGATION](state, data) {
    state.navigation = data;
    state.navTrends = state.navigation.filter(item => item.uri&&item.uri.toLowerCase() == 'trends')[0];
    state.navProducts = state.navigation.filter(item => item.uri&&item.uri.toLowerCase() == 'products')[0].children;
    state.navEsg = state.navigation.filter(item => item.uri&&item.uri.toLowerCase() == 'esg')[0].children;
    state.navGovernance = state.navigation.filter(item => item.uri&&item.uri.toLowerCase() == 'governance-parent')[0].children;
  },
  
  [types.FETCH_PRODUCT_CATEGORY](state, data) {
    state.productCate = data;
  },

  [types.FETCH_PRODUCT_ITEMS](state, data) {
    state.productItem = data;
  },

  [types.FETCH_SOLUTION_CATEGORY](state, data) {
    state.solutionCate = data;
  },

  [types.FETCH_SOLUTION_ITEMS](state, data) {
    state.solutionItem = data;
  },

  [types.FETCH_SOLUTION_DETAIL](state, data) {
    state.solutionDetail = data;
  },

  [types.FETCH_CASE_STUDY_CATEGORY](state, data) {
    state.caseStudyCate = data;
  },

  [types.FETCH_CASE_STUDY_ITEMS](state, data) {
    state.caseStudyItem = data;
  },

  [types.FETCH_CASE_STUDY_DETAIL](state, data) {
    state.caseStudyDetail = data;
  },

  [types.FETCH_BLOG_LABEL](state, data) {
    state.blogLabel = data;
  },

  [types.FETCH_BLOG_ITEMS](state, data) {
    state.blogItem = data;
  },

  [types.FETCH_BLOG_DETAIL](state, data) {
    state.blogDetail = data;
  },

  [types.FETCH_NEWS_CATEGORY](state, data) {
    state.newsCate = data;
  },

  [types.FETCH_BRAND_CATEGORY](state, data) {
    state.brandCate = data;
  },

  // should be removed
  [types.FETCH_CSR_PAGE](state, data) {
    state.csrPageItem = data;
  },


  
  [types.FETCH_ESG_PAGE](state, data) {
    state.esgPageItem = data;
  },
  

  [types.FETCH_WEBINARBANNER](state, data) {
    state.webinarbanner = data;
  },

  [types.FETCH_WEBINAR_CATEGORY](state, data) {
    state.webinarCate = data;
  },

  [types.FETCH_WEBINAR_ITEMS](state, data) {
    state.webinarItem = data;
  },
  
  [types.TOGGLE_LOADING](state, status) {
    state.isLoading = status ;
  },

  [types.CHECK_SESSION](state, pages) {
      state.routerToAfterLoggedin = pages.afterLogin;
      

      axios({
        method: 'get',
        baseURL: apibase,
        url: 'users/session',
        // params: { lang: readCookie('lang_code') }
      })
      .then(function (response) {
        if (response.data.current_user != null)
        {
          state.currentUser = response.data.current_user;
          pages.routerNext();
        }
        else
        {
          router.push(pages.login);
          // window.location = location.protocol +'//'+ location.hostname+ '/'+state.lang + pages.login;
          // window.location = location.protocol +'//' + location.hostname + pages.login;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  [types.LOGIN](state,data) {
      data['csrf_hash_name'] = readCookie(COOKIE_PREFIX + 'csrf_cookie_name');
      data['lang'] = readCookie('lang_code');

      var bodyFormData = new FormData();

      for (var k in data) bodyFormData.append(k, data[k]);

      axios(apibase + 'users/login', {
        method: 'post',
        data: bodyFormData,
        params: { lang: readCookie('lang_code') }
      })
      .then(function (response) {
        if (response.status) router.push(state.routerToAfterLoggedin);
        else alert(response.message);
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  [types.LOGOUT](state) {
      let data = {};

      data['csrf_hash_name'] = readCookie(COOKIE_PREFIX + 'csrf_cookie_name');
      data['lang'] = readCookie('lang_code');
      
      var bodyFormData = new FormData();

      for (var k in data) bodyFormData.append(k,data[k]);

      axios(apibase + 'users/logout', {
        method: 'post',
        data: bodyFormData,
        params: { lang: readCookie('lang_code') }
      })
      .then(function (response) {
        router.push('/');
      })
      .catch(function (error) {
          console.log(error);
      });
  },

  [types.SET_SELECTED_CONTACT_TOPIC](state, query) {
    state.selectedContactTopic = query;
  },

  [types.SET_WEBINAR_CONTACT_QUERY](state, query) {
    state.webinarContactQuery = query;
  },

  [types.SET_WEBINAR_STATUS](state, status) {
    state.webinarRegisterStatus = status;
  },

  [types.SET_COOKIE_ALLOW_STATUS](state, status) {
    state.cookieAllowStatus = status;
  }
}