<template>
    <div>
        <div class="article__body" v-if="displayContent === 'data'">
            <section class="cover imagebg text-left space--md" data-overlay="6">
                <div class="background-image-holder">
                    <img alt="background" :src="detail.cover">
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 col-lg-7">
                            <h1>{{detail.title}}</h1>
                            <p class="lead">{{detail.subtitle}}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section class="py-5 breadcrumbsSection">
                <div class="container">
                    <ol class="breadcrumbs">
                        <li>
                            <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name:'career-train'}" v-html="$t('nav_career')">人才招募</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name:'career-family'}" v-html="$t('nav_careerSinboner')">信邦人</router-link>
                        </li>
                        <li>{{detail.title}}</li>
                    </ol>
                </div>
            </section>
            
            <section class="py-0">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-1 col-3 text-center">
                            <div class="icon-circle">
                                <i class="icon icon--lg material-icons">
                                    <img :alt="detail.people_name" :src="detail.avatar" />
                                </i>
                            </div>
                        </div>
                        <div class="col-lg-11 col-7">
                            <span class="type--fine-print">{{detail.job}}</span>
                            <span class="block color--primary">{{detail.people_name}}</span>
                        </div>
                    </div>
                </div>
            </section>

            <div v-if="editor" v-html="editor" class="cms-content"></div>

            <section class="cover imagebg text-left space--md" data-overlay="6">
                <div class="background-image-holder">
                    <img alt="background" :src="$data.siteUrl + '/img/banner-career-1.jpg'" />
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 col-lg-7">
                            <h1 v-html="$t('career_footer_title')">成為我們的夥伴</h1>
                            <p class="lead" v-html="$t('career_footer_p')">我們深信，是卓越的團隊，造就了今天的信邦，<br/>我們不是在徵人，而是在尋找一起打拼未來的好夥伴。</p>
                            <router-link :to="{name:'career-job' }" class="btn btn--lg">
                                <span class="btn__text" v-html="$t('career_footer_cta')">加入信邦</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <missing v-else-if="displayContent==='404'" />
    </div>
</template>
<style lang="less" scoped>
.icon-circle img{
    border-radius: 50%;
}
</style>

<script>
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    // import {readCookie} from "@/libs/js/cookie.js"
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    import Missing from "../pages/Missing.vue";
	export default {
        data:function(){
            return {
                id:this.$route.params.id,
                detail:null,
                editor:null,
                metaData:{},
                displayContent:'default'
            }
        },
        metaInfo() {
            return this.metaData
        },
        components: {
            "missing": Missing
        },
        methods:{
            fetchData:function(){
                var _this=this;
                
                    axios({
                        method: 'get',
                        baseURL: apibase,
                        url: 'csrsinboner/detail/'+this.$route.params.id,
                    })
                    .then(function (response) {
                        _this.detail=response.data.row;
                        _this.displayContent = 'data';
                        _this.fetchEditor();
                        _this.$toggleLoading(false);
                    })
                    .catch(function (error) {
                        _this.displayContent = '404';
                        console.log(error);
                    });
            },
            fetchEditor:function(){
                var _this=this;
                    axios({
                        method: 'get',
                        url: _this.detail.description,
                        
                    })
                    .then(function (response) {
                        _this.editor=response.data;
                        // _this.$window.initAll();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            parseRoute:function(){
                this.detail=null;
                this.editor=null;
                if(!this.$route.params.id) this.$router.push({name:'career-family'});
                this.fetchData();
            }
        },
        created() {
            if(!this.$route.params.id) this.$router.push({name:'career-family'});
            this.fetchData();
            setMetaInfo( this, undefined, undefined, undefined, undefined )
        },
        watch:{
            '$route' () {
                this.parseRoute();
            }
        }
    };
</script>