// 建立cookie
function createCookie(name, value, days, path) {
    console.log('createCookie');
    var date = new Date();
    var expires;
    if (days) {
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "expires=" + date.toGMTString()+';';
    }
    // else expires = "";
    // 改成cookie預設一年
    else {
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
        expires = "expires=" + date.toGMTString()+';';
    }
    
    var _path='/';
    if(path) _path=path;
    
    // if( value != 'en' ) document.cookie = name + "=" + value + expires + "; path=/" + value;
    // else document.cookie = name + "=" + value + ';' + expires + "path="+_path+';';
    document.cookie = name + "=" + value + ';' + expires + "path="+_path+';';
}
//讀取
function readCookie(name) {
    // Get lang code redirect from backend. @CLHOM
    if (PYRO_GTLD != undefined && name == 'lang_code') return PYRO_GTLD;
    
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
//刪除
function eraseCookie(name) {
    // createCookie(name, "", -1);
    var date = new Date();
    date.setTime(date.getTime() - (24 * 60 * 60 * 1000));
    document.cookie = name + "=; expires=" + date.toGMTString() + "; path=/";
}
export { createCookie, readCookie, eraseCookie };