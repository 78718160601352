<template>
	<div>
		<section class="cover imagebg text-left space--sm" data-overlay="6">
            <div class="background-image-holder">
                <img alt="background" :src="$data.siteUrl + '/img/banner-corporate.jpg'" />
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1 v-html="$t('csr_download_banner_title')">報告書下載</h1>
                        <!-- <p v-html="$t('csr_download_banner_p')">這些價值導引我們的經營運作，並時時提醒我們成功的要素為何</p> -->
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('nav_csr')">企業與社會責任</li>
                    <li v-html="$t('nav_csrDownload')">報告書下載</li>
                </ol>
            </div>
        </section>
        <section>
            <div class="container text-left">
                <div class="row">
                    <div class="col">
                        <h3 class="m-0" v-html="$t('csr_download_section_title')">企業社會責任報告書</h3>
                    </div>
                </div>
            </div>
        </section>
        <section v-if="items">
            <div class="container text-center">
                <div class="row">
                    <div class="col-md-4" v-for="item in items.rows">
                        <div class="feature feature-3 boxed boxed--lg boxed--border">
                            <img :src="item.cover" :alt="item.name"/>
                            <h4>{{item.name}}</h4>
                            <!-- <a class="btn btn--primary mt-3" :href="item.file" target="_blank"><span class="btn__text" v-html="$t('ui_download')">下載PDF</span></a> -->
                            <a class="btn btn--primary mt-3" :href="item.file" target="_blank"><span class="btn__text" v-html="$t('ui_download')">下載PDF</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    // import {readCookie} from "@/libs/js/cookie.js"
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
	export default {
        data:function(){
            return{
                items:null,
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        methods:{
            fetchItems:function(){
                var _this=this;
                // 
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'csrreport/items',
                    
                })
                    .then(function (response) {
                        // console.log(response);
                        _this.items=response.data;
                        // _this.$window.initAll();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        created(){
            this.fetchItems();
            setMetaInfo( this, 'Sustainability Report', undefined, undefined, undefined )
        }
    };

</script>