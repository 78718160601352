<template>
    <div>
        <div class="governancePageDetail__back">
            <div class="wrap">
                <router-link :to="$route.path"><span style="display: inline-block; margin-right: 5px;">&#8592;</span> Back</router-link>
            </div>
        </div>
        <div v-if="pageData">
            <div v-if="pageData.status=='success'" class="governance-page">
                <div v-html="pageData.body"></div>
            </div>
            <missing v-else />
        </div>
    </div>
</template>
<style>
.governancePageDetail__back{
    padding-bottom: 0!important;
    @media screen and (min-width: 1000px){
        width:90%;
        max-width: 90%;
        margin: auto;
        padding: 2em var(--layout-fullwidth-container-xpadding);
    }
    @media screen and (max-width: 1000px){
        width: 100%;
        max-width: 100%;
        padding: 2em var(--layout-container-x-padding);
    }
}

</style>
<script>
import apibase from "@/static/apibase.js";
import axios from 'axios';
import {setMetaInfo} from "@/libs/js/metaInfo.js";
import Missing from "../pages/Missing.vue";


export default {
        data() {
            return{
                metaData:{},
                pageData:null,
            }
        },

        metaInfo() {
            return this.metaData
        },

        components: {
            "missing": Missing
        },
        methods:{
            fetchPage(){
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'pages/detail?uri='+this.$route.query.detail,
                })
                .then((response) => {
                    this.pageData=response.data;
                    this.$nextTick(function () {
                        setTimeout(function() {
                            window.pagebuilder_clip_script_init();
                        }, 1000);
                    });
                    setMetaInfo(this, undefined, undefined, undefined, undefined);
                })
            }
        },
        mounted() {
            this.fetchPage();
        }
    };
</script>