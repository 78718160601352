<template>
  <div class="masonry-filter-group">
    <div v-if="navTree" v-for="nav, lv in navTree" :key="lv" class="masonry-filter-holder">
      <div class="masonry__filters">
        <ul>
          <li v-for="item, idx in nav" :key="idx" :data-lv="lv" :data-uri="item.uri" @click="updateSlug" :class="item.active?'active':''">
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
    <!-- <div class="masonry-filter-holder">
      <div class="masonry__filters">
        <ul>
          <li 
            v-for="item in options.children"
            :class="{ 'active': active(item.uri) && (item.uri == currItem) }"
            @click="updateCategory(item, true)"
          >{{ item.title }}</li>
        </ul>
      </div>
    </div>

    <product-filter 
      v-if="childOptions != null && childOptions.children != undefined" 
      :options="childOptions"
      :parentNavCategory="currCategory"
    ></product-filter> -->
  </div>
</template>



<script>
export default {
  name: 'productFilter',

  props: [
    'options',
    'parentNavCategory',
    'firstLeval',
    'nav',//新增的參數
    'route',//新增的參數
  ],

  data() {
    return {
      currCategory: this.parentNavCategory,
      currPage: this.$route.query.page,
      childOptions: null,
      activeCate: '',
      currItem: '',


      
      navTree: null,
      currCateArr: null,
      // currCate:null,
      parentNav: null,
      updateRoute:this.route?this.route:true,
    }
  },

  watch: {
    // '$route' (to, from) {
    //   // this.currCateArr = to.query.cate.split(',');
    //   var _cateStr =this.currCateArr.join(',');
    //   if(_cateStr!=to.query.cate) this.updateSlug();
    // }
  },

  methods: {
    // updateCategory(c, updateRouteLock) {


    //   this.childOptions = null;

    //   let cate = (c.uri != (undefined || null)) ? c.uri : c;

    //   if (cate == null) cate = '';

    //   setTimeout(() => {
    //     this.childOptions = (c.uri != (undefined || null)) ? c : null;

    //     if (cate == this.currCategory) return;

    //     if (updateRouteLock) 
    //     {
    //       this.currCategory = cate;
    //       this.currPage = 1;

    //       this.$router.push({
    //         name: 'product', 
    //         query: { cate: this.currCategory, page: this.currPage }
    //       });
    //     }
    //   }, 100);
    // },

    updateSlug(e) {
      console.log('updateSlug');
      var _lv=e.target.getAttribute('data-lv');
      var _uri=e.target.getAttribute('data-uri');
      if(_uri=='all'){
        this.currCateArr.splice(Number(_lv));
      }else{
        this.currCateArr[_lv]=_uri;
        if(this.currCateArr[Number(_lv)+1]){
          this.currCateArr.splice(Number(_lv)+1);
        }
      }
      
      var _cateStr=this.currCateArr.join(',');
      if (this.updateRoute) {
        this.$router.push({
          name: this.$router.name,
          query: { cate: _cateStr, page: 1 }
        });
      }
      this.updateFilter();
    },
    updateFilter() {
      console.log('updateFilter');
      var _parentNav;
      if(!this.parentNav) _parentNav=JSON.parse(JSON.stringify(this.nav));
      this.navTree = [];

      for (var k = 0; k < this.currCateArr.length; k++) {
        var _currArr = [];
        
        if(!this.parentNav){
          _parentNav=JSON.parse(JSON.stringify(this.nav));
        }else{
          _parentNav=JSON.parse(JSON.stringify(this.parentNav));
        }

        // 先推一個all進去 _currArr
        _currArr.push(
          {
            title: this.$t('ui_allCategory'),
            uri: 'all',
            active: !this.currCateArr[k]||this.currCateArr[k]=='all' ? true : false
          },
        )
        
        //把剩下的推進去 _currArr
        for (var j = 0; j < _parentNav.length; j++) {
          _currArr.push(
            {
              title: _parentNav[j].title,
              uri: _parentNav[j].uri,
              active: this.currCateArr[k] == _parentNav[j].uri ? true : false
            },
          );
          //把這一層 active cate 的 children 記下來
          if (this.currCateArr[k] == _parentNav[j].uri) {
            this.parentNav = _parentNav[j].children;
          }
        }
        
        //把 _currArr 推進去 navTree
        if(_currArr.length>0) this.navTree.push(_currArr)
      }

      //網址沒有了，但最後一個 active 的 cate 的 children 還有，就推進去一個all跟他下面的所有分類
      if(this.parentNav){
        var _lastArr = [];
        _lastArr.push(
          {
            title: this.$t('ui_allCategory'),
            uri: 'all',
            active: true
          },
        )
        for (var i = 0; i < this.parentNav.length; i++) {
          _lastArr.push(
            {
              title: this.parentNav[i].title,
              uri: this.parentNav[i].uri,
              active: false
            },
          );
        }
        this.navTree.push(_lastArr);
      }
      this.parentNav = null;
    },

    active(elmUri) {
      let uri = elmUri.toLowerCase();
      let uriArr = uri.split(',');
      let cArr = this.currCategory.split(',');

      let result = uriArr.map(item => {
        return cArr.some(v => {
          return v === item;
        });
      });

      let checkResult = result.every(v => v === true);

      if (checkResult) {
        this.activeCate = uri;
        return checkResult;
      }
    }
  },

  mounted() {
    this.currCateArr = this.$route.query.cate.split(',');
    this.updateFilter();
  }
};
</script>