<template>
	<div>
		<section class="cover imagebg text-left space--sm" data-overlay="6">
            <div class="background-image-holder">
                <img alt="background" :src="$data.siteUrl + '/img/banner-news.jpg'" />
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1 v-html="$t('location_news_title')">最新消息</h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('nav_news')">最新消息</li>
                </ol>
            </div>
        </section>

        <section>
            <div class="container">
                <div>
                    <div class="masonry-filter-container d-flex align-items-center" v-if="categories">
                        <span v-html="$t('ui_category')">類別</span>
                        <div class="masonry-filter-group">
                            <div class="masonry-filter-holder">
                                <div class="masonry__filters">
                                    <ul>
                                        <li :class="currCategory=='all'?'active':''" @click="updateCategory('all')" v-html="$t('ui_allCategory')">所有類別</li>
                                        <!-- <li v-for="category in categories.rows" :data-masonry-filter="category.category_slug" @click="updateItems(data-masonry-filter)">{{category.category_name}}</li> -->
                                        <li :class="currCategory==category.category_slug?'active':''" v-for="category,key in categories" @click="updateCategory(category.category_slug)">{{category.category_name}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="masonry__container row masonry--active" v-if="items">
                        <!-- <div v-for="item in items.rows" class="masonry__item col-lg-4 col-md-6 filter-marketing" data-masonry-filter="Marketing" > -->
                        <div v-for="item,index in filteredRows(items.rows,currCategory,'category_slug').slice(pageStart, pageStart + itemPerPage)" class="masonry__item col-12 col-sm-6 col-md-4 col-lg-4 ">
                            <article class="feature feature-1 boxed boxed--border">
                                <router-link :to="{ name:'news-detail', params: { slug:item.slug } }" class="block" style="line-height:0;">
                                    <img  class="w-100" :alt="item.name" :src="item.cover">
                                </router-link>
                                <div class="feature__body">
                                    <span>{{item.article_date}}</span>
                                    <h5>{{item.name}}</h5>
                                    <router-link :to="{ name:'news-detail', params: { slug:item.slug } }" v-html="$t('ui_view')">瀏覽</router-link>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
            <pagination v-model="currPage" v-on:getTotalPage="totalPage" :currPage="currPage" :itemPerPage="itemPerPage" :itemTotal="totalItemNum" v-if="items"/>
        </section>
        
        <channel-box v-bind:blocks="['solution','contact','global-location']"/>
    </div>
</template>
<script> 
    import channelBox from "../components/ChannelBox.vue";
    import pagination from "../components/Pagination.vue";
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    import { mapState, mapActions } from "vuex";
    // import {readCookie} from "@/libs/js/cookie.js"
    import {setMetaInfo} from "@/libs/js/metaInfo.js"
	export default {
        data:function(){
            return{
                items:null,
                currPage:this.$route.query.page,
                itemPerPage:12,
                categories:null,
                currCategory:this.$route.query.cate,
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        computed:{
            ...mapState(['newsCate']),
            totalItemNum:function(){
                return this.filteredRows(this.items.rows,this.currCategory,'category_slug').length;
            },
            pageStart: function(){
                return (this.currPage - 1) * this.itemPerPage;
            },
        },
        methods:{
            ...mapActions(['fetchNewsCategory']),
            filteredRows: function(rows,cc,slug){
                this.$toggleLoading(false);
                this.$scrollTop();
                var c = cc.toLowerCase();
                return ( c.trim() !== 'all' ) ?
                    rows.filter(function(d){ return d[slug].toLowerCase().indexOf(c) > -1; }) :
                    rows;
            },
            updateCategory:function(c){
                if(c==this.currCategory) return;
                this.currCategory=c;
                this.currPage=1;
                this.$router.push({name:'news',query:{cate:this.currCategory,page:this.currPage}});
            },
            fetchItems:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'news/items',
                    
                })
                .then(function (response) {
                    console.log(response.data);
                    _this.items=response.data;
                    for(var i in _this.items.rows){
                        var year=_this.items.rows[i].article_date.substr(0,4);
                        var month=_this.items.rows[i].article_date.substring(_this.items.rows[i].article_date.indexOf('.')+1,_this.items.rows[i].article_date.indexOf('.',5));
                        var date=_this.items.rows[i].article_date.substr(_this.items.rows[i].article_date.indexOf('.',5)+1);
                        var d=new Date();
                        d.setFullYear(year);
                        d.setMonth(month-1);
                        d.setDate(date);
                        _this.items.rows[i]['timeStamp']=d;
                    }
                    _this.items.rows=_this.items.rows.slice().sort(function (a, b) {
                        return a['timeStamp'] > b['timeStamp'] ? -1 : 1;
                    });
                    
                    console.log('currPage',_this.currPage);
                    console.log('itemPerPage',_this.itemPerPage);
                    console.log('totalItemNum',_this.totalItemNum);
                    //_this.$window.initAll();
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            findCate:function( arr ){
                return arr.category_slug === this.$route.query.cate;
            },
            // setDesc:function(){
            //     let routeName = this.$route.name
            //     if( this.$route.query.cate != 'all'){
            //         var found = this[routeName+'Cate'].find( this.findCate )
            //         this.cateMetaDes = found.meta_desc;
            //         setMetaInfo( this, found.meta_title ,undefined, found.meta_desc, found['thumb_'+routeName+'_category']);
            //     }else{
            //         // console.log('cate ==== all, 用預設 meta');
            //         this.cateMetaDes = '';
            //         setMetaInfo( this, undefined, undefined, undefined, undefined );
            //     }
            // },
            totalPage:function(maxPage){
                if ( this.currPage > maxPage ) {
                    this.currPage = maxPage
                    this.$router.push({name:'news',query:{cate: this.currCategory, page: this.currPage }});
                }
            }
        },
        watch: {
            '$route' (to, from) {
                if(this.$route.query.cate!=this.currCategory || this.$route.query.page!=this.currPage){
                    this.currCategory=this.$route.query.cate;
                    this.currPage=1;
                }
                if( this.currPage == undefined ) this.currPage = 1;
                this.$router.push({name:'news',query:{ cate: this.currCategory, page: this.currPage }});
                // this.setDesc();
            },
            newsCate(val){
                this.categories = val;
                // this.setDesc();
            }
        },
        created(){
            setMetaInfo( this, this.$t('page_title_news'), undefined, undefined, undefined,false,true);
            if(this.currPage==undefined || this.currCategory==undefined){
                this.$router.push({name:'news',query:{cate:'all',page:1}});
            }
            this.fetchNewsCategory();
            this.fetchItems();
        },
        components: {
            "channel-box": channelBox,
            "pagination": pagination
        },
    };

</script>