<template>
	<div v-if="items.length>0">
        <section class="text-center webinar-detail__recommend">
            <div class="container">
                <p class="text-left lead" v-html="$t('recommends_webinars')">Recommend Webinars</p>
                <div class="row">
                    <div class="col">
                        <div class="slider" data-paging="true" >
                            <ul class="slides flickity-enabled is-draggable" tabindex="0">
                                <div class="flickity-viewport">
                                    <div class="flickity-slider">
                                        <li class="col-md-4 col-12 slide" v-for="item in items">
                                            <div class="">
                                                <router-link :to="{name:'webinar-detail',params:{category:item.category_slug,slug:item.slug}}">
                                                    <img :alt="item.name" :src="item.thumb"/>
                                                </router-link>
                                                <router-link class="block" :to="{name:'webinar-detail',params:{category:item.category_slug,slug:item.slug}}">
                                                    <div class="text-left webinar-detail__desc">
                                                        <h5 class="webinar-detail__articleTitle mb-1">{{ item.title }}</h5>
                                                        <div class="webinar-detail__desc-content">
                                                            <p>{{ item.subtitle }}</p>
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </div>
                                        </li>
                                    </div>
                                    <ol class="flickity-page-dots"></ol>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
	export default {
        props:['items'],
        // mounted() {
        //     this.$window.initAll();
        // },
    };
</script>