<template>
	<div>
		<section>
            <div class="container">
                <div>
                    <h2 class="mb-5" v-html="$t('career_train_learning_title')">完整的訓練體系<br/>讓夥伴們扎實的充實自身的能力</h2>
                </div>
                <div class="process-2 row">
                    <div class="col-md-4">
                        <div class="process__item">
                            <h5 v-html="$t('career_train_learning_1_title')">各種職能訓練</h5>
                            <ul>
                                <li v-html="$t('career_train_learning_1_list_1')">核心職能</li>
                                <li v-html="$t('career_train_learning_1_list_2')">專業職能</li>
                                <li v-html="$t('career_train_learning_1_list_3')">管理職能</li>
                            </ul>
                        </div>
                    </div>
                     <div class="col-md-4">
                        <div class="process__item">
                            <h5 v-html="$t('career_train_learning_2_title')">夥伴的自我成長</h5>
                            <ul>
                                <li v-html="$t('career_train_learning_2_list_1')">工作本職訓練80%</li>
                                <li v-html="$t('career_train_learning_2_list_2')">非工作本質訓練20%</li>
                            </ul>
                        </div>
                    </div>
                     <div class="col-md-4">
                        <div class="process__item">
                            <h5 v-html="$t('career_train_learning_3_title')">帶動企業成長</h5>
                            <p v-html="$t('career_train_learning_3_list_1')">高素質、專業化的人才，帶動公司整體成長，成為永續經營的原動力</p>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <section class="bg--secondary">
            <div class="container">
                <h2 class="mb-5" v-html="$t('career_train_promotion_title')">順暢的成長路徑<br/>讓有能力的夥伴能夠實現自我遠大的夢想</h2>
                <div class="row same-height-col">
                    <div class="col-md-4">
                        <div class="feature boxed boxed--lg boxed--border p-4">
                            <span v-html="$t('career_train_promotion_step_1')">階段 1</span>
                            <h3 class="mb-3" v-html="$t('career_train_promotion_step_1_title')">作業層</h3>
                            <p></p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="feature  boxed boxed--lg boxed--border p-4">
                            <span v-html="$t('career_train_promotion_step_2')">階段 2</span>
                            <h3 class="mb-3" v-html="$t('career_train_promotion_step_2_title')">專業層</h3>
                            <p v-html="$t('career_train_promotion_step_2_p')">研發、廠務、業務、企劃</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="feature  boxed boxed--lg boxed--border p-4">
                            <span v-html="$t('career_train_promotion_step_3')">階段 3</span>
                            <h3 class="mb-3" v-html="$t('career_train_promotion_step_3_title')">營運層</h3>
                            <p v-html="$t('career_train_promotion_step_3_p')">研發、廠務、業務、企劃</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="feature  boxed boxed--lg boxed--border p-4">
                            <span v-html="$t('career_train_promotion_step_4')">階段 4</span>
                            <h3 class="mb-3" v-html="$t('career_train_promotion_step_4_title')">國際營運層</h3>
                            <p v-html="$t('career_train_promotion_step_4_p')">台灣、大陸、歐洲、美國</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="feature  boxed boxed--lg boxed--border p-4">
                            <span v-html="$t('career_train_promotion_step_5')">階段 5</span>
                            <h3 class="mb-3" v-html="$t('career_train_promotion_step_5_title')">全球領導層</h3>
                            <p v-html="$t('career_train_promotion_step_5_p')">Operation BUG、Global Sales BUG</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container">
                <div class="row">
                    <div class="col height-20 feature boxed text-left imagebg" data-overlay="3">
                        <div class="background-image-holder" >
                            <img alt="background" :src="$data.siteUrl + '/img/banner-join.jpg'" />
                        </div>
                        <h3 class="pos-vertical-center" v-html="$t('career_train_banner_title')">我們重視每一個人的福利</h3>
                    </div>
                </div>
            </div>
        </section>

        <section v-if="detail">
            <div class="container benefit">
                <h4 v-html="$t('career_train_welfare_title')">完善福利制度</h4>
                <ul>
                    <li v-for="(item,idx) in detail" :key="idx" class="mb-4">
                        <h5 class="mb-1">{{item.name}}</h5>
                        <p class="benefit__desc" v-html="item.description"></p>
                    </li>
                </ul>

                
            </div>
            <!-- <div class="container benefit" v-if="$i18n.locale=='tw'">
                <h5 class="mb-1">勞資關係</h5>
                <p class="mb-1">一、本公司各項員工福利措施、進修、訓練、退休制度與其實施情形：</p>
                <div class="benefit__desc">
                    <ul>
                        <li>
                            全員參與勞工保險及全民健康保險：<br/>
                            凡本公司員工於正式到職日零時起，即由公司辦理勞保、健保事宜，以確實保障員工權益。
                        </li>
                        <li>
                            團體保險：<br/>
                            依本公司保險作業辦法為員工辦理團體保險，內容包含壽險、意外險、住院醫療險、癌症險等，保險金額依職位、工作性質不同分類，此保險費用完全由公司提撥，員工不須支付任何費用。<br/>
                            2019年再納入員工眷屬，提供傷害醫療及住院醫療保障，員工可為配偶及子女納保，保費由公司100%全額支付。
                        </li>
                        <li>
                            員工定期健康檢查：<br/>
                            員工為公司寶貴資產，其健康情況之好壞直接影響公司產能及家中  生活，故本公司經理級以上人員，每年可進行一次健康檢查，其他員工則依勞工安全衛生法之規定辦理員工健康檢查。
                        </li>
                        <li>
                            員工訓練：<br/>
                            為因應集團策略發展之目標，並滿足員工於工作所需之職能，公司提供多元化之學習方式與管道，諸如：企業內訓、國內外訓、海外研習、讀書會等。
                        </li>
                        <li>
                            員工分紅：<br/>
                            員工分享公司成長的利潤，使全體員工凝聚高度向心力，發揮團隊精神。
                        </li>
                        <li>
                            職工福利委員會：<br/>
                            <ul>
                                <li>婚喪、喜慶之禮金及補助。</li>
                                <li>定期舉辦員工旅遊。</li>
                                <li>籌組各類社團活動，促進勞資溝通及和諧。</li>
                                <li>節慶生日禮金或禮品。</li>
                                <li>與多家特約商店簽約，提供同仁優惠價格與活動。</li>
                                <li>住院醫療、災害慰問金補助。</li>
                                <li>員工進修獎學金。</li>
                                <li>不定期舉辦專業講座。</li>
                            </ul>
                        </li>
                        <li>
                            員工退休制度：<br/>
                            本公司依「勞工退休金條例」訂定之員工退休辦法每月負擔之勞工退休金提撥率，不低於員工每月薪資百分之六至勞工保險局之個人退休金帳戶。本公司民國一一一年及一一○年度認列確定提撥計畫之費用金額分別為62,526仟元及60,074仟元。<br/>
                            另依「勞動基準法」訂定之員工退休金辦法，員工退休金之支付係根據服務年資之基數及核准其退休時一個月平均工資計算。十五年以內(含)的服務年資滿一年給與兩個基數，超過十五年之服務年資每滿一年給與一個基數，惟基數累積最高以45個基數為限。本公司依規定按月就薪資總額2%提撥退休金基金，以勞工退休準備金監督委員會名義專戶儲存於臺灣銀行之專戶。截至民國一一一年十二月三十一日，本公司之確定福利計畫預期於下一年度提撥9,600仟元，確定福利義務現值為134,168仟元，淨確定福利負債為53,501仟元。退休申請可循依本公司電子簽核系統向人資部門提出。
                        </li>
                        <li>
                            停車位免費停車：<br/>
                            台北辦公室停車不易，公司特撥費用租用免費停車供特定同仁免費使用。
                        </li>
                        <li>
                            下列假別優於勞基法規定：<br/>
                            <ul>
                                <li>產假：依法規規定產假為56日，本公司給假61日。</li>
                                <li> 『父母或配偶重大傷病照護假』優於勞基法：依法並無此項假別，公司體恤員工，考量家中之父母或配偶遇重大傷病面臨此突發變故，能有充裕時間安排與進行相關照護，得於傷病發生首年，請上、下半年各10天之『父母或配偶重大傷病照護假』。</li>
                                <li>有薪事假： 除勞基法規定之無薪事假14日外，本公司額外給予員工『有薪事假』7日。</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <p>二、最近年度及截至年報刊印日止，因勞資糾紛所遭受之損失等：無此情形。</p>
            </div> -->
        </section>
    </div>
</template>
<style lang="less">
.same-height-col{
    @media screen and (min-width: 768px) {
        .boxed{
            justify-content: flex-start!important;
        }
    }
}
.benefit__desc{
    >ul{
        // padding-left: 3.2rem;
        // margin-bottom: 0.25rem;
        // list-style-type: decimal;
        padding-left: 1.3rem;
        margin-bottom: 0.25rem;
        list-style-type:disc;
        ul{
        //     padding-left: 1.3rem;
        //     margin-bottom: 0.25rem;
        //     list-style-type:disc;
        }
    }
}
</style>
<script>
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    // import {readCookie} from "@/libs/js/cookie.js"
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
	export default {
        data:function(){
            return {
                detail:null,
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        methods:{
            fetchData:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'csrrecruit/benfit',
                    
                })
                    .then(function (response) {
                        _this.detail=response.data.rows;
                        // _this.$window.initAll();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        created() {
            this.fetchData();
            setMetaInfo( this, this.$t('page_title_career_train'), undefined, undefined, undefined,false,true );
        },
    };
</script>