<template>
	<div>
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('nav_trends')">熱門趨勢</li>
                    <li v-html="$t('nav_robotic')">機器人解決方案</li>
                </ol>
            </div>
        </section>


        <section>
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-12">
                        <h1 class="mb-5" v-html="$t('robotic_title')">信邦機器人解決方案 – 連結現在與未來</h1>
                        <p class="lead" v-html="$t('robotic_content_1')">信邦多年來專注於機器人領域，除了提供客製化線材組裝與加值解決方案；包括印刷電路板組裝（PCBA）、天線、包覆射出成型以及塑膠射出成型外， 亦提供機器人整合式影音解決方案的一站式服務。</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="imageblock bg--dark text-left" data-overlay="6">
            <div class="background-image-holder ">
                <img alt="Robotic Solutions - Process Automation - SINBON" :src="$data.siteUrl + '/img/robotic-bg.jpg'" />
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <p class="sub-lead" v-html="$t('robotic_content_2')">用於機器人的線材組裝必須符合機械、化學與環境的最高標準。 相較於其它市場，機器人應用環境所需的參數是獨一無二的；須能承受瞬間的加減速、拉伸、機械與扭轉應力；並須能承受劇烈的彎曲循環，高溫、焊接飛濺物、油與多種化學物質。 我們使用合適的材質以確保線材組裝符合先進市場的客戶需求，同時也擁有最高階的測試設備，可模擬運轉並確定線材壽命。 這些測試包含振動測試、鹽霧試驗、濕度測試、粉塵測試、電磁干擾（EMI）/ 電磁相容性（EMC）以及加速老化試驗等。</p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <strong class="h3" v-html="$t('robotic_list_title')">我們的產品與服務包含</strong>
                        <div class="row mt-3">
                            <div class="col-12 col-md-6">
                                <ul class="bullets row mx-0">
                                    <li class="col-12 p-0" v-html="$t('robotic_list_content_1')"></li>
                                    <li class="col-12 p-0" v-html="$t('robotic_list_content_3')"></li>
                                    <li class="col-12 p-0" v-html="$t('robotic_list_content_5')"></li>
                                    <li class="col-12 p-0" v-html="$t('robotic_list_content_6')"></li>
                                    <li class="col-12 p-0" v-html="$t('robotic_list_content_7')"></li>
                                    <li class="col-12 p-0" v-html="$t('robotic_list_content_8')"></li>
                                    <li class="col-12 p-0" v-html="$t('robotic_list_content_9')"></li>
                                </ul>
                            </div>
                            <div class="col-12 col-md-6">
                                <ul class="bullets row mx-0">
                                    <li class="col-12 p-0" v-html="$t('robotic_list_content_2')"></li>
                                    <li class="col-12 p-0">{{$t('robotic_list_content_4')}}
                                        <ul class="bullets row mx-0 ">
                                            <li class="col-12 p-0" v-html="$t('robotic_list_content_4_1')"></li>
                                            <li class="col-12 p-0" v-html="$t('robotic_list_content_4_2')"></li>
                                            <li class="col-12 p-0" v-html="$t('robotic_list_content_4_3')"></li>
                                            <li class="col-12 p-0" v-html="$t('robotic_list_content_4_4')"></li>
                                            <li class="col-12 p-0" v-html="$t('robotic_list_content_4_5')"></li>
                                        </ul>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="imageblock switchable bg--secondary">
            <div class="imageblock__content col-md-4 col-xl-6 pos-right">
                <div class="background-image-holder">
                    <img alt="image" :src="$data.siteUrl + '/img/robotic-industrial.jpg'" />
                </div>
            </div>
            <div class="container pos-vertical-start">
                <div class="row">
                    <div class="col-md-7 col-xl-5 sub-lead">
                        <h3 v-html="$t('robotic_section_1_title')">完整充電系統客製化解決方案</h3>
                        <p v-html="$t('robotic_section_1_des')">信邦一向以客製化服務享譽全球，能依客戶的需求來進行設計，更能在早期研發階段與客戶共同開發並交流專業建議。自信邦投入電動車領域以來，無論是各國標準的交流充電槍，或是具有專利的直流充電槍設計，以及充電樁組裝服務，務求達到客戶的理念與需求，提供客製化的解決方案</p>
                        <strong class="h5 mt-3" v-html="$t('robotic_section_1_list_title')">我們為客戶提供的服務包含：</strong>
                        <ul class="bullets mt-3 row mx-0">
                            <li class="col-12 p-0" v-html="$t('robotic_section_1_list_content_1')">機械及電機設計</li>
                            <li class="col-12 p-0" v-html="$t('robotic_section_1_list_content_2')">軟韌體設計</li>
                            <li class="col-12 p-0" v-html="$t('robotic_section_1_list_content_3')">零組件與供應鏈管理</li>
                            <li class="col-12 p-0" v-html="$t('robotic_section_1_list_content_4')">原線材設計與製造</li>
                            <li class="col-12 p-0" v-html="$t('robotic_section_1_list_content_5')">線材組裝</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="imageblock switchable switchable--switch">
            <div class="imageblock__content col-md-4 col-xl-6 pos-right">
                <div class="background-image-holder">
                    <img alt="image" :src="$data.siteUrl + '/img/robotic-warehouse.jpg'" />
                </div>
            </div>
            <div class="container pos-vertical-start">
                <div class="row">
                    <div class="col-md-7 col-xl-5 sub-lead">
                        <h3 v-html="$t('robotic_section_2_title')">完整充電系統客製化解決方案</h3>
                        <p v-html="$t('robotic_section_2_des')">信邦一向以客製化服務享譽全球，能依客戶的需求來進行設計，更能在早期研發階段與客戶共同開發並交流專業建議。自信邦投入電動車領域以來，無論是各國標準的交流充電槍，或是具有專利的直流充電槍設計，以及充電樁組裝服務，務求達到客戶的理念與需求，提供客製化的解決方案</p>
                        <strong class="h5 mt-3" v-html="$t('robotic_section_2_list_title')">我們為客戶提供的服務包含：</strong>
                        <ul class="bullets mt-3 row mx-0">
                            <li class="col-12 p-0" v-html="$t('robotic_section_2_list_content_1')">機械及電機設計</li>
                            <li class="col-12 p-0" v-html="$t('robotic_section_2_list_content_2')">軟韌體設計</li>
                            <li class="col-12 p-0" v-html="$t('robotic_section_2_list_content_3')">零組件與供應鏈管理</li>
                            <li class="col-12 p-0" v-html="$t('robotic_section_2_list_content_4')">原線材設計與製造</li>
                            <li class="col-12 p-0" v-html="$t('robotic_section_2_list_content_5')">線材組裝</li>
                            <li class="col-12 p-0" v-html="$t('robotic_section_2_list_content_6')">線材組裝</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="imageblock switchable bg--secondary">
            <div class="imageblock__content col-md-4 col-xl-6 pos-right">
                <div class="background-image-holder">
                    <img alt="image" :src="$data.siteUrl + '/img/robotic-linear.jpg'" />
                </div>
            </div>
            <div class="container pos-vertical-start">
                <div class="row">
                    <div class="col-md-7 col-xl-5 sub-lead">
                        <h3 v-html="$t('robotic_section_3_title')">完整充電系統客製化解決方案</h3>
                        <p v-html="$t('robotic_section_3_des')">信邦一向以客製化服務享譽全球，能依客戶的需求來進行設計，更能在早期研發階段與客戶共同開發並交流專業建議。自信邦投入電動車領域以來，無論是各國標準的交流充電槍，或是具有專利的直流充電槍設計，以及充電樁組裝服務，務求達到客戶的理念與需求，提供客製化的解決方案</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="imageblock switchable switchable--switch">
            <div class="imageblock__content col-md-4 col-xl-6 pos-right">
                <div class="background-image-holder">
                    <img alt="image" :src="$data.siteUrl + '/img/robotic-medical.jpg'" />
                </div>
            </div>
            <div class="container pos-vertical-start">
                <div class="row">
                    <div class="col-md-7 col-xl-5 sub-lead">
                        <h3 v-html="$t('robotic_section_4_title')">完整充電系統客製化解決方案</h3>
                        <p v-html="$t('robotic_section_4_des')">信邦一向以客製化服務享譽全球，能依客戶的需求來進行設計，更能在早期研發階段與客戶共同開發並交流專業建議。自信邦投入電動車領域以來，無論是各國標準的交流充電槍，或是具有專利的直流充電槍設計，以及充電樁組裝服務，務求達到客戶的理念與需求，提供客製化的解決方案</p>
                        <strong class="h5 mt-3" v-html="$t('robotic_section_4_list_title')">我們為客戶提供的服務包含：</strong>
                        <ul class="bullets mt-3 row mx-0">
                            <li class="col-12 p-0" v-html="$t('robotic_section_4_list_content_1')">機械及電機設計</li>
                            <li class="col-12 p-0" v-html="$t('robotic_section_4_list_content_2')">軟韌體設計</li>
                            <li class="col-12 p-0" v-html="$t('robotic_section_4_list_content_3')">零組件與供應鏈管理</li>
                            <li class="col-12 p-0" v-html="$t('robotic_section_4_list_content_4')">原線材設計與製造</li>
                            <li class="col-12 p-0" v-html="$t('robotic_section_4_list_content_5')">線材組裝</li>
                            <li class="col-12 p-0" v-html="$t('robotic_section_4_list_content_6')">線材組裝</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="imageblock switchable bg--secondary">
            <div class="imageblock__content col-md-4 col-xl-6 pos-right">
                <div class="background-image-holder">
                    <img alt="image" :src="$data.siteUrl + '/img/robotic-humanoid.jpg'" />
                </div>
            </div>
            <div class="container pos-vertical-start">
                <div class="row">
                    <div class="col-md-7 col-xl-5 sub-lead">
                        <h3 v-html="$t('robotic_section_5_title')">完整充電系統客製化解決方案</h3>
                        <p v-html="$t('robotic_section_5_des')">信邦一向以客製化服務享譽全球，能依客戶的需求來進行設計，更能在早期研發階段與客戶共同開發並交流專業建議。自信邦投入電動車領域以來，無論是各國標準的交流充電槍，或是具有專利的直流充電槍設計，以及充電樁組裝服務，務求達到客戶的理念與需求，提供客製化的解決方案</p>
                        <strong class="h5 mt-3" v-html="$t('robotic_section_5_list_title')">我們為客戶提供的服務包含：</strong>
                        <ul class="bullets mt-3 row mx-0">
                            <li class="col-12 p-0" v-html="$t('robotic_section_5_list_content_1')">機械及電機設計</li>
                            <li class="col-12 p-0" v-html="$t('robotic_section_5_list_content_2')">軟韌體設計</li>
                            <li class="col-12 p-0" v-html="$t('robotic_section_5_list_content_3')">零組件與供應鏈管理</li>
                            <li class="col-12 p-0" v-html="$t('robotic_section_5_list_content_4')">原線材設計與製造</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="cover imagebg text-left bg--primary-4">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h3 class="h5" v-html="$t('robotic_footer_title')"></h3>
                        <p class="h3" v-html="$t('robotic_footer_content')"></p>
                    </div>
                </div>
            </div>
        </section>
        <!-- <recommend-item/> -->
        <div class="container">
            <hr class="m-0">
        </div>
        <channel-box v-bind:blocks="['solution','contact','global-location']"/>
    </div>
</template>
<script>
    import channelBox from "../components/ChannelBox.vue";
    // import apibase from "@/static/apibase.js";
    // import axios from 'axios';
    import {setMetaInfo} from "@/libs/js/metaInfo.js"
    export default {
        data:function(){
            return {
                meta:{
                    title: 'Industrial Robotic Solutions | Robots In Industry',
                    desc: 'SINBON has been working in robotics producing customized cable assemblies and add solutions including PCB assembly, antenna, over molding & plastic injection.',
                    img: location.protocol +'//'+ location.host + '/img/robotic-bg.jpg',
                    // keywords: 'robotics process automation, robotic solutions'
                },
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        created() {
            // this.$window.initAll();
            setMetaInfo( this, this.$t('page_title_robotic'), undefined, "SINBON offers comprehensive customize cable assembly solutions for Robotics Process Automation and Industrial applications.", this.meta.img,false,true );
            this.$toggleLoading(false);
        },
        components: {
            "channel-box": channelBox
        },
    };
</script>