<template>
	<div>
        <section class="py-0">
            <img alt="" class="w-100" src="../../public/img/charging-header.jpeg">
        </section>

        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('nav_trends')">熱門趨勢</li>
                    <li v-html="$t('ev_title')">充電槍設計製造服務</li>
                </ol>
            </div>
        </section>


        <section>
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-12">
                        <h1 class="h2 mb-5" v-html="$t('ev_title')">駛向未來 – 電動車充電解決方案</h1>
                        <p class="lead" v-html="$t('ev_content')">從2012年起，信邦憑藉著線材與連接器的高度技術研發能力，投入電動車充電設備設計與製造，從充電槍、插座到充電樁的多年耕耘，信邦讓許多國際知名的汽車品牌成為我們的重要合作夥伴。「與時俱進」與「品質」是我們最重視的。隨著電動車充電需求日益演變，信邦也投入高功率或家用充電電纜的研發。除擁有汽車業品質管理系統IATF 16949的證書外，確保品質維持在最高水準，更為產品進行各項認證與測試，保障使用者的安全。</p>
                        <div class="row text-center">
                            <div class="col-sm-4">
                                <a href="https://www.sinbon.com/files/large/c2b6dd2a3ec231d" class="w-100 btn btn--primary type--uppercase inner-link mb-3 mb-md-0" target="_blank">
                                    <span class="btn__text" v-html="$t('ev_download_btn')/*下載產品型錄*/"></span>
                                </a>
                            </div>
                            <div class="col-sm-4">
                                <div data-notification-link="contact" class="w-100 btn btn--primary type--uppercase inner-link" >
                                    <span class="btn__text" v-html="$t('ev_contact_btn')/*聯絡我們*/"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="imageblock bg--dark text-left" data-overlay="5">
            <div class="background-image-holder ">
                <img alt="" :src="$data.siteUrl + '/img/ev_solution_bg.jpg'" />
            </div>
            <div class="container">
                <div class="row my-5">
                    <div class="col-md-8 col-lg-7">
                        <h2 class="h3" v-html="$t('ev_section_1_title')">
                            完整充電系統客製化解決方案
                        </h2>
                        <p class="sub-lead" v-html="$t('ev_section_1_des')">信邦提供完整電動車電力相關解決服務，包括交流/直流充電槍，涵括美國、歐洲、中國標準，滿足不同地區客戶需求；並有攜帶式充電槍-模式二，全面照顧不同需求。<br><br>除充電槍外，亦研發與充電槍匹配的車端交流/直流插座、高壓線束等多款相關產品，讓客戶能享受一站式的客製服務。</p>
                    </div>
                </div>
                <!--end of row-->
            </div>
            <!--end of container-->
        </section>

        <section>
            <div class="container">
                <div class="row">
                    <div class="col-md-9 sub-lead">
                        <h2 class="h3" v-html="$t('ev_section_2_title')">交流充電槍</h2>
                        <p v-html="$t('ev_section_2_des')">信邦提供涵蓋歐洲標準、美國標準、中國標準的交流充電槍，滿足客戶跨地區的市場需求。<br><br>具有一體成形專利，防護等級高達IP55，有效提升產品防水性與耐受性，保障使用者安全。</p>

                        <ul class="mt-3 row mx-0">
                            <li class="col-12 col-md-6 p-0"><img class="mb-0 mr-2" src="../../public/img/ev_check_icon.svg" /><span v-html="$t('ev_section_2_list_1')">安全可靠</span></li>
                            <li class="col-12 col-md-6 p-0"><img class="mb-0 mr-2" src="../../public/img/ev_check_icon.svg" /><span v-html="$t('ev_section_2_list_2')">專利設計</span></li>
                            <li class="col-12 col-md-6 p-0"><img class="mb-0 mr-2" src="../../public/img/ev_check_icon.svg" /><span v-html="$t('ev_section_2_list_3')">高防水</span></li>
                            <li class="col-12 col-md-6 p-0"><img class="mb-0 mr-2" src="../../public/img/ev_check_icon.svg" /><span v-html="$t('ev_section_2_list_4')">符合人體工學</span></li>
                            <li class="col-12 col-md-6 p-0"><img class="mb-0 mr-2" src="../../public/img/ev_check_icon.svg" /><span v-html="$t('ev_section_2_list_5')">高結構強度</span></li>
                        </ul>

                        <h4 class="h5 mb-1" v-html="$t('ev_section_2_sub_title_1')">具附加價值的產品設計</h4>
                        <ul class="bullets mt-3 row mx-0">
                            <li class="col-12 p-0" v-html="$t('ev_section_2_sub_list_1')">全球首創以包覆成型(Overmold)提供產品良好的防水(IP55)與耐受性</li>
                            <li class="col-12 p-0" v-html="$t('ev_section_2_sub_list_2')">握把具有防鬆脫功能的按鍵設計</li>
                            <li class="col-12 p-0" v-html="$t('ev_section_2_sub_list_3')">鍍銀銅合金端子，低接觸電阻</li>
                        </ul>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6 col-lg-4" >
                        <div class="product">
                            <a target="_self" href="/product/automotive/ac-charging-cable---america-standard">
                                <img alt="EV AC Charging Cable SAE J1772" :src="$data.siteUrl + '/img/ev_acCable_1.jpg'" />
                                <div>
                                    <h5 v-html="$t('ev_section_2_pd_1')">交流充電槍 - 美國標準 (SAE J1772)</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class=" col-md-6 col-lg-4" >
                        <div class="product">
                            <a target="_self" href="/product/automotive/ac-charging-cable---europe-standard">
                                <img alt="ac charging cable europe standard" :src="$data.siteUrl + '/img/ev_acCable_2.jpg'" />
                                <div>
                                    <h5 v-html="$t('ev_section_2_pd_2')">交流充電槍 - 歐洲標準 (IEC 62196-2)</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class=" col-md-6 col-lg-4" >
                        <div class="product">
                            <a target="_self" href="/product/automotive/ac-charging-cable-china-standard">
                                <img alt="ac charging cable china standard" :src="$data.siteUrl + '/img/ev_acCable_3.jpg'" />
                                <div>
                                    <h5 v-html="$t('ev_section_2_pd_3')">交流充電槍 - 中國標準 (GB/T 20234.2)</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                
                <hr class="my-5">

                <div class="row">
                    <div class="col-md-9 sub-lead">
                        <h2 class="h3" v-html="$t('ev_section_3_title')">直流充電槍</h2>
                        <p v-html="$t('ev_section_3_des')">
                            直流充電槍除了提供歐洲標準充電槍，讓充電的速度提升一個層次；也同步提供中國標準的大電流(350A)充電槍；
                        </p>

                        <ul class="mt-3 row mx-0">
                            <li class="col-12 col-md-6 p-0"><img class="mb-0 mr-2" src="../../public/img/ev_check_icon.svg" /><span v-html="$t('ev_section_3_list_1')">安全可靠</span></li>
                            <li class="col-12 col-md-6 p-0"><img class="mb-0 mr-2" src="../../public/img/ev_check_icon.svg" /><span v-html="$t('ev_section_3_list_2')">專利設計</span></li>
                            <li class="col-12 col-md-6 p-0"><img class="mb-0 mr-2" src="../../public/img/ev_check_icon.svg" /><span v-html="$t('ev_section_3_list_3')">高防水</span></li>
                            <li class="col-12 col-md-6 p-0"><img class="mb-0 mr-2" src="../../public/img/ev_check_icon.svg" /><span v-html="$t('ev_section_3_list_4')">符合人體工學</span></li>
                            <li class="col-12 col-md-6 p-0"><img class="mb-0 mr-2" src="../../public/img/ev_check_icon.svg" /><span v-html="$t('ev_section_3_list_5')">高結構強度</span></li>
                            <li class="col-12 col-md-6 p-0"><img class="mb-0 mr-2" src="../../public/img/ev_check_icon.svg" /><span v-html="$t('ev_section_3_list_6')">防止熱插拔</span></li>
                        </ul>

                        <h4 class="h5 mb-1" v-html="$t('ev_section_3_sub_title_1')">細緻工藝技術</h4>
                        <ul class="bullets mt-3 row mx-0">
                            <li class="col-12 p-0" v-html="$t('ev_section_3_sub_list_1')">全球首創以包覆成形(overmold)提供產品良好的防水(IP55)與耐受性。</li>
                            <li class="col-12 p-0" v-html="$t('ev_section_3_sub_list_2')">阻燃(UL94 V-0)、耐紫外線。</li>
                            <li class="col-12 p-0" v-html="$t('ev_section_3_sub_list_3')">內建溫度感測，通過環境溫度-40〜50℃、終端溫升&#60;50K測試</li>
                        </ul>

                        <!-- <h4 class="h5 mb-1" v-html="$t('ev_section_3_sub_title_2')">規格</h4>
                        <p v-html="$t('ev_section_3_sub_des_2')">
                            - 額定電流：20A〜60A
                            - 額定電壓：1000 VDC
                        </p> -->
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6 col-lg-3" >
                        <div class="product">
                            <a target="_self" href="/product/automotive/ccs1-dc-charging-cable">
                                <img alt="dc charging cable europe standard" :src="$data.siteUrl + '/img/ev_dcChargingCable_3.png'" />
                                <div>
                                    <h5 v-html="$t('ev_section_3_pd_3')">CCS1 直流充電槍 - 美國標準 (SAE J1772)</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class=" col-md-6 col-lg-3" >
                        <div class="product">
                            <a target="_self" href="/product/automotive/ccs2-dc-charging-cable">
                                <img alt="dc charging cable china standard" :src="$data.siteUrl + '/img/ev_dcChargingCable_4.png'" />
                                <div>
                                    <h5 v-html="$t('ev_section_3_pd_4')">CCS2 直流充電槍 - 歐洲標準 (IEC)</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3" >
                        <div class="product">
                            <a target="_self" href="/product/automotive/dc-charging-cable-europe-standard">
                                <img alt="dc charging cable europe standard" :src="$data.siteUrl + '/img/ev_dcChargingCable_1.jpg'" />
                                <div>
                                    <h5 v-html="$t('ev_section_3_pd_1')">直流充電纜線 - 歐洲標準 (IEC 62196-3)</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class=" col-md-6 col-lg-3" >
                        <div class="product">
                            <a target="_self" href="/product/automotive/dc-charging-cable---china-standard">
                                <img alt="dc charging cable china standard" :src="$data.siteUrl + '/img/ev_dcChargingCable_2.png'" />
                                <div>
                                    <h5 v-html="$t('ev_section_3_pd_2')">直流充電槍 - 中國標準 (GB/T 20234.3)</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                
                <hr class="my-5">

                <div class="row">
                    <div class="col-md-9 sub-lead">
                        <h2 class="h3" v-html="$t('ev_section_4_title')">便攜式充電槍</h2>
                        <p v-html="$t('ev_section_4_des')">
                            信邦便攜式交流充電槍，不論何時何地，只要接上一般插座即可充電。
                        </p>

                        <ul class="mt-3 row mx-0">
                            <li class="col-12 col-md-6 p-0"><img class="mb-0 mr-2" src="../../public/img/ev_check_icon.svg" /><span v-html="$t('ev_section_4_list_1')">模組化系統</span></li>
                            <li class="col-12 col-md-6 p-0"><img class="mb-0 mr-2" src="../../public/img/ev_check_icon.svg" /><span v-html="$t('ev_section_4_list_2')">電磁兼容性強</span></li>
                            <li class="col-12 col-md-6 p-0"><img class="mb-0 mr-2" src="../../public/img/ev_check_icon.svg" /><span v-html="$t('ev_section_4_list_3')">高防水</span></li>
                            <li class="col-12 col-md-6 p-0"><img class="mb-0 mr-2" src="../../public/img/ev_check_icon.svg" /><span v-html="$t('ev_section_4_list_4')">符合人體工學</span></li>
                            <li class="col-12 col-md-6 p-0"><img class="mb-0 mr-2" src="../../public/img/ev_check_icon.svg" /><span v-html="$t('ev_section_4_list_5')">可替換式接頭</span></li>
                        </ul>

                        <h4 class="h5 mb-1" v-html="$t('ev_section_4_sub_title_1')">確保使用安全</h4>
                        <ul class="bullets mt-3 row mx-0">
                            <li class="col-12 p-0" v-html="$t('ev_section_4_sub_list_1')">專利過溫保護，通過AC插頭溫度> 80℃，PCB温度> 85℃等測試。</li>
                            <li class="col-12 p-0" v-html="$t('ev_section_4_sub_list_2')">四段可控電壓，搭配三色LED充電狀態指示燈，即時監控插頭溫度。</li>
                            <li class="col-12 p-0" v-html="$t('ev_section_4_sub_list_3')">符合IC-CPD國際標準、Type B漏電保護。</li>
                        </ul>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6 col-lg-4" >
                        <div class="product">
                            <a target="_self" href="/product/automotive/mode-2-ev-ic-cpd">
                                <img alt="mode 2 ev ic cpd" :src="$data.siteUrl + '/img/ev_evIcCpd_1.png'" />
                                <div>
                                    <h5 v-html="$t('ev_section_4_pd_1')">攜帶式充電槍 - 模式二</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                
                <hr class="my-5">

                <div class="row">
                    <div class="col-md-9 sub-lead">
                        <h2 class="h3" v-html="$t('ev_section_5_title')">插座</h2>
                        <p v-html="$t('ev_section_5_des')">
                            信邦可提供不同規格對應交流、直流充電槍的插座。
                        </p>

                        <h4 class="h5 mb-1" v-html="$t('ev_section_5_sub_title_1')">堅固耐用</h4>
                        <ul class="bullets mt-3 row mx-0">
                            <li class="col-12 p-0" v-html="$t('ev_section_5_sub_list_1')">專利過溫保護，通過AC插頭溫度> 80℃，PCB温度> 85℃等測試。</li>
                            <li class="col-12 p-0" v-html="$t('ev_section_5_sub_list_2')">四段可控電壓，搭配三色LED充電狀態指示燈，即時監控插頭溫度。</li>
                            <li class="col-12 p-0" v-html="$t('ev_section_5_sub_list_3')">符合IC-CPD國際標準、Type B漏電保護。</li>
                        </ul>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6 col-lg-3 " >
                        <div class="product">
                            <a target="_self" href="/product/automotive/ccs1-dc-vehicle-inlet">
                                <img alt="dc electric vehicle inlet china standard" :src="$data.siteUrl + '/img/ev_vehicleInlet_3.png'" />
                                <div>
                                    <h5 v-html="$t('ev_section_5_pd_3')">CCS1 電動車直流充電插座</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class=" col-md-6 col-lg-3" >
                        <div class="product">
                            <a target="_self" href="/product/automotive/ccs2-dc-vehicle-inlet">
                                <img alt="ac electric vehicle inlet china standard" :src="$data.siteUrl + '/img/ev_vehicleInlet_4.png'" />
                                <div>
                                    <h5 v-html="$t('ev_section_5_pd_4')">CCS2 電動車直流充電插座</h5>
                                </div>
                            </a>
                        </div>
                    </div><div class="col-md-6 col-lg-3 " >
                        <div class="product">
                            <a target="_self" href="/product/automotive/dc-electric-vehicle-inlet---china-standard">
                                <img alt="dc electric vehicle inlet china standard" :src="$data.siteUrl + '/img/ev_vehicleInlet_1.jpeg'" />
                                <div>
                                    <h5 v-html="$t('ev_section_5_pd_1')">電動車直流電充電插座 - 中國標準</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class=" col-md-6 col-lg-3" >
                        <div class="product">
                            <a target="_self" href="/product/automotive/ac-electric-vehicle-inlet---china-standard">
                                <img alt="ac electric vehicle inlet china standard" :src="$data.siteUrl + '/img/ev_vehicleInlet_2.jpeg'" />
                                <div>
                                    <h5 v-html="$t('ev_section_5_pd_2')">電動車交流電充電插座 - 中國標準</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <hr class="my-5">

                <div class="row">
                    <div class="col-md-9 sub-lead">
                        <h2 class="h3" v-html="$t('ev_section_7_title')">高功率電線束</h2>
                        <p v-html="$t('ev_section_7_des')">電動車的電壓一般為60~1000V的高電壓，而傳統汽車電壓只有12~48V，所以高壓連接器在此就扮演著極其重要的角色。</p>

                        <h4 class="h5 mb-1" v-html="$t('ev_section_7_sub_title_1')">堅固耐用</h4>
                        <ul class="bullets mt-3 row mx-0">
                            <li class="col-12 p-0" v-html="$t('ev_section_7_sub_list_1')">調整高壓互鎖、插座設計。</li>
                            <li class="col-12 p-0" v-html="$t('ev_section_7_sub_list_2')">改善插拔連接器時斷裂危險，減低觸電事故及損壞發生。</li>
                        </ul>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6 col-lg-4 " >
                        <div class="product">
                            <a target="_self" href="/product/automotive/high-power-electrical-wiring-harness">
                                <img alt="high power electrical wiring harness" :src="$data.siteUrl + '/img/ev_wiringHarness_1.jpeg'" />
                                <div>
                                    <h5 v-html="$t('ev_section_7_pd_1')">高功率電線束</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="imageblock switchable h-auto bg--primary">
            <div class="imageblock__content col-lg-6 col-md-4 pos-right">
                <div class="background-image-holder">
                    <img alt="" :src="$data.siteUrl + '/img/ev_design_style.jpg'" />
                </div>
            </div>
            <div class="container pos-vertical-strat ">
                <div class="row">
                    <div class="col-lg-5 col-md-7 sub-lead">
                        <h3 v-html="$t('ev_section_9_title')">信邦專利設計</h3>
                        <h4 class="h5 mb-1" v-html="$t('ev_section_9_sub_title_1')">一體成型</h4>
                        <p v-html="$t('ev_section_9_sub_des_1')">使用TPE包覆，帶來絕佳防護性能，並大幅提升結構強度。外型設計符合人體工學，觸感較市面上其他充電槍柔軟。</p>
                        <h4 class="h5 mb-1" v-html="$t('ev_section_9_sub_title_2')">專利電子鎖保護</h4>
                        <p v-html="$t('ev_section_9_sub_des_2')">有效防止帶電插拔，杜絕任何漏電、觸電的可能性。</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="imageblock switchable switchable--switch height-auto bg--deepdark">
            <div class="imageblock__content col-lg-6 col-md-4 pos-right">
                <div class="background-image-holder">
                    <img alt="" :src="$data.siteUrl + '/img/ev_quality_style.jpg'" />
                </div>
            </div>
            <div class="container pos-vertical-center sub-lead">
                <div class="row">
                    <div class="col-lg-5 col-md-7">
                        <h3 v-html="$t('ev_section_10_title')">安全可靠的品質</h3>
                        <h4 class="h5 mb-1" v-html="$t('ev_section_10_sub_title_1')">IP55</h4>
                        <p v-html="$t('ev_section_10_sub_des_1')">防護等級達IP55，防水防塵，確保安全無虞。</p>
                        <h4 class="h5 mb-1" v-html="$t('ev_section_10_sub_title_2')">電性要求</h4>
                        <p v-html="$t('ev_section_10_sub_des_2')">電性要求通過絕緣阻抗>10MΩ，端子溫升＜50K等測試。</p>
                        <h4 class="h5 mb-1" v-html="$t('ev_section_10_sub_title_3')">機構設計</h4>
                        <p v-html="$t('ev_section_10_sub_des_3')">通過插拔壽命>10,000次，直流槍插拔力F＜140N / 交流槍插拔力F＜100N等測試。</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="text-center bg--gray">
          <div class="container">
            <div class="row">
              <div class="col-md-10 col-lg-8 mb-3">
                <h2 v-html="$t('ev_section_8_title')">除了產品，我們更希望為客戶提升產品競爭力</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 px-1 d-flex">
                <div class="feature feature-3 flex-grow-1 boxed boxed--lg h-auto mb-2">
                  <img class="multiply" :src="$data.siteUrl + '/img/evcharging-icon-1.svg'" />
                  <h4 v-html="$t('ev_section_8_sub_title_1')">包覆成型</h4>
                  <div class="product-evchargin_certificate__des" v-html="$t('ev_section_8_sub_des_1')">專利一體成形工藝，有效提升產品防水性與耐受性。</div>
                </div>
              </div>
              <div class="col-md-4 px-1 d-flex">
                <div class="feature feature-3 flex-grow-1 boxed boxed--lg h-auto mb-2">
                  <img class="multiply" :src="$data.siteUrl + '/img/evcharging-icon-2.svg'" />
                  <h4 v-html="$t('ev_section_8_sub_title_2')">選材優化</h4>
                  <div class="product-evchargin_certificate__des" v-html="$t('ev_section_8_sub_des_2')">協助客戶進行原材料選材優化，提高產品附加價值。</div>
                </div>
              </div>
              <div class="col-md-4 px-1 d-flex">
                <div class="feature feature-3 flex-grow-1 boxed boxed--lg h-auto mb-2">
                  <img class="multiply" :src="$data.siteUrl + '/img/evcharging-icon-3.svg'" />
                  <h4 v-html="$t('ev_section_8_sub_title_3')">客製化設計</h4>
                  <div class="product-evchargin_certificate__des" v-html="$t('ev_section_8_sub_des_3')">針對單一客戶需求進行產品、製程客製化。</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="text-center bg--primary">
          <div class="container">
            <div class="row">
              <div class="col-md-10 mb-3">
                <h3 v-html="$t('ev_section_11_title')">有客製充電解決方案需求嗎？<br>我們將提供您最完整及專業的充電系統客製化解決方案</h3>
                <div class="row text-center">
                    <div class="col-sm-4">
                        <input
                            type="submit"
                            data-notification-link="contact"
                            :value="$t('ev_contact_btn')/*聯絡我們*/"
                            class="btn btn--primary type--uppercase inner-link text-secondary"
                        >
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- 相關產品 -->
        <!-- <section v-if="items">
            <div class="container">
                <h3 class="pt-5" v-html="$t('ev_recommen')">相關產品</h3>
                <div class="row" >
                    <div v-for="item in filteredRows(items.rows,'chargetool','group')" class=" col-6 col-md-4" data-masonry-filter="item.category_slug" >
                        <div class="product">
                            <router-link :to="{ name:'product-detail', params: {category:item.category_slug, slug:item.slug }}">
                                <img :alt="item.name" :src="item.cover">
                            </router-link>
                            <router-link :to="{ name:'product-detail', params: {category:item.category_slug, slug:item.slug }}">
                                <div>
                                    <h5>{{item.name}}</h5>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        
        <!-- <div class="container">
            <hr class="m-0">
        </div> -->
        <!-- <channel-box v-bind:blocks="['solution','contact','global-location']"/> -->
    </div>
</template>
<script>
    import channelBox from "../components/ChannelBox.vue";
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    import {setMetaInfo} from "@/libs/js/metaInfo.js"
    // import {readCookie} from "@/libs/js/cookie.js"
    export default {
        data:function(){
            return {
                items:'',
                meta:{
                    title: 'EV Charging Solutions for Home & Businesses',
                    desc: 'SINBON has invested in designing and producing EV charging solutions with its high-tech R&D capabilities in cables and connectors. ',
                    img: location.protocol +'//'+ location.host + '/img/evgun-1.jpg',
                    // keywords: 'portable ev charger, ev charger manufacturers'
                },
                metaData:{},
                currLang: PYRO_GTLD
            }
        },
        metaInfo() {
            return this.metaData
        },
        methods:{
            filteredRows: function(rows,cc,slug){
                var c = cc.toLowerCase();
                return ( c.trim() !== 'all' ) ?
                    rows.filter(function(d){ return d[slug].toLowerCase().indexOf(c) > -1; }) :
                    rows;
            },
            fetchItems:function(){
                var _this=this;
                
                    axios({
                        method: 'get',
                        baseURL: apibase,
                        url: 'product/items',
                        
                    })
                    .then(function (response) {
                        _this.items=response.data;
                        // _this.$window.initAll();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        created() {
            this.fetchItems();
            // this.meta.title=this.$t('ev_metaTitle');
            // this.meta.desc=this.$t('ev_metaDes');

            // setMetaInfo( this, this.meta.title, this.meta.url, this.meta.desc, this.meta.img);
            setMetaInfo( this, "EV Charging Cables for Home & Business | EV Charger Solution", this.meta.url, "SINBON's EV charging cable and EV charger for homes and businesses - we offer complete customized charging solutions with high-tech R&D capability.", this.meta.img,"product",false);
        },
        components: {
            "channel-box": channelBox
        },
        mounted(){
            this.$toggleLoading(false);
        }
    
    };

</script>