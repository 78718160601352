<template>
  <div class="home">
    <section class="cover imagebg videobg justify-content-center home_banner d-table" data-overlay="4">
        <div class="home_banner-cell">
            <div class="home_banner__con">
                <video playsinline autoplay loop muted>
                  <source :src="$data.siteUrl + '/img/video/video.webm'" type="video/webm" />
                  <source :src="$data.siteUrl + '/img/video/video.mp4'" type="video/mp4" />
                  <source :src="$data.siteUrl + '/img/video/video.ogv'" type="video/ogv" />
                </video>
            </div>
            
            <!-- <div class="background-image-holder">
              <img alt="image" :src="$data.siteUrl + 'img/video/poser.png'" />
            </div> -->
          
          <div class="container home_banner-txt">
            <div class="row">
                <div class="col-md-8">
                    <h1 v-html="$t('home_banner_title')">最值得信賴的線材
                    <br>與連接器夥伴
                    </h1>
                    <p class="lead" v-html="$t('home_banner_p')">提供客製化線材與連接器研發製造
                    <br>優良的品質、嚴格控制的成本、迅速的交期
                    </p>
                    <router-link :to="{name:'capability'}" class="btn btn--lg p-4">
                    <!-- <div class="modal-instance block"> -->
                        <!-- <div class="video-play-icon video-play-icon--sm modal-trigger"></div> -->
                        <span class="btn__text" v-html="$t('home_banner_cta')">我們的能力與服務</span>
                    <!-- </div> -->
                    </router-link>
                </div>
              </div>
          </div>
        </div>
          
    </section>

    <section class="home-news text-left space--sm">
      <div class="container" v-if="items">
        <div class="row">
          <div class="col py-5">
            <h3 class="h2" v-html="$t('home_more_news_title')">LATEST NEWS</h3>
            <div v-for="(item,index) in items.rows" v-if="index<3" :key="index">
              <hr v-if="index != 0" class="mt-4">
              <router-link :to="{name:'news-detail',params:{slug:item.slug}}">
                <div class="pt-3 pb-3">
                  <h4 class="mb-3">{{item.name}}</h4>
                  <p v-html="item.description"></p>
                </div>
              </router-link>
            </div>
            <!-- <hr> -->
            <router-link :to="{name:'news',query:{cate:'all',page:'1'}}" v-html="$t('home_more_news')" class="home-news__more btn btn--md mt-4">最新消息</router-link>
          </div>
        </div>
      </div>
    </section>

    <section v-if="banners">
      <div class="container">
          <div class="row justify-content-around">
              <div class="col-md-7">
                  <img alt="image" :src="banners[0].cover">
              </div>
              <div class="col-md-5">
                <h3 class="home_webinarBanner__title h2 mb-1">{{ banners[0].title }}</h3>
                <p class="home_webinarBanner__subtitle mb-3">{{ banners[0].subtitle }}</p>
                <div class="home_webinarBanner__des">
                  <img src="img/webinar-icon-calendar.svg" class="mr-2 mb-0"> <span>{{ banners[0].eventdate }}</span>
                </div>
                <a :href="banners[0].link" v-html="$t('home_register_webinar')" class="btn btn--md mt-4" target="_blank"></a>
              </div>
          </div>
      </div>
    </section>

    <section v-if="webinarItems">
      <div class="container">
          <div class="row justify-content-around">
            <div class="col-md-5 order-2 order-md-1 mt-3 mt-md-0">
              <h3 class="home_webinarItem__title mb-1">{{ webinarItems[0].title }}</h3>
              <p class="home_webinarItem__subtitle mb-3">{{ webinarItems[0].subtitle }}</p>
              <div class="home-webinarItem__des" v-html="webinarItems[0].description"></div>
              <router-link class="home-news__more btn btn--md mt-4" :to="{name:'webinar-detail',params:{category:webinarItems[0].category_slug,slug:webinarItems[0].slug}}" v-html="$t('home_on_demand_webinar')"></router-link>
            </div>
            <div class="col-md-7 order-1 order-md-2">
              <img alt="image" :src="webinarItems[0].cover">
            </div>
          </div>
      </div>
    </section>

    <!-- <section class="home_webinarBanner imageblock switchable bg--primary height-50" v-if="banners">
      <div class="imageblock__content col-md-6 pos-right">
        <div class="background-image-holder">
          <img alt="image" :src="banners[0].cover">
        </div>
      </div>
      <div class="container pos-vertical-center">
        <div class="row">
          <div class="col-md-5">
            <h3 class="home_webinarBanner__title h2 mb-1"><b>{{ banners[0].title }}</b></h3>
            <p class="home_webinarBanner__subtitle mb-3">{{ banners[0].subtitle }}</p>
            <div class="home_webinarBanner__des">
              <img src="img/webinar-icon-calendar.svg" class="mr-2 mb-0"> <span>{{ banners[0].eventdate }}</span>
            </div>
            <a :href="banners[0].link" v-html="$t('home_register_webinar')" class="btn btn--md mt-4" target="_blank"></a>
          </div>
        </div>
      </div>
    </section> -->

    <!-- <section class="home_webinarItem imageblock switchable switchable--switch height-50 bg--dark" v-if="webinarItems">
      <div class="imageblock__content col-md-6 pos-right">
        <div class="background-image-holder">
          <img alt="image" :src="webinarItems[0].cover">
        </div>
      </div>
      <div class="container pos-vertical-center">
        <div class="row">
          <div class="col-md-5">
              <h3 class="home_webinarItem__title h2 mb-1"><b>{{ webinarItems[0].title }}</b></h3>
              <p class="home_webinarItem__subtitle mb-3">{{ webinarItems[0].subtitle }}</p>
              <div class="home-webinarItem__des" v-html="webinarItems[0].description"></div>
              <router-link class="btn btn--md mt-4" :to="{name:'webinar-detail',params:{category:webinarItems[0].category_slug,slug:webinarItems[0].slug}}" v-html="$t('home_on_demand_webinar')"></router-link>
          </div>
        </div>
      </div>
    </section> -->

    <section data-overlay="6" class="home_prod pt-5 cover imagebg">
      <div class="background-image-holder">
        <img alt="background" :src="$data.siteUrl + '/img/video/poser.png'" />
      </div>
      <div class="container pt-0">
        <div class="home_prod__container pb-5">
          <h3 class="h2" v-html="$t('home_benefit_title')">
            SINBON provides you with the most complete manufacturing solutions and services including custom cable assemblies.
          </h3>
          <p v-html="$t('home_benefit_p')">
            Medical and healthcare, automobile and aerospace, green energy, industrial applications and communications
          </p>
        </div>
        <div class="home_prod__container">
          <swiper :options="swiperOption" ref="mySwiper" class="home_prod__swiper" v-if="prods">
            <swiper-slide v-for="(prod,idx) in prods" class="home_prod__slide" :key="idx" v-if="idx<6">
              <router-link :to="{name:'product-detail',params:{category:prod.category_slug,slug:prod.slug}}">
                <img class="home_prod__img" :alt="prod.name" :src="prod.cover" />
                <p class="home_prod__name">{{ prod.name }}</p>
              </router-link>
            </swiper-slide>
          </swiper>
          <div class="home_prod__btns">
            <div class="home_prod__btn swiper-button-prev-unique">
              <div class="home_prod__arrow"></div>
            </div>
            <div class="home_prod__btn swiper-button-next-unique">
              <div class="home_prod__arrow"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="home_prod pt-5">
      <div class="container pt-0">
        <div class="home_prod__container">
          <swiper :options="swiperOption" ref="mySwiper" class="home_prod__swiper" v-if="prods">
            <swiper-slide v-for="(prod,idx) in prods" class="home_prod__slide" :key="idx" v-if="idx<6">
              <router-link :to="{name:'product-detail',params:{category:prod.category_slug,slug:prod.slug}}">
                <img class="home_prod__img" :alt="prod.name" :src="prod.cover" />
                <p class="home_prod__name">{{ prod.name }}</p>
              </router-link>
            </swiper-slide>
          </swiper>
          <div class="home_prod__btns">
            <div class="home_prod__btn swiper-button-prev-unique">
              <div class="home_prod__arrow"></div>
            </div>
            <div class="home_prod__btn swiper-button-next-unique">
              <div class="home_prod__arrow"></div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    
  </div>
</template>
<script>
import apibase from "@/static/apibase.js";
import axios from "axios";
import { mapState, mapGetters, mapActions } from "vuex";
import {setMetaInfo} from "@/libs/js/metaInfo.js"
import {readCookie} from "@/libs/js/cookie.js";
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name:'home',
  data: function() {
    return {
      items: null,
      webinarItems: null,
      banners: null,
      prods: null,
      stockLink:'',
      metaData:{},
      meta:{
          title:'Complete Manufacturing & Custom Cable Assembly',
          // url:'https://www.sinbon.com',
          // des:'SINBON is the most trusted partner for custom cable assemblies and connectivity solutions.',
          // img:'https://www.sinbon.com/img/home-rv.jpg',
          // keywords:'custom cable assemblies,	custom cable manufacturersr, custom cable assembly manufacture'
      }, 
      swiperOption: {
        spaceBetween: 15,
        slidesPerView: 3,
        breakpoints: {
          1024: {
            slidesPerView: 3,
          },
          640: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          },
        },
        navigation: {
            nextEl: '.swiper-button-next-unique',
            prevEl: '.swiper-button-prev-unique',
        },
        // pagination: {
        //     el: '.swiper-pagination',
        //     dynamicBullets: true
        // }
      }
    };
  },
  components: {
    swiper,
    swiperSlide
  },
  metaInfo() {
      return this.metaData
  },
  methods: {
    ...mapActions(['fetchWebinarItems','fetchWebinarBanner','fetchProductItems']),
    ...mapGetters(["getLang"]),
    fetchItems: function() {
      var _this = this;
      axios({
        method: 'get',
        baseURL: apibase,
        url: 'news/items',
        params: { lang: readCookie('lang_code') }
      })
        .then(function (response) {
          _this.items = response.data;
          // console.log('news/items _this.items',_this.items);
          setTimeout(_this.$window.initAll,100)
        })
        .catch(function (error) {
            console.log(error);
        });
    },
  },
  computed:{
    ...mapState(['webinarItem','webinarbanner','productItem']),
  },
  watch:{
    webinarItem(val){
        this.webinarItems = val;
        // this.$window.initAll();
        // console.log('this.webinarItems',this.webinarItems);
    },
    webinarbanner(val){
        this.banners = val;
        // console.log('this.banners',this.banners);
        // this.$window.initAll();
    },
    productItem(val){
        this.prods = val;
        // this.$window.initAll();
    }
  },
  mounted() {
  },
  created() {
    this.fetchWebinarItems();
    this.fetchWebinarBanner();
    this.fetchProductItems();
    this.fetchItems();
    let lang=this.getLang();
    if(lang=='tw'||lang=='cn') this.stockLink='https://tw.stock.yahoo.com/q/bc?s=3023';
    else this.stockLink='https://finance.yahoo.com/quote/3023.TW?p=3023.TW';
    setMetaInfo( this, this.meta.title, undefined, undefined, undefined );
  }
};
</script>