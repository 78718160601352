<template>
	<div>
		<section class="cover imagebg text-left space--sm" data-overlay="6">
            <div class="background-image-holder">
                <img alt="background" :src="$data.siteUrl + '/img/banner-solution.jpg'" />
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1 v-html="$t('blog_banner_title')">提供各品牌電子產品<br/>與完善的服務支援</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="pt-5 pb-0 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('nav_blog')">案例分享</li>
                </ol>
                <button class="btn-filter" data-notification-link="filter" v-html="$t('ui_filter')">篩選 <span v-if="currLabels.length>0">({{ currLabels.length }})</span></button>
            </div>
        </section>
        <div id="filterForm" data-animation="from-bottom" data-notification-link="filter" class="d-flex notification pos-bottom notification--reveal col-10 m-0 p-0 h-100 notification--dismissed">
            <div class="container searchBox col-10 col-sm-9 align-self-center ml-auto mr-auto p-0">
                <div class="feature feature-1 mb-0">
                    <div class="feature__body boxed boxed--lg boxed--border">
                        <div class="text-block labels__header mb-2">
                            <h3>篩選</h3>
                            <button class="labels__clear" @click="clearLabel" v-html="$t('ui_clear')">清除</button>
                        </div>
                        <div class="labels__wrapper">
                            <span :class="currLabels.indexOf(label)>=0?'active':''" class="labels__item" v-for="label in labels" :data-label="label" @click="toggleLabel">{{ label }}</span>
                        </div>
                        <button class="btn btn--primary type--uppercase notification-close mt-5" style="width:100%">關閉</button>
                    </div>
                </div>
            </div>
        </div>
        <section class="pt-0">
            <div class="container">
                <div>
                    <hr>
                    <div class="masonry__container row masonry--active" v-if="items&&labels.length>0">
                        <div v-for="item,index in filteredRows(items,currLabels,'labels').slice(pageStart, pageStart + itemPerPage)" class="masonry__item col-lg-4 col-md-6 ">
                            <div class="project-thumb">
                                <router-link :to="{ name:'blog-detail', params: { slug:item.slug } }" class="solution__item">
                                    <img class="border--round" :alt="item.name" :src="item.cover">
                                    <h4 class="solution__articleTitle">{{item.name}}</h4>
                                    <span v-if="item.sample_name" class="solution__sampleName">{{item.sample_name}}</span>
                                    <div v-if="item.meta_desc" class="solution__desc">
                                        <div v-html="item.meta_desc" class="solution__desc-content"></div>
                                    </div>
                                    <button class="btn btn--sm solution__btn">Learn more</button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <pagination v-model="currPage" v-on:getTotalPage="totalPage" :itemPerPage="itemPerPage" :currPage="currPage" :itemTotal="totalItemNum" v-if="items" />
        </section>
    <channel-box v-bind:blocks="['contact','contact-custom','global-location']"/>
</div>
</template>
<style lang="less">
@color-light-blue:#7ca2c9;
@color-light-blue--hover:#8eafd0;
.breadcrumbsSection .container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.btn-filter{
    border:none;
    cursor: pointer;
    font-size: 0.875rem;
    color: @color-light-blue;
    padding:0;
    &:hover{
        color:@color-light-blue--hover;
    }
}
.labels{
    display: none;
    &.active{
        display: block;
        width:100%;
        height:100%;
        position: fixed;
        top:0;
        left:0;
        z-index: 9999;
    }
    .container{
        max-width: 800px;
        position: relative;
        z-index: 1;
    }
}
.labels__wrapper{
    width:0 0 calc(100% + 0.6rem);
    margin-left: -0.6rem;
    margin-top: 1px;
}
.labels__bg{
    width:100%;
    height:100%;
    background: rgba(255,255,255,0.9);
    position: absolute;
    top:0;
    left:0;
    z-index: 0;
}
.labels__header{
    display: flex;
    justify-content: space-between;
}
.labels__clear{
    &:extend(.btn-filter);
}
.labels__item{
    box-sizing: border-box;
    border:1px solid #ccc;
    border-radius: 99px;
    // padding:0.2rem 0.6rem 0.2rem 1.9rem;
    padding:0.2rem 0.6rem;
    margin: 0.3rem;
    cursor: pointer;
    position: relative;
    &:hover{
        border-color: lighten(@color-light-blue,15);
        color:@color-light-blue;
    }
    // &::after{
    //     content:'';
    //     position: absolute;
    //     top:50%;
    //     left:0.5rem;
    //     width:14px;
    //     height:14px;
    //     margin-top: -7px;
    //     border: 1px solid #ccc;
    //     border-radius: 99px;
    // }
    &.active{
        background-color: @color-light-blue;
        color:white;
        border-color: lighten(@color-light-blue,15);
        &:hover{
            background-color: @color-light-blue--hover;
        }
    }
}
</style>
<script>
    import channelBox from "../components/ChannelBox.vue";
    import pagination from "../components/Pagination.vue";
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    import {setMetaInfo} from "@/libs/js/metaInfo.js"
    import { mapState, mapActions } from "vuex";
	export default {
        data:function(){
            return{
                items:null,
                currPage:1,
                itemPerPage:12,
                labels:[],
                currLabels:this.$route.query.label?this.$route.query.label.split(','):[],
                meta:{
                    title: 'Blog | SINBON​',
                    desc: '',
                    // img: location.protocol +'//'+ location.host + '/img/home-rv.jpg',
                    // keywords: 'manufacturing solutions, manufacturing services, electrical layout, flexible production, electrical design services, low volume pcb assembly, flexible pcb assembly'
                },
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        computed:{
            ...mapState(['blogLabel','blogItem']),
            totalItemNum:function(){
                return this.filteredRows(this.items,this.currLabels,'labels').length;
            },
            pageStart: function(){
                return (this.currPage - 1) * this.itemPerPage;
            },
        },
        methods:{
            ...mapActions(['setSelectedContactTopic','fetchBlogLabel','fetchBlogItems']),
            filteredRows: function(){
                if(this.currLabels.length>0){
                    var _filteredItems=[];
                    for(var j in this.currLabels){
                        for(var k in this.items){
                            if(this.items[k].labels.includes(this.currLabels[j])){
                                if(!_filteredItems.includes(this.items[k])){
                                    _filteredItems.push(this.items[k]);
                                }
                            }
                        }
                    }
                    return _filteredItems;
                }else{
                    return this.items;
                }
            },
            toggleLabel:function(e){
                if(e.target.classList.contains('active')){
                    e.target.classList.remove('active');
                    this.currLabels.splice(this.currLabels.indexOf(e.target.dataset.label),1);
                }else{
                    e.target.classList.add('active');
                    this.currLabels.push(e.target.dataset.label);
                }
                this.currPage = 1;
                var _queryLabel = this.currLabels.join(',');
                this.$router.push({name:'blog',query:{label:_queryLabel,page:this.currPage}});
            },
            clearLabel:function(){
                this.currLabels=[];
                this.currPage = 1;
                this.$router.push({name:'blog',query:{page:this.currPage}});
            },
            totalPage:function(maxPage){
                if ( this.currPage > maxPage ) {
                    this.currPage = maxPage
                    this.$router.push({name:'blog',query:{label: this.currLabels, page: this.currPage }});
                }
            }
        },
        created(){
            this.fetchBlogLabel();
            this.fetchBlogItems();
            this.setSelectedContactTopic('solutions');
        },
        watch: {
            '$route' (to, from) {
                if(this.$route.query.label.split(',')!==this.currLabels){
                    this.currLabels=this.$route.query.label.split(',');
                    this.currPage=1;
                }
                if( this.currPage == undefined ) this.currPage = 1;
            },
            blogLabel(val){
                for(var k in val){
                    this.labels.push(val[k]);
                }
            },
            blogItem(val){
                console.log('blogItem',val);
                this.items = val;
                // this.$window.initAll();
            }
        },
        components: {
            "channel-box": channelBox,
            "pagination": pagination
        }
    };

</script>