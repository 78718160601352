const lang={
    /////////////////////////////////
    //sitemap
    /////////////////////////////////
    sitemap_product_medicalHealth:"医疗保健",
    sitemap_product_automotiveAndAviation:"汽车与航太",
    sitemap_product_greenEnergy:"绿色能源",
    sitemap_product_industrialApplication:"工业应用",
    sitemap_product_communication:"通讯相关",
    sitemap_solution_designManufacturingSolutions:"协作设计开发",
    sitemap_solution_integrationSolutions:"组装客制化服务",
    sitemap_solution_radioFrequencyRfSolutions:"无线射频整合设计服务",
    sitemap_csr_chairmanMsg:"经营者的话",
    sitemap_csr_corporateGovernance:"治理营运",
    sitemap_csr_green:"绿色信邦",
    sitemap_csr_givingBack:"回馈社会",
    /////////////////////////////////
    //page titles
    /////////////////////////////////
    page_title_global_location:"全球据点",
    page_title_news:"最新消息",
    page_title_privacy:"隐私权政策",
    page_title_robotic:"机器人解决方案 – 连结现在与未来",
    page_title_investor_shareholders_info:"投资人讯息",
    page_title_investor_business_overview:"营运概况",
    page_title_investor_corporate_governance:"公司治理 | 投资人专区",
    page_title_terms:"使用条款",
    page_title_webinar:"网络研讨会",
    page_title_webinar_communication:"通訊 | 网络研讨会",
    page_title_webinar_e_mobility:"智慧移动 | 网络研讨会",
    page_title_webinar_industrial_application:"工业应用 | 网络研讨会",
    page_title_solar_system:"太阳能光伏系统",
    page_title_about:"关于信邦",
    page_title_download:"电子型录",
    page_title_capability:"设计研发制造服务",
    page_title_career_job:"加入我们",
    page_title_career_train:"我们的愿景",
    page_title_contact:"联络与洽询",
    /////////////////////////////////
    //msg
    /////////////////////////////////
    msg_ix_text:"信邦电子结合广濑电机(HIROSE Electric) ix工业用系列连接器提供强固、高速传输线材解决方案，将于2019年11月26至28日参加德国纽伦堡的国际工业自动化展(SPS)，展览摊位号 Hall 10.0 Booth 630，欢迎大家前来参观交流，有关资讯请参阅 <a href='https://ix.sinbon.eu/' target='_blank'>ix.sinbon.eu</a>",
    msg_ix_btn:"前往了解",
    msg_sinbon30th_text:"想不到吧!? 我把最重要的消息放在这！30周年线上艺廊独家呈献。",
    msg_no_data:"此分类比对查无结果。",
    /////////////////////////////////
    //nav
    /////////////////////////////////
    nav_site:"信邦电子",
    nav_slogan:"Solution Provider, Assembly Manufacturing",
    nav_home:"首页",
    //resources
    nav_resources:"资源",
    nav_resourcesBrochure:"产品型录",
    nav_resourcesWebinar: "线上研讨会",
    //trands
    nav_trends:"热门趋势",
    //product
    nav_products:"产品",
    nav_evCharging:"充电解决方案",
    nav_solarSystem:"太阳光电系统",
    nav_robotic:"机器人解决方案",
    nav_ebike:"电力辅助自行车",
    nav_solution:"解决方案",
    nav_casestudy:"案例分享",
    nav_blog:"文章",
    nav_brands:"品牌代理",
    nav_capability:"能力与服务",
    //investor
    nav_investor:"投资人专区",
    nav_investorNewsroom:"投资人讯息",
    nav_investorGovernance:"公司治理",
    nav_investorFinancial:"财务信息",
    nav_investorShareholder:"股东讯息",
    nav_investorBusiness:"公司营运概况",
    //csr
    nav_csr:"企业社会责任",
    nav_csrContact:"利害关系人沟通管道",
    nav_csrDownload:"报告书下载",
    nav_sustainability_report:"永续报告书",
    nav_sustainabilityReportDownload:"报告书下载",
    //ESG
    nav_esg:"ESG企业永续",
    //
    nav_sinbon:"信邦电子",
    nav_location:"全球据点",
    nav_ix:"ix系列线材方案",
    nav_news:"最新消息",
    nav_about:"关于信邦",
    //career
    nav_career:"人才招募",
    nav_careerInfo:"成长与愿景",
    nav_careerJobs:"职缺一览",
    nav_careerStudent:"校园征才",
    nav_careerIntern:"全球实习计画",
    nav_careerSinboner:"信邦人",
    //
    nav_contact:"联络与洽询",
    nav_searchSite:"搜寻",
    nav_search:"搜寻",
    nav_dealer:"伙伴专区",
    nav_downloads:"资源下载",
    nav_sitemap:"网站地图",
    nav_copyright:"© SINBON Electronics Co., Ltd.",
    nav_terms:"使用条款",
    nav_privacy:"隐私政策",
    nav_privacy_setting:"隐私设定",
    nav_webmail:"信邦人信箱",
    /////////////////////////////////
    //ui
    /////////////////////////////////
    ui_category:"类别",
    ui_allCategory:"所有类别",
    ui_filter:"篩選",
    ui_clear:"清除",
    ui_download:"查看档案",
    ui_summary:"摘要档案",
    ui_downloadVideo:"下载影片",
    ui_contact:"联系",
    ui_ask:"洽询产品",
    ui_view:"浏览",
    ui_askme:"有任何需求吗? 请与我们联络",
    ui_subscribe_ok:"订阅成功",
    /////////////////////////////////
    //home
    /////////////////////////////////
    home_banner_title: "最值得信赖的线材与<br/>连接器伙伴",
    home_banner_p:"提供客制化线材与连接器研发制造<br/>优良的质量、严格控制的成本、迅速的交期",
    home_banner_cta:"我们的能力与服务",
    home_more_news_title: "最新消息",
    home_more_news: "更多消息",
    home_register_webinar: "注册",
    home_on_demand_webinar: "观看",
    home_benefit_title:"提供最完整的解决方案",
    home_benefit_p:"医疗保健、汽车与航天、绿色能源、工业应用、通讯，无论是在哪个领域，信邦是最值得信赖的全方位的电子产品研发、制造整合服务提供商为客户提供各种不同的电子产品解决方案",
    // home_benefit_li_1:"6座产线工厂遍布各洲",
    // home_benefit_li_2:"全球超过20个服务据点",
    // home_benefit_li_3:"超过100个品牌客户合作",
    // home_benefit_cta:"了解信邦的整合能力",
    // home_service_1:"提供完整供应链的<br/>品牌代理",
    // home_service_1_cta:"了解更多",
    // home_service_2:"符合各式标准的充电枪",
    // home_service_2_cta:"了解更多",
    // home_service_3:"节能更有效率的太阳能发电系统",
    // home_service_3_cta:"了解更多",
    home_subscribe_title:"订阅我们的电子报，随时掌握最新动态",
    home_subscribe_placeholder:"请输入电子邮件",
    home_subscribe_send:"订阅",
    /////////////////////////////////
    //about
    /////////////////////////////////
    about_banner_title:"梦想、快乐、<br/>持续创新的卓越企业",
    about_banner_p:"这些价值导引我们的经营运作，并时时提醒我们成功的要素为何",
    about_philosophy_title:"为客户提供更广泛的高附加价值产品服务",
    about_philosophy_p_1:"信邦成立于公元1989年，为提供电子零件整合设计与制造服务的领导厂商，产品应用范围涵盖资料撷取、电脑周边和其他电子产品。顺应科技产业的快速发展和日益激增的市场需求，信邦致力于产品研发和强化员工专业知识，以求达到客户的技术要求，并提供客户满意且完整的解决方案。经过这几年的努力，信邦成功转型为提供完全解决方案的制造商，并且跨入了汽车电子、航太、工业控制、医疗器材和消费性电子产品领域，为客户提供更广泛的高附加价值产品服务。",
    about_philosophy_p_2:"信邦于台湾、中国、日本、英国、德国、匈牙利和美国等地广布据点，架起市场和商业资讯可相互整合及交换的资源网络。透过此一网络，信邦可以快速响应客户的需求、加速订单的处理、缩短生产与交货时程、降低设计与开发成本，提供客户无国界和无时差的服务与支持。此外，从全球原物料搜询采购服务、产品接单、打样与制造到售后服务，信邦提供了客户最愉悦的合作环境，并且可将客户对产品的初步构想转化为高质量、高效能的产品。",
    about_feature_1:"培育人才、凝聚共识",
    about_feature_2:"双赢的原则下<br/>提供客户设计整合及服务、共创产品价值",
    about_feature_3:"创造员工与股东最大利益<br/>共同分享公司的成果",
    about_feature_4:"善尽社会公民的责任",
    about_milestone:"关键文章",
    about_quality:"品質政策",
    about_quality_des_1:"提供客戶最高品質的產品為信邦的首要之務。我們遵守業界最嚴謹的要求和標準，以確保可以如期地完成設計、並將品質優良的產品交給客戶。信邦會持續加強製造技術、加速產品交期，來符合客戶對高品質產品的需求。",
    about_quality_title_1:"關於 ISO 45001",
    about_quality_declaration_1:"安全衛生宣言",
    about_quality_declaration_2:"安全至上 / 快樂工作 / 健康幸福 / 社會和諧",
    about_quality_commitment_1:"安全衛生承諾",
    about_quality_commitment_2:"本公司基於維護員工健康及工作安全，減少因生產活動對員工所造成之影響致力於持續不斷的改善及傷害預防，提供安全工作環境積極推行安全與健康宣導遵循政府法令規章符合各項安全標準，訂定安衛目標以展現改善績效建立安衛管理系統，透過內外部溝通宣導向社會及利害關係者公開、運作審查達到永續經營。",
    /////////////////////////////////
    //distribution
    /////////////////////////////////
    distribution_banner_title:"提供各品牌电子产品与<br/>完善的服务支援",
    /////////////////////////////////
    //career
    /////////////////////////////////
    career_banner_title:"成为我们的伙伴<br/>共同成长",
    career_train_learning_title:"完整的训练体系<br/>让伙伴们扎实的充实自身的能力",
    career_train_learning_1_title:"各种职能训练",
    career_train_learning_1_list_1:"核心职能",
    career_train_learning_1_list_2:"专业职能",
    career_train_learning_1_list_3:"管理职能",
    career_train_learning_2_title:"伙伴的自我成长",
    career_train_learning_2_list_1:"工作本职训练80%",
    career_train_learning_2_list_2:"非工作本职训练20%",
    career_train_learning_3_title:"带动企业成长",
    career_train_learning_3_list_1:"高素质、专业化的人才，带动公司整体成长，成为永续经营的原动力",
    career_train_promotion_title:"顺畅的成长路径<br/>让有能力的伙伴能够实现自我远大的梦想",
    career_train_promotion_step_1:"阶段 1",
    career_train_promotion_step_1_title:"作业层",
    career_train_promotion_step_2:"阶段 2",
    career_train_promotion_step_2_title:"专业层",
    career_train_promotion_step_2_p:"研发、厂务、业务、企划",
    career_train_promotion_step_3:"阶段 3",
    career_train_promotion_step_3_title:"营运层",
    career_train_promotion_step_3_p:"研发、厂务、业务、企划",
    career_train_promotion_step_4:"階段 4",
    career_train_promotion_step_4_title:"国际营运层",
    career_train_promotion_step_4_p:"台湾、中国、欧洲、美国",
    career_train_promotion_step_5:"階段 5",
    career_train_promotion_step_5_title:"全球领导层",
    career_train_promotion_step_5_p:"营运事业群、全球业务事业群",
    career_train_banner_title:"我们重视每一个人的福利",
    career_train_welfare_title:"完善福利制度",
    career_school_area_tw:"台湾",
    career_school_area_cn:"中国",
    career_school_info:"年度校园征才场次信息",
    career_school_scene:"征才现场",
    career_job_title:"我们深信，是卓越的团队，造就了今天的信邦，<br/>我们不是在征人，而是在寻找一起打拼未来的好伙伴。<br/>誠摯邀請你與信邦共同成長!",
    career_job_view:"浏览职缺",
    career_intern_info:"计画内容",
    career_intern_story:"浏览职缺",
    career_intern_view:"看看他的故事",
    career_intern_cta:"马上加入实习计划",
    career_family_view:"看看他的故事 »",
    career_footer_title:"成为我们的伙伴",
    career_footer_p:"我们深信，是卓越的团队，造就了今天的信邦，<br/>我们不是在征人，而是在寻找一起打拼未来的好伙伴。",
    career_footer_cta:"加入信邦",
    /////////////////////////////////
    //investor
    /////////////////////////////////
    //newsroom
    investor_newsroom_banner_title:"投资人讯息",
    investor_newsroom_stocklink:"前往查询股价(公司代号：3023)",
    //governance
    investor_governance_banner_title:"公司治理",
    investor_governance_section_0_item_1:"各单位主要职掌及业务",
    investor_governance_section_0_item_2:"功能性委员会及组织成员",
    investor_governance_section_0_item_3:"公司治理运作情形",
    investor_governance_section_1_title:"董事会",
    investor_governance_section_1_item_1:"最近年度董事会绩效考核自评结果档案",
    investor_governance_section_1_item_2:"董事会成员多元化方针及落实执行情形档案",
    investor_governance_section_1_item_3:"董事会成员",
    investor_governance_section_1_item_4:"董事会决议事项",
    investor_governance_section_2_title:"公司治理规章",
    investor_governance_section_3_title:"内部稽核运作",
    investor_governance_section_3_p:"内部稽核之目的，在于协助董事会及经理人检查及覆核内部控制制度之缺失及衡量营运之效果及效率，并适时提供改进建议，以确保内部控制制度得以持续有效实施及作为检讨修正内部控制制度之依据。<br/><br/>本公司设置隸属董事会直接指挥之专责内部稽核单位，并受独立董事之督导，稽核单位应置主管一人，由董事会任免之，且稽核主管应列席董事会进行稽核业务报告，稽核主管及其所属稽核人员共计4人，依本公司修订之『公司治理实务守则』规定，内部稽核人员之任免、考评、薪资报酬由稽核主管签报董事长核定。",
    investor_governance_section_4_title:"内部稽核单位之运作",
    investor_governance_section_4_p:"稽核室应基于风险评估结果决定稽核项目，编制年度稽核计划，年度稽核计划应列明每月、每季应稽核之项目，据以执行专案(个案)稽核作业之依据。<br/><br/>内部稽核人员应与受查单位就年度稽核项目查核结果充分沟通，对于评估所发现之内部控制制度缺失及异常缺失事项，包括主管机关检查所发现、内部稽核作业所发现、内部控制制度声明书所列、自行评估及会计师专案审查所发现之各项缺失，应据实揭露于稽核报告，并于该报告陈核后加以追踪，至少按季作成追踪报告至改善为止，以确定相关单位业已及时采取适当之改善措施。<br/><br/>稽核人员应覆核各单位及子公司之内部控制制度自行检查报告，并同稽核单位所发现之内部控制缺失及异常事项改善情形，以作为评估整体内部控制制度有效性及出具内部控制制度声明书之主要依据。<br/><br/>内部稽核人员如发现有重大违规情事或公司有受重大损害之虞时，应立即作成报告陈核，并通知独立董事。",
    investor_governance_section_5_title:"薪资报酬委员会",
    investor_governance_section_5_p:'评估及检讨公司董事及经理人之薪资报酬及绩效评估与薪资报酬之政策、制度、标准与结构。<br/><br/>薪资报酬委员会每年进行评估，其评估面向包含：对公司营运之参与程度、功能性委员会职责认知、提升功能性委员会决策质量、功能性委员会组成及成员选任及内部控制，评估结果分为三个等级：超越标准、符合标准及仍可加强。<br/><br/>高阶经理人之薪酬，依据公司薪酬政策，将透过薪酬委员会综合考虑及产业营运机会与风险等，并参考外部薪酬市场及同业同类职位水平，经董事会综合考虑报酬数额、支付方式等事项后决议。绩效衡量标准包含财务面，如营收等获利之关键绩效，同时也囊括非财务面之环境、社会、治理等 ESG 永续经营成效。<br/><br/>更多与董事及经理人之酬金内容请参阅<a href="https://www.sinbon.com/tw/shareholders-info">信邦股东会年报</a>。',
    investor_governance_section_5_item_1:"薪资报酬委员会组织规程",
    investor_governance_section_5_item_2:"薪资报酬委员会运作情形",
    investor_governance_section_6_title:"企业风险管理",
    investor_governance_section_6_1_title:"风险管理政策与程序",
    investor_governance_section_6_1_desc:"信邦自2021年10月制定「风险管理政策与作业程序」，并由审计委员会同意后，经董事会决议通过，以作为本公司风险管理最高指导原则，及全体员工执行风险管理的指引。<br><br>为强化公司治理、确保风险管理制度之完整性，以落实风险管理制衡机制，提升风险管理分工之效能，及全员风险管理认知，依照公司整体营运方针来定义各类风险，透过组织规划，有效辨识、预防及控制风险，并依据内外环境变化，持续调整改善最佳风险管理实务，以保护员工、股东、合作夥伴与顾客的利益，增加公司价值，达成公司永续经营。",
    investor_governance_section_6_2_title:"风险治理与管理组织架构",
    investor_governance_section_6_2_chart_0:"董事会",
    investor_governance_section_6_2_chart_branch:"稽核室",
    investor_governance_section_6_2_chart_1:"审计委员会",
    investor_governance_section_6_2_chart_2:"经营决策小组",
    investor_governance_section_6_2_chart_3:"风险管理人员",
    investor_governance_section_6_2_chart_4:"风险责任人",
    investor_governance_section_6_2_desc_1:"信邦依循管理组织体系及内部控制循环，强调全员全面风险控管，平时落实层层防范，以最具成本效益的方式，积极面对与管控营运过程所应考量之风险，风险管理之执行按照各层级分工架构来运作。",
    investor_governance_section_6_2_th_1:"风险管理层级",
    investor_governance_section_6_2_th_2:"风险管理运作",
    investor_governance_section_6_2_tr_1_td_1:"第一线责任",
    investor_governance_section_6_2_tr_1_td_2:"各单位或业务承办人为其承办业务之风险责任人，负责最初的风险发觉、评估及控制。",
    investor_governance_section_6_2_tr_2_td_1:"第二线责任",
    investor_governance_section_6_2_tr_2_td_2:"各部门权责主管或经指派之功能/部门风险管理人员，负责相关业务之风险管理。",
    investor_governance_section_6_2_tr_3_td_1:"第三线责任",
    investor_governance_section_6_2_tr_3_td_2:"经营决策小组，审视风险管理相关机制之完整性，及监控各单位之相关风险，并定期每年向董事会报告风险治理情形。",
    investor_governance_section_6_2_desc_2:"稽核室为公司内部独立监督机制，督导各执行单位遵循核决权限与相关管理办法及作业程序，并进行有关风险管理是否有效落实执行之评估，确保制度落实与遵循。",
    investor_governance_section_6_2_desc_3:"审计委员会协助董事会督导风险管理成效，并透过会议报告了解运作情形。",
    investor_governance_section_6_3_title:"风险管理运作情形",
    investor_governance_section_6_3_desc:"信邦积极推动并落实风险管理机制，透过定期召开经营决策小组会议，及每年向董事会报告风险治理情形。2023年度风险治理主要运作情形如下：",
    investor_governance_section_6_3_item1:"每四个月召开经营决策小组会议报告风险管理执行情形，包括风险鑑别、风险衡量及因应措施。",
    investor_governance_section_6_3_item2:"2023/10/24向董事会报告风险管理治理情形，包括当年评估公司所面临的关键风险项、风险衡量及回应对策。",
    investor_governance_section_6_3_item3:"2023年透过公告与教育宣导，以持续强化风险治理与风险管理意识：",
    investor_governance_section_6_3_item3_1:"6月及8月进行「气候相关财务揭露(Task Force on Climate-related Financial Disclosures, TCFD)」教育宣导",
    investor_governance_section_6_3_item3_2:"9月执行「内部控制三道防线」说明宣导",
    investor_governance_section_6_3_item3_3:"10月透过「集团营运风险管控重申」公告",
    investor_governance_item_1:"独立董事监察人与内部稽核主管及会计师之沟通情形",
    investor_governance_section_7_title:"Business Continuity Management",
    investor_governance_section_7_1_title:"Policy and Purpose",
    investor_governance_section_7_1_p:"For the long-term pursuit of creating value for customers and with the aim of providing continuous service, SINBON implements Business Continuity Management (BCM) to simulate risk scenarios and assess the impact on the organization's operations to establish a Business Continuity Plan (BCP) with respect to the risks which may disrupt the operation of the Company to ensure the thoroughness of risk measurement and preventive measures to achieve the business continuity objectives by reducing operational impact and shortening recovery time.",
    investor_governance_section_7_2_title:"Executive Team for Business Continuity Management and Operation",
    investor_governance_section_7_2_p1:"President of SINBON is the convener of the Business Continuity Management Executive Team, which convenes relevant departments to formulate business continuity plans for the risks of disruption of continuous operations and the correlation of critical resources.",
    investor_governance_section_7_2_p2:"To ensure that critical operational processes are protected from major information system failures or disasters in the event of unplanned disasters or incidents. This is a continuous and periodic management system which includes risk measurement and business impact analysis, decisions on the business continuity management strategies, development and implementation of business continuity management, and drills, maintenance and audit of the business continuity plans.",
    investor_governance_section_7_2_chart_lv1:"BCM<br>Convener",
    investor_governance_section_7_2_chart_coordination_title:"Corp Coordination",
    investor_governance_section_7_2_chart_coordination_item1:"Finance",
    investor_governance_section_7_2_chart_coordination_item2:"Public Relations",
    investor_governance_section_7_2_chart_coordination_item3:"Customer Relations",
    investor_governance_section_7_2_chart_coordination_item4:"Supply Chain",
    investor_governance_section_7_2_chart_coordination_item5:"R&D/Engineering",
    investor_governance_section_7_2_chart_coordination_item6:"Manufacturing BU Leaders/Plant Managers",
    investor_governance_section_7_2_chart_coordination_item7:"Legal",
    investor_governance_section_7_2_chart_coordination_item8:"ADM",
    investor_governance_section_7_2_chart_coordination_item9:"HR",
    investor_governance_section_7_2_chart_coordination_item10:"MIS",
    investor_governance_section_7_2_chart_repsponse_title:"Site Response",
    investor_governance_section_7_2_chart_repsponse_p:"Emergency Response Teams in each region:including BU, factory(QE, PE, production management), procurement, warehouse, ADM(EHS, factory affairs), finance, HR, Legal, and MIS.",
    investor_governance_section_7_3_title:"Business Continuity Management Plan",
    investor_governance_section_7_3_p1:"Risk management is conducted in accordance with \"Risk Management Policy and Operating Procedure\", and a Business Continuity Management Plan is in place to address the risks which may cause operational disruption. The Business Continuity Management Plan includes risk measurement and preventive measures, business impact analysis, establishing objectives and resources of the business continuity plan, business continuity strategies and solutions, emergency response plan and business recovery plan, etc.",
    investor_governance_section_7_3_p2:"The purpose of practical drills is to ensure that the business continuity plan works effectively, and to review and revise the business continuity plan based on the results of the drills. SINBON adopts the continuous circulatory improvement cycle (Plan-Do-Check-Act, PDCA) to fully achieve the objectives of business continuity management and to make the employees have the awareness of risk management. The BCM convener may convene meetings to discuss the results of the annual drills and new risk themes to ensure the effectiveness and integrity of the business continuity plan.",
    investor_governance_section_7_3_th1:"Risks",
    investor_governance_section_7_3_th2:"The Points of Business Continuity Plan",
    investor_governance_section_7_3_r1_td1:"Infectious Diseases",
    investor_governance_section_7_3_r1_td2_li1:"Contingency measures for suppliers and logistics supply affected by infectious diseases.",
    investor_governance_section_7_3_r1_td2_li2:"Simulate the situation of factory employees contracting infectious diseases and formulate various contingency measures for critical resources.",
    investor_governance_section_7_3_r2_td1:"Suppliers run out of raw materials due to sudden or unexpected accidents",
    investor_governance_section_7_3_r2_td2_li1:"Master a variety of supply sources to meet demand quickly",
    investor_governance_section_7_3_r2_td2_li2:"Remote backup within the group and mutual allocation of materials between factories.",
    investor_governance_section_7_3_r2_td2_li3:"Backup supplier sourcing.",
    investor_governance_section_7_3_r3_td1:"Geopolitics",
    investor_governance_section_7_3_r3_td2_li1:"Develop a disaster preparedness training plan with a primary focus on ensuring employee safety.",
    investor_governance_section_7_3_r3_td2_li2:"Critical Information system offsite backup.",
    investor_governance_section_7_3_r3_td2_li3:"Emergency reserve plan.",

    //financial
    investor_financial_banner_title:"财务信息",
    investor_financial_chart_title:"公司自结合并月营收",
    investor_financial_chart_date:"日期",
    investor_financial_chart_unit:"营收(台币仟元)",
    //shareholder
    investor_shareholder_banner_title:"股东讯息",
    investor_shareholder_meeting_title:"最新年度股东会年报",
    investor_shareholder_recording:'2023股东会录影',
    investor_shareholder_recording_cta:'观看影片',
    investor_shareholder_dividend_title:"历年股利分派",
    investor_shareholder_dividend_th_1:"年度",
    investor_shareholder_dividend_th_2:"现金股利",
    investor_shareholder_dividend_th_3:"股票股利",
    investor_shareholder_dividend_th_4:"除权息交易日",
    investor_shareholder_dividend_th_5:"基准日",
    investor_shareholder_dividend_th_6:"发放日",
    investor_shareholder_dividend_th_7:"股东会日期",
    investor_shareholder_bank:"台新国际商业银行",
    investor_shareholder_address:"台北市仁爱路四段118号2楼",
    //bussiness
    investor_bussiness_banner_title:"公司营运概况",
    investor_bussiness_overview_title:"季度营运概况简报",
    investor_bussiness_overview_desc:" ",
    investor_bussiness_lawMeeting_title:"最新年度法说会简报",
    investor_bussiness_video_title:"最新年度法说会影音",
    /////////////////////////////////
    //csr
    /////////////////////////////////
    //page
    // csr_page_banner_title:"梦想、快乐、<br/>持续创新的卓越企业",
    // csr_page_banner_p:"这些价值导引我们的经营运作，并时时提醒我们成功的要素为何",
    //download
    csr_download_banner_title:"报告书下载",
    // csr_download_banner_p:"这些价值导引我们的经营运作，并时时提醒我们成功的要素为何",
    csr_download_section_title:"永续报告书",
    //contact
    csr_contact_banner_title:"利害关系人沟通管道",
    // csr_contact_banner_p:"这些价值导引我们的经营运作，并时时提醒我们成功的要素为何",
    csr_contact_group_1_title:"投资人关系与新闻连络人暨公司治理",
    csr_contact_group_1_item_1_title:"公司發言人(财务长) ",
    csr_contact_group_1_item_1_name:"张集州",
    csr_contact_group_1_item_2_title:"代理发言人",
    csr_contact_group_1_item_2_name:"游雅茹",
    csr_contact_group_2_title:"采购",
    csr_contact_group_2_item_1_title:"材料零组件相关",
    csr_contact_group_2_item_1_name:"孔寅芳",
    csr_contact_group_2_item_2_title:"行政相关",
    csr_contact_group_2_item_2_name:"陈羽凡",
    csr_contact_group_3_title:"企业社会责任",
    csr_contact_group_4_title:"业务服务信箱",
    csr_contact_group_5_title:"从业道德",
    csr_contact_group_5_item_1_title:"稽核室",
    csr_contact_group_5_item_1_name:"李惠君",
    /////////////////////////////////
    //location
    /////////////////////////////////
    location_banner_title:"我们的全球据点",
    /////////////////////////////////
    //news
    /////////////////////////////////
    location_news_title:"最新消息",
    /////////////////////////////////
    //product
    /////////////////////////////////
    product_banner_title:"提供各领域质量最稳定的<br/>客制化线材与连接器",
    product_shareto:"分享至",
    /////////////////////////////////
    //footer block
    /////////////////////////////////
    footer_block_1_title:"完善整合<br/>了解信邦全面整合力",
    footer_block_2_title:"创新服务<br/>在线客制需求的线材",
    footer_block_3_title:"联系我们<br/>寻求完善解决方案",
    footer_block_4_title:"完整的全球布局<br/>为您提供更全面的服务",
    /////////////////////////////////
    //solution
    /////////////////////////////////
    solution_banner_title:"为各种不同的需求<br/>提供最完善的解决方案",
    /////////////////////////////////
    //case study
    /////////////////////////////////
    casestudy_banner_title:"為各種不同的需求<br/>提供最完善的解決方案",
    /////////////////////////////////
    //blog
    /////////////////////////////////
    blog_banner_title:"為各種不同的需求<br/>提供最完善的解決方案",
    /////////////////////////////////
    //contact
    /////////////////////////////////
    contact_title:"联络与洽询",
    contact_subtitle_1:'投资人关係与新闻连络人暨公司治理',
    contact_subtitle_2:'企业永续发展',
    contact_subtitle_2_des: `永续办公室`,
    contact_subtitle_3:'产品相关',
    contact_subtitle_3_des: `业务负责单位`,
    contact_subtitle_4:'解决方案相关',
    contact_subtitle_4_des: `方案专家`,
    contact_subtitle_5:'品牌代理',
    contact_subtitle_5_des: `智能零组件事业处`,
    contact_subtitle_6:'企业庶务相关採购',
    contact_subtitle_7:'材料零组件相关採购',
    contact_subtitle_8:'从业道德',
    contact_subtitle_8_des: `稽核室`,
    contact_subtitle_9:'人才招募',
    contact_subtitle_9_des: `人才招募服务中心`,
    contact_subtitle_10:'员工申诉',
    contact_subtitle_10_des: `员工关係小组`,
    contact_subtitle_11:'其他问题与建议',
    contact_label_inqiery:"洽询项目",
    contact_select_inqiery:"洽询项目",
    contact_label_category:"产品类别(选填)",
    contact_select_category:"请选择产品类别",
    contact_evcharging:"充电解决方案",
    contact_solar:"充电解决方案",
    contact_robotic:"机器人解决方案",
    contact_ebike:"电力辅助自行车",
    contact_label_product:"产品(选填)",
    contact_select_product:"请选择",
    contact_label_solution:"解决方案类别(选填)",
    contact_select_solution:"请选择",
    contact_label_country:"国家",
    contact_select_country:"请选择",
    contact_label_city:"城市(选填)",
    contact_label_company:"公司名称",
    contact_label_name:"姓名",
    contact_label_fname:"名",
    contact_label_lname:"姓",
    contact_label_email:"电子邮件",
    contact_label_phone:"联络电话(选填)",
    contact_label_message:"洽询内容",
    contact_label_send:"送出讯息",
    contact_others:"其他",
    contact_label_hear_us:"从何种管道得知我们公司?",
    contact_select_hear_us_0:"请选择",
    contact_select_hear_us_1:"网络搜寻 (Google/Yahoo/Bing/百度等)",
    contact_select_hear_us_2:"社群媒体 (LinkedIn/facebook等)",
    contact_select_hear_us_3:"朋友/同事推荐",
    contact_select_hear_us_4:"新闻报导",
    contact_select_hear_us_5:"展览",
    contact_select_hear_us_6:"其他",
    // contact_agree_info:"我愿意接受信邦电子及其相关企业的信息",
    contact_agree_privacy:"当您送出后，代表您同意收到更多关于信邦的产品服务与新闻活动信息，您随时可以在未来收到的信件中取消订阅信邦电子报。",
    contact_alert_1:"请选择洽询类别",
    contact_alert_2:"请选择产品类别",
    contact_alert_3:"请选择解决方案类别",
    contact_alert_4:"请选择国家",
    contact_alert_5:"请填写城市",
    contact_alert_6:"请填写公司名称",
    contact_alert_7:"请填写姓名",
    contact_alert_8:"请填写电子邮件",
    contact_alert_9:"请填写正确的电子邮件格式",
    contact_alert_10:"请填写洽询内容",
    contact_alert_11:"送出成功，我们将尽快与您联系，谢谢您的洽询",
    contact_alert_12:"请选择得知管道",
    contact_alert_12_other:'Please fill in "How did you hear about us?"',
    contact_alert_13:"请详阅并同意隐私权政策",
    /////////////////////////////////
    //webinar contact
    /////////////////////////////////
    webinarContact_title:"线上研讨会",
    webinarContact_subtitle:"填写资讯即可观看线上研讨会",
    webinarContact_label_country:"国家",
    webinarContact_select_country:"请选择",
    webinarContact_label_city:"城市",
    webinarContact_label_company:"公司名称",
    webinarContact_label_name:"姓名",
    webinarContact_label_email:"电子邮件",
    webinarContact_label_job:"职称",
    webinarContact_label_register:"送出",
    // webinarContact_agree_info:"我愿意接受信邦电子及其相关企业的信息",
    webinarContact_agree_privacy:"当您送出后，代表您同意收到更多关于信邦的产品服务与新闻活动信息，您随时可以在未来收到的信件中取消订阅信邦电子报。",
    webinarContact_alert_1:'请选择国家',
    webinarContact_alert_2:'请填写公司名称',
    webinarContact_alert_3:'请填写姓名',
    webinarContact_alert_4:'请填写城市',
    webinarContact_alert_5:'请填写职称',
    webinarContact_alert_6:'请填写电子邮件',
    webinarContact_alert_7:'请填写正确的电子邮件格式',
    webinarContact_alert_8:'資料已送出，請繼續觀看影片',
    webinarContact_alert_9:"请详阅并同意隐私权政策",
    /////////////////////////////////
    //evCharging
    /////////////////////////////////
    ev_metaTitle:'電動車充電解決方案',
    ev_metaDes:'信邦憑藉著線材與連接器的高度技術研發能力，投入電動車充電設備設計與製造，從充電槍、插座到充電樁的多年耕耘，信邦讓許多國際知名的汽車品牌成為我們的重要合作夥伴。',
    ev_title:"驶向未来 – 电动车充电解决方案",
    ev_content:"从2012年起，信邦凭借着线材与连接器的高度技术研发能力，投入电动车充电设备设计与制造。从充电枪、插座到充电桩的多年耕耘，信邦让许多国际知名汽车品牌成为我们的重要合作伙伴。<br><br>「与时俱进」与「质量」是我们最重视的。随着电动车充电需求日益演变，信邦也投入高功率及家用充电电缆的研发。我们拥有汽车业质量管理系统IATF 16949认证，确保质量维持在最高水平。我们的产品经过各项认证与测试，保障使用者的安全。",
    ev_download_btn:"下载产品型录",
    ev_contact_btn:"联络我们",
    ev_section_1_title:"完整充电系统客制化解决方案",
    ev_section_1_des:"信邦提供完整电动车电力相关解决服务，包括交流/直流充电枪，涵括美国、欧洲、中国标准，满足不同地区客户需求；并有携带式充电枪-模式二，全面照顾不同需求。<br><br>除充电枪外，亦研发与充电枪匹配的车端交流/直流插座、高压线束等多款相关产品，让客户能享受一站式的客制服务。",
    ev_section_2_title:"交流充电枪",
    ev_section_2_des:"信邦提供涵盖欧洲标准、美国标准、中国标准的交流充电枪，满足客户跨地区的市场需求。<br><br>具有一体成形专利，防护等级高达IP55，有效提升产品防水性与耐受性，保障使用者安全。",
    ev_section_2_list_1:"安全可靠",
    ev_section_2_list_2:"专利设计",
    ev_section_2_list_3:"高防水",
    ev_section_2_list_4:"符合人体工学",
    ev_section_2_list_5:"高结构强度",
    ev_section_2_sub_title_1:"具附加价值的产品设计",
    ev_section_2_sub_list_1:"全球首创以包复成型(Overmold)提供产品良好的防水(IP55)与耐受性",
    ev_section_2_sub_list_2:"握把具有防松脱功能的按键设计",
    ev_section_2_sub_list_3:"镀银铜合金端子，低接触电阻",
    ev_section_2_pd_1:"Type1 交流充电枪 - 美国标准 (SAE J1772)",
    ev_section_2_pd_2:"Type2 交流充电枪 - 欧洲标准 (IEC)",
    ev_section_2_pd_3:"GB 交流充电枪 - 中国标准 (GB/T)",
    ev_section_3_title:"直流充电枪",
    ev_section_3_des:"信邦提供涵盖欧洲标准、美国标准及中国标准的大电流(350A)充电枪，全面提升充电速度。",
    ev_section_3_list_1:"安全可靠",
    ev_section_3_list_2:"专利设计",
    ev_section_3_list_3:"高防水",
    ev_section_3_list_4:"符合人体工学",
    ev_section_3_list_5:"高结构强度",
    ev_section_3_list_6:"防止热插拔",
    ev_section_3_sub_title_1: "细致工艺技术",
    ev_section_3_sub_list_1:"全球首创以包复成形(overmold)提供产品良好的防水(IP55)与耐受性。",
    ev_section_3_sub_list_2:"阻燃(UL94 V-0)、耐紫外线。",
    ev_section_3_sub_list_3:"内建温度感测，通过环境温度-40〜50℃、终端温升<50K测试",
    ev_section_3_pd_1:"CCS2 家用直流充电枪 - 欧洲标准 (IEC)",
    ev_section_3_pd_2:"GB 直流充电枪 - 中国标准 (GB/T)",
    ev_section_3_pd_3:"CCS1 直流充电枪 - 美国标准 (SAE J1772)",
    ev_section_3_pd_4:"CCS2 直流充电枪 - 欧洲标准 (IEC)",
    ev_section_4_title:"便携式充电枪",
    ev_section_4_des:"信邦便携式交流充电枪，不论何时何地，只要接上一般插座即可充电。",
    ev_section_4_list_1:"模组化系统",
    ev_section_4_list_2:"电磁兼容性强",
    ev_section_4_list_3:"高防水",
    ev_section_4_list_4:"符合人体工学",
    ev_section_4_list_5:"可替换式接头",
    ev_section_4_sub_title_1: "确保使用安全",
    ev_section_4_sub_list_1:"专利过温保护，通过AC插头温度> 80℃，PCB温度> 85℃等测试。",
    ev_section_4_sub_list_2:"四段可控电压，搭配三色LED充电状态指示灯，即时监控插头温度。",
    ev_section_4_sub_list_3:"符合IC-CPD国际标准、Type B漏电保护。",
    ev_section_4_pd_1:"便携式充电枪 - 模式二",
    ev_section_5_title:"插座",
    ev_section_5_des:"信邦可提供不同规格对应交流、直流充电枪的插座。",
    ev_section_5_sub_title_1:"坚固耐用",
    ev_section_5_sub_list_1:"符合IP55防护等级。",
    ev_section_5_sub_list_2:"通过插拔耐用性> 10,000次测试。",
    ev_section_5_sub_list_3:"通过环境温度-40℃ ~ +85℃测试。",
    ev_section_5_pd_1:"GB 电动车直流充电插座",
    ev_section_5_pd_2:"GB 电动车交流充电插座",
    ev_section_5_pd_3:"CCS1 电动车直流充电插座",
    ev_section_5_pd_4:"CCS2 电动车直流充电插座",
    ev_section_6_title:"交流和直流充电桩",
    ev_section_6_des:"信邦提供客制化充电桩设计与模组组装。​",
    ev_section_6_sub_title_1:"安全防护",
    ev_section_6_sub_list_1:"符合IP54防护等级。",
    ev_section_6_sub_list_2:"过压、过流、过热、漏电的安全保护。​",
    ev_section_6_pd_1:"交流和直流充电桩",
    ev_section_7_title:"高功率电线束",
    ev_section_7_des:"电动车的电压一般为60~1000V的高电压，而传统汽车电压只有12~48V，所以高压连接器在此就扮演着极其重要的角色。",
    ev_section_7_sub_title_1:"安全的环境下传输大电流和高电压",
    ev_section_7_sub_list_1:"调整高压互锁、插座设计。",
    ev_section_7_sub_list_2:"改善插拔连接器时断裂危险，减低触电事故及损坏发生。",
    ev_section_7_pd_1:"高功率电线束",
    ev_section_8_title:"除了产品，我们更希望为客户提升产品竞争力",
    ev_section_8_sub_title_1:"选材优化",
    ev_section_8_sub_des_1:"协助客户进行原材料选材优化，提高产品附加价值",
    ev_section_8_sub_title_2:"客制化设计",
    ev_section_8_sub_des_2:"针对各别客户需求进行产品、制程客制化",
    ev_section_8_sub_title_3:"加速生产效率",
    ev_section_8_sub_des_3:"简化组装流程、导入自动化设备，加速生产效率",
    ev_section_9_title:"信邦专利设计",
    ev_section_9_sub_title_1:"一体成型",
    ev_section_9_sub_des_1:"使用TPE包复，带来绝佳防护性能，并大幅提升结构强度。外型设计符合人体工学，触感较市面上其他充电枪柔软。",
    ev_section_9_sub_title_2:"专利电子锁保护",
    ev_section_9_sub_des_2:"有效防止带电插拔，杜绝任何漏电、触电的可能性。",
    ev_section_10_title:"安全可靠的品质",
    ev_section_10_sub_title_1:"IP55",
    ev_section_10_sub_des_1:"防护等级达IP55，防水防尘，确保安全无虞。",
    ev_section_10_sub_title_2:"电性要求",
    ev_section_10_sub_des_2:"电性要求通过绝缘阻抗>10MΩ，端子温升＜50K等测试。",
    ev_section_10_sub_title_3:"机构设计",
    ev_section_10_sub_des_3:"通过插拔寿命>10,000次，插拔力F＜100N等测试。",
    ev_section_11_title:"有客制充电解决方案需求吗？<br>我们将提供您最完整及专业的充电系统客制化解决方案",
    /////////////////////////////////
    //solar system
    /////////////////////////////////
    solar_title:"太阳能光电系统服务",
    solar_content:"为追求绿活意境<br/>开发整合前瞻性太阳能产品及应用<br/>期许让你我因太阳能而受益",
    solar_section_1_title:"我们提供的不只是一个产品<br/>而是一个适切的方案",
    solar_section_1_point_1:"太阳能系统组件",
    solar_section_1_point_2:"系统规划设计",
    solar_section_1_point_3:"监造与售后服务",
    solar_section_2_des:"除了提供系统整合服务外，信邦亦有提供并联系统产品销售服务，有别于传统太阳光电系统，提供单片监控功能，在维运上更能得心应手",
    solar_section_3_title_1:"最适化光电系统设计",
    solar_section_3_des_1:"源头入手，依案场设置环境条件，进行最适化光电系统设计、设施配置、使系统高效运行，降低运维成本",
    solar_section_3_title_2:"于系统验收、运维发现的问题、提出合理化维护改善作法",
    solar_section_3_des_2:"信邦太阳能的专业能力与丰富经验能精确判读电厂资料，快速找出问题并提出应对方针，优化电厂的发电状况",
    solar_section_3_title_3:"即时分析、检测与排除",
    solar_section_3_des_3:"用预防性维护概念，对光电系统的潜在故障，进行即时分析、检测与排除，以防范高风险事件发生。 搭配信邦太阳能电厂运维服务与电厂监控资料，将定期为客户进行系统检查&提出体检报告，主动发现电厂异常状况，并提供维护改善服务",
    solar_qa:"常见问题",
    solar_q1:"有办法得知系统实际的转电效率吗？",
    solar_a1:"是的。一旦完成系统安装，业主可以上我们的线上即时监控系统(手机也可以)，包括每一个Panel的即时发电讯息的回报。",
    solar_q2:"安装系统后在节能环保上究竟能有多少贡献呢？",
    solar_a2:"以设置容量 1 仟瓦(kWp)来说： 年发电量约为 1300 度， 所减少的二氧化碳排放量：约 814 公斤 ，减少氮化物、硫化物、微尘量的排放量：约 13.68 公斤。",
    solar_q3:"整个施作流程大致上的脉络是怎么样？",
    solar_a3:"1.基地探勘 2.评估规划与报价 3.申请与签约施工 4.贴心的售后服务",
    solar_q4:"到竣工验收预计会花多久的时间呢?",
    solar_a4:"一般为4~6个月，其中在台电的趸电申请这段时间比较长，几乎会占去2个月的时间。",
    solar_q5:"目前模组的转电效率大约为多少？",
    solar_a5:"单晶矽的模组转换效率会比多晶矽的模组高，约有19%的效率表现。",
    solar_q6:"电池的发电效率会逐年下降吗？",
    solar_a6:"每年会有0.8%的正常耗损，10年的效能约为原90%，20年的效能约为原80%",
    solar_q7:"法规是否有规范屋顶装设太阳能系统的高度范围限制？",
    solar_a7:"是的，依照法规所装设系统的最高点以不超过顶楼平面的4.5米为原则。",
    solar_q8:"我们台湾常装设太阳能发电系统的空间有哪些？",
    solar_a8:"常见的案例包括透天屋顶/工厂厂房屋顶/农舍的屋顶，等…",
    solar_q9:"大约投资多久后可以回收成本?",
    solar_a9:"将视区域而定，一般来说大约7~10年左右。",
    solar_q10:"外力损害是否可以赔偿? 有无保险可以投保呢?",
    solar_a10:"外力/天然灾害等不可抗力因素引发之故障或损坏，不在我们的赔偿范围内。不过可以投保产物保险，遇到上述损害可由保险公司来赔偿。",
    solar_q11:"可以申请银行贷款吗？利率大约是多少？",
    solar_a11:"安装太阳能发电系统可以申请银行贷款，利率大约3~5%，由银行评估决定。",
    solar_q12:"太阳能模组该如何维护保养? 保养的费用约多少？",
    solar_a12:"在20年的保固期间内我们会定期做巡查/维护保养，保固期满后可以再共同 签订维护合约，费用将依案子大小和维护项目而定。",
    solar_q13:"现在装设太阳能发电系统的时机好吗？",
    solar_a13:"根据国外的经验，政府对于系统的补助价格会逐年降低。而台电未来很有可能再调高每度用电的电价，所以越早装设是越有利的。",
    solar_recommen:"相关产品",
    /////////////////////////////////
    //robotic
    /////////////////////////////////
    robotic_title:"信邦机器人解决方案 – 连结现在与未来",
    robotic_content_1:"信邦多年来专注于机器人领域，除了提供客制化线材组装与加值解决方案；包括印刷电路板组装（PCBA）、天线、包覆射出成型以及塑料射出成型外， 亦提供机器人整合式影音解决方案的一站式服务。",
    robotic_content_2:"用于机器人的线材组装必须符合机械、化学与环境的最高标准。 相较于其它市场，机器人应用环境所需的参数是独一无二的；须能承受瞬间的加减速、拉伸、机械与扭转应力；并须能承受剧烈的弯曲循环，高温、焊接飞溅物、油与多种化学物质。 我们使用合适的材质以确保线材组装符合先进市场的客户需求，同时也拥有最高阶的测试设备，可模拟运转并确定线材寿命。 这些测试包含振动测试、盐雾试验、湿度测试、粉尘测试、电磁干扰（EMI）/ 电磁兼容性（EMC）以及加速老化试验等。",
    robotic_list_title:"我们的产品与服务包含",
    robotic_list_content_1:"机构及电机设计",
    robotic_list_content_2:"软韧体整合",
    robotic_list_content_3:"原线材设计与制造",
    robotic_list_content_4:"线材组装与接线器",
    robotic_list_content_4_1:"极细同轴线",
    robotic_list_content_4_2:"网络线材",
    robotic_list_content_4_3:"高速线材(包含客制化USB Type-C)",
    robotic_list_content_4_4:"移动零件专用高弹性组装",
    robotic_list_content_4_5:"固定式线材",
    robotic_list_content_5:"包覆射出成型",
    robotic_list_content_6:"印刷电路板组装",
    robotic_list_content_7:"天线设计、制造与整合",
    robotic_list_content_8:"整合式影音解决方案",
    robotic_list_content_9:"塑料射出成型",
    
    robotic_section_1_title:"工业机器人",
    robotic_section_1_des:"工业机器人通常是指应用于工业环境的多关节机器手臂，专门用于焊接、物料搬运、涂装及其它用途。其应用环境需求包括极致的弯曲与旋转活动、最高规格的灵活度以及微型化。信邦在工业环境拥有丰富经验，专精于提供全面的强固连接方案。",
    robotic_section_1_list_title:"我们的产品包含",
    robotic_section_1_list_content_1:"马达驱动控制器线材",
    robotic_section_1_list_content_2:"电力传输",
    robotic_section_1_list_content_3:"控制柜线材组装 – 连接控制、驱动与安全电路",
    robotic_section_1_list_content_4:"机柜与基座连接缆线 – 电源和马达编码控制器",
    robotic_section_1_list_content_5:"印刷电路板组装",

    robotic_section_2_title:"仓储机器人",
    robotic_section_2_des:"灵活性与效率成为零售商与物流公司开始设置机器人的主要原因，这些新型解决方案加快了交货时间、减少人为错误并能减轻仓储人员的负担。 为了要打造超越业界标准的最佳产品，信邦持续与许多仓储机器人先驱品牌合作。",
    robotic_section_2_list_title:"我们的产品包含",
    robotic_section_2_list_content_1:"机器人充电连接解决方案，包含高电压连接器线材",
    robotic_section_2_list_content_2:"红外线数据与驱动装置线材",
    robotic_section_2_list_content_3:"ODS 电源与电池充电线材",
    robotic_section_2_list_content_4:"以太网络线材",
    robotic_section_2_list_content_5:"伺服马达线材",
    robotic_section_2_list_content_6:"印刷电路板组装",

    robotic_section_3_title:"线性与协作机器人",
    robotic_section_3_des:"随着自动化兴起，线性与协作机器人的应用随之增加。 线性机器人往往具有较高精准度，因此常被作为重复性任务的理想自动化解决方案。而协作机器人的设计目的是在人机共享的工作环境下运作，这类型的连接解决方案须能承受瞬间的加减速、拉伸、机械与扭转应力。 信邦为这种应用提供讯号、电源与拖曳线材。",

    robotic_section_4_title:"医疗机器人",
    robotic_section_4_des:"信邦已在医疗市场耕耘数年，我们的工厂通过 ISO13485 与 FDA 认证。 在高性能的客制线材组装为了病患的安全与舒适，不论是用于机器人或计算机辅助手术上，都有着生物兼容性与抗菌化合物。",
    robotic_section_4_list_title:"我们的产品包含",
    robotic_section_4_list_content_1:"极细同轴线组装",
    robotic_section_4_list_content_2:"高速 USB type C",
    robotic_section_4_list_content_3:"以太网络线材",
    robotic_section_4_list_content_4:"光导纤维",
    robotic_section_4_list_content_5:"混合线材组装",
    robotic_section_4_list_content_6:"测量与控制装置线材组装",

    robotic_section_5_title:"类人型机器人",
    robotic_section_5_des:"传感器是类人型机器人的主要零件之一，而信邦持续与许多领导机器人品牌合作，以发展影音应用程序的解决方案。 除了这些解决方案，也有必要将线材组装微型化，以及延长线材寿命。",
    robotic_section_5_list_title:"我们的产品包含",
    robotic_section_5_list_content_1:"线材整合与印刷电路板组装解决方案",
    robotic_section_5_list_content_2:"影像应用的极细同轴线",
    robotic_section_5_list_content_3:"传感器线材与组装",
    robotic_section_5_list_content_4:"高速数据传输线材组装",

    robotic_footer_title:"实现你的构想",
    robotic_footer_content:"信邦不是一家常规的公司，我们往往能提供超越客户期待与需求的服务。无论是工业、仓储、医疗及类人型机器人等产业，信邦皆能从最初产品研发过程、原型设计到最终量产，提供量身订造解决方案。",
    
    /////////////////////////////////
    //ebike
    /////////////////////////////////
    ebike_title:"信邦电力辅助自行车解决方案－翻转距离定义",
    ebike_content_1:"电力辅助自行车接轨环保潮流，低污染、操作简单、省力及不需驾照就能上路成为新潮的代步工具。",
    ebike_content_2:"信邦的客制化整合服务能力可为电动自行车相关客户提供从零组件到OEM / ODM的整机组装服务解决方案，为客户大幅缩短制程或开发时间，有效降低时间成本。",
    ebike_links_title: "关于智慧移动(E-mobility)<br>你不可不知的X个关键",
    ebike_links_btn: "阅读更多",
    ebike_footer_link_1: `Connectivity Solution`,
    ebike_footer_link_2: `E-System as a whole`,
    ebike_contact_title: `Interested in customizing your E-bike solution?<br>Don't hesitate, contact us now!`,
    // ebike_section_1_title:"全方位整合性的解决方案",
    // ebike_section_1_list_1:"软韧体整合",
    // ebike_section_1_list_2:"机构及电机设计",
    // ebike_section_1_list_3:"印刷电路板组装",
    // ebike_section_1_list_4:"显示仪表版的整机组装服务",
    // ebike_section_1_list_5:"马达驱动系统解决方案",
    // ebike_section_1_list_5_sub_1:"线材组装",
    // ebike_section_1_list_5_sub_2:"印刷电路板组装",
    // ebike_section_1_list_6:"充电系统解决方案",
    // ebike_section_1_list_6_sub_1:"电源开关线束",
    // ebike_section_1_list_6_sub_2:"充电线束",
    // ebike_section_1_list_6_sub_3:"充电电池模块",
    // ebike_section_1_list_6_sub_4:"充电电池连接器",
    // ebike_section_1_list_6_sub_5:"充电转接头（含充电线＋插座）",
    // ebike_section_1_list_7:"无线通信模块与天线设计",
    // ebike_section_1_list_7_des:"可支持全球LTE 及 UMTS / HSPA+ 网络系统。",
    // ebike_section_1_list_7_sub_1:"无线通信模块解决方案",
    // ebike_section_1_list_7_sub_2:"4G 模块",
    // ebike_section_1_list_7_sub_3:"4G 天线",
    // ebike_section_1_list_7_sub_4:"GPS 模块",
    // ebike_section_1_list_7_sub_5:"NFC 天线",
    // ebike_section_1_list_7_sub_6:"多种客制化的讯号解决方案",
    // ebike_section_1_list_8:"整合传感器服务",
    // ebike_section_1_list_9:"从原线材到线材组装与客制化连接器开发",
    // ebike_section_1_list_9_sub_1:"讯号传输线",
    // ebike_section_1_list_9_sub_2:"电源传输线",
    // ebike_section_1_list_9_sub_3:"控制器线束",
    // ebike_section_1_list_9_sub_4:"电子锁线束",
    // ebike_section_1_list_9_sub_5:"各式客制化的线材",
    // ebike_section_1_list_9_sub_6:"客制化连接器开发",
    // ebike_section_1_list_10:"ECU 设计",
    // ebike_section_1_list_10_sub_1:"微控制器 (MCU)",
    // ebike_section_1_list_10_sub_2:"能量管理系统 (PMS)",
    // ebike_section_1_list_10_sub_3:"电池管理系统 (BMS)",
    // ebike_section_1_list_10_sub_4:"无线通信模块",
    // ebike_section_1_list_10_sub_5:"传感器",
    // ebike_section_1_list_10_sub_6:"马达控制",
    // ebike_section_1_list_10_sub_7:"无刷直流马达应用及电路图设计",

    // ebike_section_2_content_1:"信邦在电力辅助自行车解决方案除了提供天线、无线通信模块、线材与线材组装等服务之外，也提供物联网整合性解决方案。同时，信邦可提供显示仪表版的整机组装服务，并提供整合性的整车组装服务。信邦在电力辅助自行车显示仪表板的解决方案经过 IPX5 防水认证；马达时速可符合各国法规，并提供稳定动力的驱动系统；充电系统更可以在 -10 ℃~+85 ℃ 的环境下正常运作，完美适应世界各地的天气，并能保持充电期间的安全及稳定；无线通信模块有效解决因零件安在金属整机外壳内而阻挡讯号的问题，使讯号传输仍能长时间稳定传输。",
    // ebike_section_2_content_2:"信邦从进料、组装、测试等ㄧ站到府的客制化服务，全面实行E化管理，让每一台电力辅助自行车都拥有自己的生产履历，质量管理有保证。除此之外，信邦亦提供完整测试服务，可依据客户产品的环境需求，进行合适路段的骑乘测试，以确保产品状态良好。",

    /////////////////////////////////
    //ebike privacy
    /////////////////////////////////
    ebike_privacy_param_1_2:"当您在我们的应用程序（「App」）或我们的任何平台（与该网站一起统称为「平台」）上建立账户，订阅接收我们的及时消息或其他营销信息，或同意向我们提供商品或服务，我们可能会要求您提供您的联络信息。例如，如您是我们的客户，我们将储存您的姓名、地址、电子邮件和电话号码等信息用于填入未来订单的结账信息。当您向我们购买商品时，我们也会收集账单和帐户信息。当您作为客户、潜在客户，供货商、顾问或其他第三方与我们展开业务时，我们可能会处理您的个人信息。例如，我们可能会保存您的业务联络信息和财务帐户信息（如有）以及您与我们之间的其他通讯，以维持我们与您的业务关系。",
    /////////////////////////////////
    //ebike terms
    /////////////////////////////////
    ebike_terms_title:"使用条款",
    ebike_terms_des:"最新更新︰",
    ebike_terms_param_0:'本应用程式係由信邦电子股份有限公司(以下简称“我们”或“信邦”)提供。请在使用本App前仔细阅读本使用条款。在任何电脑、手机、平板电脑或者其他装备使用本行动应用程式 (“本应用程式”)，即表明您已经阅读、理解并同意受本使用条款和其他适用法律约束，不论您是否为註册用户。我们有权在任何时间不经通知修改本使用条款，并于公布在本应用程式之时起生效。 您如持续使用本应用程式，即视为接受本使用条款的修订。如果您不同意本使用条款，请勿使用本应用程式。',
    ebike_terms_title_1:"智慧财产权",
    ebike_terms_param_1:'除使用者产生内容，本应用程式的全部智慧财产权(包括受着作权、商标或专利法保护的材料)均为我们或我们的授权方所有。本应用程式所显示的全部商标、服务标章和商品名称为我们所有、註册和/或授权。本应用程式全部内容（除使用者产生内容外），包括但不限于文字、软体、脚本、代码、设计、图示、图片、声音、音乐、录影、应用、互动功能和其他所有内容（以下简称“内容”）均为中华民国和其他国家着作权法下的共同着作，且为我们或我们的授权方的专有财产。',
    ebike_terms_title_2:"平台使用限制",
    ebike_terms_param_2:"您只能为您自己的非商业用途使用本本应用程式。您同意不修改或删除任何您从本应用程式下载或列印的所有权声明。您同意未经我们事先书面同意，不得改变、複製、翻译、广播、演出、展示、散布、生产、再版、下载、发表、传输或销售任何本应用程式上出现的或内容，包括使用者产生内容（但是您自己合法发布于本应用程式的使用者产生内容不在此限）。本使用条款中任何内容不应被解释为授予您任何智慧财产权。",
    ebike_terms_title_3:"使用者名称/密码保护",
    ebike_terms_param_3:"为保护您使用者名称及密码的安全，请勿允许其他任何人透过您的使用者名称及密码使用本应用程式。我们对您前述行为所造成的损失，不承担任何责任，不论该使用您是否知悉。",
    ebike_terms_title_4:"使用者产生内容",
    ebike_terms_param_4:'“使用者产生内容”为您和/或其他本应用程式使用者发布于本应用程式或透过本应用程式提供的资讯，包含但不限于数据、评价、图片、档案、讯息、网站连结、音乐、录影、设计、图表、声音等其他任何内容。您的使用者产生内容并非保密或专属资讯。您授予并保证有权授予非独家、不可撤销、全球性、可转让、免权利金的，以任何目的，包括商业目的、广告目的而使用您的使用者产生内容的永久使用权，包括但不限于翻译、展示、複製、修改、散布、等商业化权利，无须支付任何费用予您。',
    ebike_terms_title_5:"您应遵守的使用者行为",
    ebike_terms_param_5_1:"请勿作出任何行为或发布任何内容使我们或其使用者可能受到任何伤害或承担任何责任。您应对您的使用者产生内容、您与其他使用者的互动和您在平台上的行为，全权负责。",
    ebike_terms_param_5_2:"请勿利用本应用程式向其他使用者徵集个人资讯或发送未经请求的电子邮件或其他通信。请勿未经他人同意或以非法目的在本应用程式上收集、使用或发布他人隐私资讯。",
    ebike_terms_param_5_3:'请勿在本应用程式上发布任何广告或进行商业行为。请勿发布任何涉及传送“垃圾邮件”或“连锁信件”',
    ebike_terms_param_5_4:"请勿伪造或提供虚假身分或年龄资讯。请勿代他人註册或销售或转移您的个人档案或帐户。请勿使用或尝试使用他人的帐户。",
    ebike_terms_param_5_5:"您担保任何您发布的使用者产生内容现在或将来皆不侵犯或违反任何他人的权利，包括着作权、商标、专利、商业祕密、隐私、肖像权等权利。",
    ebike_terms_param_5_6:"请勿採取任何干扰、破坏、损害、停用、过载或限制本应用程式功能的行为。请勿规避或修改任何本应用程式的安全技术或软体。",
    ebike_terms_title_6:"註册",
    ebike_terms_param_6:'您需註册帐户以使用本应用程式。本应用程式仅供已满20岁的使用者或年满13岁、未满20岁但已取得您的监护人同意的使用者使用。您同意在本应用程式的全部註册表格上提供您准确及最新的资料。如果您未满13岁，註册本应用程式的行为将被视为违反本使用条款，您的帐户都可能会在不加警告的情况下予以删除。社交网路和登入帐号<br>您亦可透过多种线上第三方服务，如 Facebook 或 Twitter 等社交媒体和社交网路服务 (「社交网路服务」) 启用或登入服务。我们可以要求您在社交网路服务供应商的网站上，验证、註册或登入社交网路服务。在进行此整合工作的过程中，社交网路服务会让我们存取您提供给他们的某些资讯，而我们将依据我们的隐私权政策，使用、储存与揭露此类资讯。请记住，第三方服务 (包括社交网路服务) 使用、储存与揭露您的资讯的方式，完全由此类第三方服务的政策管辖。',
    ebike_terms_title_7:"连结",
    ebike_terms_param_7:'本应用程式可能连结到由其他公司（“第三人平台”)经营的网站、应用程式或其他产品、服务。第三人平台具有独立的使用条款和隐私政策。我们对第三人平台的内容和政策不负任何责任，您需自行评估及承担是否进入或使用第三人平台的风险。',
    ebike_terms_title_8:"行动服务",
    ebike_terms_param_8:"我们尽力在众多平台上提供服务，但我们无法保证应用程式与您的装置相容。如果您使用应用程式，您的标准数据和简讯费率将适用；您从中下载应用程式之应用程式商店的规则也将适用。您同意我们得以电子方式透过您的行动装置与您通讯，如果您的手机号码更改或者停用，您须及时更新您的帐户资讯从而确保我们不会把您的讯息发送给其他人。",
    ebike_terms_title_9:"隐私",
    ebike_terms_param_9:"我们的隐私政策为本使用条款引用纳入的组成部分，将进一步解释本应用程式对资讯的蒐集和使用。",
    ebike_terms_title_10:"使用者互动的免责声明",
    ebike_terms_param_10:"您因对您透过使用本应用程式与他人的互动全权负责。您同意与其他在本应用程式上的使用者进行互动时应採取合理预防措施并在接触其他人之前进行必要的调查。我们对于您于本应用程式或透过本应用程式与本应用程式其他使用者、透过本应用程式接触您的人、或者基于您在本应用程式上发布的资讯而发现您的人之间的互动所造成的任何损失或损害概不负责。",
    ebike_terms_title_11:"免责声明",
    ebike_terms_param_11:'本应用程式、使用者产生内容、内容及本应用程式上的资料是在“现状”的基础上提供的且无任何担保。我们不担保本应用程式不会中断、没有任何错误、缺陷将获得纠正、本应用程式或本应用程式伺服器不会受到病毒的攻击或者受到任何伤害。在适用法律所允许的最大范围内，我们不对本应用程式资料和内容使用的正确性、准确性、充分性、有效性、可靠性等作任何保证或声明。您理解并同意您应自行承担下载、对于您的使用及因您的使用致损害您进入本应用程式的行动装置、电脑系统或发生资料遗失或者其他任何种类的伤害时，皆由您全权负责。我们保留随时更改任何和全部内容及本应用程式使用且不必事先告知的权利。',
    ebike_terms_title_12:"责任限制",
    ebike_terms_param_12:"我们不对任何由于使用本应用程式或不能使用本应用程式、本应用程式其他使用者的行为而引起的直接、特殊、附随，间接或衍生性损害承担责任，包括任何利益损失或资料遗失，即使我们已经被告知此类损害的可能性。您由于使用本应用程式或任何内容的唯一救济是停止使用本应用程式。",
    ebike_terms_title_13:"其他",
    ebike_terms_param_13:"您同意关于本应用程式、本使用条款、隐私政策以及任何您和我们之间的纠纷（以下简称“纠纷”）均适用中华民国法律，但选法法则除外。您同意所有由本应用程式直接或间接产生或与之相关的纠纷、索赔和诉讼应单独解决，不诉诸任何形式的集体诉讼，且均应提交台北地方法院依照中华民国法律专属解决。您同意就管辖地点及管辖权，放弃向对该纠纷具有管辖权的台北法院提出不具属人管辖及不便利法庭原则的全部抗辩权利。我们未履行或未执行任何本使用条款中的条款，不应被视为放弃该等条款或权利。",
    ebike_terms_title_14:"可分割性",
    ebike_terms_param_14:"如果本使用条款中的任一条款被认定属无效，仍得继续执行本使用条款的其他条款。如果本使用条款中的任一条款被认定是违法、无效或不可执行，则该条款将视为与本使用条款相分割，其馀条款仍属有效及可执行。",
    ebike_terms_title_15:"终止条款",
    ebike_terms_param_15:"我们得不经通知，以任何理由或无理由单方终止您帐户、删除您个人资料以及您的使用者产生内容并限制您使用本应用程式的全部或部分的权利，并且不对您或任何人承担责任。您理解并同意，即使你的使用者产生内容被移除或您的帐户被终止，您于其他平台（例如，Google等）的部分使用者产生内容，可能会继续出现在本应用程式或其他平台上。您的帐户被终止后，本使用条款仍然有效。本使用条款中的智慧财产权条款、使用者互动的免责声明、免责声明、责任限制条款、其他条款、可分割性条款以及其他按其性质于本使用条款终止后仍继续有效的条款，于本使用条款终止后仍继续有效。",
    /////////////////////////////////
    //capability
    /////////////////////////////////
    capability_title:"完整制造流程<br/>来自信邦",
    capability_section_1_title_1:"信邦的制造流程涵盖了初始设计概念、完整的内部制作流程，以及产品验证，落实了真正的垂直整合。",
    capability_section_1_desc_1:"我们为客户提供的服务包含：",
    capability_section_1_list_1:"机械及电机设计",
    capability_section_1_list_2:"软韧体设计",
    capability_section_1_list_3:"零组件与供应链管理",
    capability_section_1_list_4:"原线材设计与制造",
    capability_section_1_list_5:"线材组装",
    capability_section_1_list_6:"包复射出成型",
    capability_section_1_list_7:"塑胶射出成型",
    capability_section_1_list_8:"印刷电路板组装",
    capability_section_1_list_9:"天线设计与制造",
    capability_section_1_list_10:"模治具设计与制造",
    capability_section_2_title_1:"垂直整合帮助我们全球的客户取得显着优势",
    capability_section_2_list_1:"品质管制",
    capability_section_2_list_2:"缩短上市时间",
    capability_section_2_list_3:"管控零组件供应链",
    capability_section_2_list_4:"为客户提供一站式服务",
    capability_section_2_list_5:"具有广泛的制造能力",
    capability_section_2_list_6:"降低客户的成本",
    capability_section_2_list_7:"快速生产以满足高需求量",
    capability_section_2_list_8:"弹性制造",
    capability_acc_desc_1:"信邦工程服务在于提升项目的质量，减少不必要的开支。我们的专业团队在产品开发的各阶段即与客户进行密切的合作，从可制造性设计流程出发，以客户导向提供量身打造的设计解决方案。信邦完整的新产品开发流程及生产制造为客户成就一流的服务及产品。",
    capability_acc_1_title:"工程服务",
    capability_acc_1_text_1:"信邦提供广泛的加值工程服务，补足了我们端到端的供应链策略，并因此降低成本，加快客户产品上市速度。首先，信邦的设计和工程团队为产品开发过程创造丰厚价值，从一开始的概念发想到细节和原型设计，再到批量生产，几乎涵盖了完整设计周期。",
    capability_acc_1_text_2:"可制造性导向设计（DFM）",
    capability_acc_1_text_3:"信邦的工程师会依照可制造性导向设计原则(Design for Manufacturing, DFM) 来确保制造过程一致，并在设计阶段找出并解决任何潜在的问题。可制造性导向设计原则能降低许多成本，因为透过标准化、外购零件、模组设计和标准设计功能，我们可以用更少的零件快速组装产品。在打样阶段之前，信邦一定会提交可制造性导向设计评估报告，以确保达成客户的需求，以及可行的工程方案。除了机械及电机设计之外， 可制造性导向设计同时也包含 3D 建模。",
    capability_acc_1_text_4:"机械及电机设计",
    capability_acc_1_text_5:"信邦旗下拥有 200 多名研发工程师，协助我们将客户的设计概念推向市场。我们包含的服务有：",
    capability_acc_1_text_6:"机械设计与建模",
    capability_acc_1_text_7:"硬体设计",
    capability_acc_1_text_8:"射频设计与建模",
    capability_acc_1_text_9:"3D打印产品原型",
    capability_acc_1_text_10:"印刷电路板布线设计",
    capability_acc_1_text_11:"嵌入式系统设计",
    capability_acc_1_text_12:"韧体设计与整合",
    capability_acc_1_text_13:"新产品导入与专案管理",
    capability_acc_1_text_14:"信邦提供全面的新产品导入（NPI）流程，结合专业工程和制造服务，以及专门的专案管理支援。我们在共同的时区内工作并使用共通的语言，确保在整个制造过程中提供卓越的客户服务与技术支援。",
    capability_acc_1_text_15:"我们致力于提供可靠的电子解决方案，重视给客户即时且深入的协助，我们能够协助您将设计概念转化为市场销售产品。",
    capability_acc_2_title:"原线材设计与制造",
    capability_acc_2_text_1:"信邦量身订作的线材解决方案包含原线材设计与制造。",
    capability_acc_2_text_2:"从单芯线到庞杂的多芯结构，包含了低压线和同轴线。我们自营的原线材制造使我们能够开发生产小量电缆，以达成少量多样的线材组装制造目标。我们的绝缘与被复材料能包复所有物件并耐高、低温。上述材料包含：",
    capability_acc_2_text_3:"PVC &amp; PE HYTREL FOAM-PE SR-PVC",
    capability_acc_2_text_4:"LSZH",
    capability_acc_2_text_5:"Cross linked PVC &amp; PE TPU",
    capability_acc_2_text_6:"FEP &amp; PTFE ETFE PFA",
    capability_acc_2_text_7:"Silicone EPDM EPR",
    capability_acc_2_text_8:"TPU &amp; TPE",
    capability_acc_3_title:"线材组装设计与制造",
    capability_acc_3_text_1:"信邦追求高品质的工程制造能力，使我们能够因应任何市场或用途，设计并制造出特定的线材。我们的产品在恶劣环境中依然可运作，并维持稳固的机电完整性。若有产量方面的需求，信邦能够设计并配置全自动生产线，以确保品质一致，及提供实惠的价格。",
    capability_acc_3_text_2:"少量多样",
    capability_acc_3_text_3:"我们在许多市场上体认到少量多样的需求，为确保能提供客户最具成本效益的解决方案，我们采用组合式工作站以保证在整个精简的制造过程中减少浪费。",
    capability_acc_3_text_4:"自动化",
    capability_acc_3_text_5:"若有产量方面的需求，信邦能够设计并配置全自动生产线。自动化对品质、提高生产率、增加材料利用效率、增强安全性，以及缩短工厂交货时间等，皆能产生重大影响。",
    capability_acc_3_text_6:"我们的技术包含：",
    capability_acc_3_text_7:"焊接 - 全自动与高频（14AWG-40AWG）",
    capability_acc_3_text_8:"超音波焊接（1.5mm<sup>2</sup>  -  50mm<sup>2</sup>）",
    capability_acc_3_text_9:"电磁干扰(EMI)及电磁兼容性(EMC) 解决方案",
    capability_acc_3_text_10:"自动裁线、剥线和压接",
    capability_acc_3_text_11:"压接 － 自动（18AWG  -  28AWG）液压（4/0 AWG  -  6 AWG）",
    capability_acc_3_text_12:"连接器设计与制造",
    capability_acc_3_text_13:"印刷电路板组装整合",
    capability_acc_3_text_14:"编织",
    capability_acc_3_text_15:"雷射刻印",
    capability_acc_3_text_16:"客制化线材接头设计",
    capability_acc_3_text_17:"包复射出成型（内外模低压成型）",
    capability_acc_3_text_18:"自动化灌胶",
    capability_acc_3_text_19:"半自动化成型",
    capability_acc_4_title:"印刷电路板组装设计与制造",
    capability_acc_4_text_1:"信邦为各种市场应用设计及制造印刷电路板，主要目标为提供客户完整的解决方案。我们结合设计、制造、产品信赖度以及生命周期测试，并远超过客户的需求与期待。",
    capability_acc_4_text_2:"我们的服务和技术包含：",
    capability_acc_4_text_3:"印刷电路板设计与布线",
    capability_acc_4_text_4:"制造设计服务/测试设计服务",
    capability_acc_4_text_5:"单面、双面、多层、软性印刷电路板(FPC)",
    capability_acc_4_text_6:"全自动表面黏着与插件制程",
    capability_acc_4_text_7:"球格阵列封装(BGA)或微型 BGA 设置",
    capability_acc_4_text_8:"自动光学检测(AOI)",
    capability_acc_4_text_9:"3D X 光线检验",
    capability_acc_4_text_10:"自动化测试",
    capability_acc_4_text_11:"功能测试",
    capability_acc_4_text_12:"原型",
    capability_acc_5_title:"整机组装",
    capability_acc_5_text_1:"信邦因应市场提供完整的整机组装服务，包含医疗、汽车及航空、绿色能源、工业和通讯相关应用。我们借由合作共创良好的业务，客户重视我们的整体理念，并肯定我们将垂直整合与工程能力最佳化的过程。我们拥有跨功能的团队，能够提供优质的产品，实现客户的期望。",
    capability_acc_5_text_2:"机械及电机设计",
    capability_acc_5_text_3:"专案管理",
    capability_acc_5_text_4:"可制造性导向设计（DFM）",
    capability_acc_5_text_5:"软体设计与韧体整合",
    capability_acc_5_text_6:"印刷电路板制造与整合",
    capability_acc_5_text_7:"塑胶射出制造",
    capability_acc_5_text_8:"线材组装制造",
    capability_acc_5_text_9:"验证与生命周期测试",
    capability_acc_6_title:"天线设计与制造",
    capability_acc_6_text_1:"信邦设计并制造了一系列标准及订制的天线解决方案，包含设计概念、原型、工具、天线制造、电缆、PCBA、壳架、测试。我们的天线解决方案广泛应用于汽车、医疗、船舶、工业、通讯市场。",
    capability_acc_6_text_2:"我们的产品范围包含：",
    capability_acc_6_text_3:"芯片天线／平板天线",
    capability_acc_6_text_4:"偶极天线",
    capability_acc_6_text_5:"PCB ／ FPC ／金属／陶瓷嵌入式天线",
    capability_acc_6_text_6:"坚固外接式／ MIMO ／ GNSS 功能天线",
    capability_acc_6_text_7:"射频模组（NB-IoT / LoRa / WiFi / 3G / 4G 等）",
    capability_acc_6_text_8:"射频同轴电缆组件",
    capability_acc_6_text_9:"天线样品",
    capability_acc_6_text_10:"我们使用雕刻、蚀刻曝光机和 3D 打印快速制作工程样品。",
    capability_acc_6_text_11:"测试与验证",
    capability_acc_6_text_12:"内部环境和机械测试是由我们的产品测试与认证部门所执行，以确保产品符合客户所期望的规格。天线的电气性能用于确保天线设计符合其规格。",
    capability_acc_6_text_13:"OTA Chamber (ETS-Lindgren) 测试",
    capability_acc_6_text_14:"无源",
    capability_acc_6_text_15:"峰值增益",
    capability_acc_6_text_16:"AVG 增益",
    capability_acc_6_text_17:"效率",
    capability_acc_6_text_18:"2D 模式",
    capability_acc_6_text_19:"3D 模式",
    capability_acc_6_text_20:"ECC",
    capability_acc_6_text_21:"有源 TRP（总辐射功率）／ TIS（总全向灵敏度）",
    capability_acc_6_text_22:"GSM",
    capability_acc_6_text_23:"GSM-GPRS",
    capability_acc_6_text_24:"GSM-EGPRS",
    capability_acc_6_text_25:"CDMA",
    capability_acc_6_text_26:"CDMA-EVDO",
    capability_acc_6_text_27:"WCDMA",
    capability_acc_6_text_28:"RSS",
    capability_acc_6_text_29:"LTE-FDD",
    capability_acc_6_text_30:"LTE-TDD",
    capability_acc_6_text_31:"Wi-Fi",
    capability_acc_7_title:"射出成型",
    capability_acc_7_text_1:"信邦在塑胶射出成型的制造方面拥有丰富的经验，我们的生产设施让我们能够为客户提供广泛的选择。",
    capability_acc_7_text_2:"产品可由多种热塑性材料制成，包含 ABS、PC、PP、尼龙。产品所用的材料取决于您的机械与环境要求，以确保为您的电子解决方案提供最佳保护。",
    capability_acc_7_text_3:"多种固定方法，包含卡扣、栓锁或螺钉固定",
    capability_acc_7_text_4:"可永久防篡改的超音波焊接",
    capability_acc_7_text_5:"各种不同的材料饰面，包含「皮革漆」",
    capability_acc_7_text_6:"双料射出能力",
    capability_acc_7_text_7:"化学键射出能力",
    capability_acc_7_text_8:"所有材料在成型之前都要经过除溼",
    capability_acc_7_text_9:"中央供料系统，提高产品的效率和品质。",
    capability_acc_8_title:"模型制作",
    capability_acc_8_text_1:"信邦自行开发各种模具，包含包复射出成型、塑胶射出，以及制造压模和夹具。上述服务有助于缩短上市时间，并且使我们能够控管供应链，降低客户的成本。",
    capability_acc_8_text_2:"模型制作服务",
    capability_acc_8_text_3:"快速制造，平均 14 至 21 天",
    capability_acc_8_text_4:"模流分析",
    capability_acc_8_text_5:"暂用模具，用于较低的投资要求和原型",
    capability_acc_8_text_6:"3D建模",
    capability_acc_8_text_7:"装配夹具设计与制造",
    capability_acc_8_text_8:"测试夹具设计与制造",
    capability_acc_8_text_9:"压接模具制造",
    capability_acc_8_text_10:"印刷电路板夹具设计与制造",
    capability_acc_8_text_11:"精密测量夹具设计与制造",
    capability_acc_8_text_12:"包复射出成型模具设计与制造",
    capability_acc_8_text_13:"低压模具设计与制造",
    capability_acc_8_text_14:"塑胶射出模具设计与制造",
    capability_acc_8_text_15:"热浇道模具设计与制造",
    capability_acc_8_text_16:"连接器外壳模具设计与制造",
    capability_acc_8_text_17:"金属冲压模具设计与制造",
    capability_acc_8_text_18:"压铸模具设计与制造",
    capability_acc_8_text_19:"超音波模具设计与制造",
    capability_acc_8_text_20:"矽胶模具设计与制造",
    capability_acc_9_title:"测试与验证",
    capability_acc_9_text_1:"产品和验证测试是制造过程的关键部分。信邦致力于提供可靠且量身订作的优质制造解决方案，代表我们提供了全面的产品测试服务，使我们能够提高产量并降低制造成本。我们拥有娴熟的技术人员和测试工程师，与客户密切合作以制定测试方案，包含机电和环境测试。我们拥有健全的品质程序，每个样品都经过检查和验证，另外还包含每两小时一次的随机抽检，以确保在大量生产前满足所有品质要求。",
    capability_acc_9_text_2:"可用于线材组装的测试范例",
    capability_acc_9_text_3:"电气测试（网路分析仪）",
    capability_acc_9_text_4:"高压绝缘测试",
    capability_acc_9_text_5:"盐雾试验",
    capability_acc_9_text_6:"防潮性",
    capability_acc_9_text_7:"静态湿度测试",
    capability_acc_9_text_8:"弯曲试验（弯曲角度：45°/ 60°/ 90°/ 120°/ 180°）",
    capability_acc_9_text_9:"热冲击试验（温度范围：-50℃ -  + 150℃）",
    capability_acc_9_text_10:"热冲击和弯曲测试（二合一）",
    capability_acc_9_text_11:"拉力试验（容量范围：0.1 kg ~ 2000 kg）",
    capability_acc_9_text_12:"缠绕线材抽出测试",
    capability_acc_9_text_13:"端子截面分析",
    capability_acc_9_text_14:"冷弯试验（低至 -50℃）",
    capability_acc_9_text_15:"UL 易燃性测试",
    capability_acc_9_text_16:"自动插入和抽取测试",
    capability_acc_9_text_17:"三座标测量",
    capability_acc_9_text_18:"3d 量测显微镜",
    capability_acc_9_text_19:"能量色散 X 光线光谱仪",
    capability_acc_9_text_20:"金属涂层厚度测试",
    capability_acc_9_text_21:"时域反射仪",
    capability_acc_9_text_22:"振动测试",
    /////////////////////////////////
    //dealer
    /////////////////////////////////
    dealer_title:"伙伴专区",
    dealer_email:"电子邮件",
    dealer_password:"密码",
    dealer_signin:"登入",
    /////////////////////////////////
    //downloads
    /////////////////////////////////
    resources_title:"资源",
    downloads_title:"产品型录",
    /////////////////////////////////
    //terms
    /////////////////////////////////
    terms_param_0_1:"您得以在遵守本網站使用條款（「條款」）下使用本網站（「網站」）。除非本條款明確允許，否則不得以任何方式影印、複製、重新發布、上傳、張貼、傳輸或散佈本網站的任何資料。透過造訪或使用本網站，包括但不限於購買本網站之產品或服務，即表示您同意受本條款的約束。如您不同意受本條款約束，您必須立即停止使用本網站您得以在遵守本网站使用条款（「条款」）下使用本网站（「网站」）。除非本条款明确允许，否则不得以任何方式影印、复制、重新发布、上传、张贴、传输或散布本网站的任何数据。透过造访或使用本网站，包括但不限于购买本网站之产品或服务，即表示您同意受本条款的约束。如您不同意受本条款约束，您必须立即停止使用本网站。",
    terms_param_0_2:"信邦保留随时更新或修改本条款的权利。任何您在变更后的继续使用即视为您接受更新的条款。",
    terms_title_1:"网站内容",
    terms_param_1:"本网站，包括其所有内容，例如文字、图像和用于产生页面的 HTML（「数据」），都是我们(或我们授权者)的财产。未经信邦书面许可，您不得进行任何公众或商业目的的使用、下载、上传、复制、打印、展示、执行、复制、出版、修改、删除、新增、授权、张贴、传输或散布本网站的任何数据的全部或部分内容。我们得随时终止、变更、暂停或中止本网站任何方面的内容或服务，包括但不限于信息、数据、文字、音乐、声音、照片、图形、影片、讯息或其他数据（「内容」） 、功能和/或可用时间，并且我们不因此而需对您或任何第三方承担责任。",
    terms_title_2:"授权",
    terms_param_2:"在您造访我们的网站时，您被授予非独家且不可转让的权利使用本网站所包含的信息和服务。除非另有规定，本网站并未授予或允许您得以在本网站上存取任何内容、功能或数据，您不得修改、出租、租赁、出借、出售、散布本网站内容或创建此类内容、特征或材料(无论全部或部分)的衍生作品。除信邦另有规定外，您不得为任何目的下载或保存任何内容或屏幕的副本。如果您未依本条款使用本网站，则可能违反著作权法或其他法律，并需因此承担可能责任。您了解本网站未授予您任何本网站上所使用到我们的商标、注册商标、服务标志、任何受版权保护的数据、或其他知识产权上的许可或其他授权。惟，您同意我们得以在本条款规范下无偿任意使用您在网站上发布的任何内容。",
    terms_title_3:"使用限制和安全规则",
    terms_param_3_1:"任何人均应按合法目的使用本网站。您应对自己在本网站的行为(包括但不限于上传、传输和发布讯息、在本网站上或透过本网站发布讯息)的后果负责。信邦禁止任何人并要求所有使用者同意不为以下目的使用本网站：",
    terms_param_3_list:[
        "发布任何不完整、虚假、不准确或不属于您的讯息;",
        "冒充他人;",
        "从事或鼓励从事构成刑事犯罪、引起民事责任或任何违反法律或法规的行为;",
        "发布受著作权保护或由第三人所有的数据，除非您是该著作权所有者或获得所有者的许可;",
        "发布或泄漏商业机密，除非您是这些数据的所有者或取得所有者的许可;",
        "发布任何侵犯他人知识产权、隐私权或公开权的资料;",
        "试图以任何方式干扰、侵犯本网站、信邦的网络或网络安全，或试图利用本网站的服务以未经授权方式入侵任何计算机系统;",
        "试图获取非属您的数据或登入您无权存取的服务器或账户;",
        "试图在未经授权的情况下探测、扫描或测试系统或网络的漏洞或破坏安全或验证措施;",
        "试图干扰任何用户、主机或网络的服务，包括但不限于透过向网站投放病毒、超载、邮件轰炸；或发送未经请求的电子邮件，包括产品或服务的促销和/或广告，或在任何电子邮件或讯息群组贴文中伪造任何 TCP/IP 包头或标头信息"
    ],
    terms_param_3_2:"违反系统或信息网络安全可能涉及民事或刑事责任。我们将对异常事件进行调查，并与执法机构合作并追究参与此类违规行为使用者的责任。",
    terms_title_4:"专有权利",
    terms_param_4:"信邦（或其对应的授权者）是本网站上所出现的任何商标、注册商标和/或服务标志的所有者和/或经授权用户，也是本网站内容和/或信息的著作权所有者或被授权者。产品名称、标志、设计、标题、文字或短语可能受信邦或其他实体所有的商标、服务标记或商号的法律保护。未经我们事先许可，您同意不以任何方式展示或使用信邦识别标识、服务名称或商标。",
    terms_title_5:"免责声明",
    terms_param_5_1:"您了解并同意在适用法律允许的最大范围内，本网站，包括但不限于所有内容、功能、材料和服务均「按AS IS」提供，不提供任何明示或暗示的保证，包括但不限于信息、数据、数据处理服务或不间断服务，信息的可用性、准确性、完整性、有用性或内容的任何保证，以及有关所有权、不侵权、适销性或特定用途适用性的任何保证。信邦不保证网站或由此提供的功能、内容或服务将及时、安全、不间断或无错误，也不保证缺陷将得到纠正。信邦不保证网站将满足使用者的期望或要求。您透过本网站获得的任何建议、结果或信息或数据（无论是口头或书面）均不构成本条款的任何保证。若您不满意本网站，您唯一的补救措施是停止使用本网站。",
    terms_param_5_2:"透过使用本网站下载或以其他方式取得的任何数据均由您自行决定并自行承担风险，并且您将对下载任何此类数据所造成的任何损害承担全部责任。",
    terms_title_6:"责任限制",
    terms_param_6:"在适用法律允许的最大范围内，信邦、其关系企业或其董事、管理人员、员工、代理人、内容或服务的提供者，均不需因使用/误用或无法使用本网站或与之相关的内容、材料和功能而直接或间接相关的任何间接、特殊、偶然、继发、惩戒性或惩罚性损害承担责任，包括，但不限于收入损失，或预期利润，或业务、数据或销售损失，或替代服务成本，即使信邦、其代表已被告知发生此类损害的可能性。上述某些限制可能因某些司法管辖区不允许责任限制或排除而不适用于您。在任何情况下，信邦均不承担任何因本条款或您对本网站的使用而造成的任何损害、损失和诉讼（无论是合约规定或侵权行为，包括但不限于疏忽或其他原因）的责任。",
    terms_title_7:"一般规定",
    terms_param_7_1:"本条款构成您与信邦之间的完整协议，作为您使用本网站的管理，并取代您与信邦之间的任何先前协议。您也可能受到适用于本网站的附加条款和条件的约束。",
    terms_param_7_2:"您同意信邦与您之间不因本条款或您对本网站的使用而存在合资、合伙、雇佣或代理关系。",
    terms_param_7_3:"任何您可能与信邦或本网站间的索赔或诉讼事由必须在索赔或诉讼原因发生后一 (1) 年内提出。",
    terms_param_7_4:"任何信邦未行使或执行的本条款权利或规定，并不构成对该权利或规定的放弃。如本条款的任何规定经有管辖权的法院认为无效，双方仍同意法院应尽力实现该规定中所反映的双方的意图，并且本条款的其他规定仍然有效。",
    terms_param_7_5:"未经我们明确书面同意，您不得转让本条款或本条款项下您的任何权利或义务。",
    terms_param_7_6:"本条款中的章节标题仅为便利起见，不具法律或契约效力。",
    /////////////////////////////////
    //privacy
    /////////////////////////////////
    privacy_date:"最新更新暨生效日期：2024年7月1日",
    privacy_param_0:"本隐私权政策解释了我们收集、储存、使用和传播个人信息的内容和方式。本隐私权政策不仅是我们对客户的隐私权保护原则，也是对我们的供货商和业务合作伙伴的隐私权保护原则。",
    privacy_title_1:"我们收集哪些类型的信息？",
    privacy_title_1_1:"您提供给我们的信息",
    privacy_param_1_1:"当您注册、接受我们的服务或订购产品时，或在向我们提供产品或服务时，或为了职务招聘及人力资源管理目的时，或为了我们的任何信息通讯、或响应我们的调查时，我们会收集您自愿提供的个人信息。我们也可能在与您互动时收集您的姓名、电子邮件地址和组织讯息，以便从您那里获取商品或服务，或以其他方式与您建立关系。",
    privacy_title_1_2:"因业务开展时收集的信息",
    privacy_param_1_2:"当您在我们的网站（「网站」）或我们的任何平台（与该网站一起统称为「平台」）上建立账户，订阅接收我们的及时消息或其他营销信息，或同意向我们提供商品或服务，我们可能会要求您提供您的联络信息。例如，如您是我们的客户，我们将储存您的姓名、地址、电子邮件和电话号码等信息用于填入未来订单的结账信息。当您向我们购买商品时，我们也会收集账单和帐户信息。当您作为客户、潜在客户，供货商、顾问或其他第三方与我们展开业务时，我们可能会处理您的个人信息。例如，我们可能会保存您的业务联络信息和财务帐户信息（如有）以及您与我们之间的其他通讯，以维持我们与您的业务关系。",
    privacy_title_1_3:"电子通讯联系",
    privacy_param_1_3:"如果您在我们的联络页面向我们发送电子邮件或以其他方式上向我们提交讯息，我们将从您那里获取您在电子邮件中的信息内容以及在我们的联络信息页面上包含的信息。我们可能会将此类信息储存在专用于您的活页夹中，该保存将根据我们的保留程序进行。如您在网站上留下评论，您的用户名称、联络电子邮件和发布时间亦可能连同与该评论相关的讯息保存。我们可能会使用这些信息回复您的询问，或提供客户支持服务。",
    privacy_title_1_4:"位置数据",
    privacy_param_1_4:"我们的平台可能会收集有关您所在位置的数据，例如您的 IP 地址的大致位置。除非有您的允许，否则该位置数据将不会以能识别您个人身分的形式匿名收集。我们亦可能因您从特定位置访问本网站而向您发送通知。",
    privacy_title_1_5:"使用数据",
    privacy_param_1_5:"当您使用我们的平台时，我们可能会收集某些使用数据，例如您的网页浏览器、行动装置或操作系统，以便利您使用我们的平台，及优化您使用我们平台的体验。",
    privacy_title_1_6:"透过自动收集工具收集的信息",
    privacy_param_1_6:"在需要您同意的情况下，我们会在取得您同意时使用自动信息收集工具，例如 cookie，其使用目的是透过保存您的偏好使您可以更加便利地使用我们的平台。我们可能会无限期保留这些讯息，但我们不会将这些信息与您提供给我们的任何个人资料加以串联。我们可能允许选定的第三方在平台上放置 cookie，以便我们更了解平台的使用情况或用户人口统计数据，或向您提供相关广告。这些第三方可能在您使用我们的平台时收集有关消费者随着时间的推移和跨不同网站的在线活动的信息。我们无法控制此类第三方 cookie 的使用或由此产生的信息，也不对这些第三方的任何行为或政策负责。根据适用法律的要求，我们会事先征求您的同意以使用 cookie 或其他追踪技术。您可以随时更新或撤回此同意。欲了解更多信息，请参阅我们的 <a href='/cookie-notice'>Cookie 声明</a>。",
    privacy_param_1:"您没有义务并可拒绝向我们提供任何个人信息，但如果您不向我们提供所需的个人信息，我们可能无法向您提供我们的产品或服务、从您那里获取产品或服务、或以其他方式与您互动。",
    privacy_title_2:"我们如何使用您的信息？",
    privacy_param_2:"我们可能因各式目的需要使用自您收集到的相关信息，我们使用时将符合适用法律和法规的规定。以下是我们可能使用您的信息的例示：",
    privacy_param_2_list:[
        "按您需求提供我们的产品或服务或向您接收产品或服务；",
        "执行我们的使用条款或与您达成的协议，或遵守我们在此类协议下的义务（例如，支付款项）；",
        "回答您有关我们或我们的产品或服务的可能问题，或询问有关您提供的产品或服务的问题；",
        "优化我们的产品、服务和平台；",
        "告知您我们认为您可能感兴趣的产品、服务、促销和优惠；",
        "进行一般商业行为，包括改善客户服务、帮助我们开发和改进产品和服务的功能、更好地了解我们的客户、促进和衡量营销和促销、防止不当行为以及管理我们的业务；",
        "通知您我们平台的更新；",
        "筹办或进行促销活动；",
        "进行其他营销和商业活动；",
        "管理供货商；",
        "进行人员招募、面试活动以及录取程序；及促进和维护平台信息安全、管理和合法合规。"
    ],
    privacy_title_3:"我们会向谁披露您的信息？",
    privacy_param_3:"任何有关您的信息揭露将在取得您的允许后披露，但有以下状况时，我们得不需取得您许可进行披露：",
    privacy_param_3_list:[
        "我们可能会与信邦集团的其他关系企业、成员分享您的个人信息，例如，如果您向整个集团提供商品或服务。",
        "我们可能会披露有关您的信息以帮助您完成交易，包括与我们操作平台或完成、确认您与我们进行的交易相关信息。当我们将您的个人识别数据转移给我们的服务方时，我们会要求服务方：(a) 仅得使用此类信息来执行我们要求的服务、(b) 遵守本隐私权政策、(c) 根据适用法律要求为所收受的信息提供充足保护。",
        "我们得不受限制地揭露已不具可识别个人信息的汇总或匿名数据的结果。您的任何个人信息将确保不会在此情况下被揭露。",
        "当信邦全部或大部分资产或业务或其任何业务线有实际或潜在合并、收购或其他出售或转让时，我们可能会向一个或多个第三方披露获得的有关您的信息。我们无法保证收到此信息的本项交易第三方将遵守本隐私权政策。",
        "为接受相关专业服务并在遵守标准保密义务下，我们可能会提供予我们的审计师、法律顾问和类似第三方所收集到的有关您的资料。我们也可能因我们涉及的任何实际、潜在或预期的法律诉讼分享您的个人信息。",
        "在特定情况且有法律义务时，我们可能会向政府或第三方披露有关您的信息，例如我们平台的涉嫌涉及非法活动、响应传票或法院命令或其他法律程序。我们将尽力确保在必要且适当的情况下向执法部门、相关政府官员和监理机构提供自我们的平台收集的信息。",
        "我们也可能因i)执行我们平台的使用条款、ii) 调查潜在的违规行为、iii) 侦测、预防或以任何方式处理诈欺、信息安全或平台技术问题、或iv)预防我们平台受到损害，而向第三方揭露有关您的信息。"
    ],
    privacy_title_4:"我们采取哪些安全措施保护有关您的信息？",
    privacy_param_4:"我们采取商业上必要且合理的措施来储存所收集的数据、数据，以防止未经授权的存取及确保所收集的的资料隐私受到保护。当我们传输和接收敏感信息（例如财务信息）时，我们会将访客导向到安全服务器以传输数据。然而，请注意，尽管我们致力遵循本隐私权政策，但没有任何技术是完全安全的也无法保证数据传输过程完全安全。因此，尽管我们将采取合理的措施来保护您提供的所有信息的安全性和完整性，但信邦不能保证任何此类信息，无论是在传输过程中还是在我们的系统上储存或以其他方式由我们保管时，都会是绝对安全，不会受到未经您授权的其他人的入侵。任何此类传输或共享的风险均由您自行承担，并由您确保您发送给我们的任何个人资料均安全发送。",
    privacy_title_5:"我们的平台支持「请勿追踪」讯号吗？",
    privacy_param_5:"我们未使用得以识别您的网络浏览器发出的「请勿追踪」讯号的技术。您可以透过启动您浏览器上允许您拒绝接受cookies 的设定来拒绝cookies、于首次造访平台时拒绝同意放置 cookies、或随后撤回此类同意。然而，如果您选择停用 cookies，您可能无法使用我们平台的部分功能。",
    privacy_title_6:"连结本网站的其他第三方的隐私政策为何？",
    privacy_param_6:"本隐私政策仅适用于信邦对所获得的信息的使用和揭露的管理和保护。您应当注意，当您使用我们的平台，您可能会被引导至我们无法控制的其他应用程序或网站，而信邦不对第三方的隐私政策负责。我们预期大多数这些第三方将在您使用他们的服务时收集有关您的信息。然而，无论第三方是否或以任何方式连接到本平台，信邦不对第三方的隐私政策提供担保。我们鼓励您在与任何第三方网站、应用程序或其他产品互动时阅读其发布的隐私权政策。",
    privacy_title_7:"我们的平台对儿童使用者的政策是什么？",
    privacy_param_7:"我们不会故意收集或保存13 岁以下人士的个人性识别资料，我们的平台也非供13 岁以下的人士使用。如果您未满 13 岁，请不要在任何时候或以任何方式使用我们的平台。如果您得知您 13 岁以下的孩子未经您同意向我们提供了个人信息，请与我们联系。",
    privacy_title_8:"您的加州消费者隐私法权益",
    privacy_param_8:"加州法允许居住在加州的客户在基于个人、家庭或家用目的与信邦进行交易，且当信邦分享揭露该等加州客户的信息予第三方用以营销产品或服务时，得以要求被告知有此类揭露情形或选择退出。信邦并未出于第三方的直接营销目的与第三方分享此类信息。",
    privacy_title_9:"个人资料的跨境传输",
    privacy_param_9_1:"请注意，我们在欧盟和英国以外的区域运营，因此，信邦自欧盟和英国收集的个人资料将可能在无法提供等同欧盟和英国个人资料保护的司法管辖区进行处理。如我们将此类个人资料传输至未及GDPR保护水平的司法管辖区时，我们将以欧盟标准条款与接受方签署协议以采取适当的保障措施保障该等数据。",
    privacy_param_9_2:"我们将依据下述法律允许依据处理平台与您相关的个人信息：",
    privacy_param_9_list:[
        "<strong>合法权益。</strong>在多数情况下，我们处理您的个人信息的依据是这样做是否符合我们的合法商业利益。我们的合法商业利益规范于「我们如何使用您的信息？」的 (c) – (k) 中。如果您代表您的雇主购买、销售或提供产品或服务，这也将是我们处理您的个人信息的合法依据。",
        "<strong>同意。</strong>我们可能在您同意的情况下处理您的个人信息。当我们在您同意的基础上处理您的个人讯息，您可以随时撤回该同意。",
        "<strong>执行合约。</strong>为履行我们与您之间的合约、行使我们在合约下的权利或在与您签订合约之前根据您的要求采取措施，我们将可能于必要时处理您的个人信息。本项将是我们向您提供您订购的产品或服务、执行我们的使用条款、回答您对信邦、我们的产品或我们的服务问题时的法律依据。",
        "<strong>遵守法律义务。</strong>为了遵守法律义务，我们可能会在必要时，例如为了防止诈欺，而处理您的个人信息。"
    ],
    privacy_title_10:"您的合法权利",
    privacy_param_10:"根据适用法律，您可能有权：",
    privacy_param_10_list:[
        "请求获取我们所处理的您的个人信息；",
        "立即纠正我们持有的任何有关您个人信息的不正确处；",
        "要求删除所持有的有关您的个人信息；",
        "要求我们在不删除的情况下，禁止或限制处理您的个人信息；和在技​​术可行的情况下，请我们将您的个人信息直接传输给第三方"
    ],
    privacy_param_10_1:"您可以透过「如何联络我们？」与我们联络来行使任何这些权利。我们将在 1个月内回复您提出的请求，但如有必要，我们可将该期限再延长2个月。",
    privacy_param_10_2:"如果您认为我们未遵守本隐私权政策或适用法律规定的义务，您有权向相关当局提出申诉。我们希冀您先直接与我们联系、解决，许多数据保护机构亦要求您在提出申诉之前这么做。",
    privacy_title_11:"保存期间",
    privacy_param_11:"我们得以在您继续使用本平台、在我们这里拥有账户，或为实现本隐私权政策目的所需的期间内尽可能保存您的个人信息。您可以透过本政策约定联络我们要求关闭您的账户，我们会根据要求删除您的个人信息。然而，我们可能会基于法律、税务或监管需要，或正当合法的商业目的，按照适用法律所允许或要求的规定额外保留您的个人信息一段时间。",
    privacy_title_12:"有关本隐私政策的变更条款是什么？",
    privacy_param_12:"我们保留随时更新本隐私政策的权利。当我们进行更改时，我们将在此页面上发布更新的隐私政策以及更新的生效日期。请定期造访此页面，以便您实时收到任何变更。",
    privacy_title_13:"您如何联络我们？",
    privacy_param_13_1:"如果您对本隐私政策有任何疑问，或因任何其他原因需要联络我们，您可以透过以下任一方式与我们联络：",
    privacy_param_13_2:[
        "电子邮件：<a href='mailto:service@sinbon.com'>service@sinbon.com</a> 或透过 <a href='tel:886226989999'>+886-2-26989999</a> 致电我们",
        "邮寄：<br>信邦电子股份有限公司<br>台湾新北市汐止区新台五路一段79号4楼之13<br>收件人 法务处"
    ],
    /////////////////////////////////
    //recommends
    /////////////////////////////////
    recommends_news:"相关新闻",
    recommends_products:"相关产品",
    recommends_solutions:"相关解决方案",
    recommends_casestudy:"相關案例分享",
    recommends_blog:"相關文章",
    /////////////////////////////////
    //information
    /////////////////////////////////
    information_content:'本网站使用cookies协助我们在您使用网站时提供良好的使用者体验，亦让我们分析网站流量数据。按下「接受 Cookies」后即表示您同意我们使用cookies。',
    information_btn:"接受 Cookies",
    /////////////////////////////////
    //integrity
    /////////////////////////////////
    integrityContact_title:'违反从业道德行为举报',
    integrityContact_des:'检举人应透过信邦正常管道反应问题，因检举案件受理涉及贪渎不法情事，必须秉持公平正义原则，小心求证，勿枉勿纵，请严肃谨慎待之。<br>为能尽早调查与解决问题，请您务必提供可能的相关具体资讯与文件，若相关资讯与文件不齐备时，我们将无法进行调查。<br><br>信邦将依照个人资料保护法规定，以保密方式处理，保护检举人之身分，并采行适当之安全措施，防止个人资料被窃取、窜改、毁损、灭失或泄漏。请您自行输入个人资料，并同意信邦：<br><ul><li>・于符合个人资料保护法的相关规定下，使用您的个人资料。</li><li>・于相关业务管理及执行等范围内，对于您的相关个人资料之蒐集、处理或利用。</li><li>・以您所提供的个人资料，确认您的身分，进行联络及提供相关服务。</li></ul><p>请提供以下资讯：</p>',
    integrityContact_btn_start:'进行举报',
    integrityContact_label_relation:'您与信邦的关係？',
    integrityContact_option_please_select:'请选择',
    integrityContact_option_relation_1:'供应商',
    integrityContact_option_relation_2:'客户',
    integrityContact_option_relation_3:'其他(请说明)',
    integrityContact_relation_placeholder:'请说明您与信邦的关係',
    integrityContact_label_info:'个人资料(非必填)',
    integrityContact_label_name:'姓名',
    integrityContact_label_phone:'联络电话',
    integrityContact_label_email: '电子邮件',
    integrityContact_label_identify: '请列出涉入违规案件的信邦人员',
    integrityContact_label_who:'姓名',
    integrityContact_label_unit:'单位',
    integrityContact_label_position:'职称',
    integrityContact_label_violate:'请说明违规行为',
    integrityContact_option_violate_1:'向本公司之交易对象要求、期约、索取、收受任何不正当利益',
    integrityContact_option_violate_2:'藉职务上之关係要求本公司之交易对象馈赠佣金、酬金或其他不正当利益，唯年节礼品往来且价值不高于新台币一千元者，不在此限',
    integrityContact_option_violate_3:'于本公司任职期间持有与本公司交易对象之未上市柜股份；唯于公开市场上买卖上市柜股票则不在此限',
    integrityContact_option_violate_4:'有任何贪汙、受贿、挪用、侵佔其负责保管或使用之本公司财产',
    integrityContact_option_violate_5:'其他(请具体描述)',
    integrityContact_violate_placeholder:'请具体描述违规行为',
    integrityContact_label_how:'您如何得知此违规行为？',
    integrityContact_option_how_1:'亲身经历',
    integrityContact_option_how_2:'同事或亲友的经历',
    integrityContact_option_how_3:'藉由相关证据推测得知',
    integrityContact_option_how_4:'传闻',
    integrityContact_option_how_5:'其他(请说明)',
    integrityContact_how_placeholder:'请说明您如何得知此违规行为',
    integrityContact_label_time:'事件具体的或发生的大致时间',
    integrityContact_label_where:'事件发生的地点',
    integrityContact_label_how_long:'您认为此事件发生的持续期间？',
    integrityContact_label_year: '年',
    integrityContact_label_month: '月',
    integrityContact_label_reason:'您认为这个问题是如何发生的？',
    integrityContact_option_reason_1:'信邦员工主动要求/暗示',
    integrityContact_option_reason_2:'供应商主动提供但信邦员工未拒绝 ',
    integrityContact_option_reason_3:'透过第三方人员媒介',
    integrityContact_option_reason_4:'其他(请具体描述)',
    integrityContact_reason_placeholder:'请描述您认为事件的发生原因',
    integrityContact_label_others:'请提供本案件的细节说明及任何相关有价值之资料',
    integrityContact_label_upload:'如果您有支援此案件的文件或档案，请上传',
    integrityContact_ps:'档案须为下列格式: PDF, DOC, DOCX, XLS, XLSX, PPT, PPTX, HTML, TXT, CSV, JPG, PNG，档案大小限5MB内',
    integrityContact_checkbox_des:'提供个资代表您同意本公司蒐集、处理及利用您的个资以提供相关服务。参阅我们的<a href="../../privacy" target="_blank">隐私政策</a>以取得更多资讯。',
    integrityContact_btn_send:'送出',
    /////////////////////////////////
    //integrity remind
    /////////////////////////////////
    integrityContact_alert_1:"请选择您与信邦的关係",
    integrityContact_alert_2:"请说明您与信邦的关係",
    integrityContact_alert_3:'请填写电话',
    integrityContact_alert_4:'请填写违规案件之信邦人员姓名',
    integrityContact_alert_5:'请填写违规案件之信邦人员单位',
    integrityContact_alert_6:'请填写违规案件之信邦人员职称',
    integrityContact_alert_7:'请选择违规行为',
    integrityContact_alert_8:'请选择如何得知此违规行为',
    integrityContact_alert_9:'请填写事件发生的大致时间',
    integrityContact_alert_10:'请填写事件发生的地点',
    integrityContact_alert_11:'请填写您认为事件发生的持续时间',
    integrityContact_alert_12:'请填写您认为事件的发生原因',
    integrityContact_alert_13:'请勾选我已充分了解且同意上述说明',
    integrityContact_alert_14:'档案请小于5MB',
    integrityRemind_des:'信邦秉持「诚信合法经营、恪遵职业道德」，致力遵循各项公司治理法规。同仁于从事与公司业务行为之过程中，不得直接或间接提供、承诺、要求或收受任何不正当利益，或做出其他违反诚信、不法或违背受托义务等不诚信行为。信邦鼓勵内部及外部人员检举不合法、不道德及不诚信行为，若您有任何发现，请主动告知我们，我们将会有专员与您联络。<br>除法律另有规定外，我们将以保密方式处理检举案件，并由独立管道查证，全力保护检举人之身分、个人资料及隐私。<br><br>本公司虽并不要求提供所欲举报事项以外的更多资讯，惟评估举报内容之重要资讯包括：<br><ul><li>・您的姓名及联系资料，包含电话、邮件地址、电子邮箱地址及与本公司的关系，主为于举报调查期间，得为调查举报事项与联系用途而处理利用。</li><li>・检举案相对人的姓名或其他足资识别其身分特征之资料。</li><li>・可供调查的具体事证。</li></ul>有下列各款情形之一者，信邦得迳予回复不予受理并结案：<br><ul><li>・未依前项规定提供资料。</li><li>・检举内容涉有恶意攻讦、虚伪不实、无具体内容、非属违反法令者。</li><li>・检举内容显无具体事证可供核查。</li><li>・同一相对人之被检举情事，业经信邦查证或已结案，再次检举者。但检举人已提出新事证证明该案有重新调查之必要者，不在比限。</li></ul><p>除依法律规定要求外，您不得将调查过程中与信邦之间的联络内容及资讯向第三方揭露，或以该联络内容作为诉讼相关资料。</p>',
    integrityRemind_checkbox_des:'我已充分了解且同意上述说明',
    /////////////////////////////////
    //cookie notice
    /////////////////////////////////
    cookieNotice_title:"Cookie Notice",
    cookieNotice_date:"Last updated and effective as of: July 1, 2024",
    cookieNotice_param_0_1:"SINBON respects your privacy and values the relationship we have with you.  This Cookie Notice explains our use of cookies and similar tracking technologies on our website (“Website”) and our online Web Shop (“Web Shop,” together with the Website, our “Platforms”).",
    cookieNotice_param_0_2:"We may obtain information about your general internet usage by using “cookies”.  A cookie is a small text file that’s passed to your computer’s hard disk through your web browser, so the website remembers who you are when you return.  Cookies are widely used to make websites work or work more efficiently (our Platforms won’t work without some of the cookies we use).  They also provide useful information to website owners.  Other, similar tracking technologies exist (such as pixels).  For the purposes of this Cookie Notice, reference to cookies should be read to include these similar technologies.",
    cookieNotice_title_1:"What are essential and non-essential cookies?",
    cookieNotice_param_1_1:"Cookies that are strictly necessary for our Platforms to work are called “essential” cookies.  These are placed when you land on the relevant page on the Platform, and the law states that we do not need your consent to place these.",
    cookieNotice_param_1_2:'However, all other cookies are known as “non-essential” cookies.  We will only place non-essential cookies where you have given us your explicit consent (e.g.  by clicking ["Allow all cookies"] or ["Allow selection"] on our cookie banner).',
    cookieNotice_title_2:"What are session and persistent cookies?",
    cookieNotice_param_2_1:"Cookies can also be categorised as follows:",
    cookieNotice_param_2_list:[
        "The first type is known as a “session-based” cookie and is allocated to your device only for the duration of your visit to our Platform.  It helps you to move around the Platform faster.  This cookie automatically expires when you close down your browser.",
        "The second type is a “persistent” cookie.  These cookies store information between visits.",
    ],
    cookieNotice_param_2_2:"A full list of cookies placed by our Platforms is included in this Cookie Notice as an.",
    cookieNotice_title_3:"When will SINBON place cookies?",
    cookieNotice_param_3_1:"When you land on the relevant page on our Platforms, you will be presented with a cookie banner, asking for your permission for the Platform to place non-essential cookies.  We will only place such cookies when you have expressly given your consent for this.  You can change or withdraw your consent for our Platforms to place such cookies at any time by updating your preferences below.",
    cookieNotice_param_3_2:"You can also check your browser’s instructions for information on how to block all cookies.  However, if you block all cookies (including essential cookies) you may not be able to access all or certain parts of our Platform.  Unless you’ve set your browser to refuse cookies, our system will issue essential cookies as soon you visit our Platforms.",
    cookieNotice_param_3_3:"If you want to delete any cookies that are already on your computer, you should check the instructions for your file management software to locate the file or directory that stores cookies.",
    cookieNotice_title_4:"What other types of technology are used?",
    cookieNotice_param_4_1:"As well as cookies, we may use other technologies to recognize and track your use of our Platforms.  A web beacon (also known as a “tracking pixel” or “clear GIF”) is a clear graphic image (typically a one-pixel tag) that is delivered through a web browser or HTML e-mail, typically in conjunction with a cookie.",
    cookieNotice_param_4_2:"Web beacons may allows us, for example, to monitor how users move from one page within our Platforms to another, to track access to our communications, to understand whether users have come to our Platforms from an online advertisement displayed on a third-party website, to measure how ads have been viewed and to improve site performance.",
    cookieNotice_title_5:"Where can I find more information?",
    cookieNotice_param_5:"For more information about deleting and controlling cookies visit <a href='https://ico.org.uk/your-data-matters/online/cookies/' target='_blank'>https://ico.org.uk/your-data-matters/online/cookies/</a>, <a href='https://www.youronlinechoices.org/' target='_blank'>www.youronlinechoices.com</a> or <a href='https://www.aboutcookies.org/' target='_blank'>www.aboutcookies.org</a>.",
    cookieNotice_title_6:"How can I update my preferences?",
    cookieNotice_param_6_1:"For more information about updating your cookie preferences please visit the websites shown below depending upon which browser you are using.",
    cookieNotice_param_6_2:"How to manage cookies in:",
    cookieNotice_param_6_list:[
        "Internet Explorer: <a href='https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10' target='_blank'>https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10</a>",
        "Firefox: <a href='https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectslug=Cookies&redirectlocale=en-US' target='_blank'>https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectslug=Cookies&redirectlocale=en-US</a>",
        "Chrome: <a href='https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666' target='_blank'>https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666</a>",
        "Safari: <a href='https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac' target='_blank'>https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac</a>",
        "IOS: <a href='https://support.apple.com/en-us/HT201265' target='_blank'>https://support.apple.com/en-us/HT201265</a>"
    ],
    cookieNotice_param_6_3:'In addition, you may withdraw or adjust your consent preferences by clicking on the ["Manage consent"] icon located [on the bottom left-hand corner of the browser].',
    cookieNotice_title_7:"Appendix – cookies and similar technologies used by our Platform.",
    /////////////////////////////////
    //cookie setting
    /////////////////////////////////
    cookieSetting_title:"隐私权设定",
    cookieSetting_block1_title:"必要的 Cookies",
    cookieSetting_block1_desc:"这些 Cookie 为网站运作所必需，且您不能在系统中将其关闭。通常这些 Cookie 设定是用来反应您所做出的操作或服务请求，如隐私偏好设定、保持登入或自动填入。您可以将您的浏览器设置为禁用或向您警示这些 Cookie ，但可能会导致某些网站功能无法运作。这项技术不会储存任何个人身份信息。",
    cookieSetting_block2_title:"功能性 Cookies",
    cookieSetting_block2_desc:"这些 Cookie 使网站能够提供附加的功能和个人化服务，它们可能由我们或网站页面上提供各项服务的第三方供货商所设置。 如果您不允许这些 Cookie ，则这些服务可能部分或全部将无法正常运作。",
    cookieSetting_block3_title:"效能性 Cookies",
    cookieSetting_block3_desc:"使用这类 Cookie 我们可以计算网站流量和流量来源，以便衡量和提高我们网站的效能。 Cookie 有助于我们了解哪些页面最受欢迎、哪些最不受欢迎，并查看访问者如何浏览网站。这些 Cookie 收集的所有信息都汇整在一起，并以去识别化方式处理。如果您不允许使用这些 Cookie ，我们将不知道您何时访问了我们的网站，也无法监控其效能。",
    cookieSetting_btn_save:"储存设定",
    cookieSetting_btn_accept_all:"接受全部",
    cookieTip_btn_setting:"设定",
    cookieTip_btn_accept:"接受",
    cookieTip_content:"本网站使用cookies协助我们在您使用网站时提供良好的使用者体验，亦让我们分析网站流量数据。按下「接受」后即表示您同意我们使用cookies。"
}

export default lang