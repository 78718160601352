//存放 action 函式
import * as types from './mutations_type.js'
import axios from 'axios';
import apibase from "@/static/apibase.js";
import {readCookie} from "@/libs/js/cookie.js";

export const checkLang = ({ commit }) => {
    commit(types.CHECK_LANG);
}

export const setLang = ({ commit }, lang) => {
    commit(types.SET_LANG,lang);
}

export const fetchNavigation = ({ commit }) => {
  axios({
    method: 'get',
    baseURL: apibase,
    url: 'navigation'
  })
  .then((response) => {
    var result = response.data.items;
    commit(types.FETCH_NAVIGATION, result);
  })
  .catch((error) => {
    console.log(error);
  });
}

export const fetchProductCategory = ({ commit }) => {
  

  axios({
    method: 'get',
    baseURL: apibase,
    url: 'product/categories'
  })
  .then(function (response) {
    var result = response.data.rows;
    commit(types.FETCH_PRODUCT_CATEGORY, result);
  })
  .catch(function (error) {
    console.log(error);
  });
}

export const fetchProductItems = ({ commit }) => {
  

  axios({
    method: 'get',
    baseURL: apibase,
    url: 'product/items',  
  })
  .then(function (response) {
    var result = response.data.rows;
    commit(types.FETCH_PRODUCT_ITEMS,result);
  })
  .catch(function (error) {
    console.log(error);
  });
}

export const fetchSolutionCategory = ({ commit }) => {
  

  axios({
    method: 'get',
    baseURL: apibase,
    url: 'solution/categories'
  })
  .then(function (response) {
    var result = response.data.rows;
    commit(types.FETCH_SOLUTION_CATEGORY, result);
  })
  .catch(function (error) {
    console.log(error);
  });
}

export const fetchSolutionItems = ({ commit }) => {
  axios({
    method: 'get',
    baseURL: apibase,
    url: 'solution/items'
  })
  .then(function (response) {
    var result = response.data.rows;
    commit(types.FETCH_SOLUTION_ITEMS, result);
  })
  .catch(function (error) {
    console.log(error);
  });
}

export const fetchSolutionDetail = ({ commit },slug) => {
  axios({
    method: 'get',
    baseURL: apibase,
    url: 'solution/detail/'+slug
  })
  .then(function (response) {
    commit(types.FETCH_SOLUTION_DETAIL, response.data.row);
  })
  .catch(function (error) {
    console.log(error);
  });
}

export const fetchCaseStudyCategory = ({ commit }) => {
  axios({
    method: 'get',
    baseURL: apibase,
    url: 'casestudy/categories'
  })
  .then(function (response) {
    var result = response.data.rows;
    commit(types.FETCH_CASE_STUDY_CATEGORY, result);
  })
  .catch(function (error) {
    console.log(error);
  });
}
export const fetchCaseStudyItems = ({ commit }) => {
  axios({
    method: 'get',
    baseURL: apibase,
    url: 'casestudy'
  })
  .then(function (response) {
    var result = response.data.rows;
    commit(types.FETCH_CASE_STUDY_ITEMS, result);
  })
  .catch(function (error) {
    console.log(error);
  });
}
export const fetchCaseStudyDetail = ({ commit },slug) => {
  axios({
    method: 'get',
    baseURL: apibase,
    url: 'casestudy/detail/'+slug
  })
  .then(function (response) {
    commit(types.FETCH_CASE_STUDY_DETAIL, response.data.row);
  })
  .catch(function (error) {
    console.log(error);
  });
}



export const fetchBlogLabel = ({ commit }) => {
  axios({
    method: 'get',
    baseURL: apibase,
    url: 'article/labels'
  })
  .then(function (response) {
    var result = response.data.rows;
    commit(types.FETCH_BLOG_LABEL, result);
  })
  .catch(function (error) {
    console.log(error);
  });
}
export const fetchBlogItems = ({ commit }) => {
  axios({
    method: 'get',
    baseURL: apibase,
    url: 'article'
  })
  .then(function (response) {
    var result = response.data.rows;
    commit(types.FETCH_BLOG_ITEMS, result);
  })
  .catch(function (error) {
    console.log(error);
  });
}
export const fetchBlogDetail = ({ commit },slug) => {
  axios({
    method: 'get',
    baseURL: apibase,
    url: 'article/detail/'+slug
  })
  .then(function (response) {
    console.log(response)
    commit(types.FETCH_BLOG_DETAIL, response.data.row);
  })
  .catch(function (error) {
    console.log(error);
  });
}



export const fetchWebinarBanner = ({ commit }) => {
  axios({
    method: 'get',
    baseURL: apibase,
    url: 'webinarbanner/items'
  })
  .then(function (response) {
    var result = response.data.rows;
    commit(types.FETCH_WEBINARBANNER, result);
  })
  .catch(function (error) {
    console.log(error);
  });
}

export const fetchWebinarCategory = ({ commit }) => {
    

    axios({
      method: 'get',
      baseURL: apibase,
      url: 'webinarreport/categories'  
    })
    .then(function (response) {
      var result = response.data.rows;            
      commit(types.FETCH_WEBINAR_CATEGORY, result);
    })
    .catch(function (error) {
      console.log(error);
    });
}

export const fetchWebinarItems = ({ commit }) => {
  
  
  axios({
    method: 'get',
    baseURL: apibase,
    url: 'webinarreport/items'  
  })
  .then(function (response) {
    var result = response.data.rows;
    commit(types.FETCH_WEBINAR_ITEMS, result);
  })
  .catch(function (error) {
    console.log(error);
  });
}

export const fetchNewsCategory = ({ commit }) => {
  
  
  axios({
    method: 'get',
    baseURL: apibase,
    url: 'news/categories'
  })
  .then(function (response) {
    var result = response.data.rows;
    commit(types.FETCH_NEWS_CATEGORY, result);
  })
  .catch(function (error) {
    console.log(error);
  });
}

export const fetchBrandCategory = ({ commit }) => {
  
  axios({
    method: 'get',
    baseURL: apibase,
    url: 'brand/categories'
  })
  .then(function (response) {
    var result = response.data.rows;
    commit(types.FETCH_BRAND_CATEGORY, result);
  })
  .catch(function (error) {
    console.log(error);
  });
}


//should be removed
// export const fetchCsrPage=({commit}) => {
//   axios({
//     method: 'get',
//     baseURL: apibase,
//     url: 'csrpage/items'
//   })
//   .then(function (response) {
//     var result = response.data.rows;
//     var _result=[];
//     for(var k in result){
//       if(Number(result[k].id)<=6){
//         _result.push(result[k]);
//       }
//     }
//     commit(types.FETCH_CSR_PAGE, _result);
//   })
//   .catch(function (error) {
//     console.log(error);
//   });
// }



export const fetchEsgPage=({commit}) => {
  axios({
    method: 'get',
    baseURL: apibase,
    url: 'csrpage/items'
  })
  .then(function (response) {
    var result = response.data.rows;
    var _result=[];
    for(var k in result){
      if(Number(result[k].id)>=8){
        _result.push(result[k]);
      }
    }
    commit(types.FETCH_ESG_PAGE, _result);
  })
  .catch(function (error) {
    console.log(error);
  });
}

export const checkSession = ({ commit }, pages) => {
  commit(types.CHECK_SESSION, pages);
}

export const login=({ commit }, data) => {
  commit(types.LOGIN, data);
}

export const logout = ({ commit }) => {
  commit(types.LOGOUT);
}

export const setLoadingStatus=({ commit }, status) => {
  commit(types.TOGGLE_LOADING, status);
}

export const setSelectedContactTopic = ({ commit }, query) => {
  // console.log('setContactQuery query',query)
  commit(types.SET_SELECTED_CONTACT_TOPIC, query);
}

export const setWebinarContactQuery = ({ commit }, query) => {
  commit(types.SET_WEBINAR_CONTACT_QUERY, query);
}

export const setWebinarRegisterStatus = ({ commit }, status) => {
  commit(types.SET_WEBINAR_STATUS, status);
}

export const setCookieAllowStatus = ({ commit }, status) => {
  commit(types.SET_COOKIE_ALLOW_STATUS, status);
}