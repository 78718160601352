<template>
	<div>
		<section class="cover imagebg text-left space--sm" data-overlay="6">
            <div class="background-image-holder">
                <img alt="background" :src="$data.siteUrl + '/img/banner-investors.jpg'" />
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1 v-html="$t('investor_bussiness_banner_title')">投資人專區</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}">首頁</router-link>
                    </li>
                    <li v-html="$t('nav_investor')">投資人專區</li>
                    <li v-html="$t('nav_investorBusiness')">公司營運概況</li>
                </ol>
            </div>
        </section>
        

        <section class="cover switchable" v-if="overviewData">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-lg-5 col-md-7">
                        <div class="switchable__text" v-if="overviewData.file_1">
                            <!-- <h1>{{overviewData.name}}</h1> -->
                            <!-- <a class="btn btn--primary type--uppercase" :href="overviewData.youtube_download_url">
                                <span class="btn__text" v-html="$t('ui_downloadVideo')">下載影片</span>
                            </a> -->
                            <h1 class="mb-0" v-html="$t('investor_bussiness_overview_title')">季度營運概況</h1>
                            <p v-html="$t('investor_bussiness_overview_desc')">Quarterly Business Performance Presentation</p>
                            <a class="btn btn--primary type--uppercase" :href="overviewData.file_1" target="_blank">
                                <span class="btn__text" v-html="$t('ui_download')">下載PDF</span>
                            </a>
                        </div>
                    </div>
                    <div v-if="overviewData.youtube_url" class="col-lg-6 col-md-4 col-12">
                        <div class="video-cover border--round box-shadow-wide reveal-video">
                            <div class="background-image-holder" style="background: url(&quot;/img/inner-4.jpg&quot;); opacity: 1;">
                                <img alt="image" :src="$data.siteUrl + '/img/inner-4.jpg'" />
                            </div>
                            <div class="video-play-icon"></div>
                            <iframe :data-src="'https://www.youtube.com/embed/'+ytUrl(overviewData.youtube_url)+'?autoplay=1&rel=0'" allowfullscreen="allowfullscreen" :src="'https://www.youtube.com/embed/'+ytUrl(overviewData.youtube_url)+'?autoplay=1&rel=0'"></iframe>
                        </div>
                    </div>
                    <div v-else class="col-lg-6 col-md-4 col-12">
                        <img class="border--round box-shadow-wide" :src="$data.siteUrl + '/img/business.jpg'" />
                    </div>
                </div>
                <!--end of row-->

            </div>
            <!--end of container-->
        </section>
        <section v-if="overviewData">
            <div class="container ">
                <div class="row">
                    <div class="col-12 col-md-6" v-if="overviewData.file_2">
                       <!-- <div class="boxed border">
                            <p class="mb-2 h4" v-html="$t('investor_bussiness_overview_title')">季度營運概況簡報</p>
                            <a :href="overviewData.file_1" target="_blank" v-html="$t('ui_download')">下載PDF</a>
                       </div> -->
                       <div class="boxed border ">
                            <p class="mb-2 h4" v-html="$t('investor_bussiness_lawMeeting_title')">最新年度法說會簡報</p>
                            <a :href="overviewData.file_2" target="_blank" v-html="$t('ui_download')">下載PDF</a>
                       </div>
                    </div>
                    <div class="col-12 col-md-6" v-if="overviewData.youtube_download_url">
                       <!-- <div class="boxed border ">
                            <p class="mb-2 h4" v-html="$t('investor_bussiness_lawMeeting_title')">最新年度法說會簡報</p>
                            <a :href="overviewData.file_2" target="_blank" v-html="$t('ui_download')">下載PDF</a>
                       </div> -->
                       <div class="boxed border ">
                            <p class="mb-2 h4" v-html="$t('investor_bussiness_video_title')">最新年度法說會影音</p>
                            <a :href="overviewData.youtube_download_url" target="_blank" v-html="$t('ui_downloadVideo')">下載影片</a>
                       </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    import { mapGetters } from "vuex";
    // import {readCookie} from "@/libs/js/cookie.js"
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
	export default {
        data:function(){
            return {
                overviewData:null,
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        methods:{
            ...mapGetters(["getLang"]),
            ytUrl:function(url){
                var _url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
                //return (_url[2] !== undefined) ? _url[2].split(/[^0-9a-z_\-]/i)[0] : _url[0];
                //return (_url[2] !== undefined) ? _url[2].split(/[^0-9a-z_\-]/i)[0] : _url[0];
                return _url[0];
            },
            fetchData:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'invcomoperate/items',
                    
                })
                    .then(function (response) {
                        _this.overviewData=response.data.rows[0];
                        // _this.$window.initAll();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        created() {
            setMetaInfo( this, this.$t('page_title_investor_business_overview'), undefined, undefined, undefined,false,true );
            this.fetchData();
        },
    };

</script>