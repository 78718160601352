<template>
	<div>
		<section>
            <div class="container">
                <h2 v-html="info"></h2>
            </div>
        </section>

        <section class="bg--secondary" v-if="categories&&items">
            <div class="container">
                <div class="row same-height-col">
                    <div class="col-md-4" v-for="category in categories.rows">
                        <div class="feature boxed boxed--lg boxed--border p-4">
                            <h5 class="h3 mb-4">{{category.category_name}}</h5>
                            <div class="row" v-for="item in filteredRows(items.rows,category.category_slug,'category_slug')">
                               <div class="col">{{item.name}}</div>
                               <div class="col text-right"><a :href="item.link" target="_blank" v-html="$t('career_job_view')">瀏覽職缺</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    // import {readCookie} from "@/libs/js/cookie.js"
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
	export default {
        data:function(){
            return{
                info:null,
                items:null,
                categories:null,
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        methods:{
            filteredRows: function(rows,cc,slug){
                var c = cc.toLowerCase();
                return ( c.trim() !== 'all' ) ?
                    rows.filter(function(d){ return d[slug].toLowerCase().indexOf(c) > -1; }) :
                    rows;
            },
            fetchInfo:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'csrrecruit/info',
                    
                })
                    .then(function (response) {
                        _this.info=response.data.rows[0].description;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchItems:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'csrrecruit/items',
                    
                })
                    .then(function (response) {
                        _this.items=response.data;
                        // _this.$window.initAll();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchItemsCategory:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'csrrecruit/categories',
                    
                })
                    .then(function (response) {
                        _this.categories=response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            fetchSapJobReqQuestion(){
                // console.log('fetchSapJobReqQuestion');
                var data = null;

                var xhr = new XMLHttpRequest();
                xhr.withCredentials = false;

                xhr.addEventListener("readystatechange", function () {
                    if (this.readyState === this.DONE) {
                        console.log(this.responseText);
                    }
                });

                //setting request method
                //API endpoint for API sandbox 
                xhr.open("GET", "https://api10preview.sapsf.com/odata/v2/JobReqQuestion?%24top=20");


                //adding request headers
                xhr.setRequestHeader("config_authType", "Basic U0ZSTUtAc2luYm9uZWxlY1QxOnNhcEAyMDI0X1JNSw==");
                xhr.setRequestHeader("Authorization", "Basic U0ZSTUtAc2luYm9uZWxlY1QxOnNhcEAyMDI0X1JNSw==");
                xhr.setRequestHeader("config_packageName", "SuccessFactorsRecruiting");
                xhr.setRequestHeader("config_actualUrl", "https://api10preview.sapsf.com/odata/v2");
                xhr.setRequestHeader("config_urlPattern", "https://api10preview.sapsf.com/odata/v2");
                xhr.setRequestHeader("config_apiName", "RCMJobRequisition");
                xhr.setRequestHeader("DataServiceVersion", "2.0");
                xhr.setRequestHeader("Accept", "application/json");


                //sending request
                xhr.send(data);

                // var data = null;

                // var xhr = new XMLHttpRequest();
                // xhr.withCredentials = false;

                // xhr.addEventListener("readystatechange", function () {
                // if (this.readyState === this.DONE) {
                //     console.log(this.responseText);
                // }
                // });

                // //setting request method
                // //API endpoint for API sandbox 
                // xhr.open("GET", "ttps://api10preview.sapsf.com/odata/v2/JobReqQuestion?%24top=20");


                // //adding request headers
                // xhr.setRequestHeader("Authorization", "Basic U0ZSTUtAc2luYm9uZWxlY1QxOnNhcEAyMDI0X1JNSw==");
                // xhr.setRequestHeader("DataServiceVersion", "2.0");
                // xhr.setRequestHeader("Accept", "application/json");


                // //sending request
                // xhr.send(data);
            },
        },
        created(){
            this.fetchInfo();
            this.fetchItemsCategory();
            this.fetchItems();
            setMetaInfo( this, this.$t('page_title_career_job'), undefined, undefined, undefined,false,true );

            this.fetchSapJobReqQuestion();
        },
    };
</script>