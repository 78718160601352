<template>
    <div>
        <div v-if="detail" class="esg-page">
            <div v-if="detail.description_pb" v-html="$fixPbImgSrcForDev(detail.description_pb)" class="_pageBuilderFrame_content"></div>
        </div>
        <missing v-else-if="displayContent==='404'" />
    </div>
</template>
<script>

import apibase from "@/static/apibase.js";
import axios from 'axios';
import { mapState, mapActions } from "vuex";
import {setMetaInfo} from "@/libs/js/metaInfo.js";
import Missing from "../pages/Missing.vue";
export default {
        data() {
            return{
                slug:this.$route.params.slug,
                currId:null,
                detail:null,
                editor:null,
                esgPage:null,
                metaData:{},
                displayContent:'default',
            }
        },

        metaInfo() {
            return this.metaData
        },

        components: {
            "missing": Missing
        },

        computed:{
            ...mapState(['esgPageItem']),
        },

        methods: {
            ...mapActions(["fetchEsgPage"]),

            fetchData() {
                let _this = this;
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'csrpage/detail/' + _this.currId,
                })
                .then((response) => {
                    _this.detail=response.data.row;
                    setMetaInfo(_this, _this.detail.name, undefined, 'none', 'none');
                    _this.displayContent = 'data';
                    setTimeout(function() {
                        _this.$window.pagebuilder_clip_script_init();
                        _this.$pbScriptExtra();
                    }, 100);
                    // _this.fetchEditor();

                })
                .catch((error) => {
                    _this.displayContent = '404';
                    console.log(error);
                });
            },

            // fetchEditor() {
            //     let _this = this;

            //     axios({
            //         method: 'get',
            //         url: _this.detail.description,
                    
            //     })
            //     .then((response) => {
            //         _this.editor = response.data;
            //         // _this.$window.initAll();
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //     });
            // },

            parseRoute() {
                this.detail = null;
                this.editor = null;

                if (!this.$route.params.slug) this.$router.push({ name: 'home' });

                for (var i = 0; i < this.esgPage.length; i++) {
                    if (this.$route.params.slug == this.esgPage[i].slug) 
                    {
                        this.currId=this.esgPage[i].id;
                    }
                }
                this.fetchData();
            }
        },

        watch: {
            '$route' () {
                this.parseRoute();
            },

            esgPageItem(val) {
                this.esgPage = val;
                this.parseRoute();
            }
        },

        created() {
            if (!this.$route.params.slug) this.$router.push({ name: 'home' });
            
            this.fetchEsgPage();

            // setMetaInfo(this, undefined, undefined, undefined, undefined);
        },

        mounted() {
            // this.$nextTick(function () {
            //     setTimeout(function() {
            //         console.log('CDN_URL',window.CDN_URL);
            //         window.pagebuilder_clip_script_init();
            //         // _global.init();
            //     }, 3000);
            // });



            // axios({
            //     method: 'get',
            //     baseURL: apibase,
            //     url: 'pages/detail/Corporate-Governance-Summary',
            //     // url: 'pages/detail/7',
            // })
            // .then((response) => {
            //     console.log(response);
            // })
        }
    };
</script>