<template>
    <div>
        <div v-if="detail" class="esg-page cms-content">
            <div id="pb-content" v-if="detail.description_pb" v-html="$fixPbImgSrcForDev(detail.description_pb)" class="_pageBuilderFrame_content"></div>
        </div>
        <missing v-else-if="displayContent==='404'" />
    </div>
</template>


<style lang="less">
    // 重大性主題 的 accordion 塞入圖片
    // esg/material-topics
    .material-topics-accordions{
        ._clip_collapse#clip-357954ab-df5b-4a71-8a38-c6b198ce0729{
            ._clip_collapse__listItem{
                ._clip_collapse__header ._clip_collapse__btn span{
                    padding-left: 30px;
                    background-size: 24px 24px;
                    background-repeat: no-repeat;
                    background-position: center left;
                }
                &:nth-child(1) ._clip_collapse__btn span{
                    background-image: url('https://www.sinbon.com/files/cc6a60ffc011eb833b41593b6be161bf.png');
                }
                &:nth-child(2) ._clip_collapse__btn span{
                    background-image: url('https://www.sinbon.com/files/98083b99e07f94ed7bcdf9e51fa1e625.png');
                }
                &:nth-child(3) ._clip_collapse__btn span{
                    background-image: url('https://www.sinbon.com/files/297f7558b0c1c4e241fb6ed17c607b39.png');
                }
                &:nth-child(4) ._clip_collapse__btn span{
                    background-image: url('https://www.sinbon.com/files/1bfad025e3583382297a64fcb80b8c6b.png');
                }
                &:nth-child(5) ._clip_collapse__btn span{
                    background-image: url('https://www.sinbon.com/files/cdbbbc7ada79dbb12d6b6b25fdd3fbee.png');
                }
                &:nth-child(6) ._clip_collapse__btn span{
                    background-image: url('https://www.sinbon.com/files/a62cf8ac367ab2726f2311d6b9b3e7e1.png');
                }
                &:nth-child(7) ._clip_collapse__btn span{
                    background-image: url('https://www.sinbon.com/files/5ddddbbfb8350fb8b2b1b704de8cbd65.png');
                }
            }
        }
    }

    // banner的icon
    .esg-header{
        ._clip_img{
            display:inline-block!important;
            width:50px;
            margin:0 5px;
            a{
                width:100%!important;
                img{
                width:100%!important;
                border-radius:5px;
                }
            }
            ._pageBuilderFrame_itemEditor__tools{
                width:70px;
                height:36px;
            }
        }
        }

        //btn hover效果
        ._plt-set ._clip_btnLink--solid,
        ._plt-set ._clip_ctaLink--solid{
        transition: all 0.1s linear;
        &:hover{
            text-decoration:none;
            transform: translate3d(0, -2px, 0);
            filter: none!important;
        }
        }

        ._plt-set:not(._plt-set4):not(._plt-set5):not(._plt-set6),
        ._plt-set2,
        ._plt-set3{
        ._clip_btnLink--solid,
        ._clip_ctaLink--solid{
            &:hover{
            background:#8eafd0!important;
            }
        }
        }
</style>




<script>
import apibase from "@/static/apibase.js";
import axios from 'axios';
import { mapState, mapActions } from "vuex";
import {setMetaInfo} from "@/libs/js/metaInfo.js";
import Missing from "../pages/Missing.vue";
export default {
        data() {
            return{
                slug:this.$route.params.slug,
                currId:null,
                detail:null,
                editor:null,
                esgPage:null,
                metaData:{},
                displayContent:'default',
            }
        },

        metaInfo() {
            return this.metaData
        },

        components: {
            "missing": Missing
        },

        computed:{
            ...mapState(['esgPageItem']),
        },

        methods: {
            ...mapActions(["fetchEsgPage"]),

            fetchData() {
                let _this = this;
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'csrpage/detail/' + _this.currId,
                })
                .then((response) => {
                    _this.detail=response.data.row;
                    setMetaInfo(_this, _this.detail.name, undefined, 'none', 'none');
                    _this.displayContent = 'data';
                    setTimeout(function() {
                        _this.$window.pagebuilder_clip_script_init();
                        _this.$fixPbContent('pb-content');
                        _this.$toggleLoading(false);
                    }, 100);

                })
                .catch((error) => {
                    _this.displayContent = '404';
                    console.log(error);
                });
            },

            parseRoute() {
                this.detail = null;
                this.editor = null;

                if (!this.$route.params.slug) this.$router.push({ name: 'home' });

                for (var i = 0; i < this.esgPage.length; i++) {
                    if (this.$route.params.slug == this.esgPage[i].slug) 
                    {
                        this.currId=this.esgPage[i].id;
                    }
                }
                this.fetchData();
            }
        },

        watch: {
            '$route' () {
                this.parseRoute();
            },

            esgPageItem(val) {
                this.esgPage = val;
                this.parseRoute();
            }
        },

        created() {
            if (!this.$route.params.slug) this.$router.push({ name: 'home' });
            this.fetchEsgPage();
        },
    };
</script>