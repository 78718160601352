<template>
  <div class="masonry-filter-group">
    <div class="masonry-filter-holder">
      <div class="masonry__filters">
        <ul>
          <li 
            v-for="item, idx in options.children"
            :class="{ 'active': idx == 0 }"
            @click="updateCategory(item)"
          >{{ item.title }}</li>
        </ul>
      </div>
    </div>

    <recommend-product-filter 
      v-if="childOptions.children != null" 
      :options="childOptions"
      :parentCurrCategory="parentCurrCategory"
      :renderFunc="renderFunc"
    ></recommend-product-filter>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: 'recommendProductFilter',

    props: ['options', 'parentCurrCategory', 'firstLeval', 'renderFunc'],

    data() {
      return {
        childOptions: { children: null },
        category: null,
        active: false
      }
    },

    methods: {
      updateCategory(c) {
        this.childOptions.children = null;

        let cate = '';

        cate = c.uri;
        
        if (cate == null) cate = '';

        setTimeout(() => {
          if (cate == this.currCategory) return;

          let childOptions = [];

          if (c.children != null) 
          {
            c.children.map(option => {
              let result = null;
              let optionArr = option.uri.split(',');

              result = optionArr.map(k => {
                return this.parentCurrCategory.split(',').some(v => v === k);
              });

              if (result.every(v => v === true)) childOptions.push(option);
            });
          }
          else 
          {
            childOptions = null;
          }

          this.childOptions.children = childOptions;
          this.category = cate;
        }, 100);
      }
    },

    watch: {
      category(val) {
        this.renderFunc(val);
      }
    },

    created() {
      if (this.firstLeval) this.category = 'all';
    }
  };
</script>