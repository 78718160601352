<template>
     <div class="contact notification pos-bottom notification--reveal notification--dismissed m-0 p-0 h-100" style="padding-top:80px!important;" data-animation="from-bottom" data-notification-link="integrityContact" @mouseup="closePopup()">
        <div class="contact__table">
            <div class="contact__cell">
                <div  class="container contactBox col-10 col-sm-9 ml-auto mr-auto p-0">
                    <div class="feature feature-1 mb-0">
                        <div class="feature__body boxed boxed--lg boxed--border ">
                            
                            <!-- form start -->
                            <div v-if="integrityRemindAgreeChecked">
                                <h3 class="notification-title" v-html="$t('integrityContact_title')">違反從業道德行為舉報</h3>
                                <div class="text-left mt-4" v-html="$t('integrityContact_des')">
                                    檢舉人應透過信邦正常管道反應問題，因檢舉案件受理涉及貪瀆不法情事，必須秉持公平正義原則，小心求證，勿枉勿縱，請嚴肅謹慎待之。<br>為能盡早調查與解決問題，請您務必提供可能的相關具體資訊與文件，若相關資訊與文件不齊備時，我們將無法進行調查。<br>信邦將依照個人資料保護法規定，以保密方式處理，保護檢舉人之身分，並採行適當之安全措施，防止個人資料被竊取、竄改、毀損、滅失或洩漏。請您自行輸入個人資料，並同意信邦：<br><br><ul><li>・於符合個人資料保護法的相關規定下，使用您的個人資料。</li><li>・於相關業務管理及執行等範圍內，對於您的相關個人資料之蒐集、處理或利用。</li><li>・以您所提供的個人資料，確認您的身分，進行聯絡及提供相關服務。</li></ul><p>請提供以下資訊：</p>
                                </div>

                                <form id="integrityContactForm" class="integrityContact_form text-left mt-4" @submit.prevent="sendFrom">
                                    <div class="row">
                                        <label class="integrityContact_title pl-md-3 w-100" v-html="$t('integrityContact_label_relation')">您與信邦的關係 *</label>
                                        <div class="col-md-6">
                                            <div class="input-select">
                                                <select v-model="inputRelation">
                                                    <option disabled selected v-html="$t('integrityContact_option_please_select')">請選擇</option>
                                                    <option value="供應商" v-html="$t('integrityContact_option_relation_1')">供應商</option>
                                                    <option value="客戶" v-html="$t('integrityContact_option_relation_2')">客戶</option>
                                                    <option value="others" v-html="$t('integrityContact_option_relation_3')">其他(請說明)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <input v-model="inputRelationDes" type="text" name="relationDes" v-if="inputRelation == 'others'"  :placeholder="$t('integrityContact_relation_placeholder')" />
                                        </div>

                                        <label class="integrityContact_title pl-md-3 w-100" v-html="$t('integrityContact_label_info')">個人資料</label>
                                        <div class="col-md-6">
                                            <label v-html="$t('integrityContact_label_name')">姓名</label>
                                            <input v-model="inputName" type="text" name="name" />
                                        </div>
                                        <div class="col-md-6">
                                            <label v-html="$t('integrityContact_label_phone')">連絡電話</label>
                                            <input v-model="inputPhone" type="text" name="phone" />
                                        </div>
                                        <div class="col-md-12">
                                            <label v-html="$t('integrityContact_label_email')">電子郵件</label>
                                            <input v-model="inputEmail" type="text" name="email" />
                                        </div>

                                        <label class="integrityContact_title pl-md-3 w-100" v-html="$t('integrityContact_label_identify')">請列出涉入違規案件的信邦人員</label>
                                        <div class="col-md-4">
                                            <label v-html="$t('integrityContact_label_who')">姓名</label>
                                            <input v-model="inputWho" type="text" name="who" />
                                        </div>
                                        <div class="col-md-4">
                                            <label v-html="$t('integrityContact_label_unit')">單位</label>
                                            <input v-model="inputUnit" type="text" name="unit" />
                                        </div>
                                        <div class="col-md-4">
                                            <label v-html="$t('integrityContact_label_position')">職稱</label>
                                            <input v-model="inputPosition" type="text" name="position" />
                                        </div>

                                        <label class="integrityContact_title pl-md-3 w-100" v-html="$t('integrityContact_label_violate')">請說明違規行為</label>
                                        <div class="col-md-12">
                                            <div class="input-select">
                                                <select v-model="inputViolate">
                                                    <option disabled selected v-html="$t('integrityContact_option_please_select')">請選擇</option>
                                                    <option value="向本公司之交易對象要求、期約、索取、收受任何不正當利益" v-html="$t('integrityContact_option_violate_1')">向本公司之交易對象要求、期約、索取、收受任何不正當利益</option>
                                                    <option value="藉職務上之關係要求本公司之交易對象饋贈佣金、酬金或其他不正當利益，唯年節禮品往來且價值不高於新台幣一千元者，不在此限" v-html="$t('integrityContact_option_violate_2')">藉職務上之關係要求本公司之交易對象饋贈佣金、酬金或其他不正當利益，唯年節禮品往來且價值不高於新台幣一千元者，不在此限</option>
                                                    <option value="於本公司任職期間持有與本公司交易對象之未上市櫃股份；唯於公開市場上買賣上市櫃股票則不在此限" v-html="$t('integrityContact_option_violate_3')">於本公司任職期間持有與本公司交易對象之未上市櫃股份；唯於公開市場上買賣上市櫃股票則不在此限</option>
                                                    <option value="有任何貪污、受賄、挪用、侵佔其負責保管或使用之本公司財產" v-html="$t('integrityContact_option_violate_4')">有任何貪污、受賄、挪用、侵佔其負責保管或使用之本公司財產</option>
                                                    <option value="others" v-html="$t('integrityContact_option_violate_5')">其他(請具體描述)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12" v-if="inputViolate === 'others'">
                                            <textarea v-model="inputViolateDes" name="violateDes" rows="1" :placeholder="$t('integrityContact_violate_placeholder')"></textarea>
                                        </div>


                                        <label class="integrityContact_title pl-md-3 w-100" v-html="$t('integrityContact_label_how')">如何得知此違規行為</label>
                                        <div class="col-md-6">
                                            <div class="input-select">
                                                <select v-model="inputHow">
                                                    <option disabled selected v-html="$t('integrityContact_option_please_select')">請選擇</option>
                                                    <option value="親身經歷" v-html="$t('integrityContact_option_how_1')">親身經歷</option>
                                                    <option value="同事或親友的經歷" v-html="$t('integrityContact_option_how_2')">同事或親友的經歷</option>
                                                    <option value="藉由相關證據推測得知" v-html="$t('integrityContact_option_how_3')">藉由相關證據推測得知</option>
                                                    <option value="傳聞" v-html="$t('integrityContact_option_how_4')">傳聞</option>
                                                    <option value="others" v-html="$t('integrityContact_option_how_5')">其他(請說明)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <input v-model="inputHowDes" type="text" name="howDes" v-if="inputHow == 'others'" :placeholder="$t('integrityContact_how_placeholder')" /> <!--請說明您如何得知此違規行為-->
                                        </div>

                                        <div class="col-lg-4 integrityContact_calendar integrityContact_input__wrap">
                                            <label class="integrityContact_title" v-html="$t('integrityContact_label_time')">事件發生的大致時間</label>
                                             <el-date-picker
                                                v-model="inputTime"
                                                type="month"
                                                format="yyyy/MM"
                                                placeholder="">
                                            </el-date-picker>
                                        </div>
                                        <div class="col-lg-4 integrityContact_input__wrap">
                                            <label class="integrityContact_title" v-html="$t('integrityContact_label_where')">事件發生的地點</label>
                                            <input v-model="inputWhere" type="text" name="where" />
                                        </div>
                                        <div class="col-lg-4 integrityContact_input__wrap">
                                            <label class="integrityContact_title" v-html="$t('integrityContact_label_how_long')">您認為事件發生的持續時間</label>
                                            <div class="integrityContact_howlong">
                                                <input v-model="inputHowLongY" type="text" name="howLongY" />
                                                <span v-html="$t('integrityContact_label_year')">年</span>
                                                <input v-model="inputHowLongM" type="text" name="howLongM" />
                                                <span v-html="$t('integrityContact_label_month')">月</span>
                                            </div>
                                        </div>


                                        <label class="integrityContact_title pl-md-3 w-100" v-html="$t('integrityContact_label_reason')">您認為事件的發生原因</label>
                                        <div class="col-md-6">
                                            <div class="input-select">
                                                <select v-model="inputReason">
                                                    <option disabled selected v-html="$t('integrityContact_option_please_select')">請選擇</option>
                                                    <option value="信邦員工主動要求/暗示" v-html="$t('integrityContact_option_reason_1')">信邦員工主動要求/暗示</option>
                                                    <option value="供應商主動提供但信邦員工未拒絕" v-html="$t('integrityContact_option_reason_2')">供應商主動提供但信邦員工未拒絕 </option>
                                                    <option value="透過第三方人員媒介" v-html="$t('integrityContact_option_reason_3')">透過第三方人員媒介</option>
                                                    <option value="others" v-html="$t('integrityContact_option_reason_4')">其他(請具體描述)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <input v-model="inputReasonDes" type="text" name="reasonDes" v-if="inputReason == 'others'" :placeholder="$t('integrityContact_reason_placeholder')" /> <!-- 請描述您認為事件的發生原因 -->
                                        </div>

                                        <label class="integrityContact_title pl-md-3" v-html="$t('integrityContact_label_others')">請提供本案件的細節說明及任何相關有價值之資料</label>
                                        <div class="col-md-12">
                                            <textarea v-model="inputEvidence" name="evidence" rows="1"></textarea>
                                        </div>

                                        <div class="col-md-12">
                                            <label class="integrityContact_title" v-html="$t('integrityContact_label_upload')">如果您有支援此案件的文件或檔案，請上傳</label>
                                                <input @change="handleFileChange" ref="fileInput" class="integrityContact_upload__input" type="file" name="upload" accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .html, .txt, .csv, .jpg, .png" />
                                            <span class="integrityContact_upload__ps" v-html="$t('integrityContact_ps')">檔案須為下列格式: PDF, DOC, DOCX, XLS, XLSX, PPT, PPTX, HTML, TXT, CSV, JPG, PNG，檔案大小限5MB內</span>
                                        </div>
                                        
                                        <div class="col-md-12 checkboxSet">
                                            <div class="input-checkbox">
                                                <input id="integrityInputAgreePrivacy" type="checkbox" v-model="integrityInputAgreePrivacy">
                                                <label for="integrityInputAgreePrivacy">
                                                    <span v-html="$t('integrityContact_checkbox_des')">提供個資代表您同意本公司蒐集、處理及利用您的個資以提供相關服務。參閱我們的隱私政策以取得更多資訊。</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-3">
                                            <button type="submit" :disabled="sendIsDisabled" class="btn btn--primary type--uppercase" v-html="$t('integrityContact_btn_send')">SEND</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- form end -->

                            <!-- remind start -->
                            <div v-else>
                                <div class="text-block">
                                    <!-- <div class="row">
                                        <h3 class="col-md-12 mb-5 h2" v-html="$t('integrityContact_title')">違反從業道德行為舉報</h3>
                                        <p class="col-md-12 text-left" v-html="$t('integrityRemind_des')">
                                            信邦秉持「誠信合法經營、恪遵職業道德」，致力遵循各項公司治理法規。同仁於從事與公司業務行為之過程中，不得直接或間接提供、承諾、要求或收受任何不正當利益，或做出其他違反誠信、不法或違背受託義務等不誠信行為。信邦鼓勵內部及外部人員檢舉不合法、不道德及不誠信行為，若您有任何發現，請主動告知我們，我們將會有專員與您聯絡。<br>
                                            除法律另有規定外，我們將以保密方式處理檢舉案件，並由獨立管道查證，全力保護檢舉人之身分、個人資料及隱私。
                                            <br><br>
                                            應提供資訊：<br>
                                            <ul>
                                                <li>・您的真實姓名及正確聯繫資料。</li>
                                                <li>・檢舉案相對人的姓名或其他足資識別其身分特徵之資料。</li>
                                                <li>・可供調查的具體事證。</li>
                                            </ul>
                                            
                                            有下列各款情形之一者，信邦得不予受理：<br>
                                            <ul>
                                                <li>・未依前項規定提供資料。</li>
                                                <li>・檢舉內容涉有惡意攻訐、虛偽不實、無具體內容、非屬違反法令者。</li>
                                                <li>・同一相對人之被檢舉情事，業經信邦查證或已結案等。</li>
                                            </ul>
                                            
                                            <br>
                                            除依法律規定要求外，您不得將調查過程中與信邦之間的聯絡內容及資訊向第三方揭露，或以該聯絡內容作為訴訟相關資料。
                                        </p>
                                    </div> -->
                                    <h3 class="notification-title h3" v-html="$t('integrityContact_title')">違反從業道德行為舉報</h3>
                                    <p class="text-left mt-4" v-html="$t('integrityRemind_des')">
                                        信邦秉持「誠信合法經營、恪遵職業道德」，致力遵循各項公司治理法規。同仁於從事與公司業務行為之過程中，不得直接或間接提供、承諾、要求或收受任何不正當利益，或做出其他違反誠信、不法或違背受託義務等不誠信行為。信邦鼓勵內部及外部人員檢舉不合法、不道德及不誠信行為，若您有任何發現，請主動告知我們，我們將會有專員與您聯絡。<br>
                                        除法律另有規定外，我們將以保密方式處理檢舉案件，並由獨立管道查證，全力保護檢舉人之身分、個人資料及隱私。
                                        <br><br>
                                        應提供資訊：<br>
                                        <ul>
                                            <li>・您的真實姓名及正確聯繫資料。</li>
                                            <li>・檢舉案相對人的姓名或其他足資識別其身分特徵之資料。</li>
                                            <li>・可供調查的具體事證。</li>
                                        </ul>
                                        
                                        有下列各款情形之一者，信邦得不予受理：<br>
                                        <ul>
                                            <li>・未依前項規定提供資料。</li>
                                            <li>・檢舉內容涉有惡意攻訐、虛偽不實、無具體內容、非屬違反法令者。</li>
                                            <li>・同一相對人之被檢舉情事，業經信邦查證或已結案等。</li>
                                        </ul>
                                        
                                        <br>
                                        除依法律規定要求外，您不得將調查過程中與信邦之間的聯絡內容及資訊向第三方揭露，或以該聯絡內容作為訴訟相關資料。
                                    </p>
                                </div>
                                <form id="integrityRemindForm" class="integrityContact_form text-left">
                                    <div class="row">
                                        <div class="col-md-12 checkboxSet">
                                            <div class="input-checkbox">
                                                <input id="integrityRemindAgree" type="checkbox" v-model="integrityRemindAgree" />
                                                <label for="integrityRemindAgree">
                                                    <span v-html="$t('integrityRemind_checkbox_des')">提供個資代表您同意本公司蒐集、處理及利用您的個資以提供相關服務。參閱我們的 隱私政策 以取得更多資訊。</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-3">
                                            <div @click="setIntegrityRemindAgree" class="integrityRemind_btn btn btn--primary type--uppercase w-100" v-html="$t('integrityContact_btn_start')">SEND</div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- remind end -->
                        </div>
                    </div>
                    <div class="notification-close-cross notification-close"></div>
                </div>  
            </div>
        </div>
    </div>
</template>

<script>
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    import { mapActions, mapGetters } from "vuex";
    import { readCookie } from "@/libs/js/cookie.js";

    export default {
        data: function() {
            return {
                // remind
                integrityRemindAgree:false,
                integrityRemindAgreeChecked:false,
                
                // form
                inputRelation:'',
                inputRelationDes:'',
                inputName:'',
                inputPhone:'',
                inputEmail:'',
                inputWho:'',
                inputUnit:'',
                inputPosition:'',
                inputViolate:'',
                inputViolateDes:'',
                inputHow:'',
                inputHowDes:'',
                inputTime:'',
                inputWhere:'',
                inputHowLongY:'',
                inputHowLongM:'',
                inputReason:'',
                inputReasonDes:'',
                inputEvidence:'',

                // integrityInputAgreeInfo:false,
                integrityInputAgreePrivacy:false,
                sendIsDisabled:false
            }
        },
        methods: {
            ...mapActions(["setLoadingStatus"]),

            setIntegrityRemindAgree() {
                var _this = this;
                
                if (this.integrityRemindAgree)
                {
                    this.integrityRemindAgreeChecked = true;
                }
                else
                {
                    alert(_this.$t('integrityContact_alert_13')); //請勾選我已充分了解且同意上述說明
                }
            },
            
            closePopup: function() {
                var _this=this;

                $(document).mouseup(function(e) {
                  var _con = $('.contactBox');

                  if (!_con.is(e.target) && _con.has(e.target).length === 0) {
                    $('#contactForm').addClass('notification--dismissed');
                    $('html').removeClass('popup');
                  }
                });
            },

            handleFileChange(e){
                var _this=this;
                let file = e.target.files[0];
                
                if( file.size>5000000 ){
                    alert(_this.$t('integrityContact_alert_14'));
                    _this.$refs.fileInput.value = '';
                }
            },

            sendFrom() {
                var _this=this;

                if (_this.inputRelation.trim() == '')
                {
                    alert(_this.$t('integrityContact_alert_1')); //請選擇您與信邦的關係
                    return;
                }
                
                if (_this.inputRelation.trim() == 'others' && _this.inputRelationDes.trim() == '')
                {
                    alert(_this.$t('integrityContact_alert_2')); //請說明您與信邦的關係
                    return;
                }
                
                // if (_this.inputName.trim() == '')
                // {
                //     alert(_this.$t('contact_alert_7')); //請填寫姓名
                //     return;
                // }
                
                // if (_this.inputPhone.trim() == '')
                // {
                //     alert(_this.$t('integrityContact_alert_3')); //請填寫電話
                //     return;
                // }
                
                // if (_this.inputEmail.trim() == '')
                // {
                //     alert(_this.$t('contact_alert_8')); //請填寫電子郵件
                //     return;
                // }
                
                if (_this.inputEmail.trim() != ''){
                    var r = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    
                    if (!r.test(_this.inputEmail.trim().toLowerCase()))
                    {
                        alert(_this.$t('contact_alert_9')); // 請填寫正確的電子郵件格式
                        return;
                    }
                }
                

                if (_this.inputWho.trim() == '')
                {
                    alert(_this.$t('integrityContact_alert_4')); //請填寫違規案件之信邦人員姓名
                    return;
                }
                
                if (_this.inputUnit.trim() == '')
                {
                    alert(_this.$t('integrityContact_alert_5')); //請填寫違規案件之信邦人員單位
                    return;
                }
                
                if (_this.inputPosition.trim() == '')
                {
                    alert(_this.$t('integrityContact_alert_6')); //請填寫違規案件之信邦人員職稱
                    return;
                }
                
                if (_this.inputViolate.trim() == '')
                {
                    alert(_this.$t('integrityContact_alert_7')); //請選擇違規行為
                    return;
                }
                
                if (_this.inputHow.trim() == '')
                {
                    alert(_this.$t('integrityContact_alert_8')); //請選擇如何得知此違規行為
                    return;
                }
                
                if (_this.inputTime == '')
                {
                    alert(_this.$t('integrityContact_alert_9')); //請填寫事件發生的大致時間
                    return;
                }
                
                if (_this.inputWhere.trim() == '')
                {
                    alert(_this.$t('integrityContact_alert_10')); //請填寫事件發生的地點
                    return;
                }
                
                if (_this.inputHowLongY.trim() == ''&&_this.inputHowLongM.trim() == '')
                {
                    alert(_this.$t('integrityContact_alert_11')); //請填寫您認為事件發生的持續時間
                    return;
                }
                
                if (_this.inputReason.trim() == '')
                {
                    alert(_this.$t('integrityContact_alert_12')); //請填寫您認為事件的發生原因
                    return;
                }

                if (_this.integrityInputAgreePrivacy == false)
                {
                    alert(_this.$t('contact_alert_13')); //請詳閱並同意隱私權政策
                    return;
                }
                
                // reformat time
                const D = new Date(_this.inputTime);
                const newTime = `${D.getFullYear()}/${D.getMonth() + 1}`;

                var data = {
                    'subject_slug': 'business-conduct',
                    'relation': _this.inputRelation,
                    'relation_des': _this.inputRelationDes,
                    'name': _this.inputName,
                    'phone': _this.inputPhone,
                    'email': _this.inputEmail,
                    'who': _this.inputWho,
                    'unit': _this.inputUnit,
                    'position': _this.inputPosition,
                    'violate': _this.inputViolate,
                    'violate_des': _this.inputViolateDes,
                    'how': _this.inputHow,
                    'how_des': _this.inputHowDes,
                    'time': newTime,
                    'where': _this.inputWhere,
                    'how_long': _this.inputHowLongY + ' 年 ' + _this.inputHowLongM + ' 月',
                    'reason': _this.inputReason,
                    'reason_des': _this.inputReasonDes,
                    'evidence': _this.inputEvidence
                };

                data['csrf_hash_name'] = readCookie(COOKIE_PREFIX + 'csrf_cookie_name');
                data['d0ntf1llth1s1n'] = ' ';
                data['lang'] = readCookie('lang_code');
                
                _this.setLoadingStatus(true);
                
                var bodyFormData = new FormData();
                for(var k in data) bodyFormData.append(k,data[k]);

                // Upload file handle. @CLHOM
                var upload_file = document.querySelector('input[name="upload"]');
                if (upload_file.files && upload_file.files[0]) bodyFormData.set('upload_file', upload_file.files[0]);

                this.sendIsDisabled = 'disabled';

                axios(apibase + 'contact/report_violate', {
                    method: 'post',
                    data: bodyFormData,
                    params: {
                        lang: readCookie('lang_code')
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(function(result) {
                    if (result.data.status == 'error')
                    {
                        alert(result.data.notice);
                        return;
                    }
                    
                    _this.inputRelation = '';
                    _this.inputRelationDes = '';
                    _this.inputName = '';
                    _this.inputPhone = '';
                    _this.inputEmail = '';
                    _this.inputWho = '';
                    _this.inputUnit = '';
                    _this.inputPosition = '';
                    _this.inputViolate = '';
                    _this.inputViolateDes = '';
                    _this.inputHow = '';
                    _this.inputHowDes = '';
                    _this.inputTime = '';
                    _this.inputWhere = '';
                    _this.inputHowLongY = '';
                    _this.inputHowLongM = '';
                    _this.inputReason = '';
                    _this.inputReasonDe = '';
                    _this.inputEvidence = '';

                    $('#integrityContactForm').addClass('notification--dismissed');
                    $('html').removeClass('popup');

                    alert(_this.$t('contact_alert_11')); // 送出成功，我們將儘快與您聯繫，謝謝您的洽詢
                    
                    _this.sendIsDisabled = false;
                    _this.setLoadingStatus(false);
                    
                    $('.notification-close').trigger( "click" );
                    
                    document.getElementById('integrityContactForm').reset();
                })
                .catch(function(error) {
                    console.log(error);
                });
            }
        },
        
        created() {
            // this.syncInput();
        },
        
        computed: {
            // ...mapState(['']),
        },
        
        watch: {
            // '$route' (to, from) {
            //     this.syncInput();
            //     if (!$('#integrityContactForm').hasClass('notification--dismissed')) $('#integrityContactForm').addClass('notification--dismissed');
            // }
        }
    };
</script>