<template>
	<div>
		<section class="cover imagebg text-left space--sm " data-overlay="6">
            <div class="background-image-holder">
                <img alt="background" :src="$data.siteUrl + '/img/banner-distribution.jpg'" />
            </div>
            <div class="container">
                <div class="row">
                    <!-- <div class="col-md-8 col-lg-7"> -->
                    <div class="col-md-10 col-lg-11" style="max-width:600px;">
                        <h1 v-html="$t('distribution_banner_title')">提供各品牌電子產品與<br/>完善的服務支援</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="pt-5 pb-3 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('nav_brands')">品牌代理</li>
                </ol>
            </div>
        </section>

        <section>
            <div class="container">
                <div>
                    <div class="masonry-filter-container d-flex align-items-center" v-if="categories">
                        <span v-html="$t('ui_category')">類別</span>
                        <div class="masonry-filter-group">
                            <div class="masonry-filter-holder">
                                <div class="masonry__filters" data-filter-all-text="所有類別">
                                    <ul>
                                        <li :class="currCategory=='all'?'active':''" @click="updateCategory('all')" v-html="$t('ui_allCategory')">所有類別</li>
                                        <li :class="currCategory==category.category_slug?'active':''" v-for="category,key in categories" @click="updateCategory(category.category_slug)">{{category.category_name}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {{ cateMetaDes }}
                    <hr>
                    <div class="masonry__container row masonry--active" v-if="items">
                        <div v-for="item,index in filteredRowsByTag(items.rows,currCategory,'category_slug').slice(pageStart, pageStart + itemPerPage)" class="masonry__item col-6 col-sm-4 col-md-3" >
                            <div class="product text-center">
                                <a :href="item.link" target="_blank">
                                    <img :alt="item.name" :src="item.cover">
                                </a>
                                <a :href="item.link" target="_blank">
                                    <div>
                                        <h5 property="dc:maker">{{item.meta_title}}</h5>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <pagination v-model="currPage" v-on:getTotalPage="totalPage" :itemPerPage="itemPerPage" :currPage="currPage" :itemTotal="totalItemNum" v-if="items" />
        </section>
        <channel-box v-bind:blocks="['solution','contact-custom','global-location']"/>
    </div>
    
</template>
<script>
    import channelBox from "../components/ChannelBox.vue";
    import pagination from "../components/Pagination.vue";
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    // import {readCookie} from "@/libs/js/cookie.js"
    import { mapState, mapActions } from "vuex";
    import {setMetaInfo} from "@/libs/js/metaInfo.js"
    export default {
        data:function(){
            return{
                items:null,
                currPage:this.$route.query.page,
                itemPerPage:999,
                categories:null,
                currCategory:this.$route.query.cate,
                cateMetaDes:null,
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        components: {
            "channel-box": channelBox,
            "pagination": pagination
        },
        computed:{
            ...mapState(['brandCate']),
            totalItemNum:function(){
                return this.items.rows.length
            },
            pageStart: function(){
                return (this.currPage - 1) * this.itemPerPage;
            },
        },
        watch: {
            '$route' (to, from) {
                if(this.$route.query.cate!=this.currCategory){
                    this.currCategory=this.$route.query.cate;
                    this.currPage=1;
                }
                if( this.currPage == undefined ) this.currPage = 1;
                this.$router.push({name:'distribution',query:{ cate: this.currCategory, page: this.currPage }});
                this.setDesc();
            },
            brandCate(val){
                this.categories = val;
                this.setDesc();
            },
        },
        methods:{
            ...mapActions(['fetchBrandCategory']),
            filteredRowsByTag: function(rows,cc,slug){
                this.$toggleLoading(false);
                var c = cc.toLowerCase();
                if(c.trim() == 'all'){
                    return rows;
                }else{
                    return rows.filter(
                        function(d){ 
                            for(var k in d.category){
                                if(d.category[k][slug].toLowerCase().indexOf(c) > -1) return true;
                            }
                            return false;
                        }
                    );
                }
            },
            updateCategory:function(c){
                if(c==this.currCategory) return;
                this.currCategory=c;
                this.currPage=1;
                this.$router.push({ name:'distribution', query:{ cate:this.currCategory, page:this.currPage }});
            },
            fetchItems:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'brand/items',
                    
                })
                    .then(function (response) {
                        _this.items=response.data;
                        // _this.$window.initAll();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            findCate:function( arr ){
                return arr.category_slug === this.$route.query.cate;
            },
            setDesc:function(){
                // let routeName = this.$route.name
                if( this.$route.query.cate != 'all'){
                    var found = this.brandCate.find( this.findCate )
                    this.cateMetaDes = found.meta_desc;
                    setMetaInfo( this, found.meta_title ,undefined, found.meta_desc, found.thumb_brand_category );
                }else{
                    this.cateMetaDes = ''
                    setMetaInfo( this, undefined, undefined, undefined, undefined );
                }
            },
            totalPage:function(maxPage){
                if ( this.currPage > maxPage ) {
                    this.currPage = maxPage
                    this.$router.push({name:'distribution',query:{cate: this.currCategory, page: this.currPage }});
                }
            }
        },
        created(){
            if(this.currPage==undefined||this.currCategory==undefined){
                this.$router.push({ name:'distribution', query:{ cate: 'all', page: '1' }});
            }
            this.fetchBrandCategory();
            this.fetchItems();
        }
    };

</script>