//存放 getter 函式，只負責取 state 供元件使用

export const getAllstate = state => state

export const getLang = (state) => { return state.lang }
export const showApp = (state) => { return state.showApp }
export const isLoggedin = (state) => { return state.isLoggedin }
export const getToken = (state) => { return state.token }

export const getSelectedContactTopic = (state) => { return state.selectedContactTopic }
export const getWebinarContactQuery = (state) => { return state.webinarContactQuery }
export const getWebinarRegisterStatus = (state) => { return state.webinarRegisterStatus }
export const getCookieAllowStatus = (state) => { return state.cookieAllowStatus }