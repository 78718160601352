<template class="webinar-detail">
	<div v-if="detail">
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('resources_title')">資源</li>
                    <li>
                        <router-link :to="{name:'webinar',query:{cate:'all',page:'1'}}" v-html="$t('nav_resourcesWebinar')">Webinar</router-link>
                    </li>
                    <li>
                        <router-link :to="{name:'webinar',query:{cate:detail.category_slug,page:'1'}}">{{detail.category_name}}</router-link>
                    </li>
                </ol>
            </div>
        </section>
		<section>
            <div class="container webinar-detail__desc">
                <div class="row justify-content-start">
                    <div class="col">
                        <div class="switchable__text">
                            <h1 class="mb-1 font-weight-bold">{{detail.title}}</h1>
                            <p class="h5">{{detail.subtitle}}</p>
                            <hr class="short">
                            <p class="lead cms-content" v-html="detail.description"></p>
                        </div>
                    </div>
                </div>
                <!--end of row-->
            </div>
            <!--end of container-->
        </section>
        <!-- <section class="pb-0" v-if="detail.photo_main">
                <div class="row justify-content-center align-items-start" v-lazy-container="{ selector: 'img' }">
                    <img class="w-100 h-auto" :alt="detail.cover_desc ? detail.cover_desc:detail.name" :data-src="detail.photo_main"/>
                </div>
        </section> -->
        <section class="container">
                <div class="row webinar-detail__videoWrap justify-content-center align-items-start">
                    <video class="col webinar-detail__video fluid-component" :ref="'myVideo'+detail.id">
                        <source :src='detail.vidlink'
                            data-fluid-hd
                            title="1080p"
                            type='video/mp4'/>
                    </video>
                    <div class="col webinar-detail__videoCover" :class=" getWebinarRegisterStatus ? '' : 'active' " data-notification-link="webinarContact">
                        <div class="webinar-detail__videoBtn">
                            <div class="webinar-detail__videoArrow"></div>
                        </div>
                    </div>

                </div>
            <!--end of container-->
        </section>
        <recommend-webinar :items="detail.related_webinarreport" v-if="detail.related_webinarreport"/>
        <!-- <channel-box v-bind:blocks="['solution','contact-custom','global-location']"/> -->
    </div>
</template>
<script>
    // import channelBox from "../components/ChannelBox.vue";
    import { mapState, mapActions, mapGetters } from "vuex";
    import recommendWebinar from "../components/recommend-webinar.vue";
    import apibase from "@/static/apibase.js";
    import axios from 'axios';
    import {setMetaInfo} from "@/libs/js/metaInfo.js"
    // import {readCookie} from "@/libs/js/cookie.js"
    import fluidPlayer from 'fluid-player';

    export default {
        data:function(){
            return {
                category:this.$route.params.category,
                slug:this.$route.params.slug,
                detail:null,
                currentURL: window.location.href,
                metaData:{},
                player: null
            }
        },
        metaInfo() {
            return this.metaData
        },
        methods:{
            fetchData:function(){
                var _this=this;
                
                axios({
                    method: 'get',
                    baseURL: apibase,
                    url: 'webinarreport/detail/'+_this.$route.params.slug,
                    
                })
                .then(function (response) {
                    _this.$toggleLoading(false);
                    _this.detail=response.data.row;
                    // _this.$window.initAll();
                    var metaTitle;
                    if(_this.detail.meta_title!=undefined&&_this.detail.meta_title!=null) metaTitle=_this.detail.meta_title;
                    else metaTitle=_this.detail.name;
                    
                    var metaDesc;
                    if(_this.detail.meta_desc!=undefined&&_this.detail.meta_desc!=null) metaDesc=_this.detail.meta_desc;
                    else metaDesc=_this.$t('home_banner_p');
                    
                    var metaImg;
                    if(_this.detail.photo_main) metaImg = _this.detail.photo_main;
                    setMetaInfo( _this, metaTitle, window.location.href, metaDesc, metaImg);
                    _this.$nextTick(function(){
                        _this.$themeInitNotification();
                        _this.initPlayer();
                    });
                    // setTimeout(function(){
                    //     _this.initPlayer();
                    // },500);
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            initPlayer(){
                this.player = fluidPlayer(this.$refs['myVideo'+this.detail.id], {
                    layoutControls: {
                        fillToContainer: true,
                        preload: 'auto'
                        // preload: 'none',
                        // allowDownload: true,
                        // playbackRateEnabled: true,
                        // allowTheatre: false
                    },
                    // playerInitCallback: (function() {
                    //     console.log('PLAYER READY');
                    // })
                });
            },
            parseRoute:function(){
                this.detail=null;
                if(!this.$route.params.category||!this.$route.params.slug) this.$router.push({name:'webinar',query:{cate:'all',page:'1'}});
                this.fetchData();
            }
        },
        computed:{
            ...mapGetters(["getWebinarRegisterStatus"]),
        },
        created() {
            this.parseRoute();
            // this.$window.initAll();
            this.$store.dispatch( 'setWebinarRegisterStatus' , $cookies.get( "webinarRegistered") );
        },
        components: {
            // "channel-box": channelBox,
            "recommend-webinar": recommendWebinar
        },
        destroyed() {
            if (!this.player) {
                return;
            }
            this.player.destroy();
        },
        watch:{
            '$route' () {
                this.parseRoute();
            },
            getWebinarRegisterStatus( val ){
                if( val && this.player ) this.player.play();
            }
        }
    };

</script>

<style scoped>
    @import "~fluid-player/src/css/fluidplayer.css";
</style>