<template>
  <div>
    <section class="cover imagebg text-left space--sm" data-overlay="6">
      <div class="background-image-holder">
        <img alt="background" :src="$data.siteUrl + '/img/dealer-bg.jpg'" />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-lg-7">
            <h1 v-html="$t('nav_sitemap')">網站地圖</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="py-5 breadcrumbsSection">
      <div class="container">
        <ol class="breadcrumbs">
          <li>
            <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
          </li>
          <li v-html="$t('nav_sitemap')">網站地圖</li>
        </ol>
      </div>
    </section>
    
    <section>
      <div class="container">
        <div class="_sitemap__content row">
          <div class="col-lg-8 col-md-6">
            <div class="row">
              <div class="col-lg-6">
                <h3 v-html="$t('nav_products')">Products</h3>
                <ul class="bullets mt-2">
                  <li>
                    <router-link
                      :to="{ name:'product',query:{ cate:'all',page:'1'}  }"
                      v-html="$t('ui_allCategory')"
                    >All</router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name:'product',query:{ cate:'medical-health',page:'1'} }"
                      v-html="$t('sitemap_product_medicalHealth')"
                    ></router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name:'product',query:{ cate:'automotive-and-aviation',page:'1'} }"
                      v-html="$t('sitemap_product_automotiveAndAviation')"
                    ></router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name:'product',query:{ cate:'green-energy',page:'1'} }"
                      v-html="$t('sitemap_product_greenEnergy')"
                    ></router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name:'product',query:{ cate:'industrial-application',page:'1'} }"
                      v-html="$t('sitemap_product_industrialApplication')"
                    ></router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name:'product',query:{ cate:'communication',page:'1'} }"
                      v-html="$t('sitemap_product_communication')"
                    ></router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name: 'ev-charging' }"
                      v-html="$t('nav_evCharging')"
                    ></router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name:'solar-system' }"
                      v-html="$t('nav_solarSystem')"
                    ></router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name:'robotic'}"
                      v-html="$t('nav_robotic')"
                    ></router-link>
                  </li>
                </ul>

                <h3 class="mt-5" v-html="$t('nav_solution')">Solutions</h3>
                <ul class="bullets mt-2">
                  <li>
                    <router-link
                      :to="{ name:'solution',query:{ cate:'all',page:'1'} }"
                      v-html="$t('ui_allCategory')"
                    >All</router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name:'solution',query:{ cate:'design-manufacturing-solutions',page:'1'} }"
                      v-html="$t('sitemap_solution_designManufacturingSolutions')"
                    >All</router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name:'solution',query:{ cate:'integration-solutions',page:'1'} }"
                      v-html="$t('sitemap_solution_integrationSolutions')"
                    >All</router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name:'solution',query:{ cate:'radio-frequency-rf-solutions',page:'1'} }"
                      v-html="$t('sitemap_solution_radioFrequencyRfSolutions')"
                    >All</router-link>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6 mt-5 mt-lg-0">
                <h3 v-html="$t('nav_investor')">Investor Relations</h3>
                <ul class="bullets mt-2">
                  <li>
                    <router-link
                      :to="{ name:'investors-news',params:{ page:'1'}}"
                      v-html="$t('nav_investorNewsroom')"
                    >投資人訊息</router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name:'corporate-governance'}"
                      v-html="$t('nav_investorGovernance')"
                    >公司治理</router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name:'financial-info'}"
                      v-html="$t('nav_investorFinancial')"
                    >財務資訊</router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name:'shareholders-info' }"
                      v-html="$t('nav_investorShareholder')"
                    >股東會資料</router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name:'business-overview' }"
                      v-html="$t('nav_investorBusiness')"
                    >公司營運概況</router-link>
                  </li>
                </ul>

                <h3 class="mt-5" v-html="$t('nav_csr')">Corporate Sustainability</h3>
                <ul class="bullets mt-2">
                  <li>
                    <router-link
                      :to="{ name:'csr-page',params:{ slug:'a-message-from-the-chairman'}  }"
                      v-html="$t('sitemap_csr_chairmanMsg')"
                    ></router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name:'csr-page',params:{ slug:'corporate-governance'} }"
                      v-html="$t('sitemap_csr_corporateGovernance')"
                    ></router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name:'csr-page',params:{ slug:'green-sinbon'} }"
                      v-html="$t('sitemap_csr_green')"
                    ></router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name:'csr-page',params:{ slug:'giving-back-to-the-society'} }"
                      v-html="$t('sitemap_csr_givingBack')"
                    ></router-link>
                  </li>
                  <!-- <li>
                    <router-link
                      :to="{ name:'csr-stakeholder-contact' }"
                      v-html="$t('nav_csrContact')"
                    >利害關係人溝通管道</router-link>
                  </li> -->
                  
                  <!-- should be removed -->
                  <li>
                    <router-link :to="{ name:'csr-report' }" v-html="$t('nav_csrDownload')">報告書下載</router-link>
                  </li>
                  <!--new nav-->
                  <li>
                    <router-link :to="{ name:'sustainability-report' }" v-html="$t('nav_csrDownload')">報告書下載</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mt-5 mt-md-0">
            <router-link class="h3" :to="{ name:'capability'}" v-html="$t('nav_capability')">能力與服務</router-link>
            <router-link class="h3" :to="{ name:'distribution'}" v-html="$t('nav_brands')">品牌代理</router-link>
            <router-link class="h3" :to="{ name:'global-location' }" v-html="$t('nav_location')">全球據點</router-link>
            <router-link class="h3" :to="{ name:'news',query:{ cate:'all',page:'1'} }" v-html="$t('nav_news')">最新消息</router-link>
            <router-link class="h3" :to="{ name:'about' }" v-html="$t('nav_about')">關於信邦</router-link>
            <router-link class="h3" :to="{ name:'career-train' }" v-html="$t('nav_career')">人才招募</router-link>
            <!-- <router-link class="h3" :to="{ name:'dealer-resource' }" v-html="$t('nav_dealer')">夥伴專區</router-link>
            <router-link class="h3" :to="{ name:'downloads' }" v-html="$t('nav_downloads')">資源下載</router-link> -->
            <router-link class="h3" :to="{ name:'contact' }" v-html="$t('nav_contact')">聯絡與洽詢</router-link>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
<script>
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    export default {
        data:function(){
            return {
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        created() {
            setMetaInfo( this, undefined, undefined, undefined, undefined )
            // this.$window.initAll();
        },
    };
</script>
<style lang="less" scoped>
h3,
.h3 {
  margin-bottom: 0 !important;
}
a.h3 {
  color: #7ca2c9;
  display: block;
  line-height: 1.85em;
  &:hover {
    text-decoration: none;
  }
}
</style>