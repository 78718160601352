<template>
  <div>
    <section class="cover imagebg text-left space--sm" data-overlay="6">
      <div class="background-image-holder">
        <img alt="background" :src="$data.siteUrl + '/img/dealer-bg.jpg'" />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-lg-7">
            <h1>404</h1>
          </div>
        </div>
      </div>
    </section>
    <section>
        <div class="container">
            <div class="row justify-content-start">
                <div class="col-12">
                  Sorry, We cannot find the page you are looking for.
                </div>
            </div>
        </div>
    </section>

  </div>
</template>
<script>
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    export default {
        data:function(){
            return {
                metaData:{}
            }
        },
        metaInfo() {
            return this.metaData
        },
        created() {
            $('head').append('<meta name="prerender-status-code" content="404">')
            setMetaInfo( this, undefined, undefined, undefined, undefined )
            // this.$window.initAll();
            this.$toggleLoading(false);
        },
    };
</script>