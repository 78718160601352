<template>
	<div class="product-ebike">
        <section class="p-0 product-ebike_topImg">
            <div class="container-fluid">
                <div class="row">
                    <img alt="Portable Electric Vehicle Charging Cable Manufacturer - SINBON" :src="$data.siteUrl + '/img/ebike_new_main.jpg'" class="w-100 product-ebike_topImg__img" />
                </div>
            </div>
        </section>

        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li>
                        <router-link :to="{ name:'home'}" v-html="$t('nav_home')">首頁</router-link>
                    </li>
                    <li v-html="$t('nav_trends')">熱門趨勢</li>
                    <li v-html="$t('nav_ebike')">充電槍設計製造服務</li>
                </ol>
            </div>
        </section>

        <section class="mt-5 mt-lg-0">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col">
                        <h1 class="h2" v-html="$t('ebike_title')">
                            符合各式需求的完美工藝技術
                        </h1>
                        <p class="sub-lead" v-html="$t('ebike_content_1')"></p>
                        <p class="sub-lead" v-html="$t('ebike_content_2')"></p>
                    </div>
                </div>
            </div>
        </section>

        <section class="pb-0">
            <div class="row product-ebike__videoWrap justify-content-center align-items-start">
                <video class="col product-ebike__video fluid-component" ref="ebikeVideo">
                    <source src='/img/video/e-bike-video.mp4'
                        data-fluid-hd
                        title="1080p"
                        type='video/mp4'/>
                </video>
                <div class="col product-ebike__videoCover" :class=" playVideo ? '' : 'active' " @click="hideVideoCover()">
                    <div class="product-ebike__videoCoverWrap">
                    <img alt="" :src="$data.siteUrl + '/img/ebike_video_cover.png'" class="w-100 h-100 product-ebike__videoCoverImg">
                    </div>
                    <div class="product-ebike__videoBtn">
                        <div class="product-ebike__videoArrow"></div>
                    </div>
                </div>

            </div>
        </section>

        <section class="ebike-accordion--1 py-6">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-md-6 p-0 px-sm-3">
                        <div class="product-ebike__detail">
                            <div @click="openPopup_1(1)" :class="chosenDot_1 === 1 ? 'active' : ''" class="product-ebike__dotWrap dot dot-1">
                                <div class="product-ebike__detailDot"></div>
                                <!-- <div class="product-ebike__unitPic"><img class="w-100" :src="$data.siteUrl + '/img/ebike_helios_Basic.png'" /></div> -->
                            </div>
                            <div @click="openPopup_1(2)" :class="chosenDot_1 === 2 ? 'active' : ''" class="product-ebike__dotWrap dot dot-2">
                                <div class="product-ebike__detailDot"></div>
                                <div class="product-ebike__unitPic"><img class="w-100" :src="$data.siteUrl + '/img/ebike_battery.png'" /></div>
                            </div>
                            <div @click="openPopup_1(3)" :class="chosenDot_1 === 3 ? 'active' : ''" class="product-ebike__dotWrap dot dot-3">
                                <div class="product-ebike__detailDot"></div>
                                <div class="product-ebike__unitPic"><img class="w-100" :src="$data.siteUrl + '/img/ebike_helios_Basic.png'" /></div>
                            </div>
                            <div @click="openPopup_1(4)" :class="chosenDot_1 === 4 ? 'active' : ''" class="product-ebike__dotWrap dot dot-4">
                                <div class="product-ebike__detailDot"></div>
                                <div class="product-ebike__unitPic"><img class="w-100" :src="$data.siteUrl + '/img/ebike_chariot_R250_CR.png'" /></div>
                            </div>
                            <div class="product-ebike__bikeWrap">
                                <img class="w-100 product-ebike__ani" :src="$data.siteUrl + '/img/ebike_prod_ani_1.gif'" v-if="chosenDot_1===1" />
                                <img class="w-100" :src="$data.siteUrl + '/img/ebike_bike_1.png'" alt="Rear Hub Motor E-bike" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-5 mt-sm-0">
                            <h3 class="ebike-accordion__heading">Rear Hub Motor Solution</h3>
                            <div class="col-12 mb-0 p-0 h4">
                                <ul class="ebike-accordion accordion--right accordion-2 ">
                                    <li :class="chosenDot_1 === 1 ? 'active' : ''">
                                        <h4 class="ebike-accordion__title w-100" @click="openPopup_1(1)">
                                            <span class="mb-0 font-weight-bold">Interconnect planning</span>
                                        </h4>
                                        <div class="ebike-accordion__content">
                                            <p>SINBON offers customization service to help solve waterproof and vibration problems.  Our in-house raw cable plant makes fast and rapid cable prototyping possible.  The SINBON design team will co-work with the frame designer and assembly manufacturer to think about how cable can be hidden so exposure of cabling can be kept at a minimum.</p>
                                        </div>
                                    </li>
                                    <li :class="chosenDot_1 === 2 ? 'active' : ''">
                                        <h4 class="ebike-accordion__title w-100" @click="openPopup_1(2)">
                                            <span class="mb-0 font-weight-bold">Helios Battery Solution - Helios 375</span>
                                        </h4>
                                        <div class="ebike-accordion__content">
                                            <p>SINBON e-bike Helios battery solution can be customized in dimensions, including in-tube, in-frame, down tube, rear rack and rear rack with light, to fit the mechanical design set by the bike frame. Also we use Japanese and Korean battery cells as our first priority. </p>
                                        </div>
                                    </li>
                                    <li :class="chosenDot_1 === 3 ? 'active' : ''">
                                        <h4 class="ebike-accordion__title w-100" @click="openPopup_1(3)">
                                            <span class="mb-0 font-weight-bold">Helios Basic</span>
                                        </h4>
                                        <div class="ebike-accordion__content">
                                            <p>Helios Basic offers a fully connected e-bike experience. Via the arion APP, the city bike accesses the digital world, and provide riders better riding experience.</p>
                                        </div>
                                    </li>
                                    <li :class="chosenDot_1 === 4 ? 'active' : ''">
                                        <h4 class="ebike-accordion__title w-100" @click="openPopup_1(4)">
                                            <span class="mb-0 font-weight-bold">Chariot R250-CR</span>
                                        </h4>
                                        <div class="ebike-accordion__content">
                                            <p>Small, light-weight, high-efficiency and smooth, Chariot City is a rear hub drive engine easy to assemble and to repair that is perfect for city e-bikes. With up to 30 Nm up to a maximum of 25 km/h, the drive unit offers easy and agile handling of the e-bike. </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="ebike-accordion--2 py-6">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-md-6 order-last order-md-first mt-5 mt-sm-0">
                            <h3 class="ebike-accordion__heading">Mid-motor Solution</h3>
                            <div class="col-12 mb-0 p-0 h4">
                                <ul class="ebike-accordion accordion--right accordion-2 ">
                                    <li :class="chosenDot_2 === 1 ? 'active' : ''">
                                        <h4 class="ebike-accordion__title w-100" @click="openPopup_2(1)">
                                            <span class="mb-0 font-weight-bold">Interconnect planning</span>
                                        </h4>
                                        <div class="ebike-accordion__content">
                                            <p>SINBON offers customization service to help solve waterproof and vibration problems.  Our in-house raw cable plant makes fast and rapid cable prototyping possible.  The SINBON design team will co-work with the frame designer and assembly manufacturer to think about how cable can be hidden so exposure of cabling can be kept at a minimum.</p>
                                        </div>
                                    </li>
                                    <li :class="chosenDot_2 === 2 ? 'active' : ''">
                                        <h4 class="ebike-accordion__title w-100" @click="openPopup_2(2)">
                                            <span class="mb-0 font-weight-bold">Helios Battery Solution - Helios 252</span>
                                        </h4>
                                        <div class="ebike-accordion__content">
                                            <p>SINBON e-bike Helios battery solution can be customized in dimensions, including in-tube, in-frame, down tube, rear rack and rear rack with light, to fit the mechanical design set by the bike frame. Also we use Japanese and Korean battery cells as our first priority. </p>
                                        </div>
                                    </li>
                                    <li :class="chosenDot_2 === 3 ? 'active' : ''">
                                        <h4 class="ebike-accordion__title w-100" @click="openPopup_2(3)">
                                            <span class="mb-0 font-weight-bold">Helios Basic</span>
                                        </h4>
                                        <div class="ebike-accordion__content">
                                            <p>Helios Basic offers a fully connected e-bike experience. Via the arion APP, the city bike accesses the digital world, and provide riders better riding experience.</p>
                                        </div>
                                    </li>
                                    <li :class="chosenDot_2 === 4 ? 'active' : ''">
                                        <h4 class="ebike-accordion__title w-100" @click="openPopup_2(4)">
                                            <span class="mb-0 font-weight-bold">Chariot M25-CR</span>
                                        </h4>
                                        <div class="ebike-accordion__content">
                                            <p>Chariot Road is a best-in-class mid motor providing max torque force up to 60 Nm when in turbo mode. This drive unit was developed to deliver dynamic support for everyday use or leisure cycling, bringing a balanced mixture of riding enjoyment. </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                    </div>
                    <div class="col-md-6 order-first order-md-last p-0 px-sm-3">
                        <div class="product-ebike__detail">
                            <div @click="openPopup_2(1)" :class="chosenDot_2 === 1 ? 'active' : ''" class="product-ebike__dotWrap dot dot-5">
                                <div class="product-ebike__detailDot"></div>
                                <!-- <div class="product-ebike__unitPic"><img class="w-100" :src="$data.siteUrl + '/img/ebike_helios_Basic.png'" /></div> -->
                            </div>
                            <div @click="openPopup_2(2)" :class="chosenDot_2 === 2 ? 'active' : ''" class="product-ebike__dotWrap dot dot-6">
                                <div class="product-ebike__detailDot"></div>
                                <div class="product-ebike__unitPic"><img class="w-100" :src="$data.siteUrl + '/img/ebike_battery.png'" /></div>
                            </div>
                            <div @click="openPopup_2(3)" :class="chosenDot_2 === 3 ? 'active' : ''" class="product-ebike__dotWrap dot dot-7">
                                <div class="product-ebike__detailDot"></div>
                                <div class="product-ebike__unitPic"><img class="w-100" :src="$data.siteUrl + '/img/ebike_helios_Basic.png'" /></div>
                            </div>
                            <div @click="openPopup_2(4)" :class="chosenDot_2 === 4 ? 'active' : ''" class="product-ebike__dotWrap dot dot-8">
                                <div class="product-ebike__detailDot"></div>
                                <div class="product-ebike__unitPic"><img class="w-100" :src="$data.siteUrl + '/img/ebike_chariot_M25_CR.png'" /></div>
                            </div>

                            
                            <div class="product-ebike__bikeWrap">
                                <img class="w-100 product-ebike__ani" :src="$data.siteUrl + '/img/ebike_prod_ani_2.gif'" v-if="chosenDot_2===1" />
                                <img class="w-100" :src="$data.siteUrl + '/img/ebike_bike_2.png'" />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="ebike-links pb-0">
            <div class="container">
                <div class="row justify-content-between">
                    <!-- <div class="col-sm-6 col-12 px-1">
                        <router-link to="/solution/integrated-solutions/things-you-need-to-know-about-e-mobility-connectivity-solution" class="block">
                            <div class="ebike-links__item feature feature-7 boxed imagebg mb-0" data-overlay="5">
                                <div class="background-image-holder">
                                    <img alt="Putting two puzzles together with sunset pouring in the room" :src="$data.siteUrl + '/img/footer-1.png'" />
                                </div>
                                <h4 class="pos-vertical-center" v-html="$t('ebike_footer_link_1')">Connectivity Solution</h4>
                            </div>
                        </router-link>
                    </div> -->
                    <div class="col-12 py-5">
                        <div class="ebike-links__item boxed imagebg mb-0" data-overlay="5">
                            <div class="background-image-holder">
                                <img alt="" :src="$data.siteUrl + '/img/ebike_solution_link_bg.png'" />
                            </div>
                            <div class="col-md-8 mx-auto">
                                <h4 class="mt-4" v-html="$t('ebike_links_title')">Things you need to know about e-mobility connectivity solution</h4>
                                <router-link v-html="$t('ebike_links_btn')" to="/solution/integrated-solutions/things-you-need-to-know-about-e-mobility-connectivity-solution" class="btn mb-4 type--uppercase h6 font-weight-normal pl-4 pr-4">
                                    READ MORE
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

         <!-- <section class="text-center bg--primary">
          <div class="container">
            <div class="row">
              <div class="col-md-10 mb-3">
                <h3 v-html="$t('ebike_contact_title')">Interest in customizing your E-bike solution?<br>Dont hesitate,contact us now!</h3>
                <div class="row text-center">
                    <div class="col-sm-4">
                        
                        <router-link :to="{name:'solution',query:{ cate:'all',page:'1'}}" class="block">READMORE</router-link>
                        https://www.sinbon.com/solution/integrated-solutions/things-you-need-to-know-about-e-mobility-connectivity-s
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section> -->

        <section class="text-center bg--primary">
          <div class="container">
            <div class="row">
              <div class="col-md-10 mb-3">
                <h3 v-html="$t('ebike_contact_title')">Interest in customizing your E-bike solution?<br>Dont hesitate,contact us now!</h3>
                <div class="row text-center">
                    <div class="col-sm-4">
                        <input
                            type="submit"
                            data-notification-link="contact"
                            :value="$t('ev_contact_btn')/*聯絡我們*/"
                            class="btn btn--primary type--uppercase inner-link text-secondary"
                        >
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
    
</template>
<script>
    import {setMetaInfo} from "@/libs/js/metaInfo.js";
    import fluidPlayer from 'fluid-player';
    export default {
        data:function(){
            return {
                popupNum: '',
                meta:{
                    title: 'E-bike Customizable Rear Hub/Mid Drive Motor Solution',
                    desc: 'SINBON offers E-bike rear hub motor and mid-drive motor solutions. We provide a full range of customized and integrated services.',
                    img: location.protocol +'//'+ location.host + '/img/ebike_main.jpg'
                },
                metaData:{},
                player: null,
                playVideo: false,
                chosenDot_1: 1,
                chosenDot_2: 1
            }
        },
        metaInfo() {
            return this.metaData
        },
        methods:{
            openPopup_1:function(num){
                this.chosenDot_1 = num;
            },
            openPopup_2:function(num){
                this.chosenDot_2 = num;
            },
            initPlayer(){
                this.player = fluidPlayer( this.$refs.ebikeVideo, {
                    layoutControls: {
                        fillToContainer: true,
                        preload: 'auto'
                    },
                });
            },
            hideVideoCover(){
                this.playVideo = true;
                this.player.play();
            }
        },
        components: {
        },
        created() {
            // this.$window.initAll()
            setMetaInfo( this, this.meta.title, this.meta.url, this.meta.desc, this.meta.img);
        },
        mounted(){
            $('.ebike-accordion__title').on('click', function () {
                $(this).parents('li').addClass('active').siblings().removeClass('active');
            });

            this.initPlayer();
            this.$toggleLoading(false);
        }
    };

</script>
<style scoped>
    @import "~fluid-player/src/css/fluidplayer.css";
</style>