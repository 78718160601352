import {defaultLang} from "@/static/langs.json"
import {readCookie} from "@/libs/js/cookie.js"

// function getCookie(cname) {
//     var name = cname + "=";
//     var decodedCookie = decodeURIComponent(document.cookie);
//     var ca = decodedCookie.split(';');
//     for(var i = 0; i <ca.length; i++) {
//         var c = ca[i];
//         while (c.charAt(0) == ' ') {
//             c = c.substring(1);
//         }
//         if (c.indexOf(name) == 0) {
//             return c.substring(name.length, c.length);
//         }
//     }
//     return "";
// }

// var lang=getCookie("lang_code") ? getCookie("lang_code") : "";
var lang=defaultLang;
if (readCookie('lang_code') != undefined || readCookie('lang_code') != null) lang = readCookie('lang_code');
// Get lang code redirect from backend. @CLHOM
if (AUTO_LANGUAGE != undefined) lang = AUTO_LANGUAGE;

// var apibase
// if(window.location.hostname=='localhost') apibase = "http://sinbon.kiiiick.com/"+lang+"/api/";
// else apibase = "/"+lang+"/api/";

// var apibase = "/" + lang + "/api/";
var apibase = BASE_URI + 'api/';

export default apibase