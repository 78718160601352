<template>
	<div v-if="items.length > 0">
    <section class="text-center">
      <div class="container text-left">
        <div class="col mb-4">
          <h4 v-if="type=='solution'" v-html="$t('recommends_solutions')">其他解決方案</h4>
          <h4 v-if="type=='casestudy'" v-html="$t('recommends_casestudy')">其他案例分享</h4>
          <h4 v-if="type=='blog'" v-html="$t('recommends_blog')">其他文章</h4>
        </div>

        <!-- <div class="row"> -->
          <div class="col">
            <div id="js-recommend-item" class="swiper recommend-swiper">
              <div class="swiper-wrapper">
                <div v-for="item in items" class="swiper-slide">
                  <div class="product">
                    <router-link :to="{ name: type+'-detail', params: { category:item.category_slug,slug:item.slug } }">
                      <div>
                        <img class="border--round" :src="item.cover" :alt="item.name" />
                      </div>
                      <h5>{{ item.name }}</h5>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>

              <div class="swiper-pagination"></div>
            </div>
          </div>
        <!-- </div> -->
      </div>
    </section>
  </div>
</template>

<script>
	export default {
    props: ['items','type'],

    data() {
      return {
        swiper: null
      }
    },

    methods: {
      initSwiper() {
        this.swiper = new Swiper('#js-recommend-item', {
          spaceBetween: 30,

          navigation: {
            nextEl: '#js-recommend-item .swiper-button-next',
            prevEl: '#js-recommend-item .swiper-button-prev',
          },

          pagination: {
            el: '#js-recommend-item .swiper-pagination',
            clickable: true
          },

          breakpoints: {
            640: {
              slidesPerView: 2,
              spaceBetween: 30
            },

            768: {
              slidesPerView: 3,
              spaceBetween: 30
            }
          }
        });
      }
    },

    mounted() {
      this.initSwiper();
    }
  };
</script>