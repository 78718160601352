<template>
     <div id="searchForm" class="d-flex notification pos-bottom notification--reveal notification--dismissed m-0 p-0 h-100 " data-animation="from-bottom" data-notification-link="search" >
        <div  class=" container searchBox col-10 col-sm-9 align-self-center ml-auto mr-auto p-0">
            <div class="feature feature-1 mb-0">
                <div class="feature__body boxed boxed--lg boxed--border ">
                    <!-- <div class="text-block">
                        <div class="row">
                            <h3 class="col" v-html="$t('nav_searchSite')">搜尋</h3>
                        </div>
                    </div> -->
                    <h3 class="notification-title" v-html="$t('nav_searchSite')">搜尋</h3>
                    <form class="text-left my-0" @submit.prevent="searchSite">
                        <div class="row">
                            <div class="col-12 col-md-9 pr-0 mb-md-0">
                                <input v-model="keyword" type="search"/>
                            </div>
                            <div class="col-12 col-md-3">
                                 <button type="submit" class="btn btn--primary type--uppercase" v-html="$t('nav_search')">搜尋</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="notification-close-cross notification-close"></div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    export default {
        data:function(){
            return{
                keyword:''
            }
        },
        methods:{
            ...mapGetters(["getLang"]),
            searchSite:function(){
                let langUrl = this.getLang() != 'en' ? this.getLang()+'/' : ''
                // console.log( 'URL', location.protocol +'//'+ location.host + '/'+langUrl+'search-result/?q='+this.keyword );
                if(this.keyword.trim()!='') location.href=location.protocol +'//'+ location.host + '/'+langUrl+'search-result/?q='+this.keyword;
            }
        },
        created() {
        },
        watch: {
            '$route' (to, from) {
                if(!$('#searchForm').hasClass('notification--dismissed')) $('#searchForm').addClass('notification--dismissed');
            }
        },
    };

</script>